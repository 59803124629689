import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { baseURL, exchangeCurrency, exchangeRate } from "../../../../../App";
import "./LargeEventCard.css";

function LargeEventCard({ eventData, extraCardDetails }) {
  const [times, updateTimes] = useState([]);
  const {
    description,
    eventCost,
    eventDates,
    eventImage,
    guruId,
    guruImage,
    guruName,
    guruSpecialization,
    name,
    noOfSeats,
    noOfRemainingSeats,
    status,
    _id,
  } = eventData;

  const history = useHistory();
  const timeZone = localStorage.getItem("timeZone");

  useEffect(() => {
    if (eventDates) {
      const allTimes = [];
      eventDates.forEach((date) => {
        return date.times.map((time) => allTimes.push(time));
      });
      updateTimes(allTimes);
    }
  }, [eventDates]);

  return (
    <div>
      <div className="event-card-lg m-3 d-flex flex-column justify-content-center flex-1 ">
        {/* Event Dates */}
        <div className="date-container">
          {times?.map((date, index) => {
            if (index > 3) return <></>;
            else
              return (
                <div key={index} className="date-item">
                  {index === 3 ? (
                    <span
                      className="cursorPointer "
                      onClick={() => {
                        history.push(`/eventDetails?id=${_id}`, { id: _id });
                      }}
                    >
                      All event days
                    </span>
                  ) : (
                    <span className="">
                      {moment(date.startDate)
                        .tz(timeZone)
                        .format("DD-MM-YYYY hh:mm a")}
                    </span>
                  )}
                </div>
              );
          })}
        </div>

        <div
          className="event-container d-flex"
          style={{ position: "relative" }}
        >
          <div className="d-flex w-100">
            <figure className="banner-container">
              <img src={`${baseURL}/${eventImage}`} alt="wallpaper" />
            </figure>
            <div className="event-content-container p-3 flex-1 d-flex flex-column justify-content-between align-items-between">
              {/* <div>
                <div> */}
              {/* Status */}
              <div className="event-status d-flex justify-content-between flex-1">
                <div className="text-center">
                  {extraCardDetails &&
                    noOfRemainingSeats <= 5 &&
                    noOfRemainingSeats !== 0 && (
                      <div className="lastSeatsAlert fw-700 f-18">
                        {noOfRemainingSeats} seats left
                      </div>
                    )}
                  {extraCardDetails && noOfRemainingSeats === 0 && (
                    <p className="lastSeatsAlert fw-700 f-18 m-none">
                      Completed
                    </p>
                  )}
                  <div className="mainColor fw-500 f-18">{status}</div>
                </div>
              </div>
              <div>
                <h3 className="event-name">{name}</h3>
                <div className="event-description">
                  {description?.split("")?.length > 50
                    ? description.substr(0, 50) + "..."
                    : description}
                  {/* {description} */}
                </div>
                <div className="guru-details d-flex align-items-center">
                  <img
                    src={`${baseURL}/${guruImage}`}
                    alt="avatar"
                    className="avatar"
                  />
                  <div className="d-flex flex-1 align-items-end justify-content-between">
                    <div>
                      <div className="guru-name mainColor f-18 fw-500">
                        {guruName}
                      </div>
                      <div className="fw-500">{guruSpecialization}</div>
                    </div>
                    <div className="f-20 fw-500">
                      {exchangeCurrency}
                      {Math.ceil(eventCost * exchangeRate)}/hr
                    </div>
                  </div>
                </div>
                {/* </div>
                </div> */}
              </div>

              <div>
                <div className="divider"></div>
                <div className="text-right">
                  <button
                    className="primary-btn py-2 mt-3"
                    onClick={() => {
                      history.push(`/eventDetails?id=${_id}`, { id: _id });
                    }}
                  >
                    View Event
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    extraCardDetails: state.card.extraCardDetails,
  };
};

export default connect(mapStateToProps)(LargeEventCard);
