import React, { useState, useEffect } from "react";
import { Input, Modal } from "antd";
import OvalFilledCircle from "../svgLogos/OvalFilledCircle";
import WrongEmailCheck from "../svgLogos/WrongEmailCheck";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Alert } from "react-bootstrap";
import axios from "axios";
import { baseURL } from "../../App";
import { connect } from "react-redux";
import { AuthState } from "../../Store/Actions/AuthActions";
import "./ChangeEmailModal.css";

function ChangeEmailModal({ toggleModaal, ModalVisible, auth, AuthState }) {
	const [password, setPassword] = useState("");
	const [email, setEmail] = useState("");
	const [readOnly, setReadOnly] = useState(true);
	const [errorSign, setErrorSign] = useState(false);
	const errMsg = "*";
	const [emailCheck, setEmailCheck] = useState(false);
	const [successSign, setSuccessSign] = useState(null);
	const [isValidEmail, updateIsValidEmail] = useState(false);
	const handleChange = (e) => {
		const id = e.target.id;
		if (id === "email") {
			setEmail(e.target.value);
			const isValidEmailVar =
				/^[_A-Za-z0-9-+]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$/.test(
					e.target.value
				);
			//handleEmailCheck();
			updateIsValidEmail(isValidEmailVar);
		} else if (id === "password") {
			setPassword(e.target.value);
		}
	};

	useEffect(() => {
		const sessionCountdownNode = document.querySelector(".sessionCountdown");
		if (sessionCountdownNode) {
			sessionCountdownNode.classList.add("d-none");
		}
		return () => {
			if (sessionCountdownNode) {
				sessionCountdownNode.classList.remove("d-none");
			}
		};
	}, []);
	const handleEmailCheck = () => {
		//setReadOnly(true);
		axios({
			method: "post",
			url: baseURL + "/account/checkEmailExist",
			data: {
				email: email.toLowerCase(),
				userType: auth.authData.userType,
			},
		}).then((res) => {
			const isEmailNotValid = res.data.exist || !isValidEmail;
			setEmailCheck(isEmailNotValid);
			//setReadOnly(false);
		});
	};

	const sendData = (e) => {
		e.preventDefault();
		if (password.length === 0 || email.length === 0) {
			setErrorSign("Please Fill all the required fields");
		} else if (emailCheck) {
		} else {
			axios({
				method: "post",
				url: baseURL + "/coache/EditPersonalInfo",
				data: {
					firstName: auth.authData.user.firstName,
					lastName: auth.authData.user.lastName,
					fullName:
						auth.authData.user.firstName + " " + auth.authData.user.lastName,
					email: email.toLowerCase(),
					phone: auth.authData.user.phone,
					oldPassword: password,
					userType: auth.authData.userType,
					id: auth.authData.user._id,
				},
			})
				.then((res) => {
					if (res.data.status === 1 || res.data.status === 200) {
						auth.authData.user.email = res.data.user.email;
						setSuccessSign("Updated Successfully");
						setErrorSign(false);
						setTimeout(() => {
							AuthState(auth.authData);
							toggleModaal();
						}, 3000);
						// window.location.reload();
					}
				})
				.catch((err) => {
					setErrorSign(err.response.data.message);
				});
		}
	};

	return (
		<div className="changeEmail">
			<Modal
				visible={ModalVisible}
				onCancel={() => {
					setPassword("");
					setEmail("");
					toggleModaal();
				}}
				footer={[]}
				className="changeEmail"
				width={"30%"}
				style={{ borderRadius: "150px" }}
				centered>
				<form onSubmit={sendData}>
					<label>Email</label>
					{email === "" && <span className="text-danger">{errMsg} </span>}
					<div className="emailContainer">
						<Input
							id="email"
							type="email"
							onChange={handleChange}
							autoComplete="off"
							value={email}
							readOnly={readOnly}
							onBlur={handleEmailCheck}
							onFocus={() => setReadOnly(false)}
						/>
						<span className="emailCheckSign">
							{email !== "" &&
								(emailCheck === false ? (
									<span className="right">
										<OvalFilledCircle />
									</span>
								) : emailCheck === true ? (
									<span className="wrong">
										<WrongEmailCheck />
									</span>
								) : (
									""
								))}
						</span>
					</div>
					{emailCheck && email.length > 0 && (
						<Alert className="text-center my-2" variant="danger">
							Please Enter a Valid Email
						</Alert>
					)}
					<p style={{ color: "#CFA79D", marginBottom: "0", marginTop: "1rem" }}>
						We need to make sure it's really you before we make this change,
						please confirm your password
					</p>
					<label>Password</label>
					{password === "" && <span className="text-danger">{errMsg} </span>}
					<Input.Password
						id="password"
						type="password"
						onChange={handleChange}
						value={password}
						iconRender={(visible) =>
							visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
						}
					/>
					{errorSign && (
						<Alert className="text-center mt-2" variant="danger">
							{errorSign}
						</Alert>
					)}
					{successSign && (
						<Alert className="text-center mt-2" variant="success">
							{successSign}
						</Alert>
					)}
					<div className="text-center">
						<button className="btn">Save</button>
					</div>
				</form>
			</Modal>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		AuthState: (auth) => dispatch(AuthState(auth)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(ChangeEmailModal);
