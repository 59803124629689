import React, { Component } from "react";
import MyReviewsIcon from "../../Components/svgLogos/AccountPageFigures/MyReviews.svg";
import ReviewBox from "../../Components/ReviewBox/ReviewBox ";
import { connect } from "react-redux";
import axios from "axios";
import { baseURL } from "../../App";
import "./AccountMyReviews.css";
export class AccountMyReviews extends Component {
	state = {
		reviews: [],
	};
	componentDidMount() {
		axios({
			method: "get",
			url:
				baseURL +
				"/coache/CoachReviews?coachId=" +
				this.props.auth.authData.user._id,
		}).then((res) => {
			this.setState({ reviews: res.data.data });
		});
	}
	render() {
		//hello
		return (
			<div className="accountMyReviews">
				<div className="heading">
					<span className="icon">
						<img src={MyReviewsIcon} alt="MyReviewsIcon" />
					</span>
					Reviews
				</div>
				{this.state.reviews.length > 0 ? (
					<div>
						{this.state.reviews.map((review, reviewIndex) => {
							return (
								<div className="form" key={reviewIndex}>
									<ReviewBox review={review} />
								</div>
							);
						})}
					</div>
				) : (
					<div className="reviewConainer">
						<h4 className="text-center">No reviews available to show</h4>
					</div>
				)}
			</div>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	};
};

export default connect(mapStateToProps)(AccountMyReviews);
