import React, { useState, useEffect } from "react";
import Video from "twilio-video";
import Participant from "./Participant";
//import Controls from "./Controls";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import InValidTokenModal from "./InValidTokenModal";
import WaitingRoom from "./WaitingRoom";
import VideoChattingRoom from "./VideoChattingRoom";
import ZoomControls from "./ZoomControls";
//import MicOff from "./assets/icons/mute.svg";
//import { isMobile } from "react-device-detect";
const { createLocalTracks } = require("twilio-video");

const Room = ({
	roomName,
	token,
	sessionDates,
	handleAddExtraMins,
	userType,
	getFinishSessionSign,
	sessionId,
	auth,
}) => {
	const [room, setRoom] = useState(null);
	const [participants, setParticipants] = useState([]);
	const [toggleAudio, setToggleAudio] = useState(true);
	const [toggleVideo, setToggleVideo] = useState(true);
	const [callState, toggleCallState] = useState(false);
	const [isValidToken, updateIsValidToken] = useState(true);
	const [joinRoom, updateJoinRoom] = useState(false);
	const [remoteName, updateRemoteName] = useState("");
	const [chatState, toggleChatState] = useState(false);
	const [hasUnreadMsg, updateHasUnreadMsg] = useState(null);
	const [errorMessage, updateErrorMessage] = useState(null);
	let upcommingBoxNode = document.querySelector("#app>.nextSessionCounterBox");

	useEffect(() => {
		if (upcommingBoxNode) {
			upcommingBoxNode.classList.add("d-none");

			return () => {
				upcommingBoxNode.classList.remove("d-none");
			};
		}
	}, [upcommingBoxNode]);
	const participantConnected = (participant) => {
		setParticipants((prevParticipants) => [...prevParticipants, participant]);
	};

	const participantDisconnected = (participant) => {
		setParticipants((prevParticipants) =>
			prevParticipants.filter((p) => p !== participant)
		);
	};
	const handleCallConnect = () => {
		let isVisited = false;
		if (!isVisited) {
			Video.connect(token, {
				name: roomName,
			})
				.then((room) => {
					setRoom(room);
					room.on("participantConnected", participantConnected);
					room.on("participantDisconnected", participantDisconnected);
					room.participants.forEach(participantConnected);
					if (joinRoom) {
						handleVideoToggle();
						handleAudioToggle();
						setToggleVideo(true);
						setToggleAudio(true);
					} else {
						room.localParticipant.videoTracks.forEach((track) => {
							track.track.disable();
							setToggleVideo(track.track.isEnabled);
						});
						room.localParticipant.audioTracks.forEach((track) => {
							track.track.disable();
							setToggleAudio(track.track.isEnabled);
						});
					}
				})
				.catch((err) => {
					if (err.message && err.message.includes("Permission denied")) {
						updateIsValidToken(false);
						updateErrorMessage(
							"Please give the browser the access to your camera and microphone. And then reload the page"
						);
					} else {
						if (err.code === 20104) {
							updateIsValidToken(false);
							updateErrorMessage("Please only visit the link on session time");
						}
					}
				});
			return () => {
				setRoom((currentRoom) => {
					if (
						currentRoom &&
						currentRoom.localParticipant.state === "connected"
					) {
						currentRoom.localParticipant.tracks.forEach(function (
							trackPublication
						) {
							trackPublication.track.stop();
						});
						currentRoom.disconnect();
						return null;
					} else {
						return currentRoom;
					}
				});
			};
		}
	};
	useEffect(() => {
		if (roomName && token && joinRoom) {
			handleCallConnect();
		}
		// eslint-disable-next-line
	}, [roomName, token, joinRoom]);

	const [audioInputChannel, updateAudioInputChannel] = useState(null);
	const [videoChannel, updateVideoChannel] = useState(null);
	const handleChangeChannels = (inputType, device) => {
		if (inputType === "audioInput") {
			updateAudioInputChannel({ deviceId: device.deviceId });
		} else {
			updateVideoChannel({
				deviceId: device.deviceId,
			});
		}
	};
	useEffect(() => {
		if (audioInputChannel || videoChannel) {
			createLocalTracks({
				audio: audioInputChannel ? audioInputChannel : true,
				video: videoChannel ? videoChannel : { width: 640 },
			})
				.then((localTracks) => {
					let localTracksArr = [...localTracks];
					let localAudioTrack = localTracksArr[0];
					let localVideoTrack = localTracks[1];
					if (!toggleAudio && !toggleVideo) {
						localAudioTrack.disable();
						localVideoTrack.disable();
					} else if (!toggleAudio) {
						localAudioTrack.disable();
					} else if (!toggleVideo) {
						localVideoTrack.disable();
					} else {
						console.log("No disable");
					}
					localTracksArr = [localAudioTrack, localVideoTrack];
					return Video.connect(token, {
						name: roomName,
						tracks: localTracksArr,
					});
				})
				.then((room) => {
					setRoom(room);
					room.on("participantConnected", participantConnected);
					room.on("participantDisconnected", participantDisconnected);
					room.participants.forEach(participantConnected);
					if (joinRoom) {
						handleVideoToggle();
						handleAudioToggle();
						setToggleVideo(true);
						setToggleAudio(true);
					} else {
						room.localParticipant.videoTracks.forEach((track) => {
							track.track.disable();
							setToggleVideo(track.track.isEnabled);
						});
						room.localParticipant.audioTracks.forEach((track) => {
							track.track.disable();
							setToggleAudio(track.track.isEnabled);
						});
					}
				});
		}
		// eslint-disable-next-line
	}, [audioInputChannel, videoChannel]);

	const handleCallDisconnect = () => {
		if (room) {
			room.disconnect();
		}
	};

	const handleAudioToggle = () => {
		if (room) {
			room.localParticipant.audioTracks.forEach((track) => {
				if (track.track.isEnabled) {
					track.track.disable();
				} else {
					track.track.enable();
				}
				setToggleAudio(track.track.isEnabled);
			});
		}
	};

	const handleVideoToggle = () => {
		if (room) {
			room.localParticipant.videoTracks.forEach((track) => {
				if (track.track.isEnabled) {
					track.track.disable();
				} else {
					track.track.enable();
				}
				setToggleVideo(track.track.isEnabled);
			});
		}
	};

	const remoteParticipants = participants.map((participant) => (
		<Participant
			key={participant.sid}
			participant={participant}
			remoteName={remoteName}
			isRemote={true}
		/>
	));

	const getRemoteUserName = (clientDetails, guruDetails) => {
		if (auth.authData.userType === 1) {
			updateRemoteName(guruDetails.firstName);
		} else {
			updateRemoteName(clientDetails.firstName);
		}
	};
	useEffect(() => {
		window.addEventListener("beforeunload", alertUser);
		window.addEventListener("unload", handleCallDisconnect);
		//window.addEventListener("pagehide", handleCallDisconnect);
		window.addEventListener(
			"pagehide",
			(event) => {
				if (!event.persisted) {
					handleCallDisconnect();
					/* the page isn't being discarded, so it can be reused later */
				}
			},
			false
		);
		return () => {
			window.removeEventListener("beforeunload", alertUser);
			window.removeEventListener("unload", handleCallDisconnect);
			//	window.addEventListener("pagehide", handleCallDisconnect);
			window.removeEventListener(
				"pagehide",
				(event) => {
					if (!event.persisted) {
						handleCallDisconnect();
						/* the page isn't being discarded, so it can be reused later */
					}
				},
				false
			);
		};
	});

	const alertUser = (event) => {
		event.preventDefaut();
		return (event.returnValue = false);
	};

	if (callState) {
		return <Redirect to="/mySessions" />;
	}
	return (
		<div className="room">
			<InValidTokenModal
				// isModalVisible={!isValidToken && joinRoom}
				isModalVisible={!isValidToken}
				onCancel={() => {
					toggleCallState(true);
				}}
				errorMessage={errorMessage}
			/>
			<div>
				{!joinRoom ? (
					<WaitingRoom
						sessionId={sessionId}
						isRemotePartOnline={remoteParticipants.length > 0}
						remoteParticipants={remoteParticipants}
						handleJoinRoom={(val) => {
							updateJoinRoom(val);
						}}
						getRemoteUserName={getRemoteUserName}
					/>
				) : (
					<div>
						{remoteParticipants.length ? (
							<div className="remote-participants">
								{remoteParticipants[remoteParticipants.length - 1]}
							</div>
						) : (
							<div className="remote-participants">
								Waiting for other user to Join
							</div>
						)}

						<div className="local-participant">
							{room ? (
								<div className="participantRoom">
									<Participant
										key={room.localParticipant.sid}
										participant={room.localParticipant}
										handleAudioToggle={handleAudioToggle}
										handleVideoToggle={handleVideoToggle}
										handleCallDisconnect={handleCallDisconnect}
										toggleAudio={toggleAudio}
										toggleVideo={toggleVideo}
										sessionDates={sessionDates}
										handleAddExtraMins={handleAddExtraMins}
										userType={userType}
										getFinishSessionSign={getFinishSessionSign}
										remoteName={auth.authData.user.firstName}
									/>
								</div>
							) : (
								""
							)}
							{/* <Controls
								handleCallDisconnect={handleCallDisconnect}
								handleAudioToggle={handleAudioToggle}
								handleVideoToggle={handleVideoToggle}
								audio={toggleAudio}
								video={toggleVideo}
								handleChangeChannels={handleChangeChannels}
								toggleChatStateFun={() => { toggleChatState(!chatState) }}
								hasUnreadMsg={hasUnreadMsg}
							/> */}
							<ZoomControls
								handleCallDisconnect={handleCallDisconnect}
								handleAudioToggle={handleAudioToggle}
								handleVideoToggle={handleVideoToggle}
								audio={toggleAudio}
								video={toggleVideo}
								handleChangeChannels={handleChangeChannels}
								toggleChatStateFun={() => {
									toggleChatState(!chatState);
								}}
								hasUnreadMsg={hasUnreadMsg}
								remoteName={remoteName}
							/>
							<VideoChattingRoom
								sessionId={sessionId}
								toggleChatStateFun={() => {
									toggleChatState(!chatState);
								}}
								chatState={chatState}
								getUnreadMsgState={(val) => {
									updateHasUnreadMsg(val);
								}}
							/>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};
const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	};
};
export default connect(mapStateToProps)(Room);
