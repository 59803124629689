import React from "react";

function ChevronLeft() {
	return (
		<svg
			id="chevron-left"
			xmlns="http://www.w3.org/2000/svg"
			width="8.485"
			height="14.142"
			viewBox="0 0 8.485 14.142"
		>
			<path
				id="chevron-left-2"
				data-name="chevron-left"
				d="M16.243,6.343,14.828,4.929,7.757,12l7.071,7.071,1.414-1.414L10.586,12Z"
				transform="translate(-7.757 -4.929)"
				fill="#CFA79D"
			/>
		</svg>
	);
}

export default ChevronLeft;
