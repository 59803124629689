import React, { Component } from "react";
import FilledStar from "../../Components/svgLogos/FilledStar";
import EmptyStar from "../../Components/svgLogos/EmptyStar";
import moment from "moment";
import "./ReviewBox.css";
export class ReviewBox extends Component {
	render(props) {
		let filledString = [];
		let emptyString = [];
		filledString.length = this.props.review.rate;
		emptyString.length = 5 - this.props.review.rate;
		filledString.fill("filled");
		emptyString.fill("empty");

		return (
			<div className="reviewBox">
				<div className="header">{this.props.review.title}</div>
				<p>
					{this.props.review.comment.length === 0
						? this.props.review.report
						: this.props.review.comment}
				</p>
				<div className="clientInfo">
					<span>
						{this.props.review.isAnonymous
							? "Anonymous"
							: this.props.review.clientName[0].fullName}
					</span>
					<span>{moment(this.props.review.createdAt).format("LL")}</span>
					<span className="starContainer">
						{filledString.map((filledStar) => {
							return (
								<span className="star">
									<FilledStar />
								</span>
							);
						})}
						{emptyString.map((emptyStar) => {
							return (
								<span className="star">
									<EmptyStar />
								</span>
							);
						})}
					</span>
				</div>
			</div>
		);
	}
}

export default ReviewBox;
