import React from "react";

function ChevronRight() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="8.485"
			height="14.142"
			viewBox="0 0 8.485 14.142"
		>
			<path
				id="chevron-left"
				d="M7.757,6.343,9.172,4.929,16.243,12,9.172,19.071,7.757,17.657,13.414,12Z"
				transform="translate(-7.757 -4.929)"
				fill="#CFA79D"
			/>
		</svg>
	);
}

export default ChevronRight;
