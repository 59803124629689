import React, { Component } from "react";
class OvalFilledCircleSm extends Component {
	render() {
		return (
			<svg
				id="Atoms_Selectors_Selected"
				data-name="Atoms/ Selectors/ Selected"
				xmlns="http://www.w3.org/2000/svg"
				width="23.187"
				height="23.187"
				viewBox="0 0 23.187 23.187"
			>
				<circle id="Oval" cx="11.593" cy="11.593" r="11.593" fill="#CFA79D" />
				<path
					id="Tick_Right"
					data-name="Tick Right"
					d="M8.206.25A.852.852,0,0,1,9.412,1.455L4.3,6.57a.852.852,0,0,1-1.206,0L.25,3.728A.852.852,0,0,1,1.455,2.523L3.694,4.762Z"
					transform="translate(6.763 8.212)"
					fill="#fff"
				/>
			</svg>
		);
	}
}

export default OvalFilledCircleSm;
