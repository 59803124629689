import React, { useState, useEffect } from "react";
import { Modal } from "antd";

function InValidTokenModal({ isModalVisible, onCancel, errorMessage }) {
	const [messageArr, updateMessageArr] = useState([])
	useEffect(() => {
		if (errorMessage) {
			updateMessageArr(errorMessage.split("."))
		}
	}, [errorMessage])

	return (
		<Modal
			title="Welo"
			visible={isModalVisible}
			onCancel={onCancel}
			className="InvalidtokenModal text-center"
		>
			{messageArr.map((message) => {
				return <div>{message}</div>
			})}
		</Modal>
	);
}

export default InValidTokenModal;
