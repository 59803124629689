import React, { Component } from "react";
//import LogoLg from "../../Components/svgLogos/LogoLg";
import Logo from "../../Assets/FooterLogo.svg";
import FaceBook from "../../Components/svgLogos/FaceBook";
import Instagram from "../../Components/svgLogos/Instagram";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Email from "../../Assets/email.svg";
import Whatsapp from "../../Assets/whatsapp.svg";
import "./Footer.css";
class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-7">
              <img src={Logo} alt="Logo" className="logo mb-4" />

              <ul className="list-unstyled d-md-none">
                <li className="FooterItems">
                  <Link to="/">Find a Guru</Link>
                </li>
                {/* <li className="FooterItems">
									<Link to="/team">Our Team</Link>
								</li> */}
                <li className="FooterItems">
                  <Link to="/contact">Contact Us</Link>
                </li>{" "}
                <li className="FooterItems">
                  <Link to="/termsandconditions">Terms And Conditions</Link>
                </li>
                <li className="FooterItems">
                  <Link to="/privacyPolicy">Privacy Policy</Link>
                </li>
              </ul>
              <div className="d-flex">
                <span>
                  <a
                    href="https://www.facebook.com/WELOApp-105398001345973/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaceBook />
                  </a>
                </span>
                <span className="socialMediaIcon">
                  <a
                    href="https://instagram.com/welo.app_?utm_medium=copy_link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Instagram />
                  </a>
                </span>
              </div>

              <div className="copyRight">
                &#169;WeLO {new Date().getFullYear()}
              </div>
            </div>
            <div className="col-5">
              <div className="row">
                <div className="col-6">
                  {/*
									<div className="title">Legal</div>
									<ul className="list-unstyled">
										<li className="FooterItems">Imprint</li>
										<li className="FooterItems">Cookies</li>
										<li className="FooterItems">Security</li>
										<li className="FooterItems">Privacy Policy</li>
										<li className="FooterItems">Terms Of Use</li>
										<li className="FooterItems">Cookie Settings</li>
									</ul>*/}
                </div>
                <div className="col-6 d-none d-md-block">
                  <ul className="list-unstyled">
                    <li className="FooterItems">
                      <Link to="/">Find a Guru</Link>
                    </li>
                    <li className="FooterItems">
                      <Link to="/team">Our Team</Link>
                    </li>
                    <li className="FooterItems">
                      <Link to="/contact">Contact Us</Link>
                    </li>
                    <li className="FooterItems">
                      <Link
                        to={
                          this.props.auth.authData
                            ? this.props.auth.authData.userType === 1
                              ? "/termsandconditionsforclient"
                              : "/termsandconditionsforguru"
                            : "/termsandconditionsforclient"
                        }
                      >
                        Terms And Conditions
                      </Link>
                    </li>
                    <li className="FooterItems">
                      <Link to="/privacyPolicy">Privacy Policy</Link>
                    </li>{" "}
                    <li className="FooterItems">
                      <Link to="/refundPolicy">Refund Policy</Link>
                    </li>
                    <li className="FooterItems my-4">
                      <div className="my-2">
                        <img src={Whatsapp} alt="Whatsapp" className="icon" />
                        <span>+971 58 895 9401</span>
                      </div>
                      <div className="my-2">
                        <img src={Email} alt="email" className="icon" />
                        <span> tee@welo.app</span>
                      </div>
                    </li>
                    {/*	<li className="FooterItems">For Companies</li>
										<li className="FooterItems">Our Story</li>
										<li className="FooterItems">Our Team</li>
										<li className="FooterItems">Get matched</li>
										<li className="FooterItems">FAQ</li>
										<li className="FooterItems">Contact</li>
										<li className="FooterItems">Visit Blog</li>
										*/}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(Footer);
