import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { RegActions } from "../../../Store/Actions/RegActions";
import { Button, Col, Row } from "antd";
import GuestNav from "../../../Components/GuestNav/GuestNav";
import "./UserType.css";

class UserType extends Component {
	state = {
		userType: "1", //1 for users and 2 for coaches
	};
	activeHandler = (e) => {
		this.setState({
			userType: e.target.id,
		});
	};
	handleSubmit = (e) => {
		this.props.RegActions({ userType: this.state.userType });
	};
	componentDidMount() {
		this.props.Reg.userType &&
			this.setState({ userType: this.props.Reg.userType });
	}
	render(props) {
		return (
			<div className="userType page authPage">
				<GuestNav
					progress={this.state.userType === "1" ? 50 : 100 / 6}
					total={this.state.userType === "1" ? 2 : 6}
				/>
				<Row className="flex-1">
					<Col xs={24} md={24} className="d-flex align-items-center">
						{/* <div className="wellcome">
							Welcome to <div>WeLO</div>
						</div> */}
						<div className="userTypeBox authForm fullScreen">
							<div className="hereFor">I’m a .....</div>
							<div
								onClick={this.activeHandler}
								id="1"
								className={
									this.state.userType === "1" ? "client active " : "client"
								}>
								Client
							</div>
							<div
								onClick={this.activeHandler}
								id="2"
								className={
									this.state.userType === "2" ? "coaching active" : "coaching"
								}>
								Guru/Therapist
							</div>

							{/* <Link to="/">
								<button className="cancel btn">Cancel</button>
							</Link>
							<Link to="personalinfo">
								<button onClick={this.handleSubmit} className="btn next">
									Next
								</button>
							</Link> */}

							<div className="nextCancelContainer text-center">
								<Link to="/">
									<Button className="secondary-btn">Back</Button>
								</Link>
								<Link to="personalinfo">
									<Button className="primary-btn" onClick={this.handleSubmit}>
										Next
									</Button>
								</Link>
							</div>
						</div>
					</Col>
					<Col xs={24} md={12} className={`authSolidSection d-none`}></Col>
				</Row>
			</div>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		Reg: state.reg,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		RegActions: (reg) => dispatch(RegActions(reg)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(UserType);
