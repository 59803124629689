import React from 'react'
import { Modal } from 'antd'
import './SigninMessageModal.css'
function SigninMessageModal({ isModalVisible, onCancel, message }) {
    return (
        <Modal
            visible={isModalVisible}
            onCancel={onCancel}
            className="messageModal"
        >
            <div className='msgTXT text-center'>
                {message}
            </div>
        </Modal>
    )
}

export default SigninMessageModal