import React, { useState, useEffect } from "react";
import { Input, Modal } from "antd";
import { Alert } from "react-bootstrap";
import axios from "axios";
import { baseURL } from "../../App";
import { connect } from "react-redux";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { AuthState } from "../../Store/Actions/AuthActions";
import "./ChangePassModal.css";
function ChangePassModal({ isModalVisible, toggleModal, auth }, props) {
	const [password, setPassword] = useState("");
	const [passwordCheck, setPasswordCheck] = useState("");
	const [comfirmPass, setComfirmPass] = useState("");
	const [oldPass, setOldPass] = useState("");
	const [confirmPassFlag, setConfirmPassFlag] = useState(false);
	const [errorMsg, setErrorMsg] = useState(null);
	const [errorSign, setErrorSign] = useState(false);
	const [successMessage, setSuccessMessage] = useState(null);
	const errMsg = "*";
	const handleChange = (e) => {
		const id = e.target.id;
		if (id === "oldPass") {
			setOldPass(e.target.value);
		} else if (id === "newPass") {
			setPassword(e.target.value);
			// props.passwordPass(password)
		} else if (id === "ComfarmPass") {
			setComfirmPass(e.target.value);
		}
	};

	const passValidation = () => {
		var reg = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,}$/;
		var test = reg.test(password);
		if (!test) {
			// this.setState({ passwordCheck: true });
			setPasswordCheck(true);
		} else {
			// this.setState({ passwordCheck: false });
			setPasswordCheck(false);
		}
	};
	const sendData = (e) => {
		e.preventDefault();
		if (
			password === "" ||
			comfirmPass === "" ||
			oldPass === "" ||
			passwordCheck === true ||
			password !== comfirmPass
		) {
			setErrorSign(true);
		} else {
			setErrorSign(false);
			if (auth.authData.userType === 2) {
				axios({
					method: "post",
					url: baseURL + "/coache/EditPersonalInfo",
					data: {
						firstName: auth.authData.user.firstName,
						lastName: auth.authData.user.lastName,
						fullName:
							auth.authData.user.firstName + " " + auth.authData.user.lastName,
						email: auth.authData.user.email.toLowerCase(),
						phone: auth.authData.user.phone,
						oldPassword: oldPass,
						password: password,
						userType: auth.authData.userType,
						id: auth.authData.user._id,
					},
				})
					.then((res) => {
						if (res.data.status === 1 || res.data.status === 200) {
							setComfirmPass("");
							setOldPass("");
							setErrorMsg(null);
							setPassword("");
							setPasswordCheck(false);
							setSuccessMessage(res.data.message);
							setTimeout(() => {
								toggleModal();
								setSuccessMessage(null);
							}, 3000);
						}
					})
					.catch((err) => {
						setErrorMsg(err.response?.data?.message);
					});
			} else {
				axios({
					method: "post",
					url: baseURL + "/coache/EditPersonalInfo",
					data: {
						firstName: auth.authData.user.firstName,
						lastName: auth.authData.user.lastName,
						fullName:
							auth.authData.user.firstName + " " + auth.authData.user.lastName,
						email: auth.authData.user.email.toLowerCase(),
						phone: auth.authData.user.phone,
						oldPassword: oldPass,
						password: password,
						userType: auth.authData.userType,
						id: auth.authData.user._id,
					},
				})
					.then((res) => {
						if (res.data.status === 1 || res.data.status === 200) {
							setComfirmPass("");
							setPasswordCheck(false);
							setSuccessMessage(res.data.message);
							setTimeout(() => {
								toggleModal();
								setSuccessMessage(null);
							}, 3000);
						}
					})
					.catch((e) => {
						setErrorMsg(e.response?.data?.message);
					});
			}
		}
	};
	useEffect(() => {
		const sessionCountdownNode = document.querySelector(".sessionCountdown");
		if (sessionCountdownNode) {
			sessionCountdownNode.classList.add("d-none");
		}
		return () => {
			if (sessionCountdownNode) {
				sessionCountdownNode.classList.remove("d-none");
			}
		};
	}, []);
	return (
		<div className="changePass">
			<Modal
				visible={isModalVisible}
				onCancel={() => {
					setComfirmPass("");
					setOldPass("");
					setPassword("");
					setPasswordCheck(false);
					toggleModal();
				}}
				footer={[]}
				width={"30%"}
				style={{ borderRadius: "150px" }}
				centered>
				<form onSubmit={sendData}>
					<p
						style={{
							color: "#CFA79D",
							marginBottom: "0.5rem",
							marginRight: "15px",
						}}>
						We need to make sure it's really you before we make this change,
						please confirm your password
					</p>
					<label>Old Password</label>
					{oldPass === "" && <span className="text-danger">{errMsg} </span>}
					<Input.Password
						id="oldPass"
						className={oldPass.length === 0 && "required"}
						type="password"
						onChange={handleChange}
						value={oldPass}
						iconRender={(visible) =>
							visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
						}
						onBlur={() => passValidation()}
					/>
					<label>New Password</label>
					{password === "" && <span className="text-danger">{errMsg} </span>}
					<Input.Password
						id="newPass"
						className={password.length === 0 && "required"}
						type="password"
						onChange={handleChange}
						value={password}
						onBlur={() => passValidation()}
						iconRender={(visible) =>
							visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
						}
					/>
					{passwordCheck && password.length > 0 && (
						<Alert className="mt-2 show" variant="danger">
							Password must contain at least one uppercase letter, one lowercase
							letter, one number and must be at least 8 characters.
						</Alert>
					)}
					<label>Confirm Password</label>
					{comfirmPass === "" && <span className="text-danger">{errMsg} </span>}
					<Input.Password
						id="ComfarmPass"
						className={comfirmPass.length === 0 && "required"}
						type="password"
						onChange={handleChange}
						iconRender={(visible) =>
							visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
						}
						value={comfirmPass}
						onBlur={() => setConfirmPassFlag(true)}
					/>
					{password !== comfirmPass &&
						comfirmPass !== "" &&
						password !== "" &&
						confirmPassFlag && (
							<Alert className="mt-2 text-center" variant="danger">
								Passwords don't match!
							</Alert>
						)}
					{errorMsg && (
						<Alert className="mt-2 text-center" variant="danger">
							{errorMsg}
						</Alert>
					)}
					{successMessage && (
						<Alert className="mt-2 text-center" variant="success">
							{successMessage}
						</Alert>
					)}
					{errorSign && (
						<Alert className="text-center mt-2" variant="danger">
							Please Fill All The Required Field
						</Alert>
					)}
					<div className="text-center">
						<button className="btn">Save</button>
					</div>
				</form>
			</Modal>
		</div>
	);
}
const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		AuthState: (auth) => dispatch(AuthState(auth)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(ChangePassModal);
