const initState = {};

export const RegReducer = (state = initState, action) => {
	const RegData = action.reg;
	switch (action.type) {
		case "REGESTRATION":
			return {
				...state,
				...RegData,
			};
		case "REG_COMPLETED":
			return {};
		default:
			return {
				...state,
			};
	}
};
export default RegReducer;
