import React, { Component } from "react";
//import UploadAvatarModal from "../UploadAvatarModal/UploadAvatarModal";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import axios from "axios";
import { baseURL } from "../../App";
import { AuthState } from "../../Store/Actions/AuthActions";
import { Player, BigPlayButton } from "video-react";
import { Link } from "react-router-dom";
import PlayIcon from "../../Components/svgLogos/HomePageFigures/PlayIcon";
import HamburgerIcon from "../../Components/svgLogos/HomePageFigures/HamburgerIcon";
import "./AccountHeader.css";
class AccountHeader extends Component {
	state = {
		uploadAvatarModal: false,
		toggleVideo: false,
		loadingState: false,
		uploadingProgress: 0,
		isModalVisible: false,
	};
	toggleVideoFunc = () => {
		this.setState({ toggleVideo: !this.state.toggleVideo });
	};
	componentDidMount() {}

	render() {
		const handleUploadVideo = (e) => {
			let video = e.target.files[0];

			let videoData = new FormData();
			videoData.append("image", video);
			if (video.type.includes("video")) {
				this.setState({ loadingState: true }, () => {
					axios
						.post(baseURL + "/user/imageUpload", videoData, {
							onUploadProgress: (data) => {
								//Set the progress value to show the progress bar
								this.setState({
									uploadingProgress: Math.ceil(
										(100 * data.loaded) / data.total
									),
								});
							},
							// receive two parameter endpoint url ,form data
						})
						.then((res) => {
							const path = res.data.path;

							axios({
								method: "post",
								url: baseURL + "/user/EditImage",
								data: {
									userType: this.props.auth.authData.userType,
									id: this.props.auth.authData.user._id,
									imagePath: res.data.path,
									editVideo: true,
								},
							}).then((res) => {
								let authData = this.props.auth.authData;
								authData.user.video = path;
								this.props.AuthState(authData);
								this.setState({ loadingState: false });
								// window.location.reload();
							});
						});
				});
			} else {
				alert("Please upload a video");
			}
		};

		let completionRation =
			this.props.auth.authData.user.video && this.props.auth.authData.user.image
				? "100%"
				: "70%";

		return (
			<div className="accountHeader d-flex justify-content-between">
				<div className="imageBox">
					<span
						className={"humburgerIcon"}
						onClick={() => {
							this.props.toggleHamburgerMenu(
								!this.props.toggleHamburgerMenuState
							);
						}}>
						<HamburgerIcon color={"#505050"} />
					</span>
					{/* )} */}
					<div className="d-flex justify-content-center align-items-center flex-column">
						<div
							className="initialsContainer"
							onClick={() => this.setState({ uploadAvatarModal: true })}>
							{this.props.auth.authData.user.image ? (
								<Link
									to={
										this.props.auth.authData.userType === 1
											? "account"
											: "/gurus/" + this.props.auth.authData.user._id
									}>
									<img
										src={baseURL + "/" + this.props.auth.authData.user.image}
										alt=""
										className="initials"
									/>
								</Link>
							) : (
								<Link to={"/gurus/" + this.props.auth.authData.user._id}>
									<span className="initials">
										<span className="text-uppercase">
											{this.props.auth.authData.user.firstName[0]}
											{this.props.auth.authData.user.lastName[0]}
										</span>
									</span>
								</Link>
							)}
						</div>
					</div>
					{/*this.state.uploadAvatarModal && (
					<UploadAvatarModal
						onHide={(val) => this.setState({ uploadAvatarModal: val })}
						uploadAvatarModal={this.state.uploadAvatarModal}
					/>
				)*/}
					<span className="welcomeBox">
						Hi {this.props.auth.authData.user.firstName}
						{","} <br />
						Welcome Back
						<div>{completionRation} complete</div>
					</span>
				</div>
				{this.props.auth.authData.userType === 2 && (
					<div className="d-flex align-items-center ">
						{this.state.loadingState ? (
							<div className="loaderContainer mx-2">
								<div className="loader"></div>
								<div className="loaderPercent">
									{this.state.uploadingProgress}%
								</div>
							</div>
						) : this.props.auth.authData.user.video ? (
							<span className="videoBox">
								<div>
									<video
										style={{
											borderRadius: "50%",
											height: "70px",
											width: "70px",
											shapeOutside: "circle(50%)",
											objectFit: "cover",
											border: "2px solid #fff",
											cursor: "pointer",
										}}
										src={baseURL + "/" + this.props.auth.authData.user.video}
									/>
									<span onClick={this.toggleVideoFunc}>
										<PlayIcon
											width={"23"}
											height={"26"}
											color={"rgba(255,255,255,.8)"}
										/>
									</span>
								</div>

								{this.state.toggleVideo && (
									<Modal
										show={this.state.toggleVideo}
										onHide={this.toggleVideoFunc}
										size="lg"
										id="videoPopup"
										className="headerVideo"
										aria-labelledby="contained-modal-title-vcenterv"
										centered>
										<Modal.Header closeButton></Modal.Header>
										<Modal.Body>
											<Player
												src={
													baseURL + "/" + this.props.auth.authData.user.video
												}
												autoPlay={true}>
												<BigPlayButton position="center" />
											</Player>
										</Modal.Body>
									</Modal>
								)}
							</span>
						) : (
							""
						)}
						{!this.state.loadingState && (
							<div>
								<input
									type="file"
									id="uploadVideo"
									className="d-none"
									onChange={handleUploadVideo}
									accept={"video/*"}
								/>
								<label
									for="uploadVideo"
									id="uploadVideo"
									className="btnn text-center">
									{this.props.auth.authData.user.video
										? "Edit video"
										: "Upload video"}
								</label>
							</div>
						)}
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		AuthState: (auth) => dispatch(AuthState(auth)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountHeader);
