import React from "react";
import "./FullScreenLoading.css";
function FullScreenLoading() {
  return (
    <div className="full-screen-loading">
      <div className="loader"></div>
    </div>
  );
}

export default FullScreenLoading;
