import React from "react";
import { Modal } from "antd";
import "./ConfirmDeleteRecursiveTime.css";
function ConfirmDeleteRecursiveTime({
	isModalVisible,
	onCancel,
	handleRemoveTime,
	selectedSlotId,
}) {
	return (
		<Modal
			title="Warning"
			visible={isModalVisible}
			onCancel={onCancel}
			className="confirmDeleteRecursiveTime text-center">
			This will remove all occurrences of this recurring availability. Are you
			sure?
			<div className="btnContainer">
				<span className="mx-2">
					<button className="btn" onClick={onCancel}>
						Cancel
					</button>
				</span>
				<span
					className="mx-2 btn"
					onClick={handleRemoveTime}
					id={selectedSlotId}>
					Confirm
				</span>
			</div>
		</Modal>
	);
}

export default ConfirmDeleteRecursiveTime;
