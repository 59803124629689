import React, { Component } from "react";
import { Alert } from "react-bootstrap";
import axios from "axios";
import { baseURL } from "../../../App";
import { Redirect } from "react-router-dom";
import GuestNav from "../../../Components/GuestNav/GuestNav";
import "./EmailConfirmation.css";

export class EmailConfirmation extends Component {
	state = {
		email: "",
		msg: "",
		closeWindow: false,
		counter: { state: false, count: 59 },
		loading: false,
	};
	onChange = (e) => {
		this.setState({ email: e.target.value });
	};

	handleSubmit = (e) => {
		e.preventDefault();
		if (this.state.email.length) {
			this.setState({ loading: true });
			axios({
				method: "post",
				url: baseURL + "/user/forgotPassword",
				data: {
					email: this.state.email.toLowerCase(),
				},
			}).then((res) => {
				this.setState(
					{
						msg: res.data.message,
						loading: false,
					},
					() => {
						if (res.data.status !== -1) {
							this.setState({
								counter: {
									...this.state.counter,
									state: true,
									// count: this.state.counter.count - 1,
								},
							});
						}
					}
				);
			});
		} else {
			this.setState({
				msg: "Please, fill the Email",
			});
		}
	};
	componentDidUpdate(prevProps, prevState) {
		if (
			prevState.counter.state !== this.state.counter.state &&
			this.state.counter.state
		) {
			const myInterval = setInterval(() => {
				this.setState(
					{
						counter: {
							...this.state.counter,
							count: this.state.counter.count - 1,
						},
					},
					() => {
						if (this.state.counter.count < 0) {
							this.setState(
								{
									counter: {
										count: 59,
										state: false,
									},
								},
								() => {
									clearInterval(myInterval);
								}
							);
						}
					}
				);
			}, 1000);
		}
	}
	render() {
		if (this.state.closeWindow) return <Redirect to="/signin" />;
		return (
			<div className="emailConfirmation">
				<GuestNav />
				<div className="label">
					Password
					<br />
					Reset
				</div>
				<form onSubmit={this.handleSubmit}>
					<div className="inputBox">
						<label>Email</label>
						<input
							type="text"
							value={this.state.email}
							onChange={this.onChange}
							className="form-control"
						/>
						{this.state.msg.length > 0 && (
							<Alert
								className="mt-2 show msg"
								variant={
									this.state.msg === "Please, fill the Email" ||
									this.state.msg === "Email doesn’t exist"
										? "danger"
										: "success"
								}>
								{this.state.msg}
							</Alert>
						)}
					</div>
					<div className="btnContainer">
						<span
							onClick={() => this.setState({ closeWindow: true })}
							className="btn">
							Close
						</span>
						{this.state.counter.state ? (
							<>00:{this.state.counter.count}</>
						) : (
							<input
								type="submit"
								value="Done"
								disabled={this.state.loading}
								className="btn"
							/>
						)}
					</div>
				</form>
			</div>
		);
	}
}

export default EmailConfirmation;
