import React from "react";
import { Modal } from "antd";
import "./AccountSavingModal.css";
function AccountSavingModal({ isModalVisible }) {
	return (
		<Modal
			visible={isModalVisible}
			onCancel={() => {}}
			footer={[]}
			size="md"
			className="accountSavingModal"
			centered
			closable={false}
		>
			<div className="title">Saving Changes</div>
			<div className="loader"></div>
		</Modal>
	);
}

export default AccountSavingModal;
