import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { AuthState } from "../../Store/Actions/AuthActions";
import axios from "axios";
import { Alert } from "react-bootstrap";
import { baseURL, isSummerTime } from "../../App";
//import TimePicker from "rc-time-picker";
import moment from "moment";
import OvalFilledCircleSm from "../../Components/svgLogos/OvalFilledCircleSm";
import OvalEmptyCircleSm from "../../Components/svgLogos/OvalEmptyCircleSm";
import ArrowStickLeftSm from "../../Components/svgLogos/ArrowStickLeftSm";
import ArrowStickRightSm from "../../Components/svgLogos/ArrowStickRightSm";
import Cross from "../../Assets/PersonalInfoIconCross.png";
import { Switch, DatePicker } from "antd";
import ConfirmDeleteRecursiveTime from "../ConfirmDeleteRecursiveTime/ConfirmDeleteRecursiveTime";
import AccountSavingModal from "../AccountSavingModal/AccountSavingModal";
import AlertModal from "../AlertModal/AlertModal";
import Pusher from "pusher-js";
import "rc-time-picker/assets/index.css";
import "moment-timezone";
import "./AccountAvailability.css";

export class AccountAvailability extends Component {
  state = {
    currentDayNum: 0,
    //originalCurrentDayNum: 0,
    period: 1,
    daysArray: [],
    avDates: [],
    startTime: null,
    endTime: null,
    msg: "",
    Show: false,
    startTimeObj: null,
    editBtn: true,
    alert: false,
    setVal: null,
    timeZone: localStorage.getItem("timeZone"),
    serverDate: null,
    schedules: [],
    recursiveSchedules: [],
    selectedDate: null,
    selectedClickedDate: null,
    timePickerState: false,
    isRecursive: false,
    isDeleteModalVisible: false,
    selectedSlotId: null,
    loadingState: false,
    alertMsg: null,
    sessionType: "0",
    isFirstSessionFree: this.props.auth.authData.user.isFirstSessionFree,
    rerenderDatePicker: false,
  };

  // handleTimePicker = (time, timeString, hours, minutes) => {
  // 	let startDateTime = moment(
  // 		this.state.schedules[this.state.currentDayNum]?.fullDate
  // 	)
  // 		.set({
  // 			h: hours,
  // 			m: minutes,
  // 		})
  // 		.format();

  // 	let endDateTime = moment(
  // 		this.state.schedules[this.state.currentDayNum].fullDate
  // 	)
  // 		.set({
  // 			h: hours,
  // 			m: minutes,
  // 		})
  // 		.add(this.state.period === 1 ? 60 : 30, "minutes")
  // 		.format();

  // 	this.setState({ startTime: startDateTime, endTime: endDateTime });
  // };

  changeEndTime = (period) => {
    this.setState({
      endTime: moment(this.state.startTime)
        .add(this.state.period === 1 ? 60 : 30, "minutes")
        .format(),
    });
  };
  onSwitchChange = (checked) => {
    this.setState({
      isRecursive: checked,
      currentDayNum: 0,
      // startTime: null,
      // endTime: null,
    });
  };

  handleChooseTime = (e) => {
    e.preventDefault();
    let currentDayNumVar = this.state.schedules.findIndex(
      (schedule) =>
        schedule.fullDate === moment(this.state.startTime).format("YYYY-MM-DD")
    );
    let currentDayNumVarForNonRecursive = this.state.schedules.findIndex(
      (schedule) =>
        schedule.fullDate === moment(this.state.startTime).format("YYYY-MM-DD")
    );

    if (this.state.isRecursive && currentDayNumVar >= 7) {
      currentDayNumVar = Math.floor(currentDayNumVar % 7);
    }
    this.setState(
      {
        currentDayNum: currentDayNumVar,
        //originalCurrentDayNum: currentDayNumVar,
      },
      () => {
        let schedules = [...this.state.schedules];
        let recursiveSchedules = [...this.state.recursiveSchedules];

        if (this.state.isRecursive) {
          const newSession = {
            startDateTime: moment(this.state.startTime)
              .tz(
                this.state.timeZone === "Africa/Cairo" && isSummerTime
                  ? "Africa/Addis_Ababa"
                  : this.state.timeZone,
                true
              )
              .format(),
            endDateTime: moment(this.state.endTime)
              .tz(
                this.state.timeZone === "Africa/Cairo" && isSummerTime
                  ? "Africa/Addis_Ababa"
                  : this.state.timeZone,
                true
              )
              .format(),
            period: this.state.period,
            isRecursive: this.state.isRecursive,
            sessionType: this.state.sessionType,
          };
          var isRecursiveNotOverlapped = recursiveSchedules[
            this.state.currentDayNum
          ]?.times.every((time) => {
            const sessionStartTime = moment(time.startDateTime)
              .tz("GMT")
              .valueOf();
            const sessionEndTime = moment(time.endDateTime).tz("GMT").valueOf();
            const newSessionStartTime = moment(this.state.startTime)
              .tz("GMT")
              .valueOf();
            const newSessionEndTime = moment(this.state.endTime)
              .tz("GMT")
              .valueOf();
            return !(
              newSessionStartTime < sessionEndTime &&
              newSessionEndTime > sessionStartTime
            );
          });
          if (isRecursiveNotOverlapped) {
            recursiveSchedules[this.state.currentDayNum].times.push(newSession);
          } else {
            this.setState({
              alertMsg: `One or more session/s were not added because you have a sessions within the range!`,
            });
            return;
          }
        }
        if (this.state.isRecursive && !isRecursiveNotOverlapped) {
          return;
        }

        for (let dayIndex = 0; dayIndex < schedules.length; dayIndex += 7) {
          if (
            currentDayNumVarForNonRecursive + dayIndex <=
            schedules.length - 1
          ) {
            const getTimeOverlap = (time, startTime, endTime) => {
              const sessionStartTime = moment(time.startDateTime)
                .tz("GMT")
                .valueOf();
              const sessionEndTime = moment(time.endDateTime)
                .tz("GMT")
                .valueOf();
              const newSessionStartTime = moment(startTime).tz("GMT").valueOf();
              const newSessionEndTime = moment(endTime).tz("GMT").valueOf();

              return !(
                newSessionStartTime < sessionEndTime &&
                newSessionEndTime > sessionStartTime
              );
            };

            const getTimeWithTimeZone = (time, timeZone, isSummerTime) => {
              const timeZoneToUse =
                timeZone === "Africa/Cairo" && isSummerTime
                  ? "Africa/Addis_Ababa"
                  : timeZone;

              return moment(time)
                .add(dayIndex, "days")
                .tz(timeZoneToUse, true)
                .format();
            };

            const timeZoneToUse =
              this.state.timeZone === "Africa/Cairo" && isSummerTime
                ? "Africa/Addis_Ababa"
                : this.state.timeZone;

            const startTime = getTimeWithTimeZone(
              this.state.startTime,
              timeZoneToUse
            );
            const endTime = getTimeWithTimeZone(
              this.state.endTime,
              timeZoneToUse
            );

            const isNotOverlapped =
              schedules[
                currentDayNumVarForNonRecursive + dayIndex
              ]?.times.every((time) =>
                getTimeOverlap(time, startTime, endTime)
              ) &&
              schedules[
                currentDayNumVarForNonRecursive + dayIndex + 1
              ]?.times.every((time) =>
                getTimeOverlap(time, startTime, endTime)
              );
            if (isNotOverlapped || isNotOverlapped === undefined) {
              const newSession = {
                startDateTime: moment(this.state.startTime)
                  .add(dayIndex, "days")
                  .tz(
                    this.state.timeZone === "Africa/Cairo" && isSummerTime
                      ? "Africa/Addis_Ababa"
                      : this.state.timeZone,
                    true
                  )
                  .format(),
                endDateTime: moment(this.state.endTime)
                  .add(dayIndex, "days")
                  .tz(
                    this.state.timeZone === "Africa/Cairo" && isSummerTime
                      ? "Africa/Addis_Ababa"
                      : this.state.timeZone,
                    true
                  )
                  .format(),
                period: this.state.period,
                isRecursive: this.state.isRecursive,
                sessionType: this.state.sessionType,
              };
              schedules[currentDayNumVarForNonRecursive + dayIndex].times.push(
                newSession
              );

              schedules[currentDayNumVarForNonRecursive + dayIndex].times =
                schedules[
                  currentDayNumVarForNonRecursive + dayIndex
                ].times.sort((a, b) => {
                  return a.startDateTime < b.startDateTime ? -1 : 1;
                });

              this.setState(
                {
                  schedules,
                  selectedDate: moment(this.state.selectedDate).add(
                    this.state.period === 1 ? 60 : 30,
                    "minutes"
                  ),
                },
                () => {
                  this.setState(
                    {
                      startTime: moment(this.state.startTime)
                        .add(this.state.period === 1 ? 60 : 30, "minutes")
                        .format(),
                      endTime: moment(this.state.endTime)
                        .add(this.state.period === 1 ? 60 : 30, "minutes")
                        .format(),
                    },
                    () => {
                      if (
                        schedules[currentDayNumVarForNonRecursive].fullDate !==
                        moment(this.state.startTime).format("YYYY-MM-DD")
                      ) {
                        this.setState({
                          // currentDayNum:
                          // 	this.state.currentDayNum + dayIndex + 1,
                          startTime: moment(this.state.startTime)
                            .add(this.state.period)
                            .format(),
                          endTime: moment(this.state.endTime)
                            .add(this.state.period)
                            .format(),
                        });
                      }
                    }
                  );
                }
              );
            } else {
              if (
                schedules[currentDayNumVarForNonRecursive + dayIndex]
                  ?.fullDate !== undefined
              ) {
                if (this.state.isRecursive) {
                  this.setState({
                    // alertMsg: `You have already a session on ${
                    // 	schedules[this.state.currentDayNum + dayIndex]?.fullDate
                    // } within the range!`,
                    alertMsg: `One or more session/s were not added because you have a sessions within the range!`,
                  });
                } else {
                  this.setState({
                    alertMsg: "You have already a session within the range!",
                  });
                }
              }
            }

            if (!this.state.isRecursive) {
              return;
            }
          }
        }
      }
    );
    //this.setState({ currentDayNum: this.state.originalCurrentDayNum });
  };

  handleRemoveTime = (e) => {
    e.preventDefault();
    if (this.state.isRecursive) {
      this.setState({
        isDeleteModalVisible: true,
        selectedSlotId: e.target.id,
      });
    } else {
      let schedules = this.state.schedules;

      let newTimes = schedules[this.state.currentDayNum].times.filter(
        (time) =>
          moment(time.startDateTime).tz(this.state.timeZone).format("hh:mm") +
            "-" +
            moment(time.endDateTime).tz(this.state.timeZone).format("hh:mm") +
            "-" +
            moment(time.startDateTime).tz(this.state.timeZone).format("") !==
          e.target.id
      );
      schedules[this.state.currentDayNum].times = newTimes;
      this.setState({ schedules });
    }
  };
  handleRemoveRecursiveTime = (e) => {
    e.preventDefault();
    const starting_from = e.target.id.split("-").splice(2).join("-");
    var schedules = this.state.schedules;
    var recursiveSchedules = this.state.recursiveSchedules;
    for (let dayIndex = 0; dayIndex <= schedules.length; dayIndex += 7) {
      let newTimes = schedules[
        this.state.currentDayNum + dayIndex
      ]?.times.filter((time) => {
        const sessionId =
          moment(time.startDateTime).tz(this.state.timeZone).format("hh:mm") +
          "-" +
          moment(time.endDateTime).tz(this.state.timeZone).format("hh:mm") +
          "-" +
          starting_from;
        return sessionId !== e.target.id || time.booked === true;
      });
      if (schedules[this.state.currentDayNum + dayIndex]) {
        schedules[this.state.currentDayNum + dayIndex].times = newTimes;
      }
    }
    let newRecursiveTimes = recursiveSchedules[
      this.state.currentDayNum
    ]?.times.filter(
      (time) =>
        moment(time.startDateTime).tz(this.state.timeZone).format("hh:mm") +
          "-" +
          moment(time.endDateTime).tz(this.state.timeZone).format("hh:mm") +
          "-" +
          moment(time.startDateTime).tz(this.state.timeZone).format("") !==
        e.target.id
    );
    recursiveSchedules[this.state.currentDayNum].times = newRecursiveTimes;
    this.setState({
      schedules,
      recursiveSchedules,
      isDeleteModalVisible: false,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ loadingState: true });
    axios({
      method: "post",
      url: baseURL + "/coache/EditAvailabilityNew",
      data: {
        id: this.props.auth.authData.user._id,
        schedulesNew: this.state.schedules,
        recursiveSchedules: this.state.recursiveSchedules,
        isFirstSessionFree: this.state.isFirstSessionFree,
      },
    }).then((res) => {
      this.props.AuthState({ user: res.data.data[0], userType: 2 });
      this.setState({
        msg: res.data.message,
        alert: true,
        editBtn: true,
        loadingState: false,
      });
      // axios({
      // 	method: "post",
      // 	url: baseURL + "/account/NotifyChangeSchedule",
      // }).then((res) => {});
    });
    setTimeout(() => {
      this.setState({ alert: false });
    }, 3000);
  };

  onStartDateChange = (date) => {
    this.setState({ selectedDate: date });
    let currentDateIndex = null;
    const hours = moment(date).format("HH");
    const minutes = moment(date).format("mm");
    this.state.schedules.forEach((schedule, index) => {
      if (schedule?.fullDate === moment(date).format("YYYY-MM-DD")) {
        currentDateIndex = index;
      }
    });

    let startDateTime = moment(this.state.schedules[currentDateIndex]?.fullDate)
      .set({
        h: hours,
        m: minutes,
      })
      .format();
    let endDateTime = moment(this.state.schedules[currentDateIndex]?.fullDate)
      .set({
        h: hours,
        m: minutes,
      })
      .add(this.state.period === 1 ? 60 : 30, "minutes")
      .format();

    this.setState({
      startTime: startDateTime,
      endTime: endDateTime,
    });
  };

  componentDidMount() {
    const pusher = new Pusher("5f703903cc23623a0c27", {
      cluster: "eu",
      encrypted: true,
    });
    const setSchedules = (myCoach) => {
      this.setState({
        schedules: myCoach[0].schedulesNew,
        recursiveSchedules: myCoach[0].recursiveSchedules
          ? myCoach[0].recursiveSchedules
          : myCoach[0].schedulesNew,
      });
    };

    const channel = pusher.subscribe("Welo");
    pusher.connection.bind("error", function (err) {
      if (err.error.data.code === 4004) {
        console.log("Over limit!");
      }
    });
    const myId = this.props.auth.authData.user._id;
    channel.bind("ChangeSchedule", function (data) {
      if (data.message === "reload") {
        axios({
          method: "get",
          url: baseURL + `/coache/getCoachSchedules?coachId=${myId}`,
        }).then((res) => {
          setSchedules(res.data.data.schedules);
        });
      }
    });

    axios({
      method: "get",
      url: baseURL + `/coache/getCoachSchedules?coachId=${myId}`,
    }).then((res) => {
      this.setState(
        {
          schedules: res.data.data.schedules,
          recursiveSchedules: res.data.data.recursiveSchedules
            ? res.data.data.recursiveSchedules
            : res.data.data.schedules,
        },
        () => {
          axios({
            method: "get",
            url:
              baseURL +
              `/account/GetServerTime?timeZone=${this.state.timeZone}`,
          }).then((res) => {
            const remainder = 15 - (moment(res.data).minute() % 15);
            this.setState(
              {
                serverDate: res.data,
                selectedDate: moment(res.data)
                  .tz(this.state.timeZone)
                  .add(
                    this.state.timeZone === "Africa/Cairo" && isSummerTime
                      ? 1
                      : 0,
                    "hour"
                  )
                  .add(remainder, "minutes"),
              },
              () => {
                this.onStartDateChange(this.state.selectedDate);
                const modifiedSchedules = [];
                this.state.schedules.forEach((times) => {
                  times.times.forEach((time) => {
                    modifiedSchedules.push(time);
                  });
                });
                // this gives an object with dates as keys
                const groupedSchedules = modifiedSchedules.reduce(
                  (groups, game) => {
                    const date = game.startDateTime.split("T")[0];
                    if (!groups[date]) {
                      groups[date] = [];
                    }
                    groups[date].push(game);
                    return groups;
                  },
                  {}
                );
                const finalSchedules = Object.keys(groupedSchedules).map(
                  (date) => {
                    return {
                      fullDate: date,
                      times: groupedSchedules[date],
                    };
                  }
                );
                let schedules = [];
                let recursiveSchedulesList = [];

                const daysNames = [];
                for (let dayNumber = 0; dayNumber <= 6; dayNumber++) {
                  const dayName = moment(this.state.serverDate)
                    .add(dayNumber, "days")
                    .tz(
                      this.state.timeZone === "Africa/Cairo" && isSummerTime
                        ? "Africa/Addis_Ababa"
                        : this.state.timeZone,
                      true
                    )
                    .format("dddd");
                  daysNames.push(dayName);
                }

                for (let dayNumber = 0; dayNumber <= 6; dayNumber++) {
                  recursiveSchedulesList.push({
                    fullDate: daysNames[dayNumber],
                    times: [],
                  });
                }
                for (let dayNumber = 0; dayNumber <= 365; dayNumber++) {
                  schedules.push({
                    fullDate: moment(this.state.serverDate)
                      .add(dayNumber, "days")
                      .format("YYYY-MM-DD"),
                    times: [],
                  });
                }
                schedules.forEach((schedule, i) => {
                  finalSchedules.forEach((finalSchedule, j) => {
                    if (schedule.fullDate === finalSchedule.fullDate) {
                      schedules[i] = finalSchedules[j];
                    }
                  });
                  recursiveSchedulesList.forEach((recursiveSchedulee, i) => {
                    this.state.recursiveSchedules.forEach(
                      (finalRecursiveSchedule, j) => {
                        if (
                          recursiveSchedulee.fullDate ===
                          finalRecursiveSchedule.fullDate
                        ) {
                          recursiveSchedulesList[i] =
                            this.state.recursiveSchedules[j];
                        }
                      }
                    );
                  });
                });
                this.setState({
                  schedules,
                  recursiveSchedules: recursiveSchedulesList,
                });
              }
            );
          });
        }
      );
    });
  }

  render() {
    const currentTime = moment(this.state.serverDate)
      .tz(this.state.timeZone)
      .add(
        this.state.timeZone === "Africa/Cairo" && isSummerTime ? 1 : 0,
        "hour"
      )
      .format("hh:mm");
    if (this.state.timePickerState) {
      setTimeout(() => {
        document
          .querySelector(".rc-time-picker-panel-select:nth-child(2)")
          .addEventListener("click", () => {
            setTimeout(() => {
              this.setState({ timePickerState: false });
            }, 500);
          });
      }, 200);
    }
    // const onSessionTypeChange = (sessionType) => {
    // 	this.setState({ sessionType });
    // };

    // const disabledDate = (current) => {
    // 	// Can not select days  after today
    // 	return current <= moment(this.state.serverDate).tz(this.state.timeZone);
    // };
    const disabledDate = (current) => {
      // Get today's date
      const today = moment(this.state.serverDate).startOf("day");

      // Can not select days before today
      return current && current < today;
    };
    const disabledDateTime = () => ({
      disabledHours: () => {
        const currentHour = parseInt(currentTime.substr(0, 2));
        const disabledHours = [];
        const isSameDay =
          moment(this.state.serverDate)
            .tz(this.state.timeZone)
            .format("YYYY-MM-DD") ===
          moment(this.state.selectedClickedDate).format("YYYY-MM-DD");
        if (isSameDay) {
          for (let hour = 0; hour <= 23; hour++) {
            if (hour < currentHour) {
              disabledHours.push(hour);
            }
          }
        }
        return disabledHours;
      },
      disabledMinutes: () => {
        var disabledMinutes = [];
        const currentMinutes = parseInt(currentTime.substr(3, 4));
        const isSameDay =
          moment(this.state.serverDate)
            .tz(this.state.timeZone)
            .format("YYYY-MM-DD") ===
          moment(this.state.selectedClickedDate).format("YYYY-MM-DD");
        const isSameHour =
          moment(this.state.serverDate)
            .tz(this.state.timeZone)
            .add(
              this.state.timeZone === "Africa/Cairo" && isSummerTime ? 1 : 0,
              "hour"
            )
            .format("HH") ===
          moment(this.state.selectedClickedDate).format("HH");
        for (let i = 0; i < 60; i++) {
          if (i % 15) {
            disabledMinutes.push(i);
          }
          if (isSameDay && isSameHour) {
            if (i < currentMinutes) {
              disabledMinutes.push(i);
            }
          }
        }
        return disabledMinutes;
      },
    });
    return (
      <div className="accountAvailability mx-2">
        <div className="heading">
          Availability
          {this.state.editBtn ? (
            <button className="btn" onClick={this.handleSubmit}>
              Save
            </button>
          ) : (
            <button
              className="btn"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ editBtn: true }, () => {
                  this.props.parentCallback(this.state.editBtn);
                });
              }}
            >
              Edit
            </button>
          )}
        </div>
        <form>
          <div className="periodPicker">
            <div>
              <h6>Session Length</h6>
              {/* <div
								onClick={() => {
									this.setState(
										{ period: 30 },
										() => this.state.startTime && this.changeEndTime(30)
									);
								}}
								className="my-2">
								{this.state.period === 30 ? (
									<OvalFilledCircleSm />
								) : (
									<OvalEmptyCircleSm />
								)}
								<span> 30 Minutes</span>
							</div> */}
              <div
                onClick={() => {
                  this.setState(
                    { period: 1 },
                    () => this.state.startTime && this.changeEndTime(1)
                  );
                }}
                className="my-2"
              >
                {this.state.period === 1 ? (
                  <OvalFilledCircleSm />
                ) : (
                  <OvalEmptyCircleSm />
                )}
                <span> 1 Hour</span>
              </div>
            </div>
          </div>
          <Row className="align-items-center p-0 my-4 mx-0">
            <Col
              className="p-0"
              onClick={() => {
                this.setState(
                  {
                    rerenderDatePicker: !this.state.rerenderDatePicker,
                  },
                  () => {
                    const selectedDateNodeTitle = document
                      .querySelector(".ant-picker-cell-selected")
                      ?.getAttribute("title");
                    const selectedHourNodeTitle = document.querySelector(
                      ".ant-picker-time-panel-cell-selected > .ant-picker-time-panel-cell-inner "
                    )?.innerHTML;
                    if (selectedDateNodeTitle && selectedHourNodeTitle) {
                      this.setState({
                        selectedClickedDate:
                          selectedDateNodeTitle +
                          ` ${selectedHourNodeTitle}:00`,
                      });
                    }
                  }
                );
              }}
            >
              <h6>Start time</h6>
              <div>
                <DatePicker
                  inputReadOnly={true}
                  allowClear={false}
                  showTime={{ format: "hh:mm a" }}
                  showNow={false}
                  format="DD-MM-YYYY hh:mm a"
                  onChange={this.onStartDateChange}
                  disabledTime={disabledDateTime}
                  disabledDate={disabledDate}
                  value={this.state.selectedDate}
                  onOk={false}
                />
              </div>
            </Col>
            <Col>
              <div className="endTimeContainer">
                <h6>End time</h6>
                <span>
                  {moment(this.state.selectedDate).format("hh:mm") ===
                  "Invalid date"
                    ? ""
                    : moment(this.state.selectedDate)
                        .add(this.state.period === 30 ? 30 : 60, "minutes")
                        .format("DD-MM-YYYY hh:mm a")}
                </span>
              </div>
            </Col>
          </Row>
          <button
            onClick={this.handleChooseTime}
            className="btn d-block mt-2"
            disabled={!this.state.editBtn}
          >
            Add
          </button>
          <div className="my-4">
            <div className="cursorPointer my-2">
              <span
                onClick={() => {
                  this.setState({
                    isFirstSessionFree: !this.state.isFirstSessionFree,
                  });
                }}
              >
                {this.state.isFirstSessionFree ? (
                  <OvalFilledCircleSm />
                ) : (
                  <OvalEmptyCircleSm />
                )}
              </span>
              <span className="mx-2">Make First Session Free</span>
            </div>

            <div className="d-flex align-items-center">
              <Switch onChange={this.onSwitchChange} />
              <div className="mx-2">Recursive Calendar</div>
            </div>
          </div>

          <div className="timeTableCounter d-flex justify-content-center align-items-center">
            <div
              onClick={() => {
                if (this.state.isRecursive) {
                  if (this.state.currentDayNum !== 0) {
                    this.setState(
                      {
                        currentDayNum: this.state.currentDayNum - 1,
                      },
                      () => {
                        // this.setState({
                        // 	startTime: null,
                        // 	endTime: null,
                        // });
                      }
                    );
                  } else {
                    this.setState(
                      {
                        currentDayNum: 6,
                      },
                      () => {
                        // this.setState({
                        // 	startTime: null,
                        // 	endTime: null,
                        // });
                      }
                    );
                  }
                } else {
                  if (this.state.currentDayNum !== 0) {
                    this.setState(
                      {
                        currentDayNum: this.state.currentDayNum - 1,
                      },
                      () => {
                        // this.setState({
                        // 	startTime: null,
                        // 	endTime: null,
                        // });
                      }
                    );
                  }
                }
              }}
            >
              <ArrowStickLeftSm />
            </div>
            {this.state.schedules?.length > 0 && (
              <div className="dayName">
                <span>
                  {this.state.isRecursive
                    ? this.state.recursiveSchedules[this.state.currentDayNum]
                        ?.fullDate
                    : moment(
                        this.state.schedules[this.state.currentDayNum]?.fullDate
                      )?.format("ddd")}
                </span>
                <br />
                {!this.state.isRecursive && (
                  <span>
                    {moment(
                      this.state.schedules[this.state.currentDayNum].fullDate
                    ).format("MMM")}{" "}
                    {moment(
                      this.state.schedules[this.state.currentDayNum].fullDate
                    ).format("D")}
                  </span>
                )}
              </div>
            )}
            <div
              onClick={() => {
                if (this.state.isRecursive) {
                  if (this.state.currentDayNum !== 6) {
                    this.setState(
                      {
                        currentDayNum: this.state.currentDayNum + 1,
                      },
                      () => {
                        // this.setState({
                        // 	startTime: null,
                        // 	endTime: null,
                        // });
                      }
                    );
                  } else {
                    this.setState({ currentDayNum: 0 });
                  }
                } else {
                  if (this.state.currentDayNum !== 365) {
                    this.setState(
                      {
                        currentDayNum: this.state.currentDayNum + 1,
                      },
                      () => {
                        // this.setState({
                        // 	startTime: null,
                        // 	endTime: null,
                        // });
                      }
                    );
                  }
                }
              }}
            >
              <ArrowStickRightSm />
            </div>
          </div>
          <div className="timeTable d-flex">
            {this.state.isRecursive ? (
              <>
                {this.state.recursiveSchedules?.length &&
                  this.state.recursiveSchedules[
                    this.state.currentDayNum
                  ]?.times?.map((time) => {
                    return (
                      <div className="timeSlot mx-2">
                        <div className="d-flex justify-content-between align-items-center flex-1">
                          <div className="align-self-center">
                            {moment(time.startDateTime)
                              .tz(
                                this.state.timeZone === "Africa/Cairo" &&
                                  isSummerTime
                                  ? "Africa/Addis_Ababa"
                                  : this.state.timeZone
                              )
                              .format("hh:mm a") +
                              "-" +
                              moment(time.endDateTime)
                                .tz(
                                  this.state.timeZone === "Africa/Cairo" &&
                                    isSummerTime
                                    ? "Africa/Addis_Ababa"
                                    : this.state.timeZone
                                )
                                .format("hh:mm a")}
                            (R)
                          </div>
                          <img
                            className="mx-2"
                            id={
                              moment(time.startDateTime)
                                .tz(this.state.timeZone)
                                .format("hh:mm") +
                              "-" +
                              moment(time.endDateTime)
                                .tz(this.state.timeZone)
                                .format("hh:mm") +
                              "-" +
                              moment(time.startDateTime)
                                .tz(this.state.timeZone)
                                .format()
                            }
                            src={Cross}
                            onClick={this.handleRemoveTime}
                            alt="cross"
                          />
                        </div>
                        <div>
                          Starting From :{" "}
                          {moment(time.startDateTime)
                            .tz(this.state.timeZone)
                            .format("DD-MM-YYYY")}
                        </div>
                      </div>
                    );
                  })}
              </>
            ) : (
              this.state.schedules?.length > 0 &&
              this.state.schedules[this.state.currentDayNum]?.times?.map(
                (time) => {
                  return (
                    <div className="timeSlot mx-2">
                      <div className="d-flex justify-content-between align-items-center flex-1">
                        <div className="align-self-center">
                          {moment(time.startDateTime)
                            .tz(
                              this.state.timeZone === "Africa/Cairo" &&
                                isSummerTime
                                ? "Africa/Addis_Ababa"
                                : this.state.timeZone
                            )
                            .format("hh:mm a") +
                            "-" +
                            moment(time.endDateTime)
                              .tz(
                                this.state.timeZone === "Africa/Cairo" &&
                                  isSummerTime
                                  ? "Africa/Addis_Ababa"
                                  : this.state.timeZone
                              )
                              .format("hh:mm a")}
                          {time.isRecursive && <> (R)</>}
                        </div>
                        {!time.booked && (
                          <img
                            id={
                              moment(time.startDateTime)
                                .tz(this.state.timeZone)
                                .format("hh:mm") +
                              "-" +
                              moment(time.endDateTime)
                                .tz(this.state.timeZone)
                                .format("hh:mm") +
                              "-" +
                              moment(time.startDateTime)
                                .tz(this.state.timeZone)
                                .format()
                            }
                            src={Cross}
                            onClick={this.handleRemoveTime}
                            alt="cross"
                            className="mx-2"
                          />
                        )}
                      </div>
                    </div>
                  );
                }
              )
            )}
          </div>
          <div className="btnContainer text-center">
            {this.state.editBtn ? (
              <button className="btn" onClick={this.handleSubmit}>
                Save
              </button>
            ) : (
              <button
                className="btn"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ editBtn: true }, () => {
                    this.props.parentCallback(this.state.editBtn);
                  });
                }}
              >
                Edit
              </button>
            )}
          </div>
          {this.state.alert && (
            <div style={{ textAlign: "center", marginTop: "5px" }}>
              <Alert variant="success">
                <div>{this.state.msg}</div>
              </Alert>
            </div>
          )}
        </form>
        <ConfirmDeleteRecursiveTime
          isModalVisible={this.state.isDeleteModalVisible}
          onCancel={() => {
            this.setState({ isDeleteModalVisible: false });
          }}
          handleRemoveTime={this.handleRemoveRecursiveTime}
          selectedSlotId={this.state.selectedSlotId}
        />
        <AccountSavingModal isModalVisible={this.state.loadingState} />
        <AlertModal
          isModalVisible={this.state.alertMsg}
          msg={this.state.alertMsg}
          onCancel={() => {
            this.setState({ alertMsg: null });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    AuthState: (auth) => dispatch(AuthState(auth)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountAvailability);
