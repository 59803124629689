import React from "react";

function ChatSendIcon() {
	return (
		<svg width="55" height="52" viewBox="0 0 55 52">
			<defs>
				<filter
					id="Ellipse_31"
					x="0"
					y="0"
					width="55"
					height="52"
					filterUnits="userSpaceOnUse"
				>
					<feOffset input="SourceAlpha" />
					<feGaussianBlur stdDeviation="3" result="blur" />
					<feFlood flood-opacity="0.161" />
					<feComposite operator="in" in2="blur" />
					<feComposite in="SourceGraphic" />
				</filter>
			</defs>
			<g
				id="Group_215"
				data-name="Group 215"
				transform="translate(-307.76 -598.352)"
			>
				<g
					transform="matrix(1, 0, 0, 1, 307.76, 598.35)"
					filter="url(#Ellipse_31)"
				>
					<ellipse
						id="Ellipse_31-2"
						data-name="Ellipse 31"
						cx="18.5"
						cy="17"
						rx="18.5"
						ry="17"
						transform="translate(9 9)"
						fill="#CFA79D"
					/>
				</g>
				<path
					id="Path_1523"
					data-name="Path 1523"
					d="M0,0,18.739,8.8,1.072,15.9l2.82-7.769Z"
					transform="translate(324.807 624.183) rotate(-45)"
					fill="#fff"
				/>
			</g>
		</svg>
	);
}

export default ChatSendIcon;
