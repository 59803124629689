import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseURL } from "../../App";
import moment from "moment";
import NavBar from "../TermsAndConditions/NavBar";
import { connect } from "react-redux";
import Webcam from "react-webcam";
import TestModal from "./TestModal";
import "./WaitingRoom.css";
import VideoChattingRoom from "./VideoChattingRoom";
function WaitingRoom({
	sessionId,
	isRemotePartOnline,
	handleJoinRoom,
	auth,
	getRemoteUserName,
	remoteParticipants,
}) {
	const [sessionDetails, updateSessionDetails] = useState(null);
	const [clientDetailsToReturn, updateClientDetailsToReturn] = useState(null);
	const [guruDetailsToReturn, updateGuruDetailsToReturn] = useState(null);
	const [isTestModalVisible, updateTestModalVisible] = useState(false);
	const [chatState, toggleChatState] = useState(false);
	const timeZone = localStorage.getItem("timeZone");
	const [hasUnreadMsg, updateHasUnreadMsg] = useState(null)

	useEffect(() => {
		axios({
			method: "get",
			url: `${baseURL}/user/getSessionDetails?sessionId=${sessionId}&timeZone=${timeZone}`,
		}).then((res) => {
			updateSessionDetails(res.data.sessionDetails[0]);
			updateClientDetailsToReturn(res.data.clientDetails);
			updateGuruDetailsToReturn(res.data.guruDetails);
			getRemoteUserName(res.data.clientDetails, res.data.guruDetails);
		});
		// eslint-disable-next-line
	}, [sessionId, timeZone]);


	const userType = auth.authData.userType === 1 ? "client" : "guru";
	return (
		<div className={`waitingRoom ${chatState ? 'overflow_hidden' : ''}`}>
			<NavBar />
			{sessionDetails && clientDetailsToReturn && guruDetailsToReturn && (
				<div className="d-flex justify-content-center align-items-center mt-4 text-center flex-column">
					<figure className="avatarContainer">
						{userType === "client" ? (
							<img
								src={`${baseURL}/${guruDetailsToReturn.image}`}
								alt=""
								className="rounded-circle"
							/>
						) : clientDetailsToReturn.image ? (
							<img
								src={`${baseURL}/${clientDetailsToReturn.image}`}
								alt=""
								className="rounded-circle"
							/>
						) : (
							<span className="initials">
								<span className="text-uppercase">
									{clientDetailsToReturn.firstName[0]}
									{clientDetailsToReturn.lastName[0]}
								</span>
							</span>
						)}
					</figure>
					<div>
						Your session with{" "}
						<strong>
							{userType === "client"
								? guruDetailsToReturn.fullName
								: clientDetailsToReturn.fullName}
						</strong>{" "}
						is Scheduled for
					</div>
					<div className="mb-4">
						<strong>
							{moment(sessionDetails.sessionFullDate).tz(timeZone).format(
								"dddd, MMMM Do YYYY, h:mm a"
							)}
						</strong>
					</div>
					<Webcam />


					{!isRemotePartOnline && userType === "client" && (
						<div className="mt-4">
							You will be able to enters the room once
							<div>
								<strong>{guruDetailsToReturn.fullName}</strong> joins
							</div>
						</div>
					)}
					<div className='d-flex'>
						<button className='btn chatBtn mx-2'
							onClick={() => { toggleChatState(!chatState) }}
							type={'submit'}
						>
							Chat Now {hasUnreadMsg && hasUnreadMsg?.count !== 0 && <div className="unreadMsgDot"></div>}
						</button>

						<button
							className={
								isRemotePartOnline || userType !== "client" || true
									? "btn"
									: "btn disabled"
							}
							onClick={() => {
								if (userType === "client") {
									if (remoteParticipants.length > 0) {
										handleJoinRoom(true);
									} else {
										handleJoinRoom(true);
									}
								} else {
									handleJoinRoom(true);
								}
							}}
						>
							Join Now
						</button>
					</div>

					<div
						style={{ color: 'blue', textDecoration: 'underline', fontWeight: "bold", cursor: "pointer", padding: '50px' }}
						onClick={() => {
							updateTestModalVisible(true);
						}}
					>
						Test your speaker and microphone
					</div>
				</div>
			)}

			<TestModal
				isModalVisible={isTestModalVisible}
				onCancel={() => {
					updateTestModalVisible(false);
				}}
			/>
			<VideoChattingRoom
				sessionId={sessionId}
				toggleChatStateFun={() => { toggleChatState(!chatState) }}
				chatState={chatState}
				getUnreadMsgState={(val) => { updateHasUnreadMsg(val) }}
			/>
		</div>
	);
}
const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	};
};
export default connect(mapStateToProps)(WaitingRoom);
