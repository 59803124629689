import React, { useState } from "react";
import { Button, Card } from "antd";
import { connect } from "react-redux";
import axios from "axios";
import { baseURL, exchangeCurrency, exchangeRate } from "../../../../App";
import { Alert } from "react-bootstrap";
import "./ViewPackages.css";

function ViewPackages({
	packages,
	getMode,
	getSelectedPackage,
	getAllPackages,
}) {
	const [alertMsg, updateAlertMsg] = useState(null);
	const handleDeletePack = (packageId) => {
		axios({
			method: "delete",
			url: baseURL + `/package/deactivateCoachPackage?packageId=${packageId}`,
		}).then((res) => {
			updateAlertMsg(res.data.data.message);
			setTimeout(() => {
				updateAlertMsg(null);
			}, 4000);
			getMode(null);
			getAllPackages();
		});
	};
	return (
		<div>
			{alertMsg && (
				<Alert variant="success" className="text-center">
					{alertMsg}
				</Alert>
			)}
			<div className="d-flex justify-content-between">
				<div className="d-flex flex-1 my-2 flex-column">
					{packages.map((packageDetail, index) => {
						const packagePrice = Math.ceil(packageDetail.price * exchangeRate);
						return (
							<Card
								index={index}
								className={`mr-2 package-card my-2`}
								title={packageDetail.name}
								bordered={true}>
								<div>{packageDetail.description}</div>
								<div className="d-flex mt-4 flex-1 justify-content-between f-14">
									<div>
										Price / Sessions:{packagePrice}
										{exchangeCurrency} / {packageDetail.sessionCount} Sessions
									</div>

									<Button
										danger
										onClick={() => {
											handleDeletePack(packageDetail._id);
										}}>
										Delete
									</Button>
								</div>
							</Card>
						);
					})}
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	};
};
export default connect(mapStateToProps)(ViewPackages);
