import React, { useState } from "react";
import { Container, Col, Row, Alert } from "react-bootstrap";
import ImageIcon from "../../Assets/images.png";
import PDFIcon from "../../Assets/pdfs.png";
import { connect } from "react-redux";
import { baseURL } from "../../App";
import { AuthState } from "../../Store/Actions/AuthActions";
import axios from "axios";
import ViewDocumentsModal from "../ViewDocumentsModal/ViewDocumentsModal";
import "./AccountDocuments.css";
import AccountSavingModal from "../AccountSavingModal/AccountSavingModal";
function AccountDocuments({ auth, AuthState }) {
	const [isModalVisible, updateModalVisible] = useState(false);
	const [documentPath, updateDocumentPath] = useState(null);
	const [alert, setAlert] = useState(false);
	const [deletedDocs, updateDeletedDocs] = useState([]);
	const [loadingState, updateLoadingState] = useState(false);

	//document personalIdimage
	var documents =
		typeof auth.authData.user.document === "string"
			? [auth.authData.user.document]
			: auth.authData.user.document;

	const sendDocument = (document, type) => {
		axios
			.post(
				type === "personalId"
					? baseURL + "/user/imageUpload"
					: baseURL + "/user/fileUpload",
				document,
				{
					// receive two parameter endpoint url ,form data
				}
			)
			.then((res) => {
				let data = { ...auth.authData };
				if (type === "personalId") {
					data.user.personalIdimage = res.data.path;
				} else {
					data.user.document.push(res.data.path);
				}
				AuthState(data);
				// then print response status
			});
	};

	const fileUploader = (e, type) => {
		if (type === "personalId") {
			if (e.target.files[0].type.includes("image")) {
				let personalId = new FormData();
				personalId.append(
					"image",
					e.target.files[0],
					Math.floor(Math.random() * 1000000000000000) +
						"-" +
						e.target.files[0].name +
						"-" +
						e.target.files[0].size
				);
				sendDocument(personalId, "personalId");
			} else {
				alert("You have to upload image only");
			}
		} else {
			if (
				e.target.files[0].type.includes("image") ||
				e.target.files[0].type.includes("pdf")
			) {
				let document = new FormData();
				document.append(
					"file",
					e.target.files[0],
					Math.floor(Math.random() * 1000000000000000) +
						"-" +
						e.target.files[0].name +
						"-" +
						e.target.files[0].size
				);
				sendDocument(document, "document");
			} else {
				alert("You have to upload image or pdf only");
			}
		}
	};
	const submit = () => {
		updateLoadingState(true);
		axios({
			method: "post",
			url: baseURL + "/coache/EditDocumentsAndPersonnalId",
			data: {
				document: auth.authData.user.document,
				personalIdimage: auth.authData.user.personalIdimage,
				deletedDocumnets: deletedDocs,
				id: auth.authData.user._id,
			},
		}).then((res) => {
			updateLoadingState(false);

			if (res.data.status === 1) {
				setAlert(true);
				updateDocumentPath(null);
				updateDeletedDocs([]);
			}
		});
	};
	const deleteDoc = (document) => {
		let documents = [...auth.authData.user.document];
		let data = { ...auth.authData };
		documents = documents.filter((doc) => doc !== document);
		updateDeletedDocs([...deletedDocs, document]);
		data.user.document = documents;
		AuthState(data);
	};
	return (
		<div className="myDocuments">
			<div className="heading d-flex align-items-center my-4">
				<div>All Documents</div>
			</div>
			<Container>
				<Row>
					<Col>
						<div className="d-flex justify-content-between">
							<h5>Personal ID</h5>
							<label htmlFor="personalIDUploader" className="text-btn">
								Change
							</label>
							<input
								id="personalIDUploader"
								style={{ display: "none" }}
								type={"file"}
								onChange={(e) => fileUploader(e, "personalId")}
							/>
						</div>

						<div className="d-flex">
							<img
								src={ImageIcon}
								alt="ImageIcon"
								className="imageIcon"
								onClick={() => {
									updateModalVisible(true);
									updateDocumentPath(
										baseURL + "/" + auth.authData.user.personalIdimage
									);
								}}
							/>
							<div className="mx-2">
								{auth.authData.user.personalIdimage
									.split("-")
									.map((detail, detailIndex) => {
										if (detailIndex !== 0) {
											return <div key={detailIndex}>{detail}</div>;
										} else {
											return "";
										}
									})}
							</div>
						</div>
					</Col>
					<Col>
						<div className="d-flex justify-content-between">
							<h5>Documents</h5>
							<label htmlFor="documentsUploader" className="text-btn">
								Add
							</label>
							<input
								id="documentsUploader"
								style={{ display: "none" }}
								type={"file"}
								onChange={(e) => fileUploader(e, "documentsUploader")}
							/>
						</div>
						{documents.map((document, documentIndex) => {
							if (!document.includes("pdf")) {
								return (
									<div className="d-flex my-2">
										<img
											src={ImageIcon}
											alt="ImageIcon"
											className="imageIcon"
											key={documentIndex}
											onClick={() => {
												updateModalVisible(true);
												updateDocumentPath(baseURL + "/" + document);
											}}
										/>
										<div className="d-flex justify-content-between w-100">
											<div className="mx-2">
												{document.split("-").map((detail, detailIndex) => {
													if (detailIndex !== 0) {
														return <div key={detailIndex}>{detail}</div>;
													} else {
														return "";
													}
												})}
											</div>
											<div
												className="deleteSign "
												onClick={() => {
													deleteDoc(document);
												}}
											>
												Delete
											</div>
										</div>
									</div>
								);
							} else {
								return (
									<div className="d-flex my-2">
										<a
											href={baseURL + "/" + document}
											target="_blank"
											rel="noopener noreferrer"
										>
											<img
												src={PDFIcon}
												alt="PDFIcon"
												className="pdfIcon"
												key={documentIndex}
											/>
										</a>
										<div className="mx-2 d-flex justify-content-between w-100">
											<div>
												{document.split("-").map((detail, detailIndex) => {
													if (detailIndex !== 0) {
														return <div key={detailIndex}>{detail}</div>;
													} else {
														return "";
													}
												})}
											</div>
											<div
												className="deleteSign "
												onClick={() => {
													deleteDoc(document);
												}}
											>
												Delete
											</div>
										</div>
									</div>
								);
							}
						})}
					</Col>
				</Row>
			</Container>
			<div className="text-center mt-4">
				<button className="btn" onClick={submit}>
					Save
				</button>
			</div>
			{alert && (
				<Alert variant="success" className="text-center">
					Updated Successfully
				</Alert>
			)}
			{isModalVisible && (
				<ViewDocumentsModal
					isModalVisible={isModalVisible}
					onHide={() => {
						updateModalVisible(false);
					}}
					documentPath={documentPath}
				/>
			)}
			<AccountSavingModal isModalVisible={loadingState} />
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		AuthState: (auth) => dispatch(AuthState(auth)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(AccountDocuments);
