import React, { Component } from "react";

export class SigninEye extends Component {
	render() {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="15.516"
				height="9.63"
				viewBox="0 0 25.516 14.63"
			>
				<g
					id="Atoms_Icons_Eye_Active"
					data-name="Atoms/ Icons/ Eye Active"
					transform="translate(0.75 0.75)"
					opacity="0.55"
				>
					<g id="Eye">
						<path
							id="Path"
							d="M24.016,6.565S18.658,13.13,12.008,13.13,0,6.565,0,6.565,5.357,0,12.008,0,24.016,6.565,24.016,6.565Z"
							fill="none"
							stroke="#CFA79D"
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-miterlimit="10"
							stroke-width="1.5"
						/>
						<ellipse
							id="Oval"
							cx="3.695"
							cy="3.283"
							rx="3.695"
							ry="3.283"
							transform="translate(8.313 3.283)"
							fill="none"
							stroke="#CFA79D"
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-miterlimit="10"
							stroke-width="1.5"
						/>
					</g>
				</g>
			</svg>
		);
	}
}

export default SigninEye;
