import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../Assets/Logo.svg";
import "./GuestNav.css";
function GuestNav({ progress, total }) {
	// const progressValue = document.getElementById("progressValue");
	// console.log(progressValue);
	// if (progressValue) {
	// 	progressValue.style.width = progress + "%";
	// }
	const progressValue = {
		width: `${progress}%`,
	};

	return (
		<div className="guestNav">
			<div className="logo">
				<Link to="/">
					<img src={Logo} alt="Logo" />
				</Link>
			</div>
			{progress && (
				<>
					<div className="progressBar">
						<div className="value" style={progressValue}></div>
					</div>
					<div className="fw-700 text-center">
						Step {Math.round((progress / 100) * total)} of {total}
					</div>
				</>
			)}
		</div>
	);
}

export default GuestNav;
