import React, { Component } from "react";
import axios from "axios";
import { baseURL } from "../../App";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
// eslint-disable-next-line
import ViewSessionModal from "../../Components/ViewSessionModal/ViewSessionModal";
import FilterModal from "../../Components/FilterModal/FilterModal";
import DropDownModal from "../../Components/DropDownModal/DropDownModal";
// eslint-disable-next-line
import DateCountdown from "react-date-countdown-timer";
//import SessionPageCurve from "../../Components/svgLogos/SessionPageCurves";
import HamburgerList from "../../Components/HamburgerList/HamburgerList";
import NavBarLg from "../../Components/NavBarLg/NavBarLg";
import HamburgerIcon from "../../Components/svgLogos/HomePageFigures/HamburgerIcon";
import FilterIcon from "../../Components/svgLogos/HomePageFigures/FiltersIcon";
import FloatingBar from "../../Components/FloatingBar/FloatingBar";
import CoachBookingCard from "../../Components/CoachBookingCard/CoachBookingCard";
import WeeklyCalendar from "../../Components/WeeklyCalendar/WeeklyCalendar.js";
import "moment-timezone";
import "./SessionCalendar.css";
class Calender extends Component {
	state = {
		serverDate: null,
		date: new Date(),
		showHideCal: false,
		toggleFilterPage: false,
		toggleHamburgerMenu: false,
		toggleDropdownMenu: false,
		coachSessions: [],
		clientSessions: [],
		upcomingDate: "",
		sessionDetailsPopup: false,
		sessionStarted: false,
		filteredprops: null,
		upcommingSessionDetails: null,
		token: null,
		upcommingSessionID: null,
		endUpcomingDate: null,
		redirectToHome: false,
		timeZone: localStorage.getItem("timeZone"),
		redText: false,
	};
	toggleFilterPage = (val) => {
		this.setState({ toggleFilterPage: val });
	};
	toggleDropdownMenu = (val) => {
		this.setState({ toggleDropdownMenu: val });
	};
	toggleHamburgerMenu = (val) => {
		this.setState({ toggleHamburgerMenu: val });
	};
	filteredCoaches = (val) => {
		this.setState({ coaches: val, filteredPagesMode: true, index: 1 });
	};
	filteredPagesCount = (val) => {
		this.setState({ pagesCount: val });
	};

	onChange = (date) => {
		this.setState({ date }, () => {
			const date = new Date(this.state.date)
				.toLocaleString("en-US", {
					timeZone: this.state.timeZone,
				})
				.split(",")[0];
			axios({
				method: "get",
				url: `${baseURL}/user/ClientSessionsNew?clientId=${this.props.auth.authData.user._id}&sessionDate=${date}&userType=${this.props.auth.authData.userType}&timeZone=${this.state.timeZone}`,
			}).then((res) => {
				this.setState({
					coachSessions: res.data.coach,
					clientSessions: res.data.client,
				});
			});
		});
	};
	handlePopup = () => {
		this.setState({ sessionDetailsPopup: !this.state.sessionDetailsPopup });
	};

	componentDidMount() {
		setInterval(() => {
			const zeroNode = document.querySelector(".odometer-block");
			const minutesNode = document.querySelector(".min");
			const hoursNode = document.querySelector(".hour");
			const daysNode = document.querySelector(".day");
			if (zeroNode && zeroNode.children[0]) {
				if (
					zeroNode.children[0].innerHTML === "0" &&
					zeroNode.children[1].innerHTML === "0" &&
					zeroNode.children[2].innerHTML === "0" &&
					zeroNode.children[3].innerHTML === "0"
				) {
					zeroNode.innerHTML = "Join now";
				}
			}
			if (minutesNode && hoursNode && daysNode) {
				if (
					minutesNode.children[0].innerHTML +
						minutesNode.children[1].innerHTML ===
						"00" &&
					hoursNode.children[0].innerHTML + hoursNode.children[1].innerHTML ===
						"00" &&
					daysNode.children[0].innerHTML + daysNode.children[1].innerHTML ===
						"00"
				) {
					this.setState({ redText: true });
				} else {
					this.setState({ redText: false });
				}
			}
		}, 1000);

		// moment(this.state.serverDate).tz(this.state.timeZone, true).format() <
		// moment(this.state.endUpcomingDate).tz(this.state.timeZone).format()

		axios({
			method: "get",
			url: baseURL + `/account/GetServerTime?timeZone=${this.state.timeZone}`,
		}).then((res) => {
			this.setState({ serverDate: res.data }, () => {
				const date = new Date(this.state.date)
					.toLocaleString("en-US", {
						timeZone: this.state.timeZone,
					})
					.split(",")[0];
				this.props.auth.authData !== undefined &&
					axios({
						method: "get",
						url: `${baseURL}/user/ClientSessionsNew?clientId=${this.props.auth.authData.user._id}&sessionDate=${date}&userType=${this.props.auth.authData.userType}&timeZone=${this.state.timeZone}`,
					}).then((res) => {
						this.setState({
							coachSessions: res.data.coach,
							clientSessions: res.data.client,
						});
					});

				this.props.auth.authData !== undefined &&
					axios({
						method: "get",
						url:
							baseURL +
							"/user/UpcomingSessionNew?clientId=" +
							this.props.auth.authData.user._id +
							"&userType=" +
							this.props.auth.authData.userType +
							"&timeZone=" +
							this.state.timeZone,
					}).then((res) => {
						this.setState(
							{
								upcommingSessionDetails: res.data.coach
									? res.data.coach[0]
									: res.data.client[0],
								upcomingDate:
									res.data.client.length > 0 || res.data.coach.length > 0
										? res.data.client.length > 0
											? moment(res.data.client[0].sessionFullDate).format()
											: moment(res.data.coach[0].sessionFullDate).format()
										: null,
								endUpcomingDate:
									res.data.client.length > 0 || res.data.coach.length > 0
										? res.data.client.length > 0
											? moment(res.data.client[0].sessionEndDate).format()
											: moment(res.data.coach[0].sessionEndDate).format()
										: null,

								upcommingSessionID:
									res.data.client.length > 0 || res.data.coach.length > 0
										? res.data.coach.length > 0
											? res.data.coach[0]._id
											: res.data.client[0]._id
										: null,
							},

							() => {
								moment(this.state.upcomingDate)
									.tz(this.state.timeZone)
									.format();

								// moment(this.state.serverDate)
								// 									.tz(this.state.timeZone, true)
								// 									.format()

								if (
									moment(this.state.serverDate)
										.tz(this.state.timeZone, true)
										.format() >=
										moment(this.state.upcomingDate)
											.tz(this.state.timeZone)
											.subtract(5, "minutes")
											.format() &&
									moment(this.state.serverDate)
										.tz(this.state.timeZone, true)
										.format() <
										moment(this.state.endUpcomingDate)
											.tz(this.state.timeZone)
											.format()
								) {
									this.setState({ sessionStarted: true });
								} else {
									this.setState({ sessionStarted: false });
								}
							}
						);
					});
			});
		});
	}

	filteredprops = (val) => {
		this.setState({ filteredprops: val });
	};
	refreshPage() {
		window.location.reload();
	}
	handleGoStream = () => {
		const username = this.props.auth.authData.user._id;
		const roomName = this.state.upcommingSessionID;
		const URL = baseURL + `/video/token?identity=${username}&room=${roomName}`;
		axios({
			method: "get",
			url: URL,
			// data: {
			// 	identity: username,
			// 	room: roomName,
			// },
		}).then((response) => {
			this.setState({ token: response.data.token });
		});
	};

	render(props) {
		var minDate = new Date();
		var maxDate = new Date();
		minDate.setDate(minDate.getDate());
		maxDate.setDate(maxDate.getDate() + 30);
		minDate.setDate(minDate.getDate() - 1);
		maxDate.setDate(maxDate.getDate() + 30);

		if (this.props.auth.authData === undefined) {
			localStorage.setItem("redirectState", true);
			return <Redirect to="/signin" />;
		}
		if (this.state.token) return <Redirect to={"/room/" + this.state.token} />;
		if (this.state.redirectToHome) return <Redirect to="/" />;
		return (
			<div
				className={
					this.state.toggleFilterPage ||
					this.state.toggleDropdownMenu ||
					this.state.toggleHamburgerMenu
						? "filterPageActive sessionCalendar"
						: "sessionCalendar"
				}
			>
				<NavBarLg
					active="Schedule"
					toggleFilterPageState={this.state.toggleFilterPage}
					toggleFilterPage={this.toggleFilterPage}
					toggleDropdownMenuState={this.state.toggleDropdownMenu}
					toggleDropdownMenu={this.toggleDropdownMenu}
					searchBar={false}
					fixedNavbar={true}
					parent={"SessionCalendar"}
				/>
				<FilterModal
					filterState={this.state.toggleFilterPage}
					filteredprops={this.filteredprops}
					filteredPagesCount={this.filteredPagesCount}
					toggleFilterPage={this.toggleFilterPage}
					filteredCoaches={this.filteredCoaches}
				/>
				<DropDownModal
					toggleDropdownMenu={this.toggleDropdownMenu}
					dropDownState={this.state.toggleDropdownMenu}
				/>
				<div className="header">
					<span
						className="hamburgerIcon"
						onClick={() => {
							this.setState({
								toggleHamburgerMenu: !this.state.toggleHamburgerMenu,
							});
						}}
					>
						<HamburgerIcon color="#FFF" />
					</span>
					<span>My Schedule</span>
					<span
						onClick={() => {
							this.setState({ redirectToHome: true });
						}}
						className="filterIcon d-none"
					>
						<FilterIcon color="#FFF" />
					</span>
				</div>
				{this.state.toggleHamburgerMenu && (
					<HamburgerList
						toggleHamburgerMenu={this.toggleHamburgerMenu}
						toggleHamburgerMenuState={this.state.toggleHamburgerMenu}
					/>
				)}
				<div className="calendar">
					<WeeklyCalendar
						maxDate={maxDate}
						minDate={minDate}
						onChange={this.onChange}
					/>
				</div>
				<div className="calendarFooter">
					{/* <div className="sessionPageCurve">
						<SessionPageCurve />
					</div> */}
					<div className="fullDate">{this.state.date.toDateString()}</div>
				</div>
				<div className="container-fluid">
					<div className="row">
						{this.state.clientSessions &&
							this.state.clientSessions.map((session) => {
								return (
									<div className="col-lg-6 col-12">
										<CoachBookingCard
											sessionType="coach"
											session={session}
											date={this.state.date}
											rerender={() => this.componentDidMount()}
										/>
									</div>
								);
							})}
						{this.state.coachSessions &&
							this.state.coachSessions.map((session) => {
								return (
									<div className="col-lg-6 col-12">
										<CoachBookingCard
											sessionType="client"
											session={session}
											date={this.state.date}
											rerender={() => this.componentDidMount()}
										/>
									</div>
								);
							})}
					</div>
				</div>
				{/* {this.state.upcommingSessionID !== null && (
					<div
						className={
							this.state.redText
								? "nextSessionCounterBox red_text"
								: "nextSessionCounterBox"
						}
					>
						<div>
							UPCOMING SESSION <br /> COUNTDOWN
						</div>
						{this.state.upcomingDate.length > 0 && (
							<DateCountdown
								dateTo={moment(this.state.upcomingDate)
									.tz(this.state.timeZone)
									.format()}
								locales_plural={[]}
								mostSignificantFigure="day"
								locales={[]}
								callback={() => {
									this.componentDidMount();
								}}
								dateFrom={moment(this.state.serverDate)
									.tz(this.state.timeZone, true)
									.format()}
							/>
						)}
						<div>
							<button onClick={this.handlePopup} className="btn">
								View session
							</button>
							{this.state.upcommingSessionDetails && (
								<ViewSessionModal
									session={this.state.upcommingSessionDetails}
									sessionType={
										this.props.auth.authData.userType === 1 ? "client" : "coach"
									}
									sessionDetailsPopup={this.state.sessionDetailsPopup}
									handlePopup={this.handlePopup}
								/>
							)}
							<button
								className={
									this.state.sessionStarted ? "btn sessionActive" : "btn"
								}
								onClick={this.state.sessionStarted && this.handleGoStream}
							>
								Join now
							</button>
						</div>
					</div>
				)} */}
				<FloatingBar active="schedual" />
			</div>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	};
};

export default connect(mapStateToProps)(Calender);
