import React, { useState, useEffect } from "react";
import { baseURL } from "../../App";
import { connect } from "react-redux";
import CoachBookingCard from "../../Components/CoachBookingCard/CoachBookingCard";
import NavBarLg from "../../Components/NavBarLg/NavBarLg";
import axios from "axios";
import NavBarSm from "../../Components/NavBarSm/NavBarSm";
import HamburgerList from "../../Components/HamburgerList/HamburgerList";
import Pusher from "pusher-js";
import "./MySessions.css";
function MySessions({ auth }) {
  const [activeTab, updateActiveTab] = useState("upcoming");
  const [clientSessions, updateClientSessions] = useState([]);
  const [coachSessions, updateCoachSessions] = useState([]);
  const [hamburgerMenu, toggleHamburgerMenu] = useState(false);
  const [isLoading, updateIsLoading] = useState(false);
  const [rerender, updateRerender] = useState(false);
  const getPastUpcomingSessions = () => {
    const timeZone = localStorage.getItem("timeZone");

    axios({
      method: "get",
      url: `${baseURL}/user/ClientUpcomingAndPastSessions?timeZone=${timeZone}&userType=${
        auth.authData.userType
      }&isPast=${activeTab === "past" ? true : false}&clientId=${
        auth.authData.user._id
      }`,
    })
      .then((res) => {
        updateClientSessions(res.data.client);
        updateCoachSessions(res.data.coach);
        updateIsLoading(false);
      })
      .catch((error) => {
        updateIsLoading(false);
      });
  };
  useEffect(() => {
    getPastUpcomingSessions();
    updateIsLoading(true);
    // eslint-disable-next-line
  }, [
    auth.authData.user._id,
    auth.authData.user.userType,
    activeTab,
    rerender,
    auth.authData.userType,
  ]);
  useEffect(() => {
    const pusher = new Pusher("5f703903cc23623a0c27", {
      cluster: "eu",
      encrypted: true,
    });
    const channel = pusher.subscribe("Welo");
    pusher.connection.bind("error", function (err) {
      if (err?.error?.data?.code === 4004) {
        console.log("Over limit!");
      }
    });

    channel.bind("ChangeSchedule", function (data) {
      getPastUpcomingSessions();
    });
    // eslint-disable-next-line
  }, []);
  return (
    <div className="mySessions">
      <NavBarLg
        active="Schedule"
        searchBar={false}
        fixedNavbar={true}
        parent={"SessionCalendar"}
      />
      <NavBarSm
        parent={"contact"}
        toggleHamburgerMenu={() => {
          toggleHamburgerMenu(true);
        }}
        toggleHamburgerMenuState={hamburgerMenu}
      />
      {hamburgerMenu && (
        <HamburgerList
          toggleHamburgerMenu={() => {
            toggleHamburgerMenu(false);
          }}
          toggleHamburgerMenuState={hamburgerMenu}
        />
      )}
      <h3 className="text-center title">My Sessions</h3>
      <div className="sessionsSwitch d-flex  align-items-center">
        <div
          className={activeTab === "upcoming" ? `active item` : `item`}
          onClick={() => {
            updateActiveTab("upcoming");
          }}
        >
          Upcoming
        </div>
        <div
          className={activeTab === "past" ? `active item` : `item`}
          onClick={() => {
            updateActiveTab("past");
          }}
        >
          Past
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          {isLoading ? (
            <div className="d-flex align-items-center justify-content-between flex-1">
              <div className="loader"></div>
            </div>
          ) : (
            <>
              {clientSessions &&
                clientSessions?.map((session, index) => {
                  return (
                    <div className="col-12" key={index}>
                      <CoachBookingCard
                        sessionType="coach"
                        session={session}
                        date={new Date()}
                        rerender={() => updateRerender(!rerender)}
                      />
                    </div>
                  );
                })}
              {coachSessions &&
                coachSessions?.map((session, index) => {
                  return (
                    <div className="col-12" key={index}>
                      <CoachBookingCard
                        sessionType="client"
                        session={session}
                        date={new Date()}
                        rerender={() => updateRerender(!rerender)}
                      />
                    </div>
                  );
                })}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(MySessions);
