import React, { useEffect, useState } from "react";
//import moment from "moment";
function CountDownTimer(props) {
	const { dateTo } = props;

	var eventTime = new Date(dateTo).getTime();
	//var currentTime = new Date(dateFrom).getTime();
	//var leftTime = eventTime - currentTime; //Now i am passing the left time from controller itself which handles timezone stuff (UTC), just to simply question i used harcoded values.
	//var duration = moment.duration(leftTime, "seconds");
	var interval = 1000;
	const [sessionNow, updateSessionNow] = useState(false);
	const [dayss, updateDayss] = useState(0);
	const [hourss, updateHourss] = useState(0);
	const [minutess, updateMinutess] = useState(0);
	const [secondss, updateSecondss] = useState(0);
	const [numberStrings] = useState(["zero", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine", "ten", "eleven", "twelve", "thirteen", "fourteen"]);

	//const [isJoin, updateIsJoin] = useState(false)
	useEffect(() => {
		const timeValue = setInterval(() => {
			var timeDiff = eventTime - new Date().getTime();



			// let seconds = timeDiff / 1000;
			// const days = parseInt(seconds / 86400); // 86,400 seconds in 1 day
			// seconds = seconds % 86400; // seconds remaining after extracting days
			// const hours = parseInt(seconds / 3600); // 3,600 seconds in 1 hour
			// seconds = seconds % 3600; // seconds remaining after extracting hours
			// const minutes = parseInt(seconds / 60); // 60 seconds in 1 minute
			// seconds = parseInt(seconds % 60);
			// if (minutes <= 7) {
			// 	updateSessionNow(true);
			// 	props.callback();
			// }
			// updateDayss(days);
			// // updateDayss(days < 10 ? "0" + days : days);
			// updateHourss(hours < 10 ? "0" + hours : hours);
			// updateMinutess(minutes < 10 ? "0" + minutes : minutes);
			// updateSecondss(seconds < 10 ? "0" + seconds : seconds);

			if (timeDiff <= 0) {
				updateSessionNow(true);
				props.callback();
				clearInterval(timeValue);
				//updateIsJoin(true)
			} else {
				let seconds = timeDiff / 1000;
				const days = parseInt(seconds / 86400); // 86,400 seconds in 1 day
				seconds = seconds % 86400; // seconds remaining after extracting days
				const hours = parseInt(seconds / 3600); // 3,600 seconds in 1 hour
				seconds = seconds % 3600; // seconds remaining after extracting hours
				const minutes = parseInt(seconds / 60); // 60 seconds in 1 minute
				seconds = parseInt(seconds % 60);
				if (timeDiff < 1000) {
					updateSessionNow(true);
				}

				if (hours === 0 && minutes <= 9) {
					props.callback();
				}

				updateDayss(days);
				// updateDayss(days < 10 ? "0" + days : days);
				updateHourss(hours < 10 ? "0" + hours : hours);
				updateMinutess(minutes < 10 ? "0" + minutes : minutes);
				updateSecondss(seconds < 10 ? "0" + seconds : seconds);
			}

			// if (duration.asSeconds() <= 0) {
			// 	props.callback();
			// }
			// // eslint-disable-next-line
			// duration = moment.duration(duration.asSeconds() - 1, "seconds");
			// const { days, hours, minutes, seconds } = duration._data;
			// updateDayss(days < 10 ? "0" + days : days);
			// updateHourss(hours < 10 ? "0" + hours : hours);
			// updateMinutess(minutes < 10 ? "0" + minutes : minutes);
			// updateSecondss(seconds < 10 ? "0" + seconds : seconds);
		}, interval);
		// eslint-disable-next-line
	}, []);

	return (
		<div>
			{sessionNow &&
				<>Now</>
			}
			{!sessionNow &&
				<>
					{parseInt(dayss) < 2 &&
						<>
							{/*eslint-disable-next-line*/}
							{parseInt(dayss) != 0 ? dayss + " days and " : ""}{hourss}:{minutess}:{secondss}
						</>
					}
					{(parseInt(dayss) < 14 && parseInt(dayss) >= 2) &&
						<>
							in {numberStrings[dayss] + " days"}
						</>
					}
					{(parseInt(dayss) < 30 && parseInt(dayss) >= 14) &&
						<>
							in {numberStrings[parseInt((dayss - (dayss % 7)) / 7)] + " weeks"}
						</>
					}
					{parseInt(dayss) >= 30 &&
						<>
							in {numberStrings[parseInt((dayss - (dayss % 30)) / 30)] + " month"}
						</>
					}
				</>
			}
			{/* {isJoin ? <>Join Now</> : <div className="odometer-block">{parseInt(dayss) != 0 ? dayss + " days and " : ""}{hourss}:{minutess}:{secondss}</div>} */}
		</div>
	);
}

export default CountDownTimer;
