import React, { Component } from "react";
import { connect } from "react-redux";
// import {Link} from 'react-router-dom';
import PhoneInput from "react-phone-input-2";
import AvatarImageCropper from "react-avatar-image-cropper";
import OvalFilledCircle from "../svgLogos/OvalFilledCircle";
import WrongEmailCheck from "../svgLogos/WrongEmailCheck";
import UploadImg from "../UploadImg/UploadImg";
import { Alert } from "react-bootstrap";
import { AuthState } from "../../Store/Actions/AuthActions";
import axios from "axios";
import firebase from "firebase/app";
import AccountSavingModal from "../AccountSavingModal/AccountSavingModal";
import { baseURL } from "../../App";
import ChangePassModal from "../ChangePassModal/ChangePassModal";
import ChangeEmailModal from "../ChangeEmailModal/ChangeEmailModal";
import DefaultImg from "../../Assets/DefaultImage.svg";
import { isValidPhoneNumber } from "react-phone-number-input";
import "./AccountPersonalInfo.css";
// import checkSave from "../CheckSave/CheckSave";
class AccountPersonalInfo extends Component {
  state = {
    firstName: this.props.auth.authData.user.firstName,
    lastName: this.props.auth.authData.user.lastName,
    email: this.props.auth.authData.user.email,
    phone: this.props.auth.authData.user.phone,
    fullName: this.props.auth.authData.user.fullName,
    password: "",
    confirmPassword: "",
    passwordCheck: true,
    emailCheck: false,
    errorSign: false,
    redirect: false,
    Show: false,
    coachDetails: null,
    img: null,
    imgURL: null,
    finished: false,
    updateInfo: false,
    isModalVisible: false,
    ModalVisible: false,
    ModaalVisible: false,
    editBtn: false,
    showalert: false,
    NewPassword: " ",
    isPhotoModalVisible: false,
    loadingState: false,
    isValidPhone: true,
  };

  passwordPass = (val) => {
    this.setState({ NewPassword: val });
  };
  // const [show, setShow] = useState(false);

  handleClose = () => {
    this.setState({
      showalert: false,
    });
  };

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  passValidation = () => {
    var reg = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,}$/;
    var test =
      this.state.password === "" ? true : reg.test(this.state.password);
    this.setState({ passwordCheck: test });
  };
  handleEmailCheck = () => {
    if (
      this.state.email !== "" &&
      this.state.email !== this.props.auth.authData.user.email
    ) {
      axios({
        method: "post",
        url: baseURL + "/account/checkEmailExist",
        data: {
          email: this.state.email.toLowerCase(),
          userType: this.props.auth.authData.userType,
        },
      }).then((res) => {
        // alert(res.data)
        this.setState({ emailCheck: res.data.exist });
      });
    }
  };

  toggleModal = () => {
    this.setState({ isModalVisible: !this.state.isModalVisible });
  };
  toggleModaal = () => {
    this.setState({ ModalVisible: !this.state.ModalVisible }, () => {
      // if (!this.state.ModalVisible) {
      // 	window.location.reload();
      // }
    });
  };
  // toggleModaaal = () => {
  //   this.setState({ ModaalVisible: !this.state.ModaalVisible });
  // };

  ChangePass = (e) => {
    e.preventDefault();
    this.toggleModal();
  };
  ChangeEmail = (e) => {
    e.preventDefault();
    this.toggleModaal();
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (
      this.state.fullName !== "" &&
      this.state.email !== "" &&
      this.state.emailCheck === false &&
      this.state.phone !== "" &&
      this.state.phone.length >= 5 &&
      this.state.passwordCheck === true &&
      this.state.password === this.state.confirmPassword &&
      this.state.isValidPhone
    ) {
      this.setState({ loadingState: true, Show: true });
      if (this.props.auth.authData.userType === 2) {
        axios({
          method: "post",
          url: baseURL + "/coache/EditPersonalInfo",
          data: {
            firstName: this.state.fullName.split(" ")[0],
            lastName: this.state.fullName.split(" ")[1]
              ? this.state.fullName.split(" ")[1]
              : " ",
            fullName: this.state.fullName,
            email: this.state.email.toLowerCase(),
            phone: this.state.phone,
            password: "",
            userType: this.props.auth.authData.userType,
            id: this.props.auth.authData.user._id,
          },
        })
          .then((res) => {
            if (
              res.data.message === "Updated Successfully" ||
              res.data.status === 1
            ) {
              this.setState(
                {
                  editBtn: false,
                  loadingState: false,
                },
                () => {
                  this.props.parentCallback(this.state.editBtn);
                }
              );
            }
            this.setState({
              updateInfo: false,
            });

            if (res.data.user.isEmailConfirmed === false) {
              this.props.AuthState(0);
            } else {
              this.props.AuthState({ user: res.data.user[0], userType: 2 });
            }
          })
          .catch((err) => {
            this.setState({
              errMsg: err.response.data.message,
            });
          });
      } else {
        axios({
          method: "post",
          url: baseURL + "/coache/EditPersonalInfo",
          data: {
            firstName: this.state.fullName.split(" ")[0],
            lastName: this.state.fullName.split(" ")[1]
              ? this.state.fullName.split(" ")[1]
              : " ",
            fullName: this.state.fullName,
            email: this.state.email.toLowerCase(),
            phone: this.state.phone,
            password: "",
            userType: this.props.auth.authData.userType,
            id: this.props.auth.authData.user._id,
          },
        }).then((res) => {
          if (
            res.data.message === "Updated Successfully" ||
            res.data.status === 1
          ) {
            this.setState(
              {
                editBtn: false,
                loadingState: false,
              },
              () => {
                this.props.parentCallback(this.state.editBtn);
              }
            );
          }
          if (res.data.user.isEmailConfirmed === false) {
            this.props.AuthState(0);
          } else {
            this.props.AuthState({ user: res.data.user, userType: 1 });
          }
        });
      }
      setTimeout(() => {
        this.setState({ Show: false });
      }, 3000);
    } else {
      this.setState({ errorSign: true });
    }
  };

  apply = (blob) => {
    var file = new File([blob], blob.name);
    this.setState({ img: file }, () => {
      const data = new FormData();
      data.append(
        "image",
        this.state.img,
        Math.ceil(Math.random() * 1000000000000000) + this.state.img.name
      );
      axios
        .post(baseURL + "/user/imageUpload", data, {
          // receive two parameter endpoint url ,form data
        })
        .then((res) => {
          const path = res.data.path;
          axios({
            method: "post",
            url: baseURL + "/user/EditImage",
            data: {
              userType: this.props.auth.authData.userType,
              id: this.props.auth.authData.user._id,
              imagePath: res.data.path,
            },
          }).then((res) => {
            let authData = this.props.auth.authData;
            authData.user.image = path;
            this.props.AuthState(authData);
            this.setState({ img: null }, () => {
              firebase
                .database()
                .ref("users/" + this.props.auth.authData.user._id + "/image")
                .set(this.props.auth.authData.user.image);
            });
          });
        });
    });
  };

  render(props) {
    const toggleChangePhotoModal = () => {
      this.setState({ isPhotoModalVisible: !this.state.isPhotoModalVisible });
    };
    const actions = [
      <button key={0}>test_cancel</button>,
      <button key={1} className="button">
        Save photo
      </button>,
    ];
    const profImg = this.props.auth.authData.user.image
      ? baseURL + "/" + this.props.auth.authData.user.image
      : DefaultImg;
    const isNeedFullWidth = this.props.auth.authData.user.image ? true : false;
    const icon = (
      <svg
        id="Group_4180"
        data-name="Group 4180"
        xmlns="http://www.w3.org/2000/svg"
        width="58.165"
        height="45.78"
        viewBox="0 0 58.165 45.78"
      >
        <g id="Group_4179" data-name="Group 4179">
          <path
            id="Path_5094"
            data-name="Path 5094"
            d="M209.03,172.2a6.253,6.253,0,0,0-4.589-1.935h-9.178v-.111a4.966,4.966,0,0,0-1.493-3.594,5.061,5.061,0,0,0-3.594-1.492H173.589a5.1,5.1,0,0,0-5.142,5.086v.111h-9.123a6.253,6.253,0,0,0-4.589,1.935,6.573,6.573,0,0,0-1.935,4.589v27.535a6.255,6.255,0,0,0,1.935,4.589,6.573,6.573,0,0,0,4.589,1.935h45.116a6.253,6.253,0,0,0,4.589-1.935,6.574,6.574,0,0,0,1.935-4.589V176.786A6.254,6.254,0,0,0,209.03,172.2Zm-.94,32.124h-.056a3.587,3.587,0,0,1-3.594,3.594H159.324a3.587,3.587,0,0,1-3.594-3.594V176.786a3.587,3.587,0,0,1,3.594-3.594H170a1.487,1.487,0,0,0,1.493-1.493v-1.6a2.081,2.081,0,0,1,2.156-2.156h16.532a2.082,2.082,0,0,1,2.156,2.156v1.6a1.487,1.487,0,0,0,1.492,1.493H204.5a3.587,3.587,0,0,1,3.594,3.594Z"
            transform="translate(-152.8 -165.065)"
            fill="#CFA79D"
          />
          <path
            id="Path_5095"
            data-name="Path 5095"
            d="M197.063,188.608a13.592,13.592,0,1,0,9.62,3.981A13.626,13.626,0,0,0,197.063,188.608Zm7.52,21.176a10.679,10.679,0,0,1-15.039,0,10.571,10.571,0,0,1-3.1-7.52,10.8,10.8,0,0,1,3.1-7.519,10.57,10.57,0,0,1,7.52-3.1,10.8,10.8,0,0,1,7.52,3.1,10.569,10.569,0,0,1,3.1,7.519A10.346,10.346,0,0,1,204.583,209.784Z"
            transform="translate(-167.981 -176.721)"
            fill="#CFA79D"
          />
          <circle
            id="Ellipse_41"
            data-name="Ellipse 41"
            cx="2.709"
            cy="2.709"
            r="2.709"
            transform="translate(46.057 11.832)"
            fill="#CFA79D"
          />
        </g>
      </svg>
    );
    const emptyIcon = <svg></svg>;

    let rootStyle = {
      position: "static",
      top: "100px",
    };
    let errorStyle = { position: "absolute", top: "229px", left: "10px" };

    const border =
      this.state.errorSign &&
      this.state.phone === "" &&
      this.state.phone.length <= 5 &&
      "1px solid red";

    const inputStyle = {
      width: "100%",
      height: "39px",
      border: border,
    };
    const maxsize = 1024 * 1024 * 10;
    return (
      <div className="accountPersonalInfo">
        <UploadImg
          isModalVisible={this.state.isPhotoModalVisible}
          onCancel={toggleChangePhotoModal}
          parent="updateImg"
        />
        <div className="heading">
          Personal Info
          {this.state.editBtn ? (
            <button className="btn" onClick={this.handleSubmit}>
              Save
            </button>
          ) : (
            <button
              className="btn"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ editBtn: true }, () => {
                  this.props.parentCallback(this.state.editBtn);
                });
              }}
            >
              Edit
            </button>
          )}
        </div>

        <div className="Update Picture mb-2">
          <label className="picLabel">Update Picture</label>
          <div
            style={{
              width: 150,
              height: 150,
              cursor: "pointer",
            }}
            className="photoUploader"
            onClick={toggleChangePhotoModal}
          >
            <div
              className="d-flex justify-content-center align-items-center flex-1"
              style={{ height: "100%" }}
            >
              <img
                src={profImg}
                alt="pp"
                style={isNeedFullWidth ? { width: "100%", height: "100%" } : {}}
              />
            </div>
            <span className="d-none">
              <AvatarImageCropper
                icon={this.props.auth.authData.user.image ? emptyIcon : icon}
                text=" "
                errorStyle={errorStyle}
                rootStyle={rootStyle}
                className="avatarCropper"
                apply={this.apply.bind(this)}
                maxsize={maxsize}
                actions={actions}
                loadingNode={<div className="loader"></div>}
              />
            </span>
          </div>
        </div>
        <form>
          <label>Full Name</label>
          <input
            className={
              this.state.fullName.length === 0 && this.state.errorSign
                ? "form-control danger"
                : "form-control"
            }
            type="text"
            value={this.state.fullName}
            onChange={this.handleChange}
            id="fullName"
            disabled={!this.state.editBtn}
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <label>Email</label>
            <span>
              {this.state.ModalVisible && (
                <ChangeEmailModal
                  ModalVisible={this.state.ModalVisible}
                  toggleModaal={() => this.toggleModaal()}
                />
              )}
            </span>
            <button
              className={this.state.editBtn ? "button" : "hidden"}
              onClick={this.ChangeEmail}
              disabled={!this.state.editBtn}
            >
              Change
            </button>
          </div>
          <div className="emailContainer">
            <input
              type="email"
              className={
                this.state.email === "" && this.state.errorSign
                  ? "form-control danger"
                  : "form-control"
              }
              value={this.state.email}
              onChange={this.handleChange}
              onBlur={this.handleEmailCheck}
              id="email"
              disabled
            />
            <span className="emailCheckSign">
              {this.state.email !== "" &&
                (this.state.emailCheck === false ? (
                  <span className="right">
                    <OvalFilledCircle />
                  </span>
                ) : this.state.emailCheck === true ? (
                  <span className="wrong">
                    <WrongEmailCheck />
                  </span>
                ) : (
                  ""
                ))}
            </span>
          </div>
          <label>Phone Number</label>
          <PhoneInput
            country={"eg"}
            inputStyle={inputStyle}
            dropdownStyle={{ textAlign: "left" }}
            value={this.state.phone}
            onChange={(phone) =>
              this.setState({ phone }, () => {
                const isValid = isValidPhoneNumber(`+${phone}`);
                this.setState({ isValidPhone: isValid });
              })
            }
            //   inputClass={this.state.phone?{"required"}:{"contr"}}
            inputClass={
              this.state.errorSign && !this.state.isValidPhone
                ? "form-control danger"
                : "form-control"
            }
            disabled={!this.state.editBtn}
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <label>Password</label>
            <span>
              {this.state.isModalVisible && (
                <ChangePassModal
                  isModalVisible={this.state.isModalVisible}
                  toggleModal={() => this.toggleModal()}
                />
              )}
            </span>
            <button
              className={this.state.editBtn ? "button" : "hidden"}
              onClick={this.ChangePass}
              disabled={!this.state.editBtn}
            >
              Change
            </button>
          </div>
          <input
            onChange={this.handleChange}
            placeholder="********"
            onBlur={this.passValidation}
            id="password"
            value={this.state.password}
            className={
              this.state.passwordCheck === false && this.state.errorSign
                ? "form-control danger"
                : "form-control"
            }
            type="password"
            disabled
          />

          <div className="btnContainer">
            {this.state.editBtn ? (
              <button className="btn" onClick={this.handleSubmit}>
                Save
              </button>
            ) : (
              <button
                className="btn"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState(
                    {
                      editBtn: true,
                    },
                    () => {
                      this.props.parentCallback(this.state.editBtn);
                    }
                  );
                }}
              >
                Edit
              </button>
            )}
            {this.state.Show ? (
              <Alert variant="success" className="mt-3">
                Updated Successfully
              </Alert>
            ) : null}
          </div>
        </form>
        <AccountSavingModal isModalVisible={this.state.loadingState} />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    AuthState: (auth) => dispatch(AuthState(auth)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountPersonalInfo);
