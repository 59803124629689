import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
//import Logo from "../../../Assets/Logo.svg";
import PhoneInput from "react-phone-input-2";
import axios from "axios";
import { baseURL, exchangeRate } from "../../../App";
import { Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { RegActions } from "../../../Store/Actions/RegActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import OvalEmptyCircle from "../../../Components/svgLogos/OvalEmptyCircle";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
//import Cross from "../../../Assets/PersonalInfoIconCross.png";
import OvalFilledCircle from "../../../Components/svgLogos/OvalFilledCircle";
import WrongEmailCheck from "../../../Components/svgLogos/WrongEmailCheck";
import moment from "moment";
import { Button, Col, DatePicker, Input, Row, Space, Form } from "antd";
import { isValidPhoneNumber } from "react-phone-number-input";
import validator from "validator";
import GuestNav from "../../../Components/GuestNav/GuestNav";
import "react-datepicker/dist/react-datepicker.css";
import "react-phone-input-2/lib/style.css";
import "./PersonalInfo.css";

class PersonalInfo extends Component {
	constructor(props) {
		super(props);
		this.myRef = React.createRef();

		this.state = {
			fullName: this.props.Reg.fullName ? this.props.Reg.fullName : "",
			dateOfBirth: this.props.Reg.birthDate
				? moment(this.props.Reg.birthDate)
				: moment(moment()._d.setFullYear(moment()._d.getFullYear() - 18)),
			email: this.props.Reg.email ? this.props.Reg.email : "",
			password: this.props.Reg.password ? this.props.Reg.password : "",
			confirmPassword: this.props.Reg.password ? this.props.Reg.password : "",
			confirmPassFlag: false,
			phone: this.props.Reg.phone ? this.props.Reg.phone : "",
			languagesList: [],
			otherLangList: [],
			language: this.props.Reg.language ? this.props.Reg.language : "",
			otherLangs: this.props.Reg.otherLanguageNames
				? this.props.Reg.otherLanguageNames
				: [],
			otherLangsIDS: this.props.Reg.otherlanguage
				? this.props.Reg.otherlanguage
				: [],
			gender: this.props.Reg.gender
				? this.props.Reg.gender
				: "60b757662e296e08b6c039ce",
			errMsg: "*",
			gendersList: [],
			agreeTerms: this.props.Reg.agreeTerms ? this.props.Reg.agreeTerms : false,
			submit: false,
			errorSign: false,
			emailCheck: null,
			passwordCheck: false,
			toggleMenu: false,
			backState: false,
			errorStates: [],
			isValidPhone: false,
			isValidEmail: false,
			loading: false,
		};
	}

	onChange = (date, dateString) => {
		this.setState({ dateOfBirth: dateString });
	};
	disabledTime = (date) => {};

	handleChange = (e) => {
		var value = e.target.value;
		const id = e.target.id;
		if (value.length !== 0) {
			if (id === "fullName" && !/^[a-zA-Z\s]+$/.test(value)) {
				return;
			}
		}
		this.setState({ [id]: value }, () => {
			if (id === "email" && validator.isEmail(this.state.email)) {
				this.setState(
					{ isValidEmail: validator.isEmail(this.state.email) },
					() => {
						this.handleEmailCheck();
					}
				);
			}
			if (this.state.errorStates.length) {
				let errorsVar = {
					fullName: this.state.fullName.length === 0 && "Full Name",
					email:
						(this.state.email.length === 0 || this.state.emailCheck) && "Email",
					password:
						(this.state.password.length === 0 || this.state.passwordCheck) &&
						"Password",

					dateOfBirth: this.state.dateOfBirth.length === 0 && "Date Of Birth",
					phone: !this.state.isValidPhone && "Phone",
					agreeTerms:
						this.state.agreeTerms === false && "Agree on terms and conditions",
				};
				errorsVar = Object.values(errorsVar).filter(
					(error) => error !== false && error !== null
				);
				this.setState({
					errorSign: true,
					errorStates: errorsVar,
				});
			}
		});
	};
	hoverMenu = (e) => {
		const prevState = this.state.toggleMenu;
		this.setState({ toggleMenu: !prevState });
	};

	handleSelectLang = (e) => {
		const id = this.state.languagesList[e.target.selectedIndex - 1]._id;
		if (!this.state.otherLangs.includes(e.target.value)) {
			this.setState({
				otherLangs: [...this.state.otherLangs, e.target.value],
				otherLangsIDS: [...this.state.otherLangsIDS, id],
			});
		} else {
			this.setState({ otherLangs: [...this.state.otherLangs] });
		}
	};
	handleSubmit = (e) => {
		e.preventDefault();
		if (
			this.state.fullName === "" ||
			this.state.email === "" ||
			this.state.password === "" ||
			this.state.dateOfBirth === "" ||
			!this.state.isValidPhone ||
			this.state.emailCheck === true ||
			this.state.passwordCheck === true ||
			this.state.agreeTerms === false ||
			!this.state.isValidEmail
		) {
			let errorsVar = {
				fullName: this.state.fullName.length === 0 && "Full Name",
				email:
					(this.state.email.length === 0 || this.state.emailCheck) && "Email",
				password:
					(this.state.password.length === 0 || this.state.passwordCheck) &&
					"Password",

				dateOfBirth: this.state.dateOfBirth.length === 0 && "Date Of Birth",
				phone: !this.state.isValidPhone && "Phone",
				agreeTerms:
					this.state.agreeTerms === false && "Agree on terms and conditions",
			};
			errorsVar = Object.values(errorsVar).filter((error) => error !== false);
			this.setState({
				errorSign: true,
				errorStates: errorsVar,
			});
		} else {
			this.props.RegActions({
				fullName: this.state.fullName,
				email: this.state.email.toLowerCase(),
				phone: this.state.phone,
				birthDate: this.state.dateOfBirth,
				language: this.state.language,
				otherlanguage: this.state.otherLangsIDS,
				otherLanguageNames: this.state.otherLangs,
				gender:
					this.state.gender.length > 0
						? this.state.gender
						: "6024f6c71450fb43dcfe7e8d",
				password: this.state.password,
				agreeTerms: this.state.agreeTerms,
			});
			this.setState({ submit: true });
		}
	};
	removeSelect = (e) => {
		const newSelect = this.state.otherLangs.filter(
			(item) => item !== e.target.id
		);
		this.setState({
			otherLangs: newSelect,
			otherLangsIDS: this.state.otherLangsIDS.filter(
				(word) => word !== this.state.otherLangsIDS[e.target.name]
			),
		});
	};

	passValidation = () => {
		var reg = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,}$/;
		var test = reg.test(this.state.password);
		if (!test) {
			this.setState({ passwordCheck: true });
		} else {
			this.setState({ passwordCheck: false });
		}
	};
	handleEmailCheck = () => {
		this.setState({ loading: true });
		if (this.state.email !== "") {
			axios({
				method: "post",
				url: baseURL + "/account/checkEmailExist",
				data: {
					email: this.state.email.toLowerCase(),
					userType: this.props.Reg.userType,
				},
			})
				.then((res) => {
					this.setState({ emailCheck: res.data.exist, loading: false });
				})
				.catch((err) => {
					this.setState({ loading: false });
				});
		}
	};
	disabledDate = (current) => {
		var aYearFromNow = moment()._d;
		return (
			current &&
			current > moment()._d.setFullYear(aYearFromNow.getFullYear() - 18)
		);
	};

	executeScroll = () => this.myRef.current.scrollIntoView();

	componentDidMount() {
		if (this.state.email) {
			this.handleEmailCheck();
			const isValidEmail = validator.isEmail(this.state.email);
			this.setState({ isValidEmail });
		}
		// if (this.props.location?.state?.sessionInfo) {
		// 	this.props.RegActions({ userType: "1" });
		// }
		axios({
			method: "get",
			url: baseURL + "/account/languageList",
		}).then((res) => {
			this.setState({
				languagesList: res.data.languages,
				otherLangList: res.data.languages,
			});
		});
		axios({
			method: "get",
			url: baseURL + "/account/genderList",
			data: {},
		}).then((res) => {
			this.setState({ gendersList: res.data.genders });
		});
		if (this.state.phone?.length) {
			const isValid = isValidPhoneNumber(`+${this.state.phone}`);
			this.setState({ isValidPhone: isValid });
		}
	}

	render(props) {
		const border =
			this.state.errorSign && this.state.phone === "" && "1px solid red";
		const inputStyle = {
			width: "100%",
			height: "39px",
			border: border,
		};

		const sessionInfo = this.props.location?.state?.sessionInfo;
		if (this.state.backState) return <Redirect to="/userType" />;
		if (this.state.submit && this.props.Reg.userType === "1")
			return (
				<Redirect
					to={{
						pathname: "/addphoto",
						state: {
							sessionInfo,
						},
					}}
				/>
			);
		if (this.state.submit && this.props.Reg.userType === "2")
			return <Redirect to="/addphotocoach" />;
		if (!this.props.Reg.userType) {
			if (sessionInfo) {
				this.props.RegActions({ userType: "1" });
			} else {
				return <Redirect to="/userType" />;
			}
		}
		return (
			<div className="authPage personalInfo">
				<GuestNav
					progress={this.props.Reg.userType === "1" ? 100 : (100 / 6) * 2}
					total={this.props.Reg.userType === "1" ? 2 : 6}
				/>
				<Row className="flex-1">
					<Col
						xs={24}
						md={sessionInfo ? 12 : 24}
						className="d-flex align-items-center">
						<Form onFinish={this.handleSubmit}>
							<div className={`authForm ${!sessionInfo && "fullScreen"}`}>
								<label>
									Full Name
									{this.state.errorSign && this.state.fullName === "" && (
										<span className="text-danger">{this.state.errMsg} </span>
									)}
								</label>
								<input
									id="fullName"
									onChange={this.handleChange}
									value={this.state.fullName}
									placeholder="Full Name"
									className={
										this.state.errorSign && this.state.fullName === ""
											? "form-control required"
											: "form-control"
									}
									type="text"
								/>
								<div className="my-2">
									<div>
										<label>
											Email
											{this.state.errorSign && this.state.email === "" && (
												<span className="text-danger">{this.state.errMsg}</span>
											)}
										</label>
									</div>
									<div className="emailContainer">
										<input
											onBlur={this.handleEmailCheck}
											onPaste={(e) => {
												e.preventDefault();
												const value = e.clipboardData.getData("Text");
												const isValidEmail = validator.isEmail(value);
												this.setState({ isValidEmail, email: value }, () => {
													this.handleEmailCheck();
												});
											}}
											placeholder="Email"
											autocomplete="off"
											className={
												this.state.errorSign && this.state.email === ""
													? "form-control required"
													: "form-control"
											}
											type="email"
											id="email"
											value={this.state.email}
											onChange={this.handleChange}
										/>
										{this.state.email.length > 0 && (
											<span className="emailCheckSign">
												{!this.state.emailCheck && this.state.isValidEmail ? (
													<span className="right">
														<OvalFilledCircle />
													</span>
												) : (
													<span className="wrong">
														<WrongEmailCheck />
													</span>
												)}
												{this.state.emailCheck === true && (
													<Alert
														className="mt-2"
														style={{ width: "100%", textAlign: "center" }}
														variant={"danger"}>
														Email already exists
													</Alert>
												)}
											</span>
										)}
									</div>
								</div>

								<span className="passwordContainer">
									<label>
										<span>Password</span>
										{this.state.errorSign && this.state.password === "" && (
											<span className="text-danger">{this.state.errMsg}</span>
										)}
										<FontAwesomeIcon
											onMouseEnter={this.hoverMenu}
											onMouseLeave={this.hoverMenu}
											className="mx-2 PasswordIcon cursorPointer"
											icon={faInfoCircle}
										/>
									</label>
									<div
										className={
											this.state.toggleMenu
												? "floatingInfo show"
												: "hide floatingInfo"
										}>
										Password must contain at least one uppercase letter, one
										lowercase letter, one number and must be at least 8
										characters
									</div>
								</span>
								<Input.Password
									placeholder="Password"
									iconRender={(visible) =>
										visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
									}
									autocomplete="off"
									className={
										this.state.errorSign && this.state.password === ""
											? "required my-2 p-2"
											: "my-2 p-2"
									}
									type="password"
									id="password"
									onBlur={() => this.passValidation()}
									value={this.state.password}
									onChange={this.handleChange}
								/>
								{this.state.passwordCheck && this.state.password.length > 0 && (
									<Alert className="mt-2 show" variant="danger">
										Password must contain at least one uppercase letter, one
										lowercase letter, one number and must be at least 8
										characters
									</Alert>
								)}
								<div>
									<label>
										Phone Number
										{this.state.errorSign && this.state.phone === "" && (
											<span className="text-danger">{this.state.errMsg} </span>
										)}
									</label>
								</div>
								<div>
									<PhoneInput
										country={"eg"}
										inputStyle={inputStyle}
										dropdownStyle={{ textAlign: "left" }}
										value={this.state.phone}
										onChange={(phone) =>
											this.setState({ phone }, () => {
												const isValid = isValidPhoneNumber(`+${phone}`);
												this.setState({ isValidPhone: isValid });
											})
										}
										inputClass={
											this.state.errorSign && this.state.phone === ""
												? "form-control required"
												: "form-control"
										}
									/>
								</div>
								{this.state.errorSign &&
									!this.state.isValidPhone &&
									this.state.phone.length > 0 && (
										<Alert className="mt-2 text-center" variant="danger">
											Please Enter a valid Phone Number
										</Alert>
									)}
								<div className="d-flex my-2 flex-1 justify-content-between genderDateBox">
									<div className="genderBox">
										<label className="d-block">Gender</label>
										<div className="d-flex mt-3">
											{this.state.gendersList.map((gender) => {
												return (
													<div
														onClick={(e) => {
															this.setState({ gender: gender._id });
														}}
														id={gender._id}
														className="d-flex align-items-center flex-column mx-2"
														key={gender._id}>
														<div className="icon">
															{this.state.gender === gender._id ? (
																<OvalFilledCircle />
															) : (
																<OvalEmptyCircle />
															)}
														</div>
														<div>{gender.genderName}</div>
													</div>
												);
											})}
										</div>
									</div>
									<div className="dateOfBirth">
										<label className="d-block mb-0">
											Date of Birth
											{this.state.errorSign &&
												this.state.dateOfBirth === "" && (
													<span className="text-danger">
														{this.state.errMsg}{" "}
													</span>
												)}
										</label>
										<br />
										<div
											style={{ marginBottom: 5 }}
											ref={this.myRef}
											className="dateOfBirthContainer">
											<Space direction="vertical" className="w-100">
												<DatePicker
													className={
														this.state.errorSign &&
														this.state.dateOfBirth === ""
															? "form-control required"
															: "form-control"
													}
													placeholder="DD/MM/YYYY"
													id={"dateOfBirth"}
													onChange={this.onChange}
													onOpenChange={(value) => {
														if (value) this.executeScroll();
													}}
													inputReadOnly
													value={moment(this.state.dateOfBirth)}
													disabledDate={this.disabledDate}
													format={"YYYY"}
													picker="year"
													allowClear={false}
												/>
											</Space>
										</div>
									</div>
								</div>
								<div className="agreeTermsContainer d-flex align-items-center justify-content-center">
									<div
										className="agreeTermsIcon"
										onClick={() => {
											const prevState = this.state.agreeTerms;
											this.setState({ agreeTerms: !prevState });
										}}>
										{this.state.agreeTerms ? (
											<span className="icon">
												<OvalFilledCircle />
											</span>
										) : (
											<span className="icon">
												<OvalEmptyCircle
													danger={
														this.state.errorSign && !this.state.agreeTerms
													}
												/>
											</span>
										)}
									</div>
									<span className="agreeTerms text-center">
										I agree to
										<span>
											<Link
												target={"_blank"}
												to={
													this.props.Reg.userType === "1"
														? "termsandconditionsforclient"
														: "termsandconditionsforguru"
												}>
												{" "}
												Terms & Privacy Policy
											</Link>
										</span>
									</span>
								</div>
								{this.state.errorStates.length > 0 && (
									<Alert variant={"danger"} className="text-center">
										Please Fill The Required Fields
									</Alert>
								)}
								<div className="nextCancelContainer text-center">
									<Button
										disabled={sessionInfo}
										className="secondary-btn"
										onClick={() => this.setState({ backState: true })}>
										Back
									</Button>
									<Button
										className="primary-btn"
										type="primary"
										loading={this.state.loading}
										htmlType="submit"
										onClick={this.handleSubmit}>
										{this.props.Reg.userType === "1" ? "Done" : "Next"}
									</Button>
								</div>
							</div>
						</Form>
					</Col>
					<Col
						xs={24}
						md={12}
						className={`authSolidSection ${!sessionInfo && "d-none"}`}>
						{sessionInfo && (
							<div className="guruDetails mx-4">
								<div className="d-flex justify-content-between align-items-center flex-1 guruInfo pb-3">
									<div className="d-flex align-items-center">
										<img
											src={baseURL + "/" + sessionInfo.image}
											alt="guruImage"
											className="guruAvatar"
										/>
										<div className="mx-2">
											<div className="fw-600">{sessionInfo.fullName}</div>
											<div className="fw-300 f-14">
												{sessionInfo.specialization[0].categoryName}
											</div>
										</div>
									</div>
									<div>
										<div className="fw-600">
											{Math.ceil(sessionInfo.price * exchangeRate)}
											{sessionInfo.unit}
										</div>
										<div className="fw-300 f-14">/hr</div>
									</div>
								</div>
								{sessionInfo.sessionDateTime && (
									<div className="mt-2">
										{moment(sessionInfo.sessionDateTime.startDateTime).format(
											"DD-MM-YYYY hh:mm a"
										)}
									</div>
								)}
							</div>
						)}
					</Col>
				</Row>
			</div>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		Reg: state.reg,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		RegActions: (reg) => dispatch(RegActions(reg)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfo);
