import React, { Component } from "react";
//import LogoSm from "../../Components/svgLogos/Logo";
import logo from "../../Assets/Logo.svg";
import SearchLogo from "../../Components/svgLogos/HomePageFigures/SearchLogo";
import axios from "axios";
import FiltersIcon from "../../Components/svgLogos/HomePageFigures/FiltersIcon";
import HamburgerIcon from "../../Components/svgLogos/HomePageFigures/HamburgerIcon";
import { baseURL } from "../../App";
import { Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import "./NavBarSm.css";
class NavBarSm extends Component {
  state = {
    toggleFilter: false,
    searchValueSm: "",
    searchsuggestions: [],
    timeZone: localStorage.getItem("timeZone"),
    redirectToHome: false,
  };

  toggleFilterPage = () => {
    this.props.toggleFilterPage(this.state.toggleFilter);
  };

  handleSearchOnChange = (e) => {
    this.setState({ [e.target.id]: e.target.value }, () => {
      axios({
        method: "post",
        url: baseURL + "/coache/autocompleteSearch",
        data: {
          search: this.state.searchValueSm,
          index: 0,
        },
      }).then((res) => {
        this.setState({ searchsuggestions: res.data });
      });
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();

    if (this.props.parent !== "doctorDetails") {
      axios({
        method: "post",
        url: baseURL + `/coache/searchnew?timeZone=${this.state.timeZone}`,
        data: {
          search: this.state.searchValueSm.replace(/\s/g, " "),
          index: 0,
        },
      })
        .then((res) => {
          this.props.searchedCoachList(res.data);
        })
        .catch((err) => console.log(err.response.data));
      axios({
        method: "post",
        url: baseURL + "/coache/searchPagesCount",
        data: {
          search: this.state.searchValueSm,
        },
      })
        .then((res) => {
          this.props.searchedPagesCount(Math.ceil(res.data.count));
        })
        .catch((err) => {});
      this.props.getSearchVal(this.state.searchValueSm);
    } else {
      axios({
        method: "post",
        url: baseURL + "/coache/searchPagesCount",
        data: {
          search: this.state.searchValueSm,
        },
      })
        .then((res) => {
          localStorage.setItem("PagesCount", res.data.count);
          localStorage.setItem("searchVal", this.state.searchValueSm);
          this.setState({ redirectToHome: true });
        })
        .catch((err) => {});
    }
  };
  render(props) {
    if (this.state.redirectToHome) return <Redirect to={"/"} />;
    return (
      <div className="NavBarSm">
        {this.props.auth.authData ? (
          <div
            onClick={() => {
              this.props.toggleHamburgerMenu(
                !this.props.toggleHamburgerMenuState
              );
            }}
            className="HamburgerIcon"
          >
            <HamburgerIcon color="#3c3c3b" />
          </div>
        ) : (
          <div className="HamburgerIcon">
            <Link to="/signin">Sign in</Link>
          </div>
        )}
        <a href="/">
          <img
            src={logo}
            alt="logo"
            className="logo"
            onClick={() => {
              localStorage.setItem("refresh", true);
            }}
          />
        </a>
        {this.props.parent !== "contact" && (
          <div
            onClick={() =>
              this.setState({ toggleFilter: !this.state.toggleFilter }, () =>
                this.toggleFilterPage()
              )
            }
            className="filtersIcon"
            style={{ display: "none" }}
          >
            <FiltersIcon color="#3c3c3b" />
          </div>
        )}
        {this.props.parent !== "contact" && (
          <form
            className="searchBox"
            onSubmit={this.handleSubmit}
            onBlur={this.handleSubmit}
          >
            <input
              list="search"
              id="searchValueSm"
              name="searchValueSm"
              placeholder="Search"
              onChange={this.handleSearchOnChange}
              value={this.state.searchValueSm}
            />

            <datalist id="search">
              {this.state.searchsuggestions.map((suggestion) => {
                return <option value="suggestion" />;
              })}
            </datalist>

            <button className="searchLogo" type="submit">
              <SearchLogo />
            </button>
          </form>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
export default connect(mapStateToProps)(NavBarSm);
