import React from "react";

function HamburgerIcon(props) {
	return (
		<svg
			id="menu"
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="14.064"
			viewBox="0 0 20 14.064"
		>
			<path
				id="Path_854"
				data-name="Path 854"
				d="M2,6A1,1,0,0,1,3,5H21a1,1,0,0,1,0,2H3A1,1,0,0,1,2,6Z"
				transform="translate(-2 -5)"
				fill={props.color}
			/>
			<path
				id="Path_855"
				data-name="Path 855"
				d="M2,12.032a1,1,0,0,1,1-1H21a1,1,0,0,1,0,2H3A1,1,0,0,1,2,12.032Z"
				transform="translate(-2 -5)"
				fill={props.color}
			/>
			<path
				id="Path_856"
				data-name="Path 856"
				d="M3,17.065a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Z"
				transform="translate(-2 -5)"
				fill={props.color}
			/>
		</svg>
	);
}

export default HamburgerIcon;
