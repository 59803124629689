const initState = {};

export const CurrencyReducer = (state = initState, action) => {
  const currencyData = action.currencyData;
  switch (action.type) {
    case "ADDCURRENCY":
      return {
        ...state,
        ...currencyData,
      };

    default:
      return {
        ...state,
      };
  }
};
export default CurrencyReducer;
