import React, { useState, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import Room from "./Room";
import RatingModal from "../../Components/RatingModal/RatingModal";
import axios from "axios";
import { baseURL } from "../../App";
import moment from "moment";
import { useHistory } from "react-router-dom";
import "moment-timezone";
import "./VideoChat.css";

const VideoChat = (props) => {
	const [token, setToken] = useState(props.match.params.token);
	const [ratingBox, setRatingBox] = useState(false);
	//const [username, updateUserName] = useState(null);
	const [upcommingSession, updateUpcommingSession] = useState(null);
	const [sessionEndDate, updateSessionEndDate] = useState(null);
	const [roomName, setRoomName] = useState(null);
	const [doctorData, UpdateDoctorData] = useState(null);
	const [coachId, updateCoachId] = useState(null);
	const [sessionID, updateSessionID] = useState(null);
	const [finishSign, updateFinishSign] = useState(false);
	const [doctorSpecialization, updateDoctorSpecialization] = useState(null);
	const [serverDate, updateServerDate] = useState(null);
	const query = new URLSearchParams(props.location.search);
	const sessionId = query.get("sessionId");
	const handleLogout = useCallback((event) => {
		setToken(null);
	}, []);
	let history = useHistory();

	var userId = props.auth.authData.user._id;
	var userType = props.auth.authData.userType;

	useEffect(() => {
		const timeZone = localStorage.getItem("timeZone");
		axios({
			method: "get",
			url: `${baseURL}/user/UpcomingSessionNew?clientId=${userId}&userType=${userType}&timeZone=${timeZone}`,
		}).then((res) => {
			if (userType === 1) {
				updateCoachId(res.data.coach[0].coachId);
			}
			updateServerDate(moment(res.data.severTime).format());

			updateSessionEndDate(
				userType === 1
					? res.data.coach[0].sessionEndDate
					: res.data.client[0].sessionEndDate
			);
			updateSessionID(
				userType === 1 ? res.data.coach[0]._id : res.data.client[0]._id
			);
			updateUpcommingSession(
				userType === 1
					? res.data.coach[0].sessionFullDate
					: res.data.client[0].sessionFullDate
			);
			setRoomName(
				res.data.client.length > 0
					? res.data.client[0]._id
					: res.data.coach[0]._id
			);
			userType === 1 && UpdateDoctorData(res.data.coach[0].coach[0]);
			userType === 1 &&
				updateDoctorSpecialization(res.data.coach[0].category[0]);
		});
	}, [userId, userType]);



	useEffect(() => {
		if (upcommingSession && sessionEndDate && serverDate) {
			// const timeZone = localStorage.getItem("timeZone");
			// var eventTime = moment(sessionEndDate).tz(timeZone).valueOf();
			// var currentTime = moment(serverDate).tz(timeZone, true).valueOf();
			// var leftTime = eventTime - currentTime; //Now i am passing the left time from controller itself which handles timezone stuff (UTC), just to simply question i used harcoded values.
			// var interval = 1000;

			// setInterval(function () {
			// 	// Time Out check
			// 	if (leftTime <= 0) {
			// 		setRatingBox(true);
			// 	}
			// 	console.log(sessionEndDate);
			// 	//Otherwise
			// 	leftTime = leftTime - 1000;
			// }, interval);
			if (finishSign) {
				setRatingBox(true);
			}
		}
	}, [finishSign, upcommingSession, sessionEndDate, serverDate]);

	const handleAddExtraMins = () => {
		axios({
			method: "post",
			url: `${baseURL}/user/FireExtendTimeEvevnt`,
			data: {
				id: [coachId, userId],
			},
		});
	};
	useEffect(() => {
		const timeZone = localStorage.getItem("timeZone");
		var now = moment(serverDate).tz(timeZone, true).add(1, 'second').format();
		const sessionEndDateVar = moment(sessionEndDate).tz(timeZone).format()
		setInterval(() => {
			if (now && sessionEndDateVar) {
				now = moment(now).add(1, "second").format();
				var isAfter = moment(now).valueOf() > moment(sessionEndDateVar).add(40, 'minutes').valueOf();
				if (isAfter) {
					updateFinishSign(true);
				}
			}
		}, 1000)
	}, [serverDate, sessionEndDate])
	let render;
	if (token) {
		if (!ratingBox) {
			render = (
				<Room
					className="room"
					roomName={roomName}
					token={token}
					handleLogout={handleLogout}
					sessionDates={{
						sessionEndDate,
						serverDate,
					}}
					userType={userType}
					handleAddExtraMins={handleAddExtraMins}
					getFinishSessionSign={(val) => {
						updateFinishSign(val);
					}}
					sessionId={sessionId}
				/>
			);
		} else {
			if (props.auth.authData.userType === 1)
				render = (
					<RatingModal
						doctorData={doctorData}
						sessionID={sessionID}
						doctorSpecialization={doctorSpecialization}
					/>
				);
			else {
				history.push("/");
				window.location.reload();
			}
		}
	} else {
		render = (
			<div className="noSessionsPage">
				<span>No Sessions For now</span>
			</div>
		);
		/*
		<Redirect to="/" />*/
	}

	return render;
};

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	};
};

export default connect(mapStateToProps)(VideoChat);
