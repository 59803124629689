import React, { useState } from "react";
import { baseURL } from "../../../../App";
import DropDownModal from "../../../../Components/DropDownModal/DropDownModal";
import HamburgerList from "../../../../Components/HamburgerList/HamburgerList";
import NavBarLg from "../../../../Components/NavBarLg/NavBarLg";
import NavBarSm from "../../../../Components/NavBarSm/NavBarSm";
import like from "../../../../Assets/like.svg";
import moment from "moment";
import "./BlogDetails.css";
function BlogDetails(props) {
	const [dropdownMenu, updateDropdownMenu] = useState(false);
	const [hamburgerMenu, toggleHamburgerMenu] = useState(false);
	const toggleDropdownMenu = (val) => {
		updateDropdownMenu(val);
	};
	const { image, guruName, guruImage, title, body, createdAt, noOfLikes } =
		props.location.state;

	return (
		<div className="blog-details">
			<div className="navbarContainer">
				<NavBarLg
					toggleDropdownMenuState={dropdownMenu}
					toggleDropdownMenu={toggleDropdownMenu}
					active="blog"
				/>
				<DropDownModal
					toggleDropdownMenu={toggleDropdownMenu}
					dropDownState={dropdownMenu}
				/>

				<NavBarSm
					toggleHamburgerMenu={() => {
						toggleHamburgerMenu(true);
					}}
					toggleHamburgerMenuState={hamburgerMenu}
					parent="blog"
				/>
				{hamburgerMenu && (
					<HamburgerList
						toggleHamburgerMenu={() => {
							toggleHamburgerMenu(false);
						}}
						toggleHamburgerMenuState={hamburgerMenu}
					/>
				)}
			</div>
			<div>
				<div
					style={{ backgroundImage: `url(${baseURL + "/" + image})` }}
					className="px-4 cover-figure d-flex align-items-center">
					<div className="text-center">
						<figure className="guruImage-figure text-center m-auto">
							<img src={baseURL + "/" + guruImage} alt="guruImage" />
						</figure>
						<div className="guruName p-0 m-0">By: {guruName}</div>
						<div className="guruName">{moment(createdAt).format("LL")}</div>
						<div className="title my-2">{title}</div>
						<div className="d-flex justify-content-center">
							<img src={like} alt="like" className="mx-2" />
							<span className="likes-count">{noOfLikes} </span>
							<span className="color-white mx-1"> Likes</span>
						</div>
					</div>
				</div>
				<div className="px-4">
					<div className="main-title text-center my-4 px-4">{title}</div>
					<div>{body}</div>
				</div>
			</div>
		</div>
	);
}

export default BlogDetails;
