import React, { Component } from "react";
import axios from "axios";
import { baseURL } from "../../App";
import Cross from "../../Assets/PersonalInfoIconCross.png";
import { connect } from "react-redux";
import { AuthState } from "../../Store/Actions/AuthActions";
import OvalEmptyCircle from "../../Components/svgLogos/OvalEmptyCircle";
import { Alert } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ArrowLeftSm from "../../Components/svgLogos/ArrowLeftSm";
import ArrowRightSm from "../../Components/svgLogos/ArrowRightSm";
import OvalFilledCircle from "../../Components/svgLogos/OvalFilledCircle";
import "./AccountProfession.css";
import AccountSavingModal from "../AccountSavingModal/AccountSavingModal";
class AccountProfession extends Component {
	state = {
		prefixList: [],
		specializationsList: [],
		subSpecializationsList: [],
		specialization: this.props.auth.authData.user.specialization,
		specializationName: "",
		prefix: this.props.auth.authData.user.prefix,
		prefixName: "",
		subSpecializationId: this.props.auth.authData.user.subSpecializations,
		subSpecializationName: [],
		covidAssesmentBox: false,
		covidAssesment: this.props.auth.authData.user.covidAssessment,
		bio: this.props.auth.authData.user.coachBio,
		submitKey: 0,
		errMsg: "*",
		show: false,
		alert: false,
		editBtn: true,
		pricePerHour: this.props.auth.authData.user.pricePerHour,
		showAlert: false,
		loadingState: false,
	};

	handleChange = (e) => {
		//const typedValue = Math.floor(((e.target.value * 5) / 5) * 5);
		const typedValue = e.target.value;
		this.setState({ [e.target.id]: typedValue });
	};
	handleSubSpecialization = (e) => {
		if (this.state.specialization !== "") {
			axios({
				method: "post",
				url: baseURL + "/account/SubCategoryListByCategoryId",
				data: { categoryId: this.state.specialization },
			}).then((res) => {
				this.setState({ subSpecializationsList: res.data });
			});

			axios({
				method: "post",
				url: baseURL + "/account/CheckCategoryCovid",
				data: { categoryId: this.state.specialization },
			}).then((res) => {
				this.setState({ covidAssesmentBox: res.data });
			});
			this.setState({ subSpecializationName: [], subSpecializationId: [] });
		}
	};

	handleAddSubSpecialization = (e) => {
		//Adding subSpecialization id lists
		if (
			!this.state.subSpecializationId.includes(e.target.value) &&
			this.state.subSpecializationId.length < 5
		) {
			this.setState({
				subSpecializationId: [
					...this.state.subSpecializationId,
					e.target.value,
				],
			});
		}

		//Adding subSpecialization name list
		if (
			!this.state.subSpecializationName.includes(
				this.state.subSpecializationsList[e.target.selectedIndex - 1]
					.subCategoryName
			) &&
			this.state.subSpecializationName.length < 5
		) {
			this.setState({
				subSpecializationName: [
					...this.state.subSpecializationName,
					this.state.subSpecializationsList[e.target.selectedIndex - 1]
						.subCategoryName,
				],
			});
		}
	};
	handleRemove = (e) => {
		this.setState({
			subSpecializationName: this.state.subSpecializationName.filter(
				(word) => word !== e.target.id
			),
			subSpecializationId: this.state.subSpecializationId.filter(
				(word) => word !== this.state.subSpecializationId[e.target.name]
			),
		});
	};
	handleSubmit = (e) => {
		e.preventDefault();
		this.setState({ submitKey: 1 }, () => {
			if (
				this.state.prefix !== "" &&
				this.state.specialization !== "" &&
				this.state.bio !== "" &&
				this.state.subSpecializationId.length >= 3 &&
				this.state.subSpecializationName.length >= 3
			) {
				this.setState({ submitKey: 2, loadingState: true }, () => {
					axios({
						method: "post",
						url: baseURL + "/coache/Editprofessionalinfo",
						data: {
							Id: this.props.auth.authData.user._id,
							prefix: this.state.prefix,
							specialization: this.state.specialization,
							subSpecializations: this.state.subSpecializationId,
							coachBio: this.state.bio,
							covidAssessment: this.state.covidAssesment,
							pricePerHour: this.state.pricePerHour,
						},
					}).then((res) => {
						if (res.data.message === "Updated Successfully") {
							let data = res.data.user[0];
							if (data.pricePerHour === 0) {
								data.pricePerHour = 0;
							}
							this.setState(
								{ Show: true, editBtn: true, loadingState: false },
								() => {
									this.props.AuthState({ user: data, userType: 2 });
									// this.props.parentCallback(this.state.editBtn);
									this.props.parentCallback(!this.state.editBtn);
								}
							);
							//console.log({ user: res.data.user[0], userType: 2 });
						}
					});
				});
			} else if (this.state.subSpecializationId.length < 3) {
				this.setState({ alert: true });
			}
		});
		// setTimeout(() => {
		//   this.setState({ disable: false });
		// }, 1000);

		setTimeout(() => {
			this.setState({ Show: false });
		}, 3000);
	};

	componentDidMount() {
		axios({
			method: "get",
			url: baseURL + "/account/categoryList",
		}).then((res) => {
			this.setState({ specializationsList: res.data.categories });
		});
		axios({
			method: "get",
			url: baseURL + "/account/prefixList",
		}).then((res) => {
			let prefixList = res.data.prefixes;
			prefixList.forEach((prefix, i) => {
				if (prefix.name.length === 1) {
					prefixList[i].name = "N/A";
				}
			});
			this.setState({ prefixList });
		});

		axios({
			method: "get",
			url: `http://161.97.90.151:6001/coache/getCoachProfession?Id=${this.props.auth.authData.user._id}`,
		}).then((res) => {
			let subCategoryNames = [];
			let subCategoryIds = [];
			res.data[0].subCategory.forEach((subCategoryName) =>
				subCategoryNames.push(subCategoryName.subCategoryName)
			);
			res.data[0].subCategory.forEach((subCategoryId) =>
				subCategoryIds.push(subCategoryId._id)
			);
			this.setState(
				{
					prefix: res.data[0].prefix[0]._id,
					prefixName: res.data[0].prefix[0].name,
					specialization: res.data[0].category[0]._id,
					specializationName: res.data[0].category[0].categoryName,
					subSpecializationName: subCategoryNames,
					subSpecializationId: subCategoryIds,
					bio: res.data[0].coachBio,
				},
				() => {
					axios({
						method: "post",
						url: baseURL + "/account/CheckCategoryCovid",
						data: { categoryId: this.props.auth.authData.user.specialization },
					}).then((res) => {
						this.setState({ covidAssesmentBox: res.data });
					});
				}
			);
		});

		axios({
			method: "post",
			url: baseURL + "/account/SubCategoryListByCategoryId",
			data: { categoryId: this.state.specialization },
		}).then((res) => {
			this.setState({ subSpecializationsList: res.data });
		});
		let subSpecializationName = [];
		this.props.auth.authData.user.subcategories.forEach((subcategory) => {
			subSpecializationName = [
				...subSpecializationName,
				subcategory.subCategoryName,
			];
		});
		this.setState({
			subSpecializationName,
		});
	}
	preventNegativeAndDecimals = (e) => {
		if (e.key === "-" || e.key === ".") e.preventDefault();
	};
	render(props) {
		const handleDecPrice = () => {
			if (this.state.pricePerHour !== 0) {
				this.setState({
					pricePerHour: this.state.pricePerHour - 1,
					showAlert: false,
				});
			}
		};
		const handleIncPrice = () => {
			this.setState({
				pricePerHour: this.state.pricePerHour + 1,
				showAlert: false,
			});
		};
		return (
			<div className="accountProfession">
				<div className="heading">
					Account Profession
					{this.state.editBtn ? (
						<button className="btn" onClick={this.handleSubmit}>
							Save
						</button>
					) : (
						<button
							className="btn"
							onClick={(e) => {
								e.preventDefault();
								this.setState({ editBtn: true }, () => {
									this.props.parentCallback(this.state.editBtn);
								});
							}}>
							Edit
						</button>
					)}
				</div>
				<form>
					<div>
						<label className="mb-0">Price per hour</label>
						<div style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
							{/* Min. {exchangeRate * 1} {exchangeCurrency} */}
							Min. 1 USD
						</div>
						<div className="d-flex align-items-center">
							<span
								className={
									!this.state.editBtn
										? "prcContainer inactiveColor"
										: "prcContainer"
								}>
								<span
									onClick={() => {
										if (this.state.editBtn) {
											handleDecPrice();
										}
									}}
									className="arrowLeft">
									<ArrowLeftSm />
								</span>
								<input
									className="px-4"
									type="number"
									pattern="[0-9]*"
									inputmode="numeric"
									onKeyDown={this.preventNegativeAndDecimals}
									value={
										this.state.pricePerHour === 0
											? "Free"
											: this.state.pricePerHour
									}
									placeholder="Free"
									onChange={(e) => this.handleChange(e)}
									onPaste={(e) => e.preventDefault()}
									id="pricePerHour"
									disabled={!this.state.editBtn}
									// onBlur={() => {
									// 	if (this.state.pricePerHour < Math.ceil(exchangeRate) * 1) {
									// 		this.setState({
									// 			pricePerHour: 0,
									// 		});
									// 	}
									// }}
								/>
								<span
									onClick={() => {
										if (this.state.editBtn) {
											handleIncPrice();
										}
									}}
									className="arrowRight">
									<ArrowRightSm />
								</span>
							</span>
							<span className="fw-600 mainColor mx-2"> $</span>
						</div>
						{/* <div
							style={{
								fontSize: "12px",
								marginBottom: "0.5rem",
								textAlign: "center",
							}}>
							1 USD = {Math.ceil(exchangeRate * 100) / 100} {exchangeCurrency}
						</div> */}
					</div>
					<div>
						<label>Prefix</label>
						{this.state.prefix === "" && this.state.submitKey === 1 && (
							<span className="errMsg">{this.state.errMsg}</span>
						)}
					</div>
					<select
						onChange={this.handleChange}
						id="prefix"
						value={this.state.prefix}
						className={
							this.state.prefix === "" && this.state.submitKey === 1
								? "form-control required"
								: "form-control"
						}
						disabled={!this.state.editBtn}>
						{/* <option selected value="" hidden>
							{this.state.prefixName === ""
								? "Please Select"
								: this.state.prefixName}
						</option> */}
						{this.state.prefixList.map((prefixItem, prefixIndex) => {
							return (
								<option
									key={prefixIndex}
									value={prefixItem._id}
									hidden={prefixItem.name === ""}
									selected={prefixItem.name === ""}>
									{prefixItem.name === "" ? "-" : prefixItem.name}
								</option>
							);
						})}
					</select>
					<div>
						<label> Specialty</label>
						{this.state.specialization === "" && this.state.submitKey === 1 && (
							<span className="errMsg">{this.state.errMsg}</span>
						)}
					</div>
					<select
						onChange={(e) => {
							this.handleChange(e);
							this.handleSubSpecialization(e);
						}}
						disabled={!this.state.editBtn}
						//onBlur={this.handleSubSpecialization}
						value={this.state.specialization}
						id="specialization"
						className={
							this.state.specialization === "" && this.state.submitKey === 1
								? "form-control required"
								: "form-control"
						}>
						<option selected disabled hidden value="">
							{this.state.specializationName}
						</option>
						{this.state.specializationsList.map((specialization) => {
							return (
								<option value={specialization._id}>
									{specialization.categoryName}
								</option>
							);
						})}
					</select>
					<div>
						<label>
							Subspecialty <span>(3-5)</span>
							{this.state.subSpecializationName.length < 3 &&
								this.state.submitKey === 1 && (
									<span className="errMsg">{this.state.errMsg}</span>
								)}
						</label>
					</div>
					<select
						onChange={this.handleAddSubSpecialization}
						id="subSpecializationId"
						className={
							this.state.subSpecializationName.length < 3 &&
							this.state.submitKey === 1
								? "form-control required"
								: "form-control"
						}
						disabled={!this.state.editBtn}>
						<option className="placeholder" selected disabled hidden value="">
							{this.state.subSpecializationName.length > 0
								? this.state.subSpecializationName[
										this.state.subSpecializationName.length - 1
								  ]
								: "Please Select"}
						</option>
						{this.state.subSpecializationsList.map((subSpecialization) => {
							return (
								<option
									value={subSpecialization._id}
									key={subSpecialization._id}>
									{subSpecialization.subCategoryName}
								</option>
							);
						})}
					</select>
					<div className="subSpecializationContainer">
						{this.state.subSpecializationName.map(
							(subSpecializationName, i) => {
								return (
									<div
										key={subSpecializationName}
										className="subspecialization">
										{subSpecializationName}
										<img
											onClick={this.handleRemove}
											id={subSpecializationName}
											className="Cross"
											name={i}
											src={Cross}
											alt="Cross"
										/>
									</div>
								);
							}
						)}
					</div>
					{this.state.alert && (
						<div style={{ textAlign: "center", marginTop: "5px" }}>
							<Alert variant="danger">
								<div>Please insert 3-5 supspecialty</div>
							</Alert>
						</div>
					)}
					{this.state.covidAssesmentBox && (
						<div className="covidAssesmentBox">
							Covid Assesment
							<span
								onClick={() => {
									this.setState({ covidAssesment: !this.state.covidAssesment });
								}}>
								{this.state.covidAssesment ? (
									<OvalFilledCircle />
								) : (
									<OvalEmptyCircle />
								)}
							</span>
						</div>
					)}
					<div>
						<label> Your Bio:</label>
						{this.state.bio === "" && this.state.submitKey === 1 && (
							<span className="errMsg">{this.state.errMsg}</span>
						)}
						<div className="textareaHeading">
							What should your clients know about you?
						</div>
					</div>

					<CKEditor
						editor={ClassicEditor}
						config={{
							toolbar: ["bold", "italic", "numberedList"],
							placeholder: "Keep it friendly ...",
						}}
						//data="<p>Keep it friendly ...</p>"
						data={this.state.bio}
						disabled={!this.state.editBtn}
						className={
							this.state.bio === "" && this.state.submitKey === 1
								? "form-control required textBox"
								: "form-control textBox"
						}
						onReady={(editor) => {
							// You can store the "editor" and use when it is needed.
							console.log("Editor is ready to use!", editor);
						}}
						onChange={(event, editor) => {
							const data = editor.getData();

							this.setState({ bio: data });
						}}
						onBlur={(event, editor) => {
							console.log("Blur.", editor);
						}}
						onFocus={(event, editor) => {
							console.log("Focus.", editor);
						}}
					/>

					<div className="btnContainer">
						{this.state.editBtn ? (
							<button onClick={this.handleSubmit} className="btn">
								Save
							</button>
						) : (
							<button
								onClick={(e) => {
									e.preventDefault();
									this.setState(
										{
											editBtn: true,
										},
										() => {
											this.props.parentCallback(this.state.editBtn);
										}
									);
								}}
								className="btn">
								Edit
								{/* {this.state.editBtn?"save":"Edit"} */}
							</button>
						)}
						{this.state.Show ? (
							<Alert variant="success" className="mt-3">
								Updated Successfully
							</Alert>
						) : null}
					</div>
				</form>
				<AccountSavingModal isModalVisible={this.state.loadingState} />
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		AuthState: (auth) => dispatch(AuthState(auth)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(AccountProfession);
