import React, { Component } from "react";
import FilledStar from "../../Components/svgLogos/FilledStar";
import EmptyStar from "../../Components/svgLogos/EmptyStar";
import Clock from "../svgLogos/SessionDetails/Clock";
import { connect } from "react-redux";
import moment from "moment";
import {
  baseURL,
  exchangeCurrency,
  exchangeRate,
  isSummerTime,
} from "../../App";
import { Link } from "react-router-dom";
import ViewSessionModal from "../../Components/ViewSessionModal/ViewSessionModal";
import "./CoachBookingCard.css";
class CoachBookingCard extends Component {
  state = {
    sessionDetailsPage: "viewSessionDetails",
    date: new Date(),
    showHideCal: false,
    toggleHamburgerMenu: false,
    sessions: [],
    sessionDetailsPopup: false,
    disable: false,
    timeZone: localStorage.getItem("timeZone"),
    hasUnreadMsg: false,
  };

  handlePopup = () => {
    this.setState(
      {
        sessionDetailsPopup: !this.state.sessionDetailsPopup,
        sessionDetailsPage: "viewSessionDetails",
      },
      () => {
        if (!this.state.sessionDetailsPopup) {
          this.props.rerender();
        }
      }
    );
    // setTimeout(function(){ this.setState({disable:true},); }, 3000);
    // setTimeout(() => {
    // 	this.setState({ disable: true });
    // }, 3000);
  };

  render(props) {
    let filledStar = [];
    let emptyStar = [];
    filledStar.length = this.props.session.AverageRate;
    emptyStar.length = 5 - this.props.session.AverageRate;
    filledStar.fill("filled");
    emptyStar.fill("empty");
    return (
      <div className="coachBookCard">
        {/* {this.state.sessionDetailsPopup && ( */}
        <ViewSessionModal
          getHasUnreadMsg={(val) => {
            this.setState({ hasUnreadMsg: val });
          }}
          session={this.props.session}
          sessionType={this.props.sessionType}
          sessionDetailsPopup={this.state.sessionDetailsPopup}
          handlePopup={this.handlePopup}
          sessionDetailsPage={this.state.sessionDetailsPage}
          disable={this.state.disable}
        />
        {/* )} */}
        <div className="startTime">
          {moment(this.props.session.sessionFullDate)
            .tz(
              this.state.timeZone === "Africa/Cairo" && isSummerTime
                ? "Africa/Addis_Ababa"
                : this.state.timeZone
            )
            .format("DD-MM-YYYY hh:mm a")}
        </div>
        <div
          className={
            this.props.sessionType === "client"
              ? "client bookContainer"
              : "coach bookContainer"
          }
        >
          {this.props.sessionType === "client" ? (
            <img
              className="img"
              src={baseURL + "/" + this.props.session.coachImage}
              alt={"firstName"}
            />
          ) : this.props.session.clientImage ? (
            <img
              className="img"
              src={baseURL + "/" + this.props.session.clientImage}
              alt={baseURL + "/" + this.props.session.clientImage}
            />
          ) : (
            <div className="img">
              {this.props.session.anonymous
                ? "AN"
                : this.props.session.clientFullName
                    ?.split(" ")[0][0]
                    ?.toUpperCase() +
                  this.props.session.clientFullName
                    ?.split(" ")[0][1]
                    ?.toUpperCase()}
            </div>
          )}
          {/* {this.props.sessionType === "client" && ( */}
          <span className="price">
            {this.props.session.price === 0 ? (
              <>Free </>
            ) : (
              <>
                {exchangeCurrency +
                  " " +
                  Math.ceil(exchangeRate * this.props.session.price)}
              </>
            )}
          </span>
          {/* )} */}

          <div
            className={
              this.props.sessionType === "coach"
                ? "infoContainer"
                : "infoContainer coach"
            }
          >
            <div className="name">
              {this.props.sessionType === "client" ? (
                <Link to={"gurus/" + this.props.session.coachId}>
                  {this.props.session.prefixName +
                    " " +
                    this.props.session.coachFullName}
                </Link>
              ) : this.props.session.anonymous ? (
                "Anonymous"
              ) : (
                this.props.session.clientFullName
              )}
            </div>
            {this.props.sessionType === "client" && (
              <div>
                <div className="specialization">
                  {this.props.session.categoryName}
                </div>
                <div className="rate" style={{ display: "none" }}>
                  {filledStar.map((rate, i) => {
                    return (
                      <div key={i} className="filledStar">
                        <FilledStar />
                      </div>
                    );
                  })}
                  {emptyStar.map((emptyStar, i) => {
                    return (
                      <div key={i} className="emptyStar">
                        <EmptyStar />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <div className="divider"></div>
          <div className="timeInfo">
            <Clock />
            <span className="timeDetails">
              {moment(this.props.session.sessionFullDate)
                .tz(
                  this.state.timeZone === "Africa/Cairo" && isSummerTime
                    ? "Africa/Addis_Ababa"
                    : this.state.timeZone
                )
                .format("hh:mm a")}{" "}
              -{" "}
              {moment(this.props.session.sessionEndDate)
                .tz(
                  this.state.timeZone === "Africa/Cairo" && isSummerTime
                    ? "Africa/Addis_Ababa"
                    : this.state.timeZone
                )
                .format("hh:mm a")}
            </span>
            <button
              onClick={this.handlePopup}
              className="btn d-flex align-items-center px-2"
            >
              <div>View session</div>
              {/* {this.state.hasUnreadMsg ? (
								<div className="red-dot mx-1"></div>
							) : (
								<></>
							)} */}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
export default connect(mapStateToProps)(CoachBookingCard);
