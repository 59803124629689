import React from "react";
import { Modal } from "antd";
import WelcomeIcon from "../../Assets/WelcomeIcone.svg";
import "./MessSuccModal.css";

function MessSuccModal({ isModalVisible, toggleModal }) {
	return (
		<div>
			<Modal
				visible={isModalVisible}
				onCancel={toggleModal}
				footer={[]}
				width={"30%"}
				style={{ borderRadius: "150px" }}
				centered
				className="messageSuccess"
			>
				<div className="d-flex justify-content-center iconContainer my-2">
					<img src={WelcomeIcon} alt="WelcomeIcon" />
				</div>

				<h5 className="text-center">Message Sent Successfully</h5>
			</Modal>
		</div>
	);
}

export default MessSuccModal;
