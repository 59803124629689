import React, { useState, useRef, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import HomeIcon from "../../Components/svgLogos/FloatingBarIcons/HomeIcon";
import SchedualIcon from "../../Components/svgLogos/FloatingBarIcons/SchedualIcon";
import { Modal } from "react-bootstrap";
import AccountIcon from "../../Components/svgLogos/FloatingBarIcons/AccountIcon";
import { connect } from "react-redux";
//import axios from "axios";
import ChatIcon from "../../Components/svgLogos/FloatingBarIcons/ChatIcon";
import ChatSendIcon from "../../Components/svgLogos/ChatSendIcon";
import firebase from "firebase/app";
import { baseURL } from "../../App";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "firebase/messaging";
import "firebase/firestore";
import "firebase/auth";
import "./FloatingBar.css";
import "firebase/firebase-database";

// firebase.initializeApp({
// 	apiKey: "AIzaSyCESDBNFfFZ7poCpd550hjuF2DKhUfBclQ",
// 	authDomain: "welo-e1758.firebaseapp.com",
// 	databaseURL: "https://welo-e1758-default-rtdb.firebaseio.com",
// 	projectId: "welo-e1758",
// 	storageBucket: "welo-e1758.appspot.com",
// 	messagingSenderId: "301368606527",
// 	appId: "1:301368606527:web:ac92d72e8184991b715d8a",
// 	measurementId: "G-SE0RL59NP0",
// });

const FloatingBar = (props) => {
  const id = props?.auth?.authData && props?.auth?.authData?.user?._id;
  const dummy = useRef();
  const [reqAuth, updateReqAuth] = useState(false);
  const [chatPopup, togglechatPopup] = useState(false);
  const [message, updateMessage] = useState("");
  const [userImg, setUserImg] = useState("");
  const [redirectToContact, updateRedirectToContact] = useState(false);
  const [isMsgRead, updateMsgRead] = useState(false);
  const [msgsCollection, setMsgCollection] = useState([]);
  const [redFlashState, updateRedFlashState] = useState(false);
  const [isUpcomingSession, updateUpcomingSession] = useState(false);
  //const history = useHistory();

  const onChatPopupClick = () => {
    if (props.auth.authData) {
      // axios({
      // 	method: "get",
      // 	url: baseURL + "/admin/CheckOnlineAdmins",
      // }).then((res) => {
      // 	if (res.data.OnlineAdmins) {
      // 		togglechatPopup(!chatPopup);
      // 		updateRedFlashState(false);
      // 		dummy.current.scrollIntoView({ behavior: "smooth" });
      // 	} else {
      // 		updateRedirectToContact(!redirectToContact);
      // 	}
      // });
      updateRedirectToContact(!redirectToContact);
    } else {
      updateReqAuth(true);
    }
  };
  // const createNotification = (type) => {
  // 	return () => {
  // 		switch (type) {
  // 			case "info":
  // 				NotificationManager.info("Info message");
  // 				break;
  // 			case "success":
  // 				NotificationManager.success("Success message", "Title here");
  // 				break;
  // 			case "warning":
  // 				NotificationManager.warning(
  // 					"Warning message",
  // 					"Close after 3000ms",
  // 					3000
  // 				);
  // 				break;
  // 			case "error":
  // 				NotificationManager.error("Error message", "Click me!", 5000, () => {
  // 					alert("callback");
  // 				});
  // 				break;
  // 			default:
  // 				break;
  // 		}
  // 	};
  // };
  useEffect(() => {
    if (props.requestASession) {
      updateMessage(props.message);
      onChatPopupClick();
    }
    // eslint-disable-next-line
  }, [props.requestASession, props.message]);

  useEffect(() => {
    const db = firebase.database();
    var messagesRef = db.ref("messages/" + id);
    var usersRef = db.ref("users/" + id);

    messagesRef.on("value", (snapshot) => {
      if (snapshot.val()) {
        const values = Object.values(snapshot.val());
        setMsgCollection(values);
      }
    });
    usersRef.on("value", (snapshot) => {
      if (snapshot.val()) {
        setUserImg(snapshot.val().image);
        if (!snapshot.val().isUserRead) {
          updateMsgRead(true);
          if (!chatPopup) {
            updateRedFlashState(true);
            localStorage.setItem("redFlash", true);
          }
        }
      }
    });
  }, [id, chatPopup]);
  const sendMsg = (e) => {
    if (message.length > 0) {
      firebase
        .database()
        .ref("messages/" + id)
        .push(
          {
            msg: message,
            createdAt: firebase.database.ServerValue.TIMESTAMP,
            // createdAt:new Date().toDateString(),
            uid: id,
            img: props.auth.authData.user.image
              ? baseURL + "/" + props.auth.authData.user.image
              : "",
            initials:
              props.auth.authData.user.firstName[0] +
              props.auth.authData.user.lastName[0],
            firstName: props.auth.authData.user.firstName,
            lastName: props.auth.authData.user.lastName,
            userType: props.auth.authData.userType,
            sender: "user",
          },
          (error) => {
            if (error) {
              // The write failed...
            } else {
              // Data saved successfully!
            }
          }
        );
      firebase
        .database()
        .ref("users/" + id)
        .set({
          name:
            props.auth.authData.user.firstName +
            " " +
            props.auth.authData.user.lastName,
          userType: props.auth.authData.userType === 2 ? "Guru" : "Client",
          message: message,
          lastDate: firebase.firestore.Timestamp.now().toDate().toString(),
          lastTime:
            firebase.firestore.Timestamp.now().toDate().getHours().toString() +
            ":" +
            firebase.firestore.Timestamp.now().toDate().getMinutes().toString(),
          key: id,
          image: props.auth.authData.user.image,
          isAdminRead: false,
          isUserRead: true,
          email: props.auth.authData.user.email,
          phone: props.auth.authData.user.phone,
        })
        .then(() => {
          updateMessage("");
          dummy.current.scrollIntoView({ behavior: "smooth" });
        });
    }
  };
  useEffect(() => {
    if (isMsgRead && id) {
      NotificationManager.info("", "New chat message from WeLO", 4000, () => {
        togglechatPopup(true);
        updateRedFlashState(false);
      });
      firebase
        .database()
        .ref("users/" + id + "/isUserRead")
        .set(true);
      setTimeout(() => {
        updateMsgRead(false);
      }, 1000);
    }
  }, [isMsgRead, id, chatPopup]);
  useEffect(() => {
    if (localStorage.getItem("redFlash")) {
      updateRedFlashState(true);
      localStorage.removeItem("redFlash");
    }
  }, []);

  let upcomingSessionNode = document.querySelector(".sessionCountdown");

  useEffect(() => {
    if (upcomingSessionNode) {
      updateUpcomingSession(true);
      if (chatPopup) {
        upcomingSessionNode.classList.add("d-none");
      } else {
        upcomingSessionNode.classList.remove("d-none");
      }
    } else {
      updateUpcomingSession(false);
    }
  }, [upcomingSessionNode, chatPopup]);

  let classes = "chattingIcon";
  if (redFlashState) {
    classes += " redFlash";
  }
  if (isUpcomingSession) {
    classes += " toTop";
  }

  if (redirectToContact)
    return (
      <Redirect
        to={{
          pathname: "/contact",
          state: { message: props.message },
        }}
      />
    );
  //if (reqAuth) return <Redirect to="/signin" />;
  if (reqAuth) {
    // history.push("/signin");
    return (
      <Redirect
        to={{
          pathname: "/signin",
          state: { message: "Please signin/signup before chatting with us" },
        }}
      />
    );
  }
  return (
    <div className={props.bookingPopup ? "d-none" : "floatingBarContainer"}>
      {props.auth.authData && (
        <Modal
          show={chatPopup}
          onHide={() => togglechatPopup(!chatPopup)}
          size="lg"
          id="chat"
          aria-labelledby="contained-modal-title-vcenterv"
          centered
        >
          <Modal.Header closeButton>
            <div>Chat</div>
          </Modal.Header>
          <Modal.Body>
            <div className="chatContainer" id="chatBox">
              {msgsCollection &&
                msgsCollection.map((message) => {
                  return (
                    <div
                      key={message.id}
                      className={
                        message.sender === "user"
                          ? "msgContainer sender"
                          : "msgContainer reciever "
                      }
                    >
                      {message.sender === "admin" ? (
                        message.image ? (
                          <img src={message.image} alt="adminImg" />
                        ) : (
                          <div className="initials">{message.initials}</div>
                        )
                      ) : userImg && userImg.length > 0 ? (
                        <img
                          className="initials"
                          src={baseURL + "/" + userImg}
                          alt="userImg"
                        />
                      ) : (
                        <div className="initials">{message.initials}</div>
                      )}
                      <div
                        className={
                          message.sender === "user"
                            ? "msg sender"
                            : "msg reciever"
                        }
                        style={{ marginBottom: 15, whiteSpace: "preWrap" }}
                      >
                        {message.msg}
                      </div>
                    </div>
                  );
                })}
              <div ref={dummy}></div>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                sendMsg();
              }}
            >
              <div className="sendBar">
                <input
                  type="text"
                  onChange={(e) => updateMessage(e.target.value)}
                  className="form-control"
                  value={message}
                  id="message"
                  onKeyPress={(e) => {
                    // if (e.which === 13 && !e.shiftKey) {
                    // 	e.preventDefault();
                    // 	sendMsg();
                    // }
                  }}
                />
                <button>
                  <ChatSendIcon />
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      )}
      <div
        className="floatingBar"
        style={{ display: "none" }}
        // style={
        // 	props.auth.authData && props.auth.authData.userType === 1
        // 		? { display: "none" }
        // 		: {}
        // }
      >
        <span>
          <div className={props.active === "home" && "active"}></div>
          <Link to="/">
            <HomeIcon active={props.active} />
          </Link>
        </span>
        <span>
          <div className={props.active === "schedual" && "active"}></div>
          <Link to="/mySessions">
            <SchedualIcon active={props.active} />
          </Link>
        </span>
        <span>
          <div className={props.active === "account" && "active"}></div>
          <Link to="/account">
            <AccountIcon active={props.active} />
          </Link>
        </span>

        <span
          onClick={() => {
            if (props.auth.authData) {
              // axios({
              // 	method: "get",
              // 	url: baseURL + "/admin/CheckOnlineAdmins",
              // }).then((res) => {
              // 	if (res.data.OnlineAdmins) {
              // 		togglechatPopup(!chatPopup);
              // 		updateRedFlashState(false);
              // 	} else {
              // 		updateRedirectToContact(!redirectToContact);
              // 	}
              // });
              updateRedirectToContact(!redirectToContact);
            } else {
              updateReqAuth(true);
            }
          }}
        >
          <div className={props.active === "chat" && "active"}></div>
          <ChatIcon active={props.active} />
        </span>
      </div>
      {!chatPopup && (
        <div onClick={onChatPopupClick} className={classes}>
          <ChatIcon active={props.active} />
        </div>
      )}

      <NotificationContainer />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
export default connect(mapStateToProps)(FloatingBar);
