import React, { Component } from "react";
import { Redirect } from "react-router-dom";
class PostPay extends Component {
	render(props) {
		return <Redirect to="/" />;
		//return <div></div>;
	}
}

export default PostPay;
