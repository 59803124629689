import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../Assets/Logo.svg";
import LogoLg from "../../Assets/Logo.svg";
import "./Navbar.css";
function NavBar() {
	return (
		<div className="plainNavbar">
			<Link to="/">
				<div className="logo sm">
					<img src={Logo} alt="Logo" />
				</div>
				<div className="logo lg">
					<img src={LogoLg} alt="LogoLg" />
				</div>
			</Link>
			<div className="divider"></div>
		</div>
	);
}

export default NavBar;
