import React, { Component } from "react";
import axios from "axios";
import {
  baseURL,
  exchangeCurrency,
  exchangeRate,
  isSummerTime,
} from "../../App";
import { connect } from "react-redux";
import { Modal, Container, Row, Button } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import OvalFilledDot from "../svgLogos/OvalFilledDot";
import OvalEmptyDot from "../svgLogos/OvalEmptyDot";
import RemainAnonTickBox from "../svgLogos/BookingFigures/RemainAnonTickBox";
import RemainAnonEmptyTick from "../svgLogos/BookingFigures/RemainAnonEmptyTick";
import { BookAction } from "../../Store/Actions/BookAction";
import { AuthState } from "../../Store/Actions/AuthActions";
import WrongEmailCheck from "../svgLogos/WrongEmailCheck";
import RightSign from "../svgLogos/BookingFigures/RightSign";
import QuickBook from "./QuickBook/QuickBook";
import moment from "moment";
import ArrowSVG from "../../Assets/arrow.svg";
import StripeContainer from "../StripePayment/StripeContainer";
import { Card } from "antd";
import "./BookingModal.css";

class BookingModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: new Date(),
      bookPages: "addSessionDetails",
      availableTimes: [],
      isOpen: true,
      isClicked: false,
      theme: "ios",
      dayName: null,
      prevDayName: null,
      nextDayName: null,
      timeSlot: "",
      period: this.props.exact ? this.props.exact.period : "",
      sessionTopic: "",
      anonymous: false,
      PaymentURL: null,
      pricePerHour: this.props.pricePerHour,
      redirectToPay: false,
      availableTimesMessage: "",
      timeZone: localStorage.getItem("timeZone"),
      timeServer: null,
      startDateTime: this.props.exact ? this.props.exact.startDateTime : null,
      endDateTime: this.props.exact ? this.props.exact.endDateTime : null,
      timeslotId: this.props.exact ? this.props.exact._id : null,
      discount: this.props.exact
        ? this.props.auth.authData.user.freeSessionCount
          ? 0
          : this.props.exact.adminDiscount
          ? parseInt(this.props.exact.adminDiscount) / 100
          : parseInt(this.props.perDayDiscount) / 100
        : 0,
      loadingState: false,
      promoCode: "",
      promoLoadingState: false,
      promoCodeState: 0,
      nextSessionShow: false,
      nextAvailableSession: null,
      selectedPackage: null,
      hasPackage: false,
      isFirstSessionFree: false,
    };
  }

  scrollToDate = (targetId) => {
    setTimeout(() => {
      try {
        var leftPos = document.getElementById(targetId).offsetLeft;
        document.getElementById("dateContainer").scrollLeft = leftPos - 50;
      } catch (e) {
        console.log("Couldn't find element with ID: " + targetId);
      }
    }, 1000);
  };
  handleTimeSlot = (e) => {
    console.log("e", e.target.id);
    this.setState({
      startDateTime: e.target.id.split("-")[0],
      endDateTime: e.target.id.split("-")[1],
      period: e.target.id.split("-")[2],
      timeslotId: e.target.id.split("-")[3],
      discount: !this.props.auth.authData
        ? 0
        : this.props.auth.authData.user.freeSessionCount
        ? 0
        : e.target.id.split("-")[4] > 0
        ? parseInt(e.target.id.split("-")[4]) / 100
        : parseInt(this.props.perDayDiscount) / 100,
    });
  };
  handleChange = (time) => {
    const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    var myDate;
    this.setState({ time, timeslotId: null }, () => {
      myDate = new Date(
        this.state.time.getTime() - this.state.time.getTimezoneOffset() * 60000
      );

      var dayNum = myDate.getDay();
      var nextDayNum = myDate.getDay() + 1;
      var prevDayNum = myDate.getDay() - 1;
      if (prevDayNum === -1) {
        prevDayNum = 6;
      }
      if (nextDayNum === 7) {
        nextDayNum = 0;
      }

      // this.getAvailableTimes(myDate);
      this.setState({
        availableTimes: [],
        availableTimesMessage: "Loading availabilities...",
        nextSessionShow: false,
      });

      try {
        axios({
          method: "post",
          url: baseURL + "/coache/GetCoachAvailableTimesNew",
          data: {
            coachId: this.props.id,
            date: JSON.stringify(myDate).substr(1, 10),
            timeZone: this.state.timeZone,
          },
        }).then((res) => {
          this.setState(
            {
              nextSessionShow: res.data.nextSessionShow,
              availableTimes: res.data.availableTimes,
              availableTimesMessage:
                res.data.availableTimes.length === 0
                  ? "No availabilities for this date"
                  : "",
            },
            () => {
              if (this.state.nextSessionShow) {
                this.setState({
                  nextAvailableSession: res.data.availableTimes,
                });
              }
            }
          );

          if (res.data.availableTimes.length === 0) {
            // this.getNextAvailableTimes();
          }
        });
      } catch (e) {
        this.setState({
          availableTimes: [],
          availableTimesMessage: "Something went wrong...",
        });
        console.log("ERROR: booking modal couldn't get available times\n", e);
      }

      this.setState({
        prevDayName: weekday[prevDayNum],
        dayName: weekday[dayNum],
        nextDayName: weekday[nextDayNum],
      });
    });
  };

  handleDateChange = (e) => {
    var time = e.target.id.split("-D-")[1];
    this.scrollToDate(e.target.id);
    this.handleChange(new Date(time));
  };

  componentDidMount() {
    var weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    var dayNum = new Date(this.state.time).getDay();
    var nextDayNum = new Date(this.state.time).getDay() + 1;
    var prevDayNum = new Date(this.state.time).getDay() - 1;
    axios({
      method: "get",
      url:
        baseURL +
        `/coache/checkIsFirstSessionFree?clientId=${this.props.auth.authData.user._id}&coachId=${this.props.id}`,
    }).then((res) => {
      this.setState({ isFirstSessionFree: res.data.firstFreeSession });
    });
    axios({
      method: "get",
      url:
        baseURL +
        `/package/checkClientHasPackageForCoach?clientId=${this.props.auth.authData.user._id}&coachId=${this.props.id}`,
    }).then((res) => {
      this.setState({ hasPackage: res.data.data.packageId });
    });

    if (prevDayNum === -1) {
      prevDayNum = 6;
    }
    if (nextDayNum === 7) {
      nextDayNum = 0;
    }

    if (this.props.isPackage) {
      this.setState({ bookPages: "packagesDetails" });
    }

    if (this.props.exact) {
      const eventt = {
        target: {
          id:
            this.props.exact.startDateTime +
            "-" +
            this.props.exact.endDateTime +
            "-" +
            this.props.exact.period +
            "-" +
            this.props.exact._id +
            "-" +
            this.props.exact.adminDiscount,
        },
      };
      let nextDate = new Date(this.props.exact.startDateTime);
      nextDate.setHours(0, 0, 0);
      let data = {
        target: {
          id: "dateBox-D-" + nextDate.toString(),
        },
      };
      this.handleDateChange(data);
      this.handleTimeSlot(eventt);
    }

    // this.getAvailableTimes(this.props.exactDate
    // 	? this.props.exactDate.toISOString().substr(0, 10)
    // 	: new Date(res.data))

    this.setState({
      availableTimes: [],
      availableTimesMessage: "Loading availabilities...",
    });

    axios({
      method: "get",
      url: baseURL + `/account/GetServerTime?timeZone=${this.state.timeZone}`,
    }).then((res) => {
      this.setState(
        {
          timeServer: res.data,
          time: this.props.exact
            ? new Date(this.props.exact.startDateTime)
            : new Date(res.data),
        },
        () => {
          try {
            axios({
              method: "post",
              url: baseURL + "/coache/GetCoachAvailableTimesNew",
              data: {
                coachId: this.props.id,
                date: this.props.exact
                  ? moment(this.props.exact.startDateTime).format("YYYY-MM-DD")
                  : moment(this.state.time).format("YYYY-MM-DD"),
                timeZone: this.state.timeZone,
              },
            }).then((res) => {
              this.setState(
                {
                  nextSessionShow: res.data.nextSessionShow,
                  availableTimes: res.data.availableTimes,
                  availableTimesMessage:
                    res.data.availableTimes.length === 0
                      ? "No availabilities for this date"
                      : "",
                },
                () => {
                  if (this.state.nextSessionShow) {
                    this.setState({
                      nextAvailableSession: res.data.availableTimes,
                    });
                  }
                }
              );

              if (res.data.availableTimes.length === 0) {
                // this.getNextAvailableTimes();
              }
            });
          } catch (e) {
            this.setState({
              availableTimes: [],
              availableTimesMessage: "Something went wrong...",
            });
            console.log(
              "ERROR: booking modal couldn't get available times\n",
              e
            );
          }
        }
      );
    });

    this.setState({
      prevDayName: weekday[prevDayNum],
      dayName: weekday[dayNum],
      nextDayName: weekday[nextDayNum],
    });
  }

  requestPaymentUrl = () => {
    // this.props.auth.authData &&
    // 	axios({
    // 		method: "post",
    // 		url: baseURL + "/payment/CreatePaymentSession",
    // 		data: {
    // 			clientEmail: this.props.auth.authData.user.email,
    // 			coacheEmail: this.props.email,
    // 			currency: exchangeCurrency,
    // 			period: this.state.period,
    // 			exchangeRate,
    // 			timeSlotId: this.state.timeslotId,
    // 		},
    // 	}).then((res) => {
    // 		this.setState({ PaymentURL: res.data.url });
    // 	});
  };

  handleSwitchPage = (e) => {
    this.setState({ bookPages: e.target.id }, () => {
      if (this.state.bookPages === "sessionPayment") {
        console.log("this.state.time", this.state.time);
        console.log("this.state.endDateTime", this.state.endDateTime);

        axios({
          method: "post",
          url: baseURL + "/user/CheckValidBooking",
          data: {
            coachId: this.props.id,
            clientId: this.props.auth.authData.user._id,
            fullDate: moment(this.state.startDateTime)
              .add(
                this.state.timeZone === "Africa/Cairo" && isSummerTime ? 1 : 0,
                "h"
              )
              .tz(this.state.timeZone)
              .format(),
            endDate: moment(this.state.endDateTime)
              .add(
                this.state.timeZone === "Africa/Cairo" && isSummerTime ? 1 : 0,
                "h"
              )
              .tz(this.state.timeZone)
              .format(),
          },
        })
          .then((res) => {})
          .catch((err) => {
            if (err.response && err.response.data.code === -3) {
              this.props.toggleBookingPages();
              alert(err.response.data.message);
            }
          });

        this.props.BookAction({
          startDateTime: this.state.startDateTime,
          endDateTime: this.state.endDateTime,
          coachFirstName: this.props.firstName,
          coachLastName: this.props.lastName,
          coachImage: this.props.image,
          coachPrefix: this.props.prefix,
          coachSpecialization: this.props.specialization,
          coachId: this.props.id,
          time: this.state.time,
          timeSlot: this.state.timeSlot,
          period: this.props.exactPeriod
            ? this.props.exactPeriod
            : this.state.period,
          periodMin: this.props.exactPeriod
            ? this.props.exactPeriod
            : this.state.period,
          sessionTopic: this.state.sessionTopic,
          anonymous: this.state.anonymous,
          price: this.props.pricePerHour,
          unit: this.props.unit[0].unitName,
          coachTimeId: this.state.timeslotId,
          currency: exchangeCurrency,
          isPackage: false,
        });
      }
      if (
        this.state.bookPages === "confirmSessionDetails" ||
        this.props.pricePerHour !== 0
      ) {
        this.requestPaymentUrl();
      }
    });
  };
  changeHandler = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  /*componentDidUpdate() {
		if (this.props.exactDate) {
			axios({
				method: "post",
				url: baseURL + "/coache/GetCoachAvailableTimes",
				data: {
					coachId: this.props.id,
					date: this.props.exactDate
						? JSON.stringify(
								new Date(
									this.props.exactDate.getTime() -
										this.props.exactDate.getTimezoneOffset() * 60000
								)
						  ).substr(1, 10)
						: JSON.stringify(
								new Date(
									new Date().getTime() - new Date().getTimezoneOffset() * 60000
								)
						  ).substr(1, 10),
				},
			}).then((res) => {
				this.setState({
					availableTimes: res.data.availableTimes,
					timeSlot: this.props.exactTime,
					period: this.props.exactPeriod,
					time: this.props.exactDate,
				});
			});
		}
	}
	*/
  // getAllPackages = () => {
  // 	axios({
  // 		method: "get",
  // 		url: baseURL + `/package/coachPackages?coachId=${this.props.id}`,
  // 	}).then((res) => {
  // 		this.setState({ packages: res.data.data.coachPackages });
  // 	});
  // };
  getAvailableTimes = async (date) => {
    try {
      this.setState({
        availableTimes: [],
        availableTimesMessage: "Loading availabilities...",
        nextSessionShow: false,
      });

      await axios({
        method: "get",
        url: baseURL + `/account/GetServerTime?timeZone=${this.state.timeZone}`,
      }).then((res) => {
        this.setState(
          {
            timeServer: res.data,
            time: this.props.exact
              ? new Date(this.props.exact.startDateTime)
              : new Date(res.data),
          },
          () => {
            axios({
              method: "post",
              url: baseURL + "/coache/GetCoachAvailableTimesNew",
              data: {
                coachId: this.props.id,
                date: this.props.exact
                  ? moment(this.props.exact.startDateTime).format("DD-MM-YYYY")
                  : moment(this.state.time).format("DD-MM-YYYY"),
                timeZone: this.state.timeZone,
              },
            }).then((res) => {
              this.setState(
                {
                  nextSessionShow: res.data.nextSessionShow,
                  availableTimes: res.data.availableTimes,
                },
                () => {
                  if (this.state.nextSessionShow) {
                    this.setState({
                      nextAvailableSession: res.data.availableTimes,
                    });
                  }
                }
              );
            });
          }
        );
      });
    } catch (e) {
      this.setState({
        availableTimes: [],
        availableTimesMessage: "Something went wrong...",
      });
      console.log("ERROR: booking modal couldn't get available times\n", e);
    }
  };
  componentWillUnmount() {
    const sessionCountdownNode = document.querySelector(".sessionCountdown");
    if (sessionCountdownNode) {
      sessionCountdownNode.classList.remove("d-none");
    }
  }
  render(props) {
    const sessionCountdownNode = document.querySelector(".sessionCountdown");
    const isAuth = this.props.auth.authData;
    if (sessionCountdownNode) {
      sessionCountdownNode.classList.add("d-none");
    }
    var minDate = new Date(this.state.timeServer);
    var maxDate = new Date(this.state.timeServer);
    if (this.props.auth.authData) {
      var paymentData = {
        clientEmail: this.props.auth.authData.user.email,
        coacheEmail: this.props.email,
        currency: exchangeCurrency,
        period: this.state.period,
        // exchangeRate: exchangeRate,
        timeSlotId: this.state.timeslotId,
      };
    }

    maxDate.setDate(minDate.getDate() + 89);
    const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    const fullDayNames = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const fullMonthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const applyPromoCode = () => {
      this.setState({
        promoLoadingState: true,
      });
      let data = {
        coupon: this.state.promoCode.trim(),
        coachId: this.props.id,
        timeSlotId: this.state.timeslotId,
        clientId: this.props.auth.authData.user._id,
        packageId: this.state.selectedPackage?._id,
        clientEmail: this.props.auth.authData.user.email,
      };
      axios({
        method: "post",
        url: baseURL + "/account/CheckCoupon",
        data,
      })
        .then((res) => {
          this.setState({
            promoLoadingState: false,
            promoCodeState: 1,
            discount: res.data.discountPercentage / 100,
          });
        })
        .catch((error) => {
          this.setState({ promoLoadingState: false });
          if (error.response.data.code === -1) {
            this.setState({ promoCodeState: -1 });
          }
        });
    };

    const handleGoToToday = () => {
      var tempDate = new Date(minDate);

      tempDate = new Date(tempDate.setHours(0));
      tempDate = new Date(tempDate.setMinutes(0));
      tempDate = new Date(tempDate.setSeconds(0));

      this.handleChange(tempDate);
      this.scrollToDate("dateBox-D-" + tempDate);
    };

    const handleDateNav = (e) => {
      var change = 0;

      switch (e.target.id) {
        case "btnPrevDay":
          change = -1;
          break;
        case "btnNextDay":
          change = 1;
          break;
        default:
          break;
      }

      var tempDate = new Date(
        new Date(this.state.time).setDate(this.state.time.getDate() + change)
      );

      tempDate = new Date(tempDate.setHours(0));
      tempDate = new Date(tempDate.setMinutes(0));
      tempDate = new Date(tempDate.setSeconds(0));
      this.setState({ time: tempDate }, () => {
        this.handleChange(new Date(tempDate));
        this.scrollToDate("dateBox-D-" + tempDate);
      });
    };
    const isSameDate = (d1, d2) => {
      return (
        d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate()
      );
    };

    const timeSpanToAMPM = (time) => {
      var startTime = new Date(time.startDateTime);
      var endTime = new Date(time.endDateTime);
      // time = time.split('-');
      return (
        timeToAMPM(startTime.getHours() + ":" + startTime.getMinutes()) +
        " to " +
        timeToAMPM(endTime.getHours() + ":" + endTime.getMinutes())
      );
    };

    const timeToAMPM = (time) => {
      time = time.split(":");

      time[2] = time[0] < 12 ? "AM" : "PM";
      time[0] = +time[0] % 12 || 12;

      if (time[1] < 10) time[1] = "0" + time[1];

      return `${time[0]}:${time[1]} ${time[2]}`;
    };

    var dateBoxes = [];
    var tempDate = new Date(minDate);
    tempDate = new Date(tempDate.setHours(0));
    tempDate = new Date(tempDate.setMinutes(0));
    tempDate = new Date(tempDate.setSeconds(0));

    var todayBox = (
      <div
        class={
          isSameDate(this.state.time, minDate)
            ? "date-box today selected"
            : "date-box today"
        }
        id={"dateBox-D-" + tempDate}
        onClick={this.handleDateChange}
      >
        <div class="date-day-name">{weekday[tempDate.getDay()]}</div>
        <div class="date-day-number">{tempDate.getDate()}</div>
      </div>
    );

    dateBoxes.push(todayBox);

    for (var i = 1; i < 90; i++) {
      tempDate.setDate(tempDate.getDate() + 1);

      var box = (
        <div
          class={
            isSameDate(this.state.time, tempDate)
              ? "date-box selected"
              : "date-box"
          }
          id={"dateBox-D-" + tempDate}
          onClick={this.handleDateChange}
        >
          <div class="date-day-name">{weekday[tempDate.getDay()]}</div>
          <div class="date-day-number">{tempDate.getDate()}</div>
        </div>
      );

      dateBoxes.push(box);
    }

    if (this.state.redirectToPay) {
      localStorage.setItem("bookingStatus", true);
      if (this.props.location === "doctorDetails") {
        return <Redirect to="/" />;
      } else {
        window.location.reload(false);
      }
    }
    const setRedirectToPay = () => {
      this.setState({ redirectToPay: true });
    };

    const handleSubmit = () => {
      this.setState({ loadingState: true });
      let data = {
        sessionTopic: this.state.sessionTopic,
        coachId: this.props.id,
        clientId: this.props.auth.authData.user._id,
        anonymous: this.state.anonymous,
        price: 0,
        userType: this.props.auth.authData.userType,
        paymentNumber: 0,
        coachTimeId: this.state.timeslotId,
        timeZone: this.state.timeZone,
        applyFreeSession: false,
        // eslint-disable-next-line
        period: this.state.period == "1" ? 1 : 0,
        // eslint-disable-next-line
        periodMin: this.state.period == "1" ? 0 : 30,
        applyCoupon: this.state.promoCodeState === 1 ? true : false,
        currency: exchangeCurrency,
        packageId: this.state.hasPackage,
        applyPackage: this.state.hasPackage ? true : false,
        isFirstSessionFree: this.state.isFirstSessionFree,
        coupon:
          this.state.promoCodeState === 1 ? this.state.promoCode.trim() : null,
      };
      axios({
        method: "post",
        url: baseURL + "/user/bookSessionNew",
        data,
      })
        .then((res) => {
          axios({
            method: "post",
            url: baseURL + "/account/NotifyChangeSchedule",
          }).then((res) => {});
          this.props.BookAction({
            ...this.props.book,
            userToken: res.data.data.usertoken,
            sessionId: res.data.data.sessionId,
          });

          localStorage.setItem("transactionState", "Success");
          this.setState({ redirectToPay: true });
        })
        .catch((err) => {
          if (err.response && err.response.data.code === -3) {
            localStorage.setItem("transactionState", "Fail");
            alert(err.response.data.message);
          }
        });
    };

    var priceAfterDiscount = "0";
    var priceBeforeDiscount = "0";
    const price = Math.ceil(this.props.pricePerHour * exchangeRate);
    // eslint-disable-next-line
    if (this.state.period == 30) {
      priceAfterDiscount = Math.ceil(
        price * 0.6 - price * 0.6 * this.state.discount
      );
      priceBeforeDiscount = Math.ceil(price * 0.6);
    } else {
      priceAfterDiscount = Math.ceil(price - price * this.state.discount);
      priceBeforeDiscount = Math.ceil(price);
    }
    if (priceAfterDiscount < 1) {
      priceAfterDiscount = 1;
    }

    priceAfterDiscount = Math.ceil(priceAfterDiscount) + " " + exchangeCurrency;
    priceBeforeDiscount = priceBeforeDiscount + " " + exchangeCurrency;

    if (priceAfterDiscount.startsWith("0")) {
      priceAfterDiscount = "Free";
    }
    const isSessionFree =
      this.state.pricePerHour -
        this.state.pricePerHour * this.state.discount ===
        0 ||
      this.state.pricePerHour === 0 ||
      this.state.hasPackage ||
      this.state.isFirstSessionFree
        ? true
        : false;
    var isPackageFree =
      this.state.selectedPackage?.price * (1 - this.state.discount) === 0;

    const handleSubmitPackage = () => {
      axios({
        method: "post",
        url: baseURL + "/payment/payCoachPackage",
        data: {
          packageId: this.state.selectedPackage?._id,
          coachId: this.props.id,
          clientId: this.props.auth.authData.user._id,
          currency: exchangeCurrency,
          exchangeRate: exchangeRate,
          coupon: this.state.promoCode,
        },
      }).then((res) => {
        if (res.data.code === 1) {
          localStorage.setItem("transactionState", "Success");
          setRedirectToPay();
        } else {
          localStorage.setItem("transactionState", "Fail");
          setRedirectToPay();
        }
      });
    };

    return (
      <Modal
        show={this.props.bookingPopup}
        onHide={this.props.toggleBookingPages}
        size="md"
        id="bookPages"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {this.state.bookPages === "addSessionDetails" && (
              <div className="addSessionDetails">
                <div className="title">
                  <div>Session Date</div>
                </div>
                <div className="Progress">
                  <OvalFilledDot />
                  <span className="dottedStroke"></span>
                  <OvalEmptyDot />
                  <span className="dottedStroke"></span>
                  <OvalEmptyDot />
                </div>
              </div>
            )}
            {this.state.bookPages === "confirmSessionDetails" && (
              <div className="confirmSessionDetails">
                <div className="title">
                  {/* <div>Confirm</div> */}
                  <div>Session Details </div>
                </div>
                <div className="Progress">
                  <OvalEmptyDot />
                  <span className="filledStroke"></span>
                  <OvalFilledDot />
                  <span className="dottedStroke"></span>
                  <OvalEmptyDot />
                </div>
              </div>
            )}
            {this.state.bookPages === "sessionPayment" && (
              <div className="sessionPayment">
                <div className="title">
                  <div>Session Payment</div>
                  {/* <div>Payment</div> */}
                </div>
                <div className="Progress">
                  <OvalEmptyDot />
                  <span className="filledStroke"></span>
                  <OvalEmptyDot />
                  <span className="filledStroke"></span>
                  <OvalFilledDot />
                </div>
              </div>
            )}
            {this.state.bookPages === "packagePayment" && (
              <div className="sessionPayment">
                <div className="title">
                  <div>Package Payment</div>
                  {/* <div>Payment</div> */}
                </div>
                <div className="Progress">
                  <OvalEmptyDot />
                  <span className="filledStroke"></span>
                  <OvalEmptyDot />
                  <span className="filledStroke"></span>
                  <OvalFilledDot />
                </div>
              </div>
            )}
            {this.state.bookPages === "packagesDetails" && (
              <div className="sessionPayment">
                <div className="title">
                  <div>Select a Package</div>
                </div>
                <div className="Progress">
                  <OvalEmptyDot />
                  <span className="filledStroke"></span>
                  <OvalFilledDot />
                  <span className="filledStroke"></span>
                  <OvalEmptyDot />
                </div>
              </div>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.loadingState ? (
            <div className="loader"></div>
          ) : this.props.isPackage ? (
            <div className="package-payment-form">
              <div className="PersonInfo">
                <img
                  src={baseURL + "/" + this.props.image}
                  className="initials"
                  alt="avatar"
                />
                <span className="info">
                  <div className="name">
                    {this.props.prefix}
                    {this.props.firstName} {this.props.lastName}
                  </div>
                  <div className="specialization">
                    {this.props.specialization[0].categoryName}
                  </div>
                </span>
              </div>
              {this.state.bookPages === "packagesDetails" ? (
                <div>
                  <div className="d-flex flex-wrap flex-1 justify-content-center">
                    {this.props.packages?.map((packageDetail, index) => {
                      return (
                        <Card
                          index={index}
                          onClick={() => {
                            this.setState({ selectedPackage: packageDetail });
                          }}
                          className={`mr-2 my-2 package-card ${
                            this.state.selectedPackage?._id ===
                            packageDetail._id
                              ? "selected-package"
                              : ""
                          }`}
                          title={packageDetail.name}
                          bordered={true}
                        >
                          <div>{packageDetail.description}</div>
                          <div className="d-flex mt-4 flex-1 justify-content-end f-14">
                            <div>
                              Price:
                              {Math.ceil(exchangeRate * packageDetail.price)}
                              {exchangeCurrency}/{packageDetail.sessionCount}{" "}
                              Sessions
                            </div>
                          </div>
                        </Card>
                      );
                    })}
                  </div>
                  <div className="btnContainer mt-4 px-2">
                    <button
                      id={"packagePayment"}
                      onClick={(e) => {
                        if (this.state.selectedPackage) {
                          this.props.BookAction({
                            ...this.state.selectedPackage,
                            coachId: this.props.id,
                            currency: exchangeCurrency,
                            exchangeRate,
                            isPackage: true,
                            price: this.state.selectedPackage.price,
                            coachFirstName: this.props.firstName,
                            coachLastName: this.props.lastName,
                            coachImage: this.props.image,
                            coachPrefix: this.props.prefix,
                            coachSpecialization: this.props.specialization,
                          });
                          this.handleSwitchPage(e);
                        }
                      }}
                      className={
                        this.state.selectedPackage ? "btn" : "btn disabled"
                      }
                    >
                      {this.state.selectedPackage && "Next"}
                      {this.state.selectedPackage == null &&
                        "Please select a package"}
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <div className="my-4">
                    <div className="d-flex align-items-center justify-content-center">
                      <div className={"promoCodeContainer"}>
                        <input
                          type="text"
                          className="form-control promoCodeField"
                          placeholder="PromoCode"
                          value={this.state.promoCode}
                          onChange={(e) => {
                            this.setState({
                              promoCode: e.target.value,
                            });
                          }}
                        />
                        <div className="promoCodeSign">
                          {this.state.promoCodeState !== 0 ? (
                            this.state.promoCodeState === 1 ? (
                              <RightSign />
                            ) : (
                              <WrongEmailCheck />
                            )
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <Button
                        className="btn mx-2 my-0 promoApplyBtn d-flex justify-content-center align-items-center"
                        onClick={applyPromoCode}
                        disabled={!this.state.promoCode}
                        style={{ border: "none" }}
                      >
                        {this.state.promoLoadingState ? (
                          <div className="loader"></div>
                        ) : (
                          <div>Apply</div>
                        )}
                      </Button>
                    </div>
                    <div>
                      {this.state.promoCodeState === -1 && (
                        <div className="text-danger text-center">
                          The promo code you entered is invalid. Please try
                          again.
                        </div>
                      )}{" "}
                    </div>
                  </div>

                  <div className="total my-4">
                    Total:{" "}
                    <>
                      <span
                        className={
                          this.state.promoCodeState === 1 &&
                          "priceBeforeDiscount"
                        }
                      >
                        {Math.ceil(
                          this.state.selectedPackage?.price * exchangeRate
                        )}
                        {exchangeCurrency}
                      </span>
                      {this.state.promoCodeState === 1 && (
                        <span className="priceAfterDiscount">
                          {" "}
                          {isPackageFree ? (
                            "Free"
                          ) : (
                            <>
                              {Math.ceil(
                                this.state.selectedPackage?.price *
                                  (1 - this.state.discount) *
                                  exchangeRate
                              )}{" "}
                              {exchangeCurrency}
                            </>
                          )}
                        </span>
                      )}
                    </>
                  </div>

                  {isPackageFree && this.state.promoCodeState ? (
                    <div className="text-center">
                      <button
                        id="packagesDetails"
                        onClick={(e) => {
                          isPackageFree = false;
                          this.handleSwitchPage(e);
                          this.setState({
                            promoCode: "",
                            promoLoadingState: false,
                            promoCodeState: 0,
                          });
                        }}
                        className="btn"
                      >
                        Back
                      </button>
                      <button className="btn" onClick={handleSubmitPackage}>
                        <div>Confirm</div>
                      </button>
                    </div>
                  ) : (
                    <div className="my-4">
                      <StripeContainer
                        id="packagesDetails"
                        paymentData={paymentData}
                        setRedirectToPay={setRedirectToPay}
                        onClick={(e) => {
                          isPackageFree = false;
                          this.handleSwitchPage(e);
                          this.setState({
                            promoCode: "",
                            promoLoadingState: false,
                            promoCodeState: 0,
                          });
                        }}
                        coupon={
                          this.state.promoCodeState === 1
                            ? this.state.promoCode
                            : null
                        }
                        back={"packagesDetails"}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          ) : (
            <>
              {this.state.bookPages === "addSessionDetails" && (
                <div className="addSessionDetails">
                  <div className="PersonInfo" style={{ display: "none" }}>
                    <img
                      src={baseURL + "/" + this.props.image}
                      className="initials"
                      alt="avatar"
                    />
                    <span className="info">
                      <div className="name">
                        {this.props.prefix}
                        {this.props.firstName} {this.props.lastName}
                      </div>
                      <div className="specialization">
                        {this.props.specialization[0].categoryName}
                      </div>
                    </span>
                  </div>
                  <Container>
                    <Row>
                      <div
                        class="availability-row"
                        style={{
                          cursor: "unset",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          border: "none",
                        }}
                      >
                        <label id="userDate">
                          {fullMonthNames[this.state.time.getMonth()]}
                        </label>
                        <div class="calendar-nav-row">
                          <button
                            disabled={isSameDate(this.state.time, minDate)}
                            class="calendar-nav-button"
                            style={{ paddingLeft: "0" }}
                            type="button"
                            id="btnPrevDay"
                            onClick={handleDateNav}
                          >
                            <img
                              alt={""}
                              class="caret-icon"
                              style={{ transform: "rotate(180deg)" }}
                              src={ArrowSVG}
                            />
                          </button>
                          <div
                            class="date-container-container"
                            id="dateContainer"
                          >
                            <div class="date-box-container">{dateBoxes}</div>
                          </div>
                          <button
                            disabled={isSameDate(this.state.time, maxDate)}
                            class="calendar-nav-button"
                            style={{ paddingRight: 0 }}
                            type="button"
                            id="btnNextDay"
                            onClick={handleDateNav}
                          >
                            <img class="caret-icon" src={ArrowSVG} alt={""} />
                          </button>
                        </div>
                        <button
                          class={
                            isSameDate(this.state.time, minDate)
                              ? "calendar-text-button hyper disabled"
                              : "calendar-text-button hyper"
                          }
                          style={{ margin: "0 auto 0 0" }}
                          type="button"
                          id="btnToday"
                          onClick={handleGoToToday}
                        >
                          Go to today
                        </button>
                      </div>
                    </Row>
                    <Row>
                      <div className="timeSlots timeSlotsContainer">
                        <div className="container-fluid">
                          <div className="row">
                            <div class="availability-container">
                              {this.state.availableTimesMessage ?? (
                                <div
                                  className="p-2"
                                  style={{ padding: "0.5rem!important" }}
                                >
                                  {this.state.availableTimesMessage}
                                </div>
                              )}

                              {this.state.nextSessionShow ? (
                                this.state.nextAvailableSession &&
                                this.state.nextAvailableSession.period ? (
                                  <div className="p-2">
                                    No sessions available today, the next
                                    availability is at{" "}
                                    <div
                                      className="nextSession"
                                      id={
                                        this.state.nextAvailableSession
                                          .startDateTime +
                                        "-" +
                                        this.state.nextAvailableSession
                                          .endDateTime +
                                        "-" +
                                        this.state.nextAvailableSession.period +
                                        "-" +
                                        this.state.nextAvailableSession._id +
                                        "-" +
                                        this.state.nextAvailableSession
                                          .adminDiscount
                                      }
                                      onClick={(e) => {
                                        let nextDate = new Date(
                                          this.state.nextAvailableSession.startDateTime
                                        );
                                        nextDate.setHours(0, 0, 0);
                                        let data = {
                                          target: {
                                            id:
                                              "dateBox-D-" +
                                              nextDate.toString(),
                                          },
                                        };
                                        this.handleDateChange(data);
                                        this.handleTimeSlot(e);
                                        console.log("data", data);
                                        console.log("e", e);
                                      }}
                                    >
                                      {moment(
                                        this.state.nextAvailableSession
                                          .startDateTime
                                      )
                                        .tz(this.state.timeZone, true)
                                        .format("Do of MMMM  YYYY, h:mm:ss a")}
                                    </div>
                                  </div>
                                ) : (
                                  "No upcoming sessions"
                                )
                              ) : (
                                this.state.availableTimes.map(
                                  (timeslot, index) => {
                                    return (
                                      <span key={index}>
                                        {!timeslot.booked &&
                                          timeslot.shouldRemoved !== true && (
                                            <div
                                              key={timeslot._id}
                                              onClick={this.handleTimeSlot}
                                              id={
                                                timeslot.startDateTime +
                                                "-" +
                                                timeslot.endDateTime +
                                                "-" +
                                                timeslot.period +
                                                "-" +
                                                timeslot._id +
                                                "-" +
                                                timeslot.adminDiscount
                                              } //{timeslot.time + timeslot.period}
                                              className={
                                                this.state.timeslotId ===
                                                timeslot._id
                                                  ? "availability-row availability-row-time selected"
                                                  : "availability-row availability-row-time"
                                              }
                                            >
                                              <p class="availability-name">
                                                {timeSpanToAMPM(timeslot)}{" "}
                                                {timeslot.adminDiscount > 0 && (
                                                  <span>
                                                    ({timeslot.adminDiscount}%
                                                    off )
                                                  </span>
                                                )}
                                                {/* {timeslot.sessionType ===
																								"0" ? (
																									<span>(One to one)</span>
																								) : (
																									<span>(One to many)</span>
																								)} */}
                                              </p>

                                              <p class="caret-icon">&gt;</p>
                                            </div>
                                          )}
                                      </span>
                                    );
                                  }
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Row>
                  </Container>
                  <div className="btnContainer">
                    <button
                      id={
                        this.props.quickBook && !isAuth
                          ? "QuickBook"
                          : "confirmSessionDetails"
                      }
                      onClick={this.state.timeslotId && this.handleSwitchPage}
                      className={this.state.timeslotId ? "btn" : "btn disabled"}
                    >
                      {this.state.timeslotId && "Next"}
                      {this.state.timeslotId == null && "Please select a time"}
                    </button>
                  </div>
                </div>
              )}
              {this.state.bookPages === "confirmSessionDetails" && (
                <div className="confirmSessionDetails">
                  <div className="PersonInfo">
                    <img
                      src={baseURL + "/" + this.props.image}
                      className="initials"
                      alt="avatar"
                    />
                    <span className="info">
                      <div className="name">
                        {this.props.prefix}
                        {this.props.firstName} {this.props.lastName}
                      </div>
                      <div className="specialization">
                        {this.props.specialization[0].categoryName}
                      </div>
                    </span>
                  </div>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12">
                        <div className="sessionDateTime">
                          <div
                            id="addSessionDetails"
                            onClick={this.handleSwitchPage}
                            className="sessionInfo d-flex justify-content-between"
                          >
                            <div id="addSessionDetails" className="date">
                              {fullDayNames[this.state.time.getDay()]} <br />
                              {this.state.time.getDate()}{" "}
                              {fullMonthNames[this.state.time.getMonth()]}
                              {","}
                              {this.state.time.getFullYear()}
                            </div>
                            <div id="addSessionDetails" className="time">
                              {timeSpanToAMPM(this.state)}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="sessionTopic">
                          <div>
                            Is there anything you'd like to share with your
                            Guru? <span className="optional">(Optional)</span>
                          </div>
                          {/* <div>What will be the topic of the session?</div> */}
                          <textarea
                            onChange={this.changeHandler}
                            id="sessionTopic"
                            value={this.state.sessionTopic}
                            className="form-control"
                          />
                          <div
                            onClick={() => {
                              this.setState({
                                anonymous: !this.state.anonymous,
                              });
                            }}
                            style={{ cursor: "pointer", display: "none" }}
                          >
                            <span>
                              {this.state.anonymous ? (
                                <RemainAnonTickBox />
                              ) : (
                                <RemainAnonEmptyTick />
                              )}
                            </span>
                            I want to remain anonymous
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="btnContainer">
                    <button
                      id="addSessionDetails"
                      onClick={this.handleSwitchPage}
                      className="btn"
                    >
                      Back
                    </button>
                    <button
                      id={"sessionPayment"}
                      onClick={(e) => {
                        this.handleSwitchPage(e);
                        this.setState({
                          promoCode: "",
                          promoLoadingState: false,
                          promoCodeState: 0,
                        });
                      }}
                      className="btn"
                    >
                      Next
                    </button>
                  </div>
                </div>
              )}

              {this.state.bookPages === "QuickBook" && (
                <QuickBook
                  gurusDetails={{
                    image: this.props.image,
                    prefix: this.props.prefix,
                    firstName: this.props.firstName,
                    lastName: this.props.lastName,
                    email: this.props.email,
                    specialization: this.props.specialization[0].categoryName,
                    pricePerHour: this.props.pricePerHour,
                    _id: this.props.id,
                    coachImage: this.props.image,
                    coachPrefix: this.props.prefix,
                    coachSpecialization: this.props.specialization,
                  }}
                  SessionDateTime={this.state.startDateTime}
                  endDateTime={this.state.endDateTime}
                  timeSlotId={this.state.timeslotId}
                  sessionPeriod={this.state.period}
                />
              )}
              {this.state.bookPages === "sessionPayment" && (
                <div className="sessionPayment">
                  <div className="PersonInfo">
                    <img
                      src={baseURL + "/" + this.props.image}
                      className="initials"
                      alt="avatar"
                    />
                    <span className="info">
                      <div className="name">
                        {this.props.prefix}
                        {this.props.firstName} {this.props.lastName}
                      </div>
                      <div className="specialization">
                        {this.props.specialization[0].categoryName}
                      </div>
                    </span>
                  </div>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12">
                        <div className="sessionDateTime ">
                          <div
                            id="addSessionDetails"
                            onClick={this.handleSwitchPage}
                            className="sessionInfo d-flex justify-content-between"
                          >
                            <div id="addSessionDetails" className="date">
                              {fullDayNames[this.state.time.getDay()]} <br />
                              {this.state.time.getDate()}{" "}
                              {fullMonthNames[this.state.time.getMonth()]}
                              {","}
                              {this.state.time.getFullYear()}
                            </div>
                            <div id="addSessionDetails" className="time">
                              {timeSpanToAMPM(this.state)}
                              {/* {moment(this.state.startDateTime).format("HH:mm")} */}
                            </div>
                          </div>
                        </div>
                        {!isSessionFree && !this.state.hasPackage && (
                          <div className="my-2 d-flex align-items-center justify-content-center">
                            <div className={"promoCodeContainer"}>
                              <input
                                type="text"
                                className="form-control promoCodeField"
                                placeholder="PromoCode"
                                value={this.state.promoCode}
                                onChange={(e) => {
                                  this.setState({
                                    promoCode: e.target.value,
                                  });
                                }}
                              />
                              <div className="promoCodeSign">
                                {this.state.promoCodeState !== 0 ? (
                                  this.state.promoCodeState === 1 ? (
                                    <RightSign />
                                  ) : (
                                    <WrongEmailCheck />
                                  )
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <Button
                              className="btn mx-2 my-0 promoApplyBtn d-flex justify-content-center align-items-center"
                              onClick={applyPromoCode}
                              disabled={!this.state.promoCode}
                              style={{ border: "none" }}
                            >
                              {this.state.promoLoadingState ? (
                                <div className="loader"></div>
                              ) : (
                                <div>Apply</div>
                              )}
                            </Button>
                          </div>
                        )}
                        {this.state.promoCodeState === -1 && (
                          <div className="text-danger">
                            The promo code you entered is invalid. Please try
                            again.
                          </div>
                        )}{" "}
                        {this.props.pricePerHour !== 0 && (
                          <div>
                            <div className="total">
                              Total:{" "}
                              {this.state.hasPackage || isSessionFree ? (
                                <>Free</>
                              ) : (
                                <>
                                  <span
                                    className={
                                      this.state.promoCodeState === 1 &&
                                      "priceBeforeDiscount"
                                    }
                                  >
                                    {priceBeforeDiscount}
                                  </span>
                                  {this.state.promoCodeState === 1 && (
                                    <span className="priceAfterDiscount">
                                      {" "}
                                      {isSessionFree
                                        ? "Free"
                                        : priceAfterDiscount}
                                    </span>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="col-12">
                        {/* {this.props.pricePerHour !== 0 && (
												<iframe
													tabindex="1"
													src={this.state.PaymentURL}
													title="description"
												/>
											)} */}
                        {/* <StripeContainer /> */}
                      </div>
                    </div>
                  </div>
                  <div className="btnContainer">
                    {isSessionFree ? (
                      <span>
                        <button
                          id="confirmSessionDetails"
                          onClick={this.handleSwitchPage}
                          className="btn"
                        >
                          Back
                        </button>
                        <button className="btn" onClick={handleSubmit}>
                          <div>Confirm</div>
                        </button>
                      </span>
                    ) : (
                      <StripeContainer
                        id="confirmSessionDetails"
                        paymentData={paymentData}
                        setRedirectToPay={setRedirectToPay}
                        onClick={this.handleSwitchPage}
                        coupon={
                          this.state.promoCodeState === 1
                            ? this.state.promoCode
                            : null
                        }
                        isPackage={this.state.selectedPackage ? true : false}
                      />
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </Modal.Body>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    BookAction: (book) => dispatch(BookAction(book)),
    AuthState: (auth) => dispatch(AuthState(auth)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BookingModal);
