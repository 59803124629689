import React, { useEffect, useState } from "react";
import NavBarLg from "../../NavBarLg/NavBarLg";
import NavBarSm from "../../NavBarSm/NavBarSm";
import HamburgerList from "../../HamburgerList/HamburgerList";
import { Button, Menu } from "antd";
import EventCard from "../Components/EventCard/EventCard";
import axios from "axios";
import { connect } from "react-redux";
import { baseURL } from "../../../App";
import { LeftOutlined } from "@ant-design/icons";
import "./ViewEvents.css";
import halfCircleArrow from "../../../Assets/half-circle-arrow.svg";
import UpcomingEventIcon from "../../../Assets/UpcomingEventIcon";
import PastEventIcon from "../../../Assets/PastEventIcon";
import { hideElement, showElement } from "../../../Store/Actions/CardActions";

function ViewEvents({ auth, show, hide }) {
  const [hamburgerMenu, toggleHamburgerMenu] = useState(false);
  const [open, setOpen] = useState(false);
  const [isSmallScreen, updateIsSmallScreen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [menuLocation, setMenuLocation] = useState(false);
  const [extraCardDetails, setExtraCardDetails] = useState(true);
  const [eventData, updateEventData] = useState([
    {
      dates: [new Date()],
    },
  ]);
  const [menuKey, updateMenuKey] = useState(1);
  const timeZone = localStorage.getItem("timeZone");
  const { SubMenu } = Menu;

  const showDrawer = () => {
    console.log("clicked");
    setOpen(true);
  };

  const closeDrawer = () => {
    setOpen(false);
  };

  const onResize = (size) => {
    if (size > 991) {
      updateIsSmallScreen(false);
    } else {
      updateIsSmallScreen(true);
    }
  };

  const onMenuClick = (key) => {
    updateMenuKey(key.key);
    if (key.key === "3") {
      hide();
    } else {
      show();
    }
  };

  useEffect(() => {
    setIsLoading(true);

    if (menuKey && timeZone) {
      var url = `${baseURL}/event/getAllEvents?type=${menuKey}&timeZone=${timeZone}`;
      if (auth?.authData?.userType === 1) {
        url = `${baseURL}/event/getAllEventsForClient?type=${menuKey}&clientId=${auth?.authData?.user?._id}&timeZone=${timeZone}`;
        // url += `&clientId=${auth?.authData?.user?._id}`;
      } else if (auth?.authData?.userType === 2) {
        url += `&coachId=${auth?.authData?.user?._id}`;
      }

      axios({
        method: "get",
        url,
      })
        .then((res) => {
          const data = res.data.data;
          updateEventData(data);
        })
        .finally(() => setIsLoading(false));
    }
  }, [menuKey, timeZone, auth?.authData?.user?._id, auth?.authData?.userType]);

  useEffect(() => {
    window.addEventListener("resize", (e) => {
      onResize(e.target.innerWidth);
    });
    onResize(window.innerWidth);
    return () => {
      window.removeEventListener("resize", (e) => {
        onResize(e.target.innerWidth);
      });
    };
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 10) {
        setMenuLocation(true);
      } else {
        setMenuLocation(false);
      }
    });
  }, []);

  return (
    <div>
      <NavBarLg
        active="Events"
        searchBar={false}
        fixedNavbar={true}
        parent={"SessionCalendar"}
      />
      <NavBarSm
        parent={"contact"}
        toggleHamburgerMenu={() => {
          toggleHamburgerMenu(true);
        }}
        toggleHamburgerMenuState={hamburgerMenu}
      />

      {hamburgerMenu && (
        <HamburgerList
          toggleHamburgerMenu={() => {
            toggleHamburgerMenu(false);
          }}
          toggleHamburgerMenuState={hamburgerMenu}
        />
      )}
      <div className="viewEvents d-flex">
        {isSmallScreen ? (
          <>
            <div className="drawer-container">
              {!open && isSmallScreen && (
                <Button className="open-close-btn" onClick={showDrawer}>
                  <img src={halfCircleArrow} alt="Drawer" />
                </Button>
              )}
              {open && isSmallScreen && (
                <div
                  className={`${isSmallScreen ? "drawer" : ""}`}
                  style={{ top: `${menuLocation ? "0" : ""}` }}
                >
                  <Menu
                    onClick={onMenuClick}
                    style={{
                      width: 256,
                      transition: "all 1s ease",
                      fontSize: "16px",
                    }}
                    defaultSelectedKeys={["1"]}
                    defaultOpenKeys={["sub1"]}
                    mode="inline"
                  >
                    <Button
                      className="open-close-btn__left"
                      onClick={closeDrawer}
                      icon={<LeftOutlined />}
                    />
                    <Menu.Item key="1" onClick={closeDrawer}>
                      All Events
                    </Menu.Item>
                    {auth?.authData ? (
                      <SubMenu
                        key="sub2"
                        title={
                          <span>
                            <span>
                              {auth?.authData?.userType === 1
                                ? "Booked Events"
                                : "My Events"}
                            </span>
                          </span>
                        }
                      >
                        <Menu.Item
                          key="3"
                          onClick={closeDrawer}
                          style={{ fontSize: "14px" }}
                        >
                          <PastEventIcon /> Past Events
                        </Menu.Item>
                        <Menu.Item
                          key="2"
                          onClick={closeDrawer}
                          style={{ fontSize: "14px" }}
                        >
                          <UpcomingEventIcon /> Upcoming Events
                        </Menu.Item>
                      </SubMenu>
                    ) : (
                      <></>
                    )}
                  </Menu>
                </div>
              )}
              {/* <Drawer
                title="Events"
                placement="right"
                onClose={onClose}
                open={open}
                style={{
                  width: "400px",
                  position: "relative",
                  zIndex: 100000,
                }}
                className="viewEvents"
                extra={
                  <Space>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button type="primary" onClick={onClose}>
                      OK
                    </Button>
                  </Space>
                }
              > */}
              {/* <Menu
                onClick={onMenuClick}
                style={{ width: 256 }}
                defaultSelectedKeys={["1"]}
                defaultOpenKeys={["sub1"]}
                mode="inline"
              >
                <Menu.Item key="1">All Events</Menu.Item>
                {auth?.authData ? (
                  <SubMenu
                    key="sub2"
                    title={
                      <span>
                        <span>
                          {auth?.authData?.userType === 1
                            ? "Booked Events"
                            : "My Events"}
                        </span>
                      </span>
                    }
                  >
                    <Menu.Item key="2">Past Events</Menu.Item>
                    <Menu.Item key="3">Upcoming Events</Menu.Item>
                    <button>Test btn</button>
                  </SubMenu>
                ) : (
                  <></>
                )}
              </Menu> */}
              {/* </Drawer> */}
            </div>
          </>
        ) : (
          <div className="static-drawer">
            <Menu
              onClick={onMenuClick}
              style={{ width: 256, fontSize: "16px" }}
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["sub1"]}
              mode="inline"
            >
              <Menu.Item key="1">All Events</Menu.Item>
              {auth?.authData ? (
                <SubMenu
                  key="sub2"
                  title={
                    <span>
                      <span>
                        {auth?.authData?.userType === 1
                          ? "Booked Events"
                          : "My Events"}
                      </span>
                    </span>
                  }
                >
                  <Menu.Item key="3" style={{ fontSize: "14px" }}>
                    <PastEventIcon /> Past Events
                  </Menu.Item>
                  <Menu.Item key="2" style={{ fontSize: "14px" }}>
                    <UpcomingEventIcon /> Upcoming Events
                  </Menu.Item>
                </SubMenu>
              ) : (
                <></>
              )}
            </Menu>
          </div>
        )}

        <div className="w-100">
          {isLoading && (
            <div className="filter-loading">
              <div className="loader"></div>
            </div>
          )}
          {auth?.authData && eventData.length === 0 && !isLoading && (
            <div className="reserved-events">
              <p>No events!</p>
            </div>
          )}
          {eventData?.map((event) => {
            return (
              <EventCard
                isSmallScreen={isSmallScreen}
                eventData={event}
                extraCardDetails={extraCardDetails}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    show: () => dispatch(showElement()),
    hide: () => dispatch(hideElement()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewEvents);
