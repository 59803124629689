import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Logo from "../../Assets/Logo.svg";
import "./TransactionCard.css";
export class TransactionCard extends Component {
	state = { transactionDetails: false };
	toggleDetails = () => {
		this.setState({ transactionDetails: !this.state.transactionDetails });
	};
	render(props) {
		return (
			<div className="TransactionCard">
				<span className="clientName">
					{this.props.transaction.userFirstName +
						" " +
						this.props.transaction.userLastName}
				</span>
				<span className="price">
					{this.props.transaction.price}
					{this.props.transaction.unit}
				</span>
				<div className="paymentNumber">
					Payment Number: {this.props.transaction.paymentNumber}
				</div>
				<span className="date">{this.props.transaction.date}</span>
				<button onClick={this.toggleDetails} className="btn">
					View
				</button>
				<Modal
					show={this.state.transactionDetails}
					onHide={this.toggleDetails}
					dialogClassName="modal-90w"
					id="transactionCard"
					aria-labelledby="example-custom-modal-styling-title"
				>
					<Modal.Header closeButton>
						<Modal.Title id="example-custom-modal-styling-title">
							Receipt
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="receipte">
							<div className="upSawtooth"></div>
							<div className="receipteContainer">
								<div className="logo">
								<img src={Logo} alt="Logo" style={{width:"75px"}} />
								</div>
								<div className="divider"></div>
								<div className="session">
									<div className="title">Session</div>
									<div className="personalinfo">
										<div className="fullName">
											{this.props.transaction.userFirstName}
											<span>
												{this.props.transaction.unit}
												{this.props.transaction.price}
											</span>
										</div>
										<div className="specialization">
											{this.props.transaction.userLastName}
											<span>{this.props.transaction.date}</span>
										</div>
										<div className="discount">
											WeLO20% <span>$0</span>
										</div>
									</div>
									<div className="divider"></div>
								</div>
								<div className="total">
									{/* Total:{" "} */}
									Total:	
									<span>
									{this.props.transaction.unit}
										{this.props.transaction.price}
									</span>
									{/* < div>
										{this.props.transaction.unit}
										{this.props.transaction.price}
									</div> */}
								</div>
								<div className="divider"></div>
								<div className="thankYou">Thank you!</div>
							</div>
						</div>
					</Modal.Body>
				</Modal>
			</div>
		);
	}
}

export default TransactionCard;
