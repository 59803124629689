//import axios from "axios";
//import { baseURL } from "../../App";
import React, { useState } from "react";
import { connect } from "react-redux";
import "./WeeklyCalendar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faChevronLeft,
	faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const WeeklyCalendar = (props) => {
	const monthNameArray = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];
	const weekdayNameArray = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
	const dayInMilli = 86400000;
	const [selectedDate] = useState(new Date());
	//const [selectArray, setSelectArr] = useState([]);
	const selectDate = (index) => {
		//if ((selectedDate.getDay() + (index - 3)) >= minDate.getDay() && (selectedDate.getDay() + (index - 3)) < maxDate.getDay()) {

		const newSeconds = selectedDate.getTime() + (index - 3) * dayInMilli;
		selectedDate.setTime(newSeconds);

		const calendarAttributes = getCalendarAttributes(selectedDate);

		setHeader(calendarAttributes.header);
		setDays(calendarAttributes.daysToShow);
		props.onChange(selectedDate);
	};

	// const getAvailabiltyDate = (data) => {
	// 	let testArr = [];
	// 	data.forEach((dataValue) => {
	// 		dayArray.forEach((dayValue, j) => {
	// 			let dataValues = dataValue.sessionFullDate.substr(0, 10);
	// 			let dayValues = new Date(
	// 				new Date(dayValue[3]).getTime() -
	// 					new Date(dayValue[3]).getTimezoneOffset() * 60000
	// 			)
	// 				.toISOString()
	// 				.substring(0, 10);
	// 			if (dataValues === dayValues) {
	// 				testArr = [...testArr, j];
	// 			}
	// 		});
	// 	});

	// 	let x = new Set(testArr);
	// 	let y = [...x];

	// 	if (y.length > 0) setSelectArr(y);
	// 	dayArray.forEach((selectDate) => {
	// 		if (selectDate.length > 4) {
	// 			selectDate.pop();
	// 		}
	// 	});
	// 	selectArray.forEach((i) => {
	// 		dayArray[i].splice(4, 1, "active");
	// 	});
	// };

	const getCalendarAttributes = (date) => {
		const calendarAttributes = {
			dates: [],
			daysToShow: [],
			header: "",
		};

		const dates = [
			new Date(date.getTime() + dayInMilli * -3),
			new Date(date.getTime() + dayInMilli * -2),
			new Date(date.getTime() + dayInMilli * -1),
			new Date(date.getTime()),
			new Date(date.getTime() + dayInMilli * 1),
			new Date(date.getTime() + dayInMilli * 2),
			new Date(date.getTime() + dayInMilli * 3),
		];

		calendarAttributes.dates = dates;

		calendarAttributes.daysToShow = [
			[
				weekdayNameArray[dates[0].getDay()],
				dates[0].getDate(),
				weekdayNameArray[dates[0].getDay()],
				dates[0],
			],
			[
				weekdayNameArray[dates[1].getDay()],
				dates[1].getDate(),
				weekdayNameArray[dates[0].getDay()],
				dates[1],
			],
			[
				weekdayNameArray[dates[2].getDay()],
				dates[2].getDate(),
				weekdayNameArray[dates[0].getDay()],
				dates[2],
			],
			[
				weekdayNameArray[dates[3].getDay()],
				dates[3].getDate(),
				weekdayNameArray[dates[0].getDay()],
				dates[3],
			],
			[
				weekdayNameArray[dates[4].getDay()],
				dates[4].getDate(),
				weekdayNameArray[dates[0].getDay()],
				dates[4],
			],
			[
				weekdayNameArray[dates[5].getDay()],
				dates[5].getDate(),
				weekdayNameArray[dates[0].getDay()],
				dates[5],
			],
			[
				weekdayNameArray[dates[6].getDay()],
				dates[6].getDate(),
				weekdayNameArray[dates[0].getDay()],
				dates[6],
			],
		];

		// selectArray.forEach((selectDay,i)=>{
		//     calendarAttributes.daysToShow[selectDay].splice(5,0,'select')
		// })

		if (dates[0].getDate() > dates[6].getDate()) {
			calendarAttributes.header =
				dates[0].getDate() +
				" " +
				monthNameArray[dates[0].getMonth()] +
				"-" +
				dates[6].getDate() +
				" " +
				monthNameArray[dates[6].getMonth()] +
				" " +
				dates[6].getFullYear();
		} else {
			calendarAttributes.header =
				dates[0].getDate() +
				"-" +
				dates[6].getDate() +
				" " +
				monthNameArray[dates[0].getMonth()] +
				" " +
				dates[6].getFullYear();
		}

		return calendarAttributes;
	};

	const [dayArray, setDays] = useState(
		getCalendarAttributes(selectedDate).daysToShow
	);
	const [weeklyCalendarHeader, setHeader] = useState(
		getCalendarAttributes(selectedDate).header
	);

	// useEffect(() => {
	// 	axios({
	// 		method: "get",
	// 		url: `${baseURL}/user/GetAllSessionByUserId?userType=${props.auth.authData.userType}&id=${props.auth.authData.user._id}`,
	// 	}).then((res) => {
	// 		getAvailabiltyDate(res.data.sessions);
	// 	});
	// }, []);

	return (
		<div className="main weeklyCalendar">
			<div
				className="calendarHeader"
				style={{ width: "100%", margin: "0 auto" }}>
				<button className="weekdayButton col-1" onClick={() => selectDate(-4)}>
					<FontAwesomeIcon className="calendarNavIcon" icon={faChevronLeft} />
				</button>
				<span className="col-3">{weeklyCalendarHeader}</span>
				<button className="weekdayButton col-1" onClick={() => selectDate(10)}>
					<FontAwesomeIcon className="calendarNavIcon" icon={faChevronRight} />
				</button>
			</div>
			<br />
			<br />
			<div className="calendarButtons">
				<button className="weekdayButton" onClick={() => selectDate(0)}>
					<span className="topText">{dayArray[0][0].charAt(0)}</span>
					<br />
					<span
						className={
							dayArray[0][4] === "active" ? "active bottomText" : " bottomText"
						}>
						{dayArray[0][1]}
					</span>
				</button>
				<button className="weekdayButton" onClick={() => selectDate(1)}>
					<span className="topText">{dayArray[1][0].charAt(0)}</span>
					<br />
					<span
						className={
							dayArray[1][4] === "active" ? "active bottomText" : " bottomText"
						}>
						{dayArray[1][1]}
					</span>
				</button>
				<button className="weekdayButton" onClick={() => selectDate(2)}>
					<span className="topText">{dayArray[2][0].charAt(0)}</span>
					<br />
					<span
						className={
							dayArray[2][4] === "active" ? "active bottomText" : " bottomText"
						}>
						{dayArray[2][1]}
					</span>
				</button>
				<button className="weekdayButton" onClick={() => selectDate(3)}>
					<span className="topText">{dayArray[3][0].charAt(0)}</span>
					<br />
					<span className="bottomText selected">{dayArray[3][1]}</span>
				</button>
				<button className="weekdayButton" onClick={() => selectDate(4)}>
					<span className="topText">{dayArray[4][0].charAt(0)}</span>
					<br />
					<span
						className={
							dayArray[4][4] === "active" ? "active bottomText" : " bottomText"
						}>
						{dayArray[4][1]}
					</span>
				</button>
				<button className="weekdayButton" onClick={() => selectDate(5)}>
					<span className="topText">{dayArray[5][0].charAt(0)}</span>
					<br />
					<span
						className={
							dayArray[5][4] === "active" ? "active bottomText" : " bottomText"
						}>
						{dayArray[5][1]}
					</span>
				</button>
				<button className="weekdayButton" onClick={() => selectDate(6)}>
					<span className="topText">{dayArray[6][0].charAt(0)}</span>
					<br />
					<span
						className={
							dayArray[6][4] === "active" ? "active bottomText" : " bottomText"
						}>
						{dayArray[6][1]}
					</span>
				</button>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	};
};

export default connect(mapStateToProps)(WeeklyCalendar);
