import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../../Assets/Logo.svg";
import LogoLg from "../../Assets/Logo.svg";
import Close from "../../Components/svgLogos/Cross";

function RefundPolicy() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [])
	return (
		<div className="refundPolicy">
			<Link to="/">
				<div className="logo sm">
					<img src={Logo} alt="Logo" />
				</div>
				<div className="logo lg">
					<img src={LogoLg} alt="LogoLg" />
				</div>
			</Link>
			<div className="divider"></div>
			<Container>
				<div className="d-flex justify-content-between">
					<h4 className="main-title my-4">REFUND POLICY</h4>
					<Link to="/" className="closeBtn">
						<Close />
					</Link>
				</div>
				<div className="my-2">
					Thank you for purchasing our services at https://welo.app.
				</div>
				<div className="my-2">
					In line with providing our customers with the best quality and client
					experience, we have established a considerate refund policy.
				</div>
				<div className="my-2">
					Welo is mainly led by women. We offer one-to-one online sessions
					including Psychology, life coaching, career mentoring and skills
					coaching in general.
				</div>
				<div className="my-2">
					All our sessions begin with a complete discovery/analysis. We then
					create a scope to enhance our client’s overall wellbeing.
				</div>
				<div className="my-2">
					As there is time and effort invested in providing you with our
					services and completing every session with the best quality possible,
					a refund will not be possible for all delivered sessions.
				</div>
				<h5 className="my-4">REFUNDS</h5>
				<div className="my-2">
					Having said this, we offer 100% full refund if a cancellation is made
					more than 12 hours before the scheduled session. Still, if you have
					any doubts related to the refund and cancellation of our service,
					please feel free to contact us at tee@welo.app.
				</div>
				<div className="my-2">
					Your eligible refunds will be issued onto the original payment method
				</div>
				<h5 className="my-4">DOUBLE PURCHASE</h5>
				<div className="my-2">
					In case of double payment, due to any reason, you will receive 100% of
					your money back. Usually, our system automatically detects such
					transactions and refunds them. If, due to any reason the
					aforementioned actions are not carried out, please reach out to us at
					tee@welo.app.
				</div>
				<div className="my-2">
					We keep our pricing very transparent and honest.
				</div>
			</Container>
		</div>
	);
}

export default RefundPolicy;
