import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { baseURL, isSummerTime } from "../../App";
import { Modal } from "react-bootstrap";
import ICalendarLink from "react-icalendar-link";
import firebase from "firebase/app";
import "firebase/firestore";
import "./BookingModalStatus.css";
export class BookingModalStatus extends Component {
	state = {
		paymentStatus: null,
		timeZone: localStorage.getItem("timeZone"),
	};
	createChattingRoom = async () => {
		if (this.state.paymentStatus === "Success" && !this.props.book.isPackage) {
			const roomId = this.props.book.sessionId;
			const roomsDocRef = firebase
				.firestore()
				.collection("sessionRooms")
				.doc(roomId);

			const messages = this.props.book.sessionTopic.length
				? [
						{
							senderId: this.props.auth.authData.user._id,
							senderInitials: (
								this.props.auth.authData.user.firstName[0] +
								this.props.auth.authData.user.lastName[0]
							).toUpperCase(),
							message: this.props.book.sessionTopic,
							isRead: false,
						},
				  ]
				: [];
			await roomsDocRef.set({
				messages,
			});
		}
	};
	componentDidMount() {
		this.setState({ paymentStatus: this.props.paymentStatus }, () => {
			this.createChattingRoom();
		});
	}

	render() {
		const event = {
			title: `Welo (Session with ${this.props.book.coachFirstName})`,
			description: "",
			startTime: moment(this.props.book.startDateTime)
				.tz(this.state.timeZone, true)
				.subtract(
					isSummerTime && this.state.timeZone === "Africa/Cairo" ? 1 : 0,
					"h"
				)
				.format(),
			endTime: moment(this.props.book.endDateTime)
				.tz(this.state.timeZone, true)
				.subtract(
					isSummerTime && this.state.timeZone === "Africa/Cairo" ? 1 : 0,
					"h"
				)
				.format(),
			location: this.props.book.userToken,
			fileName: "Welo Session.ics",
		};
		const fullDayNames = [
			"Sunday",
			"Monday",
			"Tuesday",
			"Wednesday",
			"Thursday",
			"Friday",
			"Saturday",
		];
		const fullMonthNames = [
			"January",
			"February",
			"March",
			"April",
			"May",
			"June",
			"July",
			"August",
			"September",
			"October",
			"November",
			"December",
		];

		return (
			<Modal
				show={this.props.paymentMsgModal}
				onHide={this.props.togglePaymentMsgModal}
				size="md"
				id="bookPages"
				aria-labelledby="contained-modal-title-vcenter"
				centered>
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					{this.props.book.coachImage && this.state.paymentStatus && (
						<div className="bookingConfirmed">
							<div className="CompleteBookImg">
								<p className="bookingStatusMessage">
									{this.state.paymentStatus === "Success"
										? this.props.book.isPackage
											? "Your package was booked successfuly!"
											: "Your session was booked successfuly!"
										: "Something went wrong, we couldn't complete your booking!"}
								</p>
								{this.state.paymentStatus === "Success" &&
									!this.props.book.isPackage && (
										<p className="checkEmailText">
											You will receive a confirmation email shortly
											<br />
											(Please check your junk folder)
										</p>
									)}
							</div>
							<div className="sessionPayment">
								<div className="PersonInfo">
									<img
										src={baseURL + "/" + this.props.book.coachImage}
										className="initials"
										alt="avatar"
									/>
									<span className="info">
										<div className="name">
											{this.props.book.coachPrefix}
											{this.props.book.coachFirstName}{" "}
											{this.props.book.coachLastName}
										</div>
										<div className="specialization">
											{this.props.book.coachSpecialization[0].categoryName}
										</div>
									</span>
								</div>
								{!this.props.book.isPackage && (
									<div className="container-fluid">
										<div className="row">
											<div className="col-12">
												<div className="sessionDateTime">
													<div className="sessionInfo">
														<div className="date">
															{
																fullDayNames[
																	new Date(this.props.book.time).getDay()
																]
															}{" "}
															<br />
															{new Date(this.props.book.time).getDate()}{" "}
															{
																fullMonthNames[
																	new Date(this.props.book.time).getMonth()
																]
															}
															{","}
															{new Date(this.props.book.time).getFullYear()}
															{"."}
														</div>
														<div className="time">
															{moment(this.props.book.startDateTime).format(
																"hh:mm  a"
															)}{" "}
															to{" "}
															{moment(this.props.book.endDateTime).format(
																"hh:mm  a"
															)}
														</div>
													</div>
												</div>
												<div className="btnContainer d-flex justify-content-center">
													<button
														onClick={this.props.togglePaymentMsgModal}
														className="secondary-btn mx-2">
														Close
													</button>
													{this.state.paymentStatus === "Success" &&
														!this.props.book.isPackage && (
															<div className="text-center">
																<ICalendarLink event={event}>
																	<button
																		onClick={this.props.togglePaymentMsgModal}
																		className="primary-btn mx-2">
																		Add to Calendar
																	</button>
																</ICalendarLink>
															</div>
														)}
												</div>
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
					)}
				</Modal.Body>
			</Modal>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		book: state.book,
		auth: state.auth,
	};
};

export default connect(mapStateToProps)(BookingModalStatus);
