import React from "react";

function SearchLogo() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="21.171"
			height="21.171"
			viewBox="0 0 21.171 21.171"
		>
			<path
				id="search"
				d="M20.04,15.984a8.961,8.961,0,1,0-1.581,1.581q.023.026.047.05l4.753,4.753a1.12,1.12,0,0,0,1.584-1.584l-4.753-4.753ZM17.714,5.734a6.721,6.721,0,1,1-9.505,0A6.721,6.721,0,0,1,17.714,5.734Z"
				transform="translate(-4 -1.525)"
				fill="#898989"
			/>
		</svg>
	);
}

export default SearchLogo;
