const initState = {};

export const BookReducer = (state = initState, action) => {
  const BookData = action.book;
  switch (action.type) {
    case "BOOKING":
      return {
        ...state,
        ...BookData,
      };
    case "BOOK_COMPLETED":
      return {};
    default:
      return {
        ...state,
      };
  }
};
export default BookReducer;
