import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../../Assets/Logo.svg";
import LogoLg from "../../Assets/Logo.svg";
import Close from "../../Components/svgLogos/Cross";
import "./TermsAndConditions.css";
function TermsForClient() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [])
	return (
		<div className="termsAndCond">
			<Link to="/">
				<div className="logo sm">
					<img src={Logo} alt="Logo" />
				</div>
				<div className="logo lg">
					<img src={LogoLg} alt="LogoLg" />
				</div>
			</Link>

			<Container className="terms">
				<div className="d-flex justify-content-between">
					<h4 className="main-title my-4">TERMS & CONDITIONS</h4>
					<Link to="/" className="closeBtn">
						<Close />
					</Link>
				</div>
				<h5 className="my-4">INTRODUCTION</h5>
				<div className="my-2">
					These are the terms and conditions governing the use of
					https://welo.app, (the Website/Website) and the agreement that
					operates between us and you (the Terms/Terms). The Website is owned
					and operated by Welo (us/our/we) and these Terms set out the rights
					and obligations of all users (you/your) in relation to your use of the
					Website.
				</div>
				<div className="my-2">
					Please read these Terms carefully. By using the Website, you are
					consenting to be bound by the current Terms. We may revise the Terms
					and information contained on the Website at any time and without
					notifying you of such changes. If you do not agree to these Terms or
					the Privacy Statement, please refrain from using the Website.
				</div>
				<div className="my-2">
					If you have any questions about the Terms, please contact us through
					the following e-mail tee@welo.app.
				</div>
				<h5 className="my-4">CONDUCT OF USE</h5>
				<div className="my-2">
					You are not allowed to use this website in any way that is unlawful,
					abusive, threatening, harassing, obscene, libellous, hateful, or in
					any other way that is not compliant with these Terms and Conditions.
				</div>
				<h5 className="my-4">
					DISCLAIMER OF WARRANTY, LIMITATION OF LIABILITY AND INDEMNIFICATION
				</h5>
				<div className="my-2">
					The material contained within the Website is provided without any
					guarantees, conditions or warranties as to its accuracy. Welo does not
					undertake that the information contained on or available via the
					Website is accurate or complete and accordingly, should not be relied
					on as such. Any arrangements made between you and any other person,
					using or named on the Website are entirely at your sole risk and
					responsibility. By consenting to these Terms, you undertake,
					acknowledge and understand that Welo is merely an online platform,
					acting purely as a medium between you and the gurus. As such, Welo
					assumes no responsibility whatsoever to any acts and/or omissions
					arising out of or in relation to your sessions with the gurus. You
					also undertake and acknowledge that the relationship between yourself
					and the gurus is direct, and that Welo assumes no liability whatsoever
					in this regard. You also hereby indemnify, protect and hold Welo, any
					all affiliate/sister companies/contracting parties, subsidiaries,
					partners, officers, directors, agents, contractors, licensees, service
					providers, subcontractors, suppliers, interns and employees, harmless
					from claims, actions, demands, lawsuits, requests, including
					reasonable attorney’s fees, that could arise - whether directly or
					indirectly- out of or in relation to the use of the website, including
					but not limited to:
				</div>
				<div className="m-4">
					<div className="my-2">
						all conditions, warranties and other terms which might otherwise be
						implied by statute, common law or the law of equity;
					</div>
					<div className="my-2">
						any liability for loss or damage incurred by any user in connection
						with the use, inability to use, or results of the use of the
						Website, any websites linked to it and any material posted on it;
					</div>
					<div className="my-2">
						any liability for any bugs or faults in our systems or tools;
					</div>
					<div className="my-2">
						any liability claims, demands, actions, class actions, lawsuits made
						by any third party due to or arising out of your breach of these
						Terms and Conditions or the documents they incorporate by reference
						or your infringement of any law or the rights of a third-party, or
						for any indirect, incidental, consequential, special, punitive, or
						exemplary damages;
					</div>
					<div className="my-2">
						any and all causes of action and claims of any nature resulting from
						the counsellor/gurus services or the platform, including (without
						limitation) any act, omission, opinion, response, lack of response,
						advise, suggestion, information, and/or service of any counsellor
						and/or any other content or information accessible through the
						platform and;
					</div>
					<div className="my-2">
						any liability for any direct, special, indirect or consequential
						loss or damage incurred by any user in connection with the Website
						or in connection with the use, inability to use, or results of the
						use of the Website, any websites linked to it and any materials
						posted on it, including, without limitation any liability for:
					</div>
					<div className="m-4">
						<div className="my-2">1.    loss of income or revenue;</div>
						<div className="my-2">2.    loss of business;</div>
						<div className="my-2">3.    loss of profits or contracts;</div>
						<div className="my-2">4.    loss of anticipated savings;</div>
						<div className="my-2">5.    loss of data;</div>
						<div className="my-2">6.    loss of goodwill;</div>
						<div className="my-2">
							7.    wasted management or office time; and
						</div>
						<div className="my-2">
							8.    for any other loss or damage of any kind, however arising
							and whether caused by tort (including negligence), breach of
							contract or otherwise, even if foreseeable.
						</div>
					</div>
				</div>
				<div className="my-2">
					You understand, agree and acknowledge that the Platform is provided
					“as is” without any express, or implied warranties of any kind,
					including but not limited to merchantability, non-infringement,
					security, results, fitness for a particular purpose, or accuracy. The
					use of Welo is at your own risk, to the fullest extent of the law, we
					expressly disclaim all warranties of any kind, whether expressed or
					implied.
				</div>
				<div className="my-2">
					You understand, agree and acknowledge that our aggregate liability for
					damages arising with respect to these Terms, and any and all use of
					Welo will not exceed the total amount of money paid by you or on your
					behalf through Welo within a timeframe of 12 months prior to the date
					of the claim.
				</div>
				<div className="my-2">
					If the applicable law does not allow the limitation of liability as
					set forth above, the limitation will be deemed modified solely to the
					extent necessary to comply with applicable law.
				</div>
				<div className="my-2">
					This section shall survive the termination or expiration of this
					Agreement.
				</div>
				<h5 className="my-4">LIMITS OF USE</h5>
				<div className="my-2">
					You may use our Website only for lawful purposes. You may not use our
					Website:
				</div>
				<div className="m-4">
					<div className="my-2">
						In any way that breaches any applicable local, national or
						international law or regulation;
					</div>
					<div className="my-2">
						In any way that is unlawful or fraudulent, or has any unlawful or
						fraudulent purpose or effect;
					</div>
					<div className="my-2">
						To send, knowingly receive, upload, download, use or re-use any
						material which does not comply with our content standards;
					</div>
					<div className="my-2">
						To transmit any data, send or upload any material that contains
						viruses, trojan horses, worms, time-bombs, keystroke loggers,
						spyware, adware or any other harmful programs or similar computer
						code designed to adversely affect the operation of any computer
						software or hardware.
					</div>
				</div>
				<h5 className="my-4">You also hereby also agree:</h5>
				<div className="m-4">
					<div className="my-2">
						Not to reproduce, duplicate, copy or re-sell any part of our website
						in contravention of the provisions of these Terms;
					</div>
					<div className="my-2">Not to use ad-blocking software;</div>
					<div className="my-2">
						Not to re-use text or graphics from the Website or parts thereof.
					</div>
				</div>
				<h5 className="my-4">CHANGES TO TERMS</h5>
				<div className="my-2">
					We are committed to ensuring that our website is as useful and
					efficient as possible. For that reason, we reserve the right to make
					changes to the services, at any time. We will never charge you for any
					service without making it very clear to you precisely what you're
					paying for.
				</div>
				<div className="my-2">
					Any new features which are added to the current site shall also be
					subject to this Terms and Conditions. You can always review the most
					current version of the Terms and Conditions at any time on this page.
					We reserve the right to update, change or retrieve any part of these
					Terms and Conditions by posting updates and/or changes to our website.
					It is your responsibility to check this page periodically for changes.
					Your continued use of or access to the website following the posting
					of any changes constitutes acceptance of those changes.
				</div>
				<h5 className="my-4">SERVICE TERMS</h5>
				<div className="my-2">
					1. Welo at this moment grants the User a non-exclusive,
					non-transferable, limited right to access and use the Service, under
					the conditions of these Terms & Conditions and for the duration of the
					Agreement.
				</div>{" "}
				<div className="my-2">
					2. The use of the Service is at the User's own expense and risk. The
					User is responsible for meeting the technical and functional
					requirements and using the electronic communication facilities that
					are necessary to be able to access and use the Service. The User will
					at all times bear the risk of loss, theft or damage to any of its
					data.
				</div>{" "}
				<div className="my-2">
					3. Welo will have the right (but not the obligation), at its sole
					discretion, to review, edit, limit, refuse or remove Content, or to
					limit or refuse the User access to the Service. More specifically in
					the event the use of the Service, according to us, violates these
					Terms.
				</div>{" "}
				<div className="my-2">
					4. We may disclose the User's Personal Data or Content, or other data
					relating to the use of the Service, to third parties where it
					believes, in good faith, that it is necessary to comply with a court
					order, ongoing judicial proceeding, criminal or civil subpoena, or
					other legal process or to exercise its constitutional rights of
					defence against legal claims.
				</div>{" "}
				<div className="my-2">
					5. You shouldn’t record any communication with your therapist whether
					it is video or audio, only text messages are saved for you to get back
					to at any time.
				</div>{" "}
				<div className="my-2">
					6. While we hope our Services are beneficial to you, you understand,
					agree and acknowledge that they may not be the appropriate solution
					for everyone’s needs and that they may not be appropriate for every
					particular situation and/or may not be a complete substitute for a
					face-to-face examination and/or care in every particular situation.
				</div>
				<h5 className="my-4">LIABILITY</h5>
				<div className="my-2">
					If you are thinking about suicide or if you are considering harming
					yourself or others or if you feel that any other person may be in any
					danger or if you have any medical emergency, you must immediately call
					the corresponding emergency service number and notify the relevant
					authorities. Seek immediate in-person assistance. We do not hold any
					liabilities for any kind of mishappening.
				</div>
				<div className="my-2">
					The platform is not intended for the provision of clinical diagnosis
					requiring an in-person evaluation or requiring drugs or medical
					treatment as potential solutions. You should disregard any such advice
					if delivered through the platform.
				</div>
				<div className="my-2">
					Do not disregard, avoid, or delay obtaining in-person care from your
					doctor or any other qualified professional because of information or
					advice you received through the platform.
				</div>
				<h5 className="my-4">PRICE AND PAYMENT</h5>
				<div className="my-2">
					You must pay in the currency in which the service price is quoted or
					is selected. If you do not hold an account in the relevant currency
					you may pay by debit or credit card (or any other method that Welo may
					introduce from time to time) and your card company should exchange the
					amount charged to the currency of your country at their current rate.
				</div>
				<ul className="m-4">
					<li className="my-2">
						Payment may be made by credit card, debit card or any other such
						method as Welo may introduce at its absolute discretion.
					</li>
					<li className="my-2">
						Welo reserves the absolute right to accept or refuse any payment
						made in any form.
					</li>
					<li className="my-2">
						Welo cannot guarantee that a particular service will always be
						available.
					</li>
					<li className="my-2">
						To increase security, Welo does not hold any credit/debit card
						details. They are held by third party payment providers
					</li>
					<li className="my-2">
						Your credit card company may also carry out security checks to
						confirm it is you making the Order. Your statutory rights are
						unaffected by these Terms.
					</li>
				</ul>
				<h5>PERSONAL INFORMATION</h5>
				<div className="my-2">
					Your submission of personal information through Welo is regulated by
					our Privacy Policy. Please review our
					<a href="/privacyPolicy"> Privacy Policy.</a>
				</div>
				<h5>AGE CONSENT</h5>
				<div className="my-2">
					The use of Welo is not intended for users under 18 years of age.
					Before you register your account or quote us for our services, your
					age must be 18 and above. You hereby undertake, accept and acknowledge
					that you understand the age requirement and you hereby disclose that
					you are 18 years old or above.
				</div>
				<h5 className="my-4">IFRAMES</h5>
				<div className="my-2">
					Without prior approval and written permission from Welo, you may not
					create frames around our Webpages that alter in any way the visual
					presentation or appearance of our Website.
				</div>{" "}
				<h5 className="my-4">SEVERABILITY</h5>
				<div className="my-2">
					In the event that any provision of these Terms and Conditions is
					discovered to be unlawful, null or unenforceable, such provision shall
					notwithstanding be enforceable to the fullest extent permitted by
					applicable law, and the unenforceable portion shall be viewed to be
					remover from these Terms and Conditions, such determination shall not
					affect the credibility, validity and enforceability of all other
					remaining provisions.
				</div>{" "}
				<h5 className="my-4">ENTIRE AGREEMENT</h5>
				<div className="my-2">
					Our inability to exercise or enforce any right or provision of these
					Terms and Conditions shall not constitute a discharge of such right or
					provision.
				</div>
				<div className="my-2">
					These Terms and any policies and/or operating rules posted by us on
					this website or in respect to the Service constitutes the entire
					agreement and understanding between the User and Welo and govern your
					use of the Service, pre-empt any prior or synchronous agreements,
					communications and proposals, whether oral or written, between you and
					us.
				</div>
				<div className="my-2">
					Any ambiguities in the interpretation of these Terms shall not be
					construed against the drafting party.
				</div>
				<h5 className="my-4">IFRAMES</h5>
				<div className="my-2">
					Without prior approval and written permission from Welo, you may not
					create frames around our Webpages that alter in any way the visual
					presentation or appearance of our Website.
				</div>{" "}
				<h5 className="my-4">ACCEPTANCE OF THESE TERMS</h5>
				<div className="my-2">
					You acknowledge that you have read these Terms and that you agree to
					their content in full. By using the website or its services you agree
					to be bound by this Agreement as it constitutes a binding legal
					document, valid and enforceable on both parties. If you do not agree
					to abide by the terms of this agreement, you are not authorized to use
					or access the website and its services.
				</div>{" "}
				<h5 className="my-4">CONTACT INFORMATION</h5>
				<div className="my-2">
					If you would like to: access, correct, register a complaint, or simply
					want more information please contact us:
				</div>
				<div className="my-4">Email: tee@welo.app</div>
			</Container>
		</div>
	);
}

export default TermsForClient;
