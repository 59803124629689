import React from "react";

function OvalEmptyCircle({ danger }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="40"
			height="40"
			viewBox="0 0 40 40"
		>
			<g
				id="Atoms_Selectors_Default"
				data-name="Atoms/ Selectors/ Default"
				transform="translate(0.215 0.231)"
			>
				<g
					id="Default"
					transform="translate(-0.215 -0.231)"
					fill="#fff"
					stroke={danger ? "#ff0000" : "#7A4238"}
					strokeMiterlimit="10"
					strokeWidth="1"
				>
					<circle cx="20" cy="20" r="20" stroke="none" />
					<circle cx="20" cy="20" r="19.5" fill="none" />
				</g>
			</g>
		</svg>
	);
}

export default OvalEmptyCircle;
