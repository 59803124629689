import React from "react";

const PastEventIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 36 36"
      style={{ marginRight: "10px" }}
    >
      <g id="upcoming" transform="translate(-2 -2)">
        <path
          id="Path_42778"
          data-name="Path 42778"
          d="M11,2a1.8,1.8,0,0,1,1.8,1.8V5.6H27.2V3.8a1.8,1.8,0,1,1,3.6,0V5.6h1.8A5.4,5.4,0,0,1,38,11V21.8a1.8,1.8,0,0,1-1.8,1.8H25.4a7.2,7.2,0,0,0-7.2,7.2v5.4A1.8,1.8,0,0,1,16.4,38h-9A5.4,5.4,0,0,1,2,32.6V11A5.4,5.4,0,0,1,7.4,5.6H9.2V3.8A1.8,1.8,0,0,1,11,2Z"
          fill="#cfa79d"
          fill-rule="evenodd"
        />
        <path
          id="Path_42779"
          data-name="Path 42779"
          d="M13.5,18.9a1.7,1.7,0,1,1,2.4-2.4l3.4,3.4a1.7,1.7,0,0,1,0,2.4l-3.4,3.4a1.7,1.7,0,0,1-2.4-2.4l2.2-2.2Z"
          transform="translate(9.075 11.8)"
          fill="#cfa79d"
        />
        <path
          id="Path_42780"
          data-name="Path 42780"
          d="M18.5,18.9a1.7,1.7,0,0,1,2.4-2.4l3.4,3.4a1.7,1.7,0,0,1,0,2.4l-3.4,3.4a1.7,1.7,0,0,1-2.4-2.4l2.2-2.2Z"
          transform="translate(13.2 11.8)"
          fill="#cfa79d"
        />
      </g>
    </svg>
  );
};

export default PastEventIcon;
