import React, { useState, useEffect } from "react";
import {
	testAudioInputDevice,
	testAudioOutputDevice,
	AudioInputTest,
	AudioOutputTest,
} from "@twilio/rtc-diagnostics";
import { Select, Modal, Button } from "antd";
function TestModal({ isModalVisible, onCancel }) {
	const [channels, updateChannels] = useState({});
	const [selectedAudioInput, updateSelectedAudioInput] = useState(null);
	const [selectedAudioOutput, updateSelectedAudioOutput] = useState(null);
	const [audioInputLevel, updateAudioInputLevel] = useState(0);
	const [audioOutputLevel, updateAudioOutputLevel] = useState(0);
	const { Option } = Select;

	function handleAudioOutputChange(value) {
		updateSelectedAudioOutput(value);
	}
	function handleAudioInputChange(value) {
		updateSelectedAudioInput(value);
	}
	const getAudioVideoChannels = () => {
		navigator.mediaDevices
			.getUserMedia({ audio: true, video: false })
			.then((s) => {
				navigator.mediaDevices.enumerateDevices().then((devices) => {
					devices.forEach((device) => {
						const audioInput = devices.filter(
							(device) => device.kind === "audioinput"
						);
						const audioOutput = devices.filter(
							(device) => device.kind === "audiooutput"
						);

						if (audioInput.length) {
							updateSelectedAudioInput(audioInput[0].deviceId);
						}
						if (audioOutput.length) {
							updateSelectedAudioOutput(audioOutput[0].deviceId);
						}
						updateChannels({ audioInput, audioOutput });
					});
				});
			})
			.catch((error) => {
				console.log("Error :", error);
			});
	};
	useEffect(() => {
		getAudioVideoChannels();
	}, []);

	useEffect(() => {
		if (selectedAudioInput) {
			const audioInputDeviceTest = testAudioInputDevice({
				deviceId: selectedAudioInput,
			});

			audioInputDeviceTest.on(AudioInputTest.Events.Volume, (volume) => {
				updateAudioInputLevel(Math.ceil(volume));
			});

			audioInputDeviceTest.on(AudioInputTest.Events.Error, (error) => {
				console.error(error);
			});

			audioInputDeviceTest.on(AudioInputTest.Events.End, (report) => {
				console.log(report);
			});

			//   setTimeout(() => {
			//     audioInputDeviceTest.stop();
			//   }, 10000);
		}
	}, [selectedAudioInput]);
	const [isButtonDisabled, updateIsButtonDisables] = useState(false);
	const handleTestSpeaker = (stopState) => {
		updateIsButtonDisables(true);
		const audioOutputDeviceTest = testAudioOutputDevice({
			deviceId: selectedAudioOutput,
		});
		audioOutputDeviceTest.on(AudioOutputTest.Events.Volume, (volume) => {
			updateAudioOutputLevel(Math.ceil(volume));
		});

		audioOutputDeviceTest.on(AudioOutputTest.Events.Error, (error) => {
			console.error(error);
		});

		audioOutputDeviceTest.on(AudioOutputTest.Events.End, (report) => {
			console.log(report);
		});

		setTimeout(() => {
			audioOutputDeviceTest.stop();
			updateAudioOutputLevel(0);
			updateIsButtonDisables(false);
		}, 1300);
	};

	return (
		<Modal
			visible={isModalVisible}
			onCancel={() => {
				if (!isButtonDisabled) {
					onCancel();
				}
			}}
			className="testModal"
			centered>
			<label className="label">Speaker</label>
			<div className="d-flex">
				<Button
					className="mx-2"
					onClick={() => {
						if (!isButtonDisabled) {
							handleTestSpeaker();
						}
					}}>
					Test Speaker
				</Button>
				{channels.audioOutput && (
					<Select
						onChange={handleAudioOutputChange}
						defaultValue={
							channels.audioOutput.length
								? channels.audioOutput[0].label
								: "Default"
						}
						style={{ width: "60%" }}>
						{channels.audioOutput.map((channel) => {
							return (
								<Option value={channel.deviceId} key={channel.deviceId}>
									{channel.label}
								</Option>
							);
						})}
					</Select>
				)}
			</div>
			<div className="d-flex align-items-center mt-2">
				<div>Output level</div>
				<div className="levelContainer">
					<div
						className="levelBar"
						style={{ width: `${audioOutputLevel}%` }}></div>
				</div>
			</div>

			<label className="label mt-2">Microphone</label>
			<div className="d-flex">
				{channels.audioInput && channels.audioInput.length && (
					<Select
						onChange={handleAudioInputChange}
						defaultValue={
							channels.audioInput.length
								? channels.audioInput[0].label
								: "Default"
						}>
						{channels.audioInput.map((channel) => {
							return (
								<Option value={channel.deviceId} key={channel.deviceId}>
									{channel.label}
								</Option>
							);
						})}
					</Select>
				)}
			</div>
			<div className="d-flex align-items-center mt-2">
				<div>Input level</div>
				<div className="levelContainer">
					<div
						className="levelBar"
						style={{ width: `${audioInputLevel}%` }}></div>
				</div>
			</div>
		</Modal>
	);
}

export default TestModal;
