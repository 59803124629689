import React, { Component } from "react";
import Cross from "../../../Assets/PersonalInfoIconCross.png";
import OvalFilledCircleSm from "../../../Components/svgLogos/OvalFilledCircleSm";
import OvalEmptyCircleSm from "../../../Components/svgLogos/OvalEmptyCircleSm";
import ArrowLeftSm from "../../../Components/svgLogos/ArrowLeftSm";
import ArrowRightSm from "../../../Components/svgLogos/ArrowRightSm";
import ArrowStickLeftSm from "../../../Components/svgLogos/ArrowStickLeftSm";
import ArrowStickRightSm from "../../../Components/svgLogos/ArrowStickRightSm";
import axios from "axios";
//import TimePicker from "rc-time-picker";
import { baseURL, isSummerTime } from "../../../App";
import { Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { RegActions } from "../../../Store/Actions/RegActions";
import { AuthState } from "../../../Store/Actions/AuthActions";
import { Link, Redirect } from "react-router-dom";
import { Button, Switch, Row, Col, DatePicker } from "antd";
import moment from "moment";
import ConfirmDeleteRecursiveTime from "../../../Components/ConfirmDeleteRecursiveTime/ConfirmDeleteRecursiveTime";
import AlertModal from "../../../Components/AlertModal/AlertModal";
import GuestNav from "../../../Components/GuestNav/GuestNav";
import "./PrcAndAv.css";
class PrcAndAv extends Component {
	state = {
		paypal: false,
		pricePerHour: 1,
		currentDayNum: 0,
		currencyList: [],
		existIndex: 0,
		paypalEmail: "",
		confirmPaypalEmail: "",
		unit: "",
		avDates: [],
		enteranceFlag: 0,
		coach: null,
		daysArray: [],
		period: 1,
		startTime: null,
		endTime: null,
		finished: false,
		showAlert: false,
		startTimeObj: null,
		boldFont: false,
		schedules: [],
		recursiveSchedules: [],
		timeZone: localStorage.getItem("timeZone"),
		serverDate: null,
		timePickerState: false,
		isRecursive: false,
		isDeleteModalVisible: false,
		selectedSlotId: null,
		submitState: false,
		alertMsg: null,
		loading: false,
		selectedDate: null,
		selectedClickedDate: null,

		//submitkey: false,
	};
	keydownHandler = (e) => {
		if (e.keyCode === 66 && e.ctrlKey) {
			this.setState({
				boldFont: true,
			});
		}
	};
	handleFocus = (event) => {
		event.target.select();
	};

	componentWillUnmount() {
		document.removeEventListener("keydown", this.keydownHandler);
	}
	handleChange = (e) => {
		this.setState({ [e.target.id]: e.target.value });
	};

	handleTimePicker = (time, timeString, hours, minutes) => {
		const startDateTime = moment(
			this.state.schedules[this.state.currentDayNum].fullDate
		)
			.set({
				h: hours,
				m: minutes,
			})
			.format();
		const endDateTime = moment(startDateTime)
			.add(this.state.period === 1 ? 60 : 30, "minutes")
			.format();
		this.setState({ startTime: startDateTime, endTime: endDateTime });
	};
	changeEndTime = (period) => {
		this.setState({
			endTime: moment(this.state.startTime)
				.add(this.state.period === 1 ? 60 : 30, "minutes")
				.format(),
		});
	};

	handleChooseTime = (e) => {
		e.preventDefault();
		let currentDayNumVar = this.state.schedules.findIndex(
			(schedule) =>
				schedule.fullDate === moment(this.state.startTime).format("YYYY-MM-DD")
		);
		let currentDayNumVarForNonRecursive = this.state.schedules.findIndex(
			(schedule) =>
				schedule.fullDate === moment(this.state.startTime).format("YYYY-MM-DD")
		);

		if (this.state.isRecursive && currentDayNumVar >= 7) {
			currentDayNumVar = Math.floor(currentDayNumVar % 7);
		}
		this.setState(
			{
				currentDayNum: currentDayNumVar,
				//originalCurrentDayNum: currentDayNumVar,
			},
			() => {
				let schedules = [...this.state.schedules];
				let recursiveSchedules = [...this.state.recursiveSchedules];

				if (this.state.isRecursive) {
					const newSession = {
						startDateTime: moment(this.state.startTime)
							.tz(
								this.state.timeZone === "Africa/Cairo" && isSummerTime
									? "Africa/Addis_Ababa"
									: this.state.timeZone,
								true
							)
							.format(),
						endDateTime: moment(this.state.endTime)
							.tz(
								this.state.timeZone === "Africa/Cairo" && isSummerTime
									? "Africa/Addis_Ababa"
									: this.state.timeZone,
								true
							)
							.format(),
						period: this.state.period,
						isRecursive: this.state.isRecursive,
						sessionType: this.state.sessionType,
					};
					var isRecursiveNotOverlapped = recursiveSchedules[
						this.state.currentDayNum
					]?.times?.every((time) => {
						const sessionStartTime = moment(time.startDateTime)
							.tz("GMT")
							.valueOf();
						const sessionEndTime = moment(time.endDateTime).tz("GMT").valueOf();
						const newSessionStartTime = moment(this.state.startTime)
							.tz("GMT")
							.valueOf();
						const newSessionEndTime = moment(this.state.endTime)
							.tz("GMT")
							.valueOf();
						return !(
							newSessionStartTime < sessionEndTime &&
							newSessionEndTime > sessionStartTime
						);
					});
					if (isRecursiveNotOverlapped) {
						recursiveSchedules[this.state.currentDayNum].times.push(newSession);
					} else {
						this.setState({
							alertMsg: `One or more session/s were not added because you have a sessions within the range!`,
						});
						return;
					}
				}

				for (let dayIndex = 0; dayIndex < schedules.length; dayIndex += 7) {
					if (
						currentDayNumVarForNonRecursive + dayIndex <=
						schedules.length - 1
					) {
						const getTimeOverlap = (time, startTime, endTime) => {
							const sessionStartTime = moment(time.startDateTime)
								.tz("GMT")
								.valueOf();
							const sessionEndTime = moment(time.endDateTime)
								.tz("GMT")
								.valueOf();
							const newSessionStartTime = moment(startTime).tz("GMT").valueOf();
							const newSessionEndTime = moment(endTime).tz("GMT").valueOf();

							return !(
								newSessionStartTime < sessionEndTime &&
								newSessionEndTime > sessionStartTime
							);
						};

						const getTimeWithTimeZone = (time, timeZone, isSummerTime) => {
							const timeZoneToUse =
								timeZone === "Africa/Cairo" && isSummerTime
									? "Africa/Addis_Ababa"
									: timeZone;

							return moment(time)
								.add(dayIndex, "days")
								.tz(timeZoneToUse, true)
								.format();
						};

						const timeZoneToUse =
							this.state.timeZone === "Africa/Cairo" && isSummerTime
								? "Africa/Addis_Ababa"
								: this.state.timeZone;

						const startTime = getTimeWithTimeZone(
							this.state.startTime,
							timeZoneToUse
						);
						const endTime = getTimeWithTimeZone(
							this.state.endTime,
							timeZoneToUse
						);

						const isNotOverlapped =
							schedules[
								currentDayNumVarForNonRecursive + dayIndex
							]?.times.every((time) =>
								getTimeOverlap(time, startTime, endTime)
							) &&
							schedules[
								currentDayNumVarForNonRecursive + dayIndex + 1
							]?.times.every((time) =>
								getTimeOverlap(time, startTime, endTime)
							);
						if (isNotOverlapped || isNotOverlapped === undefined) {
							const newSession = {
								startDateTime: moment(this.state.startTime)
									.add(dayIndex, "days")
									.tz(
										this.state.timeZone === "Africa/Cairo" && isSummerTime
											? "Africa/Addis_Ababa"
											: this.state.timeZone,
										true
									)
									.format(),
								endDateTime: moment(this.state.endTime)
									.add(dayIndex, "days")
									.tz(
										this.state.timeZone === "Africa/Cairo" && isSummerTime
											? "Africa/Addis_Ababa"
											: this.state.timeZone,
										true
									)
									.format(),
								period: this.state.period,
								isRecursive: this.state.isRecursive,
								sessionType: this.state.sessionType,
							};
							schedules[currentDayNumVarForNonRecursive + dayIndex].times.push(
								newSession
							);

							schedules[currentDayNumVarForNonRecursive + dayIndex].times =
								schedules[
									currentDayNumVarForNonRecursive + dayIndex
								].times.sort((a, b) => {
									return a.startDateTime < b.startDateTime ? -1 : 1;
								});

							this.setState(
								{
									schedules,
									selectedDate: moment(this.state.selectedDate).add(
										this.state.period === 1 ? 60 : 30,
										"minutes"
									),
								},
								() => {
									this.setState(
										{
											startTime: moment(this.state.startTime)
												.add(this.state.period === 1 ? 60 : 30, "minutes")
												.format(),
											endTime: moment(this.state.endTime)
												.add(this.state.period === 1 ? 60 : 30, "minutes")
												.format(),
										},
										() => {
											if (
												schedules[currentDayNumVarForNonRecursive].fullDate !==
												moment(this.state.startTime).format("YYYY-MM-DD")
											) {
												this.setState({
													// currentDayNum:
													// 	this.state.currentDayNum + dayIndex + 1,
													startTime: moment(this.state.startTime)
														.add(this.state.period)
														.format(),
													endTime: moment(this.state.endTime)
														.add(this.state.period)
														.format(),
												});
											}
										}
									);
								}
							);
						} else {
							if (
								schedules[currentDayNumVarForNonRecursive + dayIndex]
									?.fullDate !== undefined
							) {
								if (this.state.isRecursive) {
									this.setState({
										// alertMsg: `You have already a session on ${
										// 	schedules[this.state.currentDayNum + dayIndex]?.fullDate
										// } within the range!`,
										alertMsg: `One or more session/s were not added because you have a sessions within the range!`,
									});
								} else {
									this.setState({
										alertMsg: "You have already a session within the range!",
									});
								}
							}
						}

						if (!this.state.isRecursive) {
							return;
						}
					}
				}
			}
		);
		//this.setState({ currentDayNum: this.state.originalCurrentDayNum });
	};
	handleRemoveTime = (e) => {
		e.preventDefault();
		if (this.state.isRecursive) {
			this.setState({
				isDeleteModalVisible: true,
				selectedSlotId: e.target.id,
			});
		} else {
			let schedules = this.state.schedules;

			let newTimes = schedules[this.state.currentDayNum].times.filter(
				(time) =>
					moment(time.startDateTime).tz(this.state.timeZone).format("HH:mm") +
						"-" +
						moment(time.endDateTime).tz(this.state.timeZone).format("HH:mm") +
						"-" +
						moment(time.startDateTime)
							.tz(this.state.timeZone)
							.format("YYYY-MM-DD") !==
					e.target.id
			);
			schedules[this.state.currentDayNum].times = newTimes;
			this.setState({ schedules });
		}
	};

	handleRemoveRecursiveTime = (e) => {
		e.preventDefault();
		const starting_from = e.target.id.split("-").splice(2).join("-");

		var schedules = this.state.schedules;
		var recursiveSchedules = this.state.recursiveSchedules;
		for (let dayIndex = 0; dayIndex <= schedules.length; dayIndex += 7) {
			let newTimes = schedules[
				this.state.currentDayNum + dayIndex
			]?.times.filter((time) => {
				const sessionId =
					moment(time.startDateTime).tz(this.state.timeZone).format("HH:mm") +
					"-" +
					moment(time.endDateTime).tz(this.state.timeZone).format("HH:mm") +
					"-" +
					starting_from;
				return sessionId !== e.target.id || time.booked === true;
			});
			if (schedules[this.state.currentDayNum + dayIndex]) {
				schedules[this.state.currentDayNum + dayIndex].times = newTimes;
			}
		}
		let newRecursiveTimes = recursiveSchedules[
			this.state.currentDayNum
		]?.times.filter(
			(time) =>
				moment(time.startDateTime).tz(this.state.timeZone).format("HH:mm") +
					"-" +
					moment(time.endDateTime).tz(this.state.timeZone).format("HH:mm") +
					"-" +
					moment(time.startDateTime)
						.tz(this.state.timeZone)
						.format("YYYY-MM-DD") !==
				e.target.id
		);
		recursiveSchedules[this.state.currentDayNum].times = newRecursiveTimes;
		this.setState({
			schedules,
			recursiveSchedules,
			isDeleteModalVisible: false,
		});
	};
	handleSubmit = () => {
		this.setState({ submitState: true, loading: true });
		let [firstName, lastName] = this.props.Reg.fullName.split(" ");
		axios({
			method: "post",
			url: baseURL + "/user/register",
			data: {
				email: this.props.Reg.email.toLowerCase(),
				password: this.props.Reg.password,
				firstName,
				lastName: lastName ? lastName : " ",
				phone: this.props.Reg.phone,
				userType: this.props.Reg.userType,
				image: this.props.Reg.image,
				birthDate: this.props.Reg.birthDate,
				// language: this.props.Reg.language,
				// otherlanguage: this.props.Reg.otherlanguage,
				gender: this.props.Reg.gender,
				prefix: this.props.Reg.prefix,
				specialization: this.props.Reg.specialization,
				subSpecializations: this.props.Reg.subSpecializationId,
				covidAssessment: this.props.Reg.covidAssesment,
				coachBio: this.props.Reg.bio,
				personalIdimage: this.props.Reg.imgIDUploader,
				document: this.props.Reg.docUploader,
				video: this.props.Reg.videoUploader,
				unit: this.state.unit,
				pricePerHour:
					this.state.pricePerHour === "" || this.state.pricePerHour === 0
						? 0
						: this.state.pricePerHour,
				schedulesNew: this.state.schedules,
				recursiveSchedules: this.state.recursiveSchedules,
				timeZone: this.state.timeZone,
			},
		})
			.then((res) => {
				this.setState({ loading: false });
				this.setState({ finished: true }, () => {
					this.props.RegActions("completed");
				});
			})
			.catch((err) => {
				this.setState({ loading: false });
			});

		//this.setState({ submitkey: true });
	};
	onStartDateChange = (date) => {
		this.setState({ selectedDate: date });
		let currentDateIndex = null;
		const hours = moment(date).format("HH");
		const minutes = moment(date).format("mm");
		this.state.schedules.forEach((schedule, index) => {
			if (schedule?.fullDate === moment(date).format("YYYY-MM-DD")) {
				currentDateIndex = index;
			}
		});

		let startDateTime = moment(this.state.schedules[currentDateIndex]?.fullDate)
			.set({
				h: hours,
				m: minutes,
			})
			.format();

		let endDateTime = moment(this.state.schedules[currentDateIndex].fullDate)
			.set({
				h: hours,
				m: minutes,
			})
			.add(this.state.period === 1 ? 60 : 30, "minutes")
			.format();

		this.setState({
			startTime: startDateTime,
			endTime: endDateTime,
		});
	};
	componentDidMount() {
		setTimeout(() => {
			axios({
				method: "get",
				url: baseURL + `/account/GetServerTime?timeZone=${this.state.timeZone}`,
			}).then((res) => {
				this.setState({ serverDate: res.data }, () => {
					const remainder = 15 - (moment(res.data).minute() % 15);

					this.setState(
						{
							serverDate: res.data,
							selectedDate: moment(res.data)
								.tz(this.state.timeZone)
								.add(remainder, "minutes"),
						},
						() => {
							this.onStartDateChange(this.state.selectedDate);
						}
					);
					document.addEventListener("keydown", this.keydownHandler);
					let schedules = [];
					let recursiveSchedulesList = [];

					const daysNames = [];
					for (let dayNumber = 0; dayNumber <= 6; dayNumber++) {
						const dayName = moment(this.state.serverDate)
							.add(dayNumber, "days")
							.tz(
								this.state.timeZone === "Africa/Cairo" && isSummerTime
									? "Africa/Addis_Ababa"
									: this.state.timeZone,
								true
							)
							.format("dddd");
						daysNames.push(dayName);
					}

					for (let dayNumber = 0; dayNumber <= 6; dayNumber++) {
						recursiveSchedulesList.push({
							fullDate: daysNames[dayNumber],
							times: [],
						});
					}
					for (let dayNumber = 0; dayNumber <= 365; dayNumber++) {
						schedules.push({
							fullDate: moment(this.state.serverDate)
								.add(dayNumber, "days")
								.format("YYYY-MM-DD"),
							times: [],
						});
					}
					this.setState(
						{ schedules, recursiveSchedules: recursiveSchedulesList },
						() => {
							this.setState({
								pricePerHour: 1,
							});
						}
					);
					axios({
						method: "get",
						url: baseURL + "/account/unitList",
						data: {},
					})
						.then((res) => {
							this.setState({
								currencyList: res.data.units,
								unit: res.data.units[0]._id,
							});
						})
						.catch((err) => console.log(err.response.data));
				});
			});
		}, 200);
	}

	onSwitchChange = (checked) => {
		this.setState({
			isRecursive: checked,
			currentDayNum: 0,
			// startTime: null,
			// endTime: null,
		});
	};
	preventNegativeAndDecimals = (e) => {
		if (e.key === "-" || e.key === ".") e.preventDefault();
	};
	render() {
		const disabledDate = (current) => {
			// Get today's date
			const today = moment(this.state.serverDate).startOf("day");

			// Can not select days before today
			return current && current < today;
		};
		const disabledDateTime = () => ({
			disabledHours: () => {
				const currentHour = parseInt(currentTime.substr(0, 2));
				const disabledHours = [];
				const isSameDay =
					moment(this.state.serverDate)
						.tz(this.state.timeZone)
						.format("YYYY-MM-DD") ===
					moment(this.state.selectedClickedDate).format("YYYY-MM-DD");
				if (isSameDay) {
					for (let hour = 0; hour <= 23; hour++) {
						if (hour < currentHour) {
							disabledHours.push(hour);
						}
					}
				}
				return disabledHours;
			},
			disabledMinutes: () => {
				var disabledMinutes = [];
				const currentMinutes = parseInt(currentTime.substr(3, 4));
				const isSameDay =
					moment(this.state.serverDate)
						.tz(this.state.timeZone)
						.format("YYYY-MM-DD") ===
					moment(this.state.selectedClickedDate).format("YYYY-MM-DD");
				const isSameHour =
					moment(this.state.serverDate)
						.tz(this.state.timeZone)
						.add(
							this.state.timeZone === "Africa/Cairo" && isSummerTime ? 1 : 0,
							"hour"
						)
						.format("HH") ===
					moment(this.state.selectedClickedDate).format("HH");
				for (let i = 0; i < 60; i++) {
					if (i % 15) {
						disabledMinutes.push(i);
					}
					if (isSameDay && isSameHour) {
						if (i < currentMinutes) {
							disabledMinutes.push(i);
						}
					}
				}
				return disabledMinutes;
			},
		});
		if (this.state.timePickerState) {
			setTimeout(() => {
				document
					.querySelector(".rc-time-picker-panel-select:nth-child(2)")
					.addEventListener("click", () => {
						setTimeout(() => {
							this.setState({ timePickerState: false });
						}, 500);
					});
			}, 200);
		}
		const currentTime = moment(this.state.serverDate).format("HH:mm");
		if (this.state.finished) {
			return (
				<Redirect
					to={{
						pathname: "/signin",
						state: { welcome: true },
					}}
				/>
			);
		}
		const handleDecPrice = () => {
			if (this.state.pricePerHour !== 0) {
				this.setState({
					pricePerHour: this.state.pricePerHour - 1,
					showAlert: false,
				});
			}
		};
		const handleIncPrice = () => {
			this.setState({
				pricePerHour: this.state.pricePerHour + 1,
				showAlert: false,
			});
		};
		if (!this.props.Reg.specialization) return <Redirect to="/docandvid" />;
		return (
			<div className="prcAndAv authPage">
				<GuestNav progress={(100 / 6) * 6} total={6} />
				<div className="d-flex align-items-center">
					<div className="prcAndAvContainer authForm fullScreen">
						<div>
							<label>Price per hour</label>
							<div style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
								Min. 1 USD
							</div>
						</div>
						<div className="text-center">
							<span className="pricing">
								<span style={{ marginBottom: "15px" }}>
									<span onClick={handleDecPrice} className="arrowLeft">
										<ArrowLeftSm />
									</span>
									<input
										className="px-4"
										type="number"
										pattern="[0-9]*"
										inputmode="numeric"
										onKeyDown={this.preventNegativeAndDecimals}
										value={
											this.state.pricePerHour === 0
												? "Free"
												: this.state.pricePerHour
										}
										placeholder="Free"
										onChange={(e) => this.handleChange(e)}
										onPaste={(e) => e.preventDefault()}
										id="pricePerHour"
										disabled={!this.state.editBtn}
									/>

									<span onClick={handleIncPrice} className="arrowRight">
										<ArrowRightSm />
									</span>
								</span>
							</span>
							<span className="mx-2">
								{/* {this.props.currencyData.exchangeCurrency} */}
								USD
							</span>
							{/* <div
								style={{
									fontSize: "12px",
									marginBottom: "0.5rem",
									textAlign: "center",
								}}>
								1 USD = {Math.ceil(this.props.currencyData.exchangeRate * 100) / 100}
								{this.props.currencyData.exchangeCurrency}
							</div> */}
						</div>

						{this.state.showAlert && (
							<Alert className="mt-2" style={{ textAlign: "center" }}>
								Rounded to the nearest 0 or 5
							</Alert>
						)}
						<div>
							<label>
								Availability
								{/* {this.state.submitkey && <span className="error">*</span>} */}
							</label>
						</div>
						<Row className="justify-content-between">
							<Col className="periodPicker">
								<h6>Session Length</h6>

								{/* <div
									onClick={() => {
										this.setState(
											{ period: 30 },
											() => this.state.startTime && this.changeEndTime(30)
										);
									}}>
									{this.state.period === 30 ? (
										<OvalFilledCircleSm />
									) : (
										<OvalEmptyCircleSm />
									)}
									<span>
										{" "}
										30 Minutes{" "}
										<b>
											{this.state.pricePerHour === 0
												? "Free"
												: Math.ceil((this.state.pricePerHour * 0.6) / 1) *
												  1}{" "}
											{this.state.pricePerHour !== 0 &&
												this.props.currencyData.exchangeCurrency}
										</b>
									</span>
								</div> */}
								<div
									onClick={() => {
										this.setState(
											{ period: 1 },
											() => this.state.startTime && this.changeEndTime(1)
										);
									}}>
									{this.state.period === 1 ? (
										<OvalFilledCircleSm />
									) : (
										<OvalEmptyCircleSm />
									)}
									<span>
										{" "}
										1 Hour{" "}
										<b>
											{this.state.pricePerHour === 0
												? "Free"
												: this.state.pricePerHour}{" "}
											{this.state.pricePerHour !== 0 && "USD"}
										</b>
									</span>
								</div>
							</Col>
							<Col className="text-left">
								<h6>Start time</h6>

								{/* <TimePicker
									onOpen={(open) => {
										this.setState({ timePickerState: open.open });
									}}
									onClose={(open) => {
										this.setState({ timePickerState: open.open });
									}}
									open={this.state.timePickerState}
									showSecond={false}
									onChange={(time) => {
										this.handleTimePicker(
											time,
											moment(time).format("HH:mm"),
											moment(time).format("HH"),
											moment(time).format("mm")
										);
									}}
									allowEmpty={false}
									value={
										moment(this.state.startTime).format() === "Invalid date"
											? ""
											: moment(this.state.startTime)
									}
									defaultOpenValue={moment(currentTime, "HH:mm")
										.tz(this.state.timeZone)
										.set("minutes", 45)}
									minuteStep={15}
									format={"HH:mm"}
									onSelect={(time) => {
										this.handleTimePicker(
											time,
											moment(time).format("HH:mm"),
											moment(time).format("HH"),
											moment(time).format("mm")
										);
									}}
									disabledMinutes={() => {
										if (
											this.state.schedules.length > 0 &&
											!this.state.isRecursive
										) {
											if (
												moment(
													this.state.schedules[this.state.currentDayNum]
														.fullDate
												).format("YYYY-MM-DD") ===
													moment(this.state.serverDate).format("YYYY-MM-DD") &&
												(moment(this.state.serverDate)
													.tz(this.state.timeZone)
													.format("HH") ===
													moment(this.state.startTime).format("HH") ||
													!this.state.startTime)
											) {
												let disabledMinutes = [];
												const currentMinute = currentDate.format("mm");
												for (let i = 0; i < currentMinute; i++) {
													disabledMinutes = [...disabledMinutes, i];
												}
												return disabledMinutes;
											}
										}
									}}
									disabledHours={() => {
										if (
											this.state.schedules.length > 0 &&
											!this.state.isRecursive
										) {
											if (
												moment(
													this.state.schedules[this.state.currentDayNum]
														.fullDate
												).format("YYYY-MM-DD") ===
												moment(this.state.serverDate).format("YYYY-MM-DD")
											) {
												let disabledHours = [];
												const currentHour = currentDate
													.tz(this.state.timeZone)
													.format("HH");
												for (let i = 0; i < currentHour; i++) {
													disabledHours = [...disabledHours, i];
												}
												return disabledHours;
											}
										}
									}}
								/> */}
								<DatePicker
									inputReadOnly={true}
									allowClear={false}
									showTime={{ format: "hh:mm a" }}
									showNow={false}
									format="DD-MM-YYYY hh:mm a"
									onChange={this.onStartDateChange}
									disabledTime={disabledDateTime}
									disabledDate={disabledDate}
									value={this.state.selectedDate}
									onOk={false}
								/>

								<div className="endTimeContainer f-14">
									End time:{" "}
									<span>
										{moment(this.state.selectedDate).format("HH:mm") ===
										"Invalid date"
											? ""
											: moment(this.state.selectedDate)
													.add(this.state.period === 30 ? 30 : 60, "minutes")
													.format("DD-MM-YYYY HH:mm")}
									</span>
								</div>
								<Button className="primary-btn" onClick={this.handleChooseTime}>
									Add
								</Button>
							</Col>
						</Row>
						<div className="weeks">
							<div className="d-flex align-items-center justify-content-center">
								<Switch onChange={this.onSwitchChange} />
								<div className="mx-2">Recursive Calendar</div>
							</div>

							<div className="timeTableCounter d-flex justify-content-center align-items-center">
								<div
									onClick={() => {
										if (this.state.isRecursive) {
											if (this.state.currentDayNum !== 0) {
												this.setState(
													{
														currentDayNum: this.state.currentDayNum - 1,
													},
													() => {
														// this.setState({
														// 	startTime: null,
														// 	endTime: null,
														// });
													}
												);
											} else {
												this.setState(
													{
														currentDayNum: 6,
													},
													() => {
														// this.setState({
														// 	startTime: null,
														// 	endTime: null,
														// });
													}
												);
											}
										} else {
											if (this.state.currentDayNum !== 0) {
												this.setState(
													{
														currentDayNum: this.state.currentDayNum - 1,
													},
													() => {
														// this.setState({
														// 	startTime: null,
														// 	endTime: null,
														// });
													}
												);
											}
										}
									}}>
									<ArrowStickLeftSm />
								</div>
								{this.state.schedules?.length > 0 && (
									<div className="dayName">
										<span>
											{this.state.isRecursive
												? this.state.recursiveSchedules[
														this.state.currentDayNum
												  ]?.fullDate
												: moment(
														this.state.schedules[this.state.currentDayNum]
															?.fullDate
												  )?.format("ddd")}
										</span>
										<br />
										{!this.state.isRecursive && (
											<span>
												{moment(
													this.state.schedules[this.state.currentDayNum]
														.fullDate
												).format("MMM")}{" "}
												{moment(
													this.state.schedules[this.state.currentDayNum]
														.fullDate
												).format("D")}
											</span>
										)}
									</div>
								)}
								<div
									onClick={() => {
										if (this.state.isRecursive) {
											if (this.state.currentDayNum !== 6) {
												this.setState({
													currentDayNum: this.state.currentDayNum + 1,
													// startTime: null,
													// endTime: null,
												});
											} else {
												this.setState({ currentDayNum: 0 });
											}
										} else {
											if (this.state.currentDayNum !== 365) {
												this.setState(
													{
														currentDayNum: this.state.currentDayNum + 1,
													},
													() => {
														// this.setState({
														// 	startTime: null,
														// 	endTime: null,
														// });
													}
												);
											}
										}
									}}>
									<ArrowStickRightSm />
								</div>
							</div>
							<div className="timeTable d-flex">
								{this.state.isRecursive ? (
									<>
										{this.state.recursiveSchedules[
											this.state.currentDayNum
										]?.times?.map((time) => {
											return (
												<div className="timeSlot mx-2 px-2">
													<div className="d-flex justify-content-between align-items-center flex-1">
														<div className="align-self-center">
															{moment(time.startDateTime)
																.tz(
																	this.state.timeZone === "Africa/Cairo" &&
																		isSummerTime
																		? "Africa/Addis_Ababa"
																		: this.state.timeZone
																)
																.format("hh:mm a") +
																"-" +
																moment(time.endDateTime)
																	.tz(
																		this.state.timeZone === "Africa/Cairo" &&
																			isSummerTime
																			? "Africa/Addis_Ababa"
																			: this.state.timeZone
																	)
																	.format("hh:mm a")}
															(R)
														</div>
														<img
															id={
																moment(time.startDateTime)
																	.tz(this.state.timeZone)
																	.format("hh:mm a") +
																"-" +
																moment(time.endDateTime)
																	.tz(this.state.timeZone)
																	.format("hh:mm a") +
																"-" +
																moment(time.startDateTime)
																	.tz(this.state.timeZone)
																	.format("YYYY-MM-DD")
															}
															src={Cross}
															onClick={this.handleRemoveTime}
															alt="cross"
															className="mx-2"
														/>
													</div>
													<div>
														Starting From :{" "}
														{moment(time.startDateTime)
															.tz(this.state.timeZone)
															.format("YYYY-MM-DD")}
													</div>
												</div>
											);
										})}
									</>
								) : (
									this.state.schedules?.length > 0 &&
									this.state.schedules[this.state.currentDayNum]?.times?.map(
										(time) => {
											return (
												<>
													<div className="timeSlot mx-2 d-flex justify-content-between px-2">
														<div className="align-self-center flex-1">
															{moment(time.startDateTime)
																.tz(
																	this.state.timeZone === "Africa/Cairo" &&
																		isSummerTime
																		? "Africa/Addis_Ababa"
																		: this.state.timeZone
																)
																.format("hh:mm a") +
																"-" +
																moment(time.endDateTime)
																	.tz(
																		this.state.timeZone === "Africa/Cairo" &&
																			isSummerTime
																			? "Africa/Addis_Ababa"
																			: this.state.timeZone
																	)
																	.format("hh:mm a")}
															{time.isRecursive && <> (R)</>}
														</div>
														<div className="align-self-end mx-2">
															<img
																id={
																	moment(time.startDateTime)
																		.tz(this.state.timeZone)
																		.format("HH:mm") +
																	"-" +
																	moment(time.endDateTime)
																		.tz(this.state.timeZone)
																		.format("HH:mm") +
																	"-" +
																	moment(time.startDateTime)
																		.tz(this.state.timeZone)
																		.format("YYYY-MM-DD")
																}
																src={Cross}
																onClick={this.handleRemoveTime}
																alt="cross"
															/>
														</div>
													</div>
												</>
											);
										}
									)
								)}
							</div>
						</div>

						<div className="my-4 btnContainer">
							<div className="text-center">
								<Link to="/docandvid">
									<Button className="secondary-btn">Back</Button>
								</Link>
								<Button
									loading={this.state.loading}
									className="primary-btn"
									onClick={this.handleSubmit}>
									Done
								</Button>
							</div>
						</div>
					</div>
				</div>

				<ConfirmDeleteRecursiveTime
					isModalVisible={this.state.isDeleteModalVisible}
					onCancel={() => {
						this.setState({ isDeleteModalVisible: false });
					}}
					handleRemoveTime={this.handleRemoveRecursiveTime}
					selectedSlotId={this.state.selectedSlotId}
				/>
				<AlertModal
					isModalVisible={this.state.alertMsg}
					msg={this.state.alertMsg}
					onCancel={() => {
						this.setState({ alertMsg: null });
					}}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		Reg: state.reg,
		currencyData: state.currencyReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		RegActions: (reg) => dispatch(RegActions(reg)),
		AuthState: (auth) => dispatch(AuthState(auth)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(PrcAndAv);
