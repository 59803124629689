import React, { useState, useEffect, useRef } from "react";
//import moment from "moment";
import DateCountdown from "react-date-countdown-timer";
import { connect } from "react-redux";
import MicOff from "./assets/icons/mute.svg";
import CamOff from "./assets/icons/no-video.svg";
const Participant = ({
	participant,
	sessionDates,
	handleAddExtraMins,
	userType,
	getFinishSessionSign,
	remoteName,
	auth,
	toggleAudio,
	toggleVideo,
	getLocalVideoRef,
	getLocalAudioRef,
	isRemote,
}) => {
	const [videoTracks, setVideoTracks] = useState([]);
	const [audioTracks, setAudioTracks] = useState([]);
	//const [clicked, updateClicked] = useState(false);
	const [redText, updateRedText] = useState(false);
	const [isVideoEnabled, updateIsVideoEnabled] = useState(true);
	const [isAudioEnabled, updateIsAudioEnabled] = useState(true);
	const [refresh, updateRefresh] = useState(false);
	const [participantType, updateParticipantType] = useState("");
	const [showCountdown, updateShowCountdown] = useState(false);
	const videoRef = useRef();
	const audioRef = useRef();

	// getLocalVideoRef(videoRef);
	// getLocalAudioRef(audioRef);
	const trackpubsToTracks = (trackMap) =>
		Array.from(trackMap.values())
			.map((publication) => publication.track)
			.filter((track) => track !== null);
	useEffect(() => {
		updateParticipantType(participant.constructor.name);
		if (isRemote) {
			const videoTrackEntries = participant.videoTracks.entries();
			const audioTrackEntries = participant.audioTracks.entries();

			const isVideoEnabledVar =
				videoTrackEntries.next().value[1].isTrackEnabled;
			const isaudioEnabledVar =
				audioTrackEntries.next().value[1].isTrackEnabled;
			updateIsAudioEnabled(isaudioEnabledVar);
			updateIsVideoEnabled(isVideoEnabledVar);
			participant.on("trackDisabled", () => {
				updateRefresh(!refresh);
			});
			participant.on("trackEnabled", () => {
				updateRefresh(!refresh);
			});
		}

		setVideoTracks(trackpubsToTracks(participant.videoTracks));
		setAudioTracks(trackpubsToTracks(participant.audioTracks));

		const trackSubscribed = (track) => {
			if (track.kind === "video") {
				setVideoTracks((videoTracks) => [...videoTracks, track]);
			} else {
				setAudioTracks((audioTracks) => [...audioTracks, track]);
			}
		};

		const trackUnsubscribed = (track) => {
			if (track.kind === "video") {
				setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
			} else {
				setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
			}
		};

		participant.on("trackSubscribed", trackSubscribed);
		participant.on("trackUnsubscribed", trackUnsubscribed);
		return () => {
			setVideoTracks([]);
			setAudioTracks([]);
			participant.removeAllListeners();
		};
	}, [participant, isVideoEnabled, refresh, isRemote]);
	useEffect(() => {
		const videoTrack = videoTracks[0];
		if (videoTrack) {
			videoTrack.attach(videoRef.current);
			return () => {
				videoTrack.detach();
			};
		}
	}, [videoTracks]);

	useEffect(() => {
		const audioTrack = audioTracks[0];
		if (audioTrack) {
			audioTrack.attach(audioRef.current);
			return () => {
				audioTrack.detach();
			};
		}
	}, [audioTracks]);
	useEffect(() => {
		setInterval(() => {
			const minutesNode = document.querySelector(".min");
			if (minutesNode) {
				if (
					parseInt(
						minutesNode.children[0].innerHTML +
							minutesNode.children[1].innerHTML
					) < 10
				) {
					updateShowCountdown(true);
				}
				if (
					parseInt(
						minutesNode.children[0].innerHTML +
							minutesNode.children[1].innerHTML
					) < 5
				) {
					updateRedText(true);
				} else {
					updateRedText(false);
				}
			}
		}, 1000);
	});
	return (
		<div className="participant" style={{ position: "relative" }}>
			{sessionDates && (
				<div className="text-center">
					<div
						className={
							showCountdown
								? redText
									? "nextSessionCounterBox red_text"
									: "nextSessionCounterBox"
								: "d-none"
						}
					>
						Remaining meeting time:{" "}
						<DateCountdown
							dateTo={sessionDates.sessionEndDate}
							locales_plural={[]}
							mostSignificantFigure="min"
							locales={[]}
							callback={() => {
								// getFinishSessionSign(true);
								// addTimeBuffer(10);
								const odoMeter = document.querySelector(".odometer-block");
								if (odoMeter) {
									odoMeter.innerHTML = "session time is completed";
								}
							}}
							dateFrom={sessionDates.serverDate}
							key={sessionDates.sessionEndDate}
						/>
						{/* {userType === 1 && !clicked && (
							<button
								className="btn"
								onClick={() => {
									updateClicked(true);
									handleAddExtraMins();
								}}
							>
								Extra 5 Mins
							</button>
						)} */}
					</div>
				</div>
			)}

			<div className="videoBox">
				{isVideoEnabled || participantType === "LocalParticipant" ? (
					<video ref={videoRef} autoPlay={true} id="local-media" />
				) : (
					<div className="remoteName d-flex flex-1 justify-content-center align-items-center text-center">
						{/* <div className='text-center'>{remoteName}</div> */}
					</div>
				)}
				{participantType === "LocalParticipant" && (
					<div className="statusBar text-center d-flex justify-content-center align-items-center">
						{!toggleAudio && (
							<figure className="micOffFigure mx-2">
								<img src={MicOff} alt="MicOff" />
							</figure>
						)}
						{!toggleVideo && (
							<figure className="micOffFigure mx-2">
								<img src={CamOff} alt="MicOff" />
							</figure>
						)}
						<div className="name">{auth.authData.user.firstName}</div>
					</div>
				)}

				{participantType !== "LocalParticipant" && (
					<div className="statusBar d-flex justify-content-start px-2">
						{!isAudioEnabled && (
							<figure className="micOffFigure mx-2">
								<img src={MicOff} alt="MicOff" />
							</figure>
						)}
						{!isVideoEnabled && (
							<figure className="micOffFigure mx-2">
								<img src={CamOff} alt="MicOff" />
							</figure>
						)}
						<div className="name text-right">{remoteName}</div>
					</div>
				)}
			</div>
			{/* {!isAudioEnabled && participantType === "RemoteParticipant" && (
				<figure className="micOffFigure">
					<img src={MicOff} alt="MicOff" />
				</figure>
			)} */}
			<audio ref={audioRef} autoPlay={true} />
		</div>
	);
};
const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	};
};
export default connect(mapStateToProps)(Participant);
