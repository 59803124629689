import { SHOW_ELEMENT, HIDE_ELEMENT } from "../Actions/CardActions";

const initialState = {
  extraCardDetails: true,
};

const cardReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_ELEMENT:
      return { ...state, extraCardDetails: true };
    case HIDE_ELEMENT:
      return { ...state, extraCardDetails: false };
    default:
      return state;
  }
};

export default cardReducer;
