import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
//import LogoLg from "../../Components/svgLogos/LogoLg";
import logo from "../../Assets/Logo.svg";
import SearchLogo from "../../Components/svgLogos/HomePageFigures/SearchLogo";
import axios from "axios";
import FiltersIcon from "../../Components/svgLogos/HomePageFigures/FiltersIcon";
//import DropDownArrow from "../../Components/svgLogos/HomePageFigures/DropDownArrow";
import { baseURL } from "../../App";
import SignOutLogo from "../svgLogos/HomePageFigures/SignOutLogo";
import { connect } from "react-redux";
import { AuthState } from "../../Store/Actions/AuthActions";
import "./NavBarLg.css";
class NavBarLg extends Component {
  state = {
    toggleFilter: this.props.toggleFilterPageState,
    toggleDropdownMenu: this.props.toggleDropdownMenuState,
    searchValue: "",
    searchsuggestions: [],
    searchedCoachList: [],
    redirectToHome: false,
    timeZone: localStorage.getItem("timeZone"),
  };

  toggleFilterPage = () => {
    this.props.toggleFilterPage(this.state.toggleFilter);
  };
  toggleDropdownMenu = () => {
    this.props.toggleDropdownMenu(this.state.toggleDropdownMenu);
  };
  searchedCoachList = () => {
    this.props.searchedCoachList(this.state.searchedCoachList);
  };
  handleSearchOnChange = (e) => {
    this.setState({ [e.target.id]: e.target.value }, () => {
      axios({
        method: "post",
        url: baseURL + "/coache/autocompleteSearch",
        data: {
          search: this.state.searchValue,
          index: 0,
        },
      }).then((res) => {
        this.setState({ searchsuggestions: res.data });
      });
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    axios({
      method: "post",
      url: baseURL + `/coache/searchnew?timeZone=${this.state.timeZone}`,
      data: {
        search: this.state.searchValue,
        index: "0",
      },
    })
      .then((res) => {
        this.props.searchedCoachList(res.data);
      })
      .catch((err) => {});

    axios({
      method: "post",
      url: baseURL + "/coache/searchPagesCount",
      data: {
        search: this.state.searchValue,
      },
    })
      .then((res) => {
        this.props.searchedPagesCount(Math.ceil(res.data.count));
      })
      .catch((err) => {});

    this.props.getSearchVal(this.state.searchValue);
  };

  // componentDidMount() {
  //   axios({
  //     method: "post",
  //     url: baseURL + "/coache/autocompleteSearch",
  //     data: {
  //       search: this.state.searchValue,
  //     },
  //   }).then((res) => {
  //     this.setState({ searchsuggestions: res.data });
  //   });
  // }

  render(props) {
    if (this.state.redirectToHome) return <Redirect to="/" />;
    return (
      <div
        className={this.props.fixedNavbar ? "fixedNavbar NavBarLg" : "NavBarLg"}
      >
        <a href="/">
          <img
            src={logo}
            alt="logo"
            className="logo"
            onClick={() => {
              localStorage.setItem("refresh", true);
            }}
          />
        </a>

        <span className="items">
          <form style={{ display: "inline" }} onSubmit={this.handleSubmit}>
            {this.props.searchBar && (
              <span className="searchBox">
                <input
                  list="search"
                  id="searchValue"
                  name="searchValue"
                  placeholder="Search"
                  onChange={this.handleSearchOnChange}
                  value={this.state.searchValue}
                />
                <datalist id="search">
                  {this.state.searchsuggestions.map((suggestion) => {
                    return (
                      <option
                        key={suggestion._id}
                        value={`${suggestion.firstName} ${suggestion.lastName}`}
                      />
                    );
                  })}
                </datalist>
                <button type="submit" className="searchLogo float-right">
                  <SearchLogo />
                </button>
              </span>
            )}
          </form>

          <a href="/">
            <div
              className={
                this.props.active === "Doctors" ? "tabs active" : "tabs"
              }
              onClick={() => {
                localStorage.setItem("refresh", true);
              }}
            >
              Gurus
            </div>
          </a>
          <Link
            to={
              this.props.auth.authData
                ? {
                    pathname: "/mySessions",
                  }
                : {
                    pathname: "/signin",
                    state: {
                      message: "Don't have an account yet? Please Signup now!",
                    },
                  }
            }
          >
            <div
              className={
                this.props.active === "Schedule" ? "tabs active" : "tabs"
              }
            >
              My Sessions
            </div>
          </Link>
          <Link to={"/Events"}>
            <div
              className={
                this.props.active === "Events" ? "tabs active" : "tabs"
              }
            >
              Events
            </div>
          </Link>
          <Link
            to={
              this.props.auth.authData
                ? {
                    pathname: "/account",
                  }
                : {
                    pathname: "/signin",
                    state: {
                      message: "Don't have an account yet? Please Signup now!",
                    },
                  }
            }
          >
            <div
              className={
                this.props.active === "Account" ? "tabs active" : "tabs"
              }
            >
              Account
            </div>
          </Link>
          <Link
            to={
              this.props.auth.authData
                ? {
                    pathname: "/blog",
                  }
                : {
                    pathname: "/signin",
                    state: {
                      message: "Don't have an account yet? Please Signup now!",
                    },
                  }
            }
          >
            <div
              className={this.props.active === "blog" ? "tabs active" : "tabs"}
            >
              Blog
            </div>
          </Link>

          {this.props.active !== "Account" && (
            <div
              onClick={() => {
                if (this.props.parent === "SessionCalendar") {
                  this.setState({ redirectToHome: true }, () => {
                    localStorage.setItem("filterState", true);
                  });
                } else {
                  this.state.toggleDropdownMenu
                    ? this.setState(
                        {
                          toggleFilter: !this.state.toggleFilter,
                          toggleDropdownMenu: !this.state.toggleDropdownMenu,
                        },
                        () => {
                          this.toggleDropdownMenu();
                          this.toggleFilterPage();
                        }
                      )
                    : this.setState(
                        { toggleFilter: !this.state.toggleFilter },
                        () => this.toggleFilterPage()
                      );
                }
              }}
              className="filtersIcon"
            >
              <FiltersIcon />
            </div>
          )}
          {/* <div
						onClick={() =>
							this.state.toggleFilter
								? this.setState(
									{
										toggleDropdownMenu: !this.state.toggleDropdownMenu,
										toggleFilter: false,
									},
									() => {
										this.toggleDropdownMenu();
										this.toggleFilterPage();
									}
								)
								: this.setState(
									{ toggleDropdownMenu: !this.state.toggleDropdownMenu },
									() => this.toggleDropdownMenu()
								)
						}
						className="dropDownIcon"
					>
						<DropDownArrow />
					</div> */}

          {!this.props.auth.authData ? (
            <span className="guestLinks">
              <Link to="/signin" className="actionsLink">
                <span className="dropDownItem mx-2">Log in</span>
              </Link>
              <Link to="/usertype" className="actionsLink">
                <span className="dropDownItem mx-2">Join</span>
              </Link>
            </span>
          ) : (
            <span className="userLinks mx-2">
              <Link to="/signin" className="actionsLink">
                <span
                  onClick={() => {
                    this.props.AuthState(0);
                    // this.props.toggleDropdownMenu(false);
                  }}
                  className="dropDownItem signout"
                >
                  <span className="mx-1">
                    <SignOutLogo />
                  </span>
                  LogOut
                </span>
              </Link>
            </span>
          )}
        </span>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    AuthState: (auth) => dispatch(AuthState(auth)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBarLg);
