import React from "react";
function FiltersIcon(props) {
	return (
		<svg
			id="options"
			xmlns="http://www.w3.org/2000/svg"
			width="18"
			height="17"
			viewBox="0 0 18 17"
		>
			<g id="Component_62_1" data-name="Component 62 – 1">
				<path
					id="Path_943"
					data-name="Path 943"
					d="M7,3a4,4,0,0,1,3.874,3H19V8H10.874A4,4,0,1,1,7,3ZM7,9A2,2,0,1,0,5,7,2,2,0,0,0,7,9Z"
					transform="translate(-3 -3)"
					fill={props.color}
					fillRule="evenodd"
				/>
			</g>
			<g
				id="Component_63_1"
				data-name="Component 63 – 1"
				transform="translate(2 9)"
			>
				<path
					id="Path_944"
					data-name="Path 944"
					d="M17,20a4,4,0,0,1-3.874-3H5V15h8.126A4,4,0,1,1,17,20Zm0-2a2,2,0,1,0-2-2A2,2,0,0,0,17,18Z"
					transform="translate(-5 -12)"
					fill={props.color}
					fillRule="evenodd"
				/>
			</g>
		</svg>
	);
}

export default FiltersIcon;
