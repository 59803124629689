import React, { useState, useEffect } from "react";
//import styled from "styled-components";
import Mic from "./assets/icons/microphone.svg";
import MicOff from "./assets/icons/mute.svg";
import Video from "./assets/icons/video-camera.svg";
import VideoOff from "./assets/icons/no-video.svg";
//import End from "./assets/icons/end.svg";
import { Redirect } from "react-router-dom";
import ArrowUp from "./assets/icons/ArrowUp.svg";
import { Menu, Dropdown } from "antd";
import CloseIcon from "../../Components/svgLogos/Cross";
import ChatIcon from "../../Components/svgLogos/FloatingBarIcons/ChatIcon";
//import { useIdleTimer } from "react-idle-timer";
import "./ZoomControls.css";
function ZoomControls({
	handleAudioToggle,
	handleVideoToggle,
	audio,
	video,
	handleChangeChannels,
	toggleChatStateFun,
	hasUnreadMsg,
	chatState,
	remoteName,
}) {
	const [disconnect, handleDisconnect] = useState(false);
	// eslint-disable-next-line
	const [isControlShown, updateIsControlShown] = useState(true);
	const [channels, updateChannels] = useState({
		videoInput: [],
		audioInput: [],
	});
	const [lastMsgContainer, updateLastMsgContainer] = useState(false);
	const videoMenu = (
		<Menu
			onClick={({ key }) => {
				handleChangeChannels("videoInput", channels.videoInput[key]);
			}}
		>
			{channels.videoInput.map((video, videoIndex) => {
				return <Menu.Item key={videoIndex}>{video.label}</Menu.Item>;
			})}
		</Menu>
	);
	const audioInputMenu = (
		<Menu
			onClick={({ key }) => {
				handleChangeChannels("audioInput", channels.audioInput[key]);
			}}
		>
			{channels.audioInput.map((audio, audioIndex) => {
				return <Menu.Item key={audioIndex}>{audio.label}</Menu.Item>;
			})}
		</Menu>
	);

	useEffect(() => {
		if (hasUnreadMsg && hasUnreadMsg?.count !== 0) {
			updateLastMsgContainer(true);
		}
	}, [hasUnreadMsg]);
	const getAudioVideoChannels = () => {
		navigator.mediaDevices.enumerateDevices().then((devices) => {
			const videoInput = devices.filter(
				(device) => device.kind === "videoinput"
			);
			const audioInput = devices.filter(
				(device) => device.kind === "audioinput"
			);

			updateChannels({ videoInput, audioInput });
		});
	};

	useEffect(() => {
		getAudioVideoChannels();
	}, []);
	if (disconnect) return <Redirect to="/mySessions" />;
	return (
		<div
			className={
				isControlShown
					? "zoomControls d-flex justify-content-between align-items-center"
					: "d-none"
			}
		>
			<div className="d-flex">
				<div className="item">
					<div onClick={handleAudioToggle}>
						<img src={audio ? Mic : MicOff} alt="mic" className="icon" />
						<div className="icon-info">{audio ? "Mute" : "Unmute"}</div>
					</div>
					<Dropdown overlay={audioInputMenu} placement="topLeft" arrow>
						<img src={ArrowUp} alt="arrowUp" className="arrowUp" />
					</Dropdown>
				</div>
				<div className="item">
					<div onClick={handleVideoToggle}>
						<img
							src={video ? Video : VideoOff}
							alt="videoIcon"
							className="icon"
						/>
						<div className="icon-info">
							{video ? "Stop Video" : "Start Video"}
						</div>
					</div>
					<Dropdown overlay={videoMenu} placement="topRight" arrow>
						<div className="d-flex align-items-start arrowUpContainer">
							<img src={ArrowUp} alt="arrowUp" className="arrowUp" />
						</div>
					</Dropdown>
				</div>
			</div>
			<div>
				{lastMsgContainer && !chatState && (
					<div className="lastUnreadMsgBox p-2">
						<div className="title d-flex justify-content-between">
							<div>From {remoteName} to ME</div>
							<div
								className="closeIconContainer"
								onClick={() => {
									updateLastMsgContainer(false);
								}}
							>
								<CloseIcon color={"#fff"} />
							</div>
						</div>
						<div className="message">{hasUnreadMsg?.msg}</div>
					</div>
				)}
				<div className="item">
					<div onClick={toggleChatStateFun}>
						<ChatIcon color={"#AAABAC"} />
						<div className="icon-info">Chat</div>
						{hasUnreadMsg?.count !== 0 && (
							<div className="unreadMsgCount">{hasUnreadMsg?.count}</div>
						)}
					</div>
				</div>
			</div>
			<div className="d-flex align-items-center">
				<button
					className="endButton"
					onClick={() => {
						handleDisconnect(true);
					}}
				>
					Leave
				</button>
			</div>
		</div>
	);
}

export default ZoomControls;
