import React, { Component } from "react";
import AccountDiscountsVase from "../svgLogos/AccountPageFigures/AccountDiscountsVase";
import { connect } from "react-redux";
//import Facebook from "../svgLogos/FaceBook";
//import GooglePlus from "../svgLogos/GooglePlus";
//import Instagram from "../svgLogos/Instagram";
//import LinkedIn from "../svgLogos/LinkedIn";
import axios from "axios";
import { baseURL } from "../../App";
import "./AccountDiscounts.css";
class AccountDiscounts extends Component {
	state = {
		referralCode: null,
	};
	componentDidMount() {
		axios({
			method: "get",
			url:
				baseURL +
				"/user/getUserSharedCode?id=" +
				this.props.auth.authData.user._id,
		}).then((res) => this.setState({ referralCode: res.data.userCode }));
	}
	render() {
		
		return (
			<div className="accountDiscounts">
				<div className="heading">
					<span>20% </span>Promotions
				</div>
				<div className="content">
					<div className="icon">
						<AccountDiscountsVase />
					</div>
					<div className="invite">Invite a Friend</div>
					<p>
						Invite a friend 20% off your next two
						<br /> sessions with WeLO.
					</p>
					<div className="codeBox">
						Your referral code is
						<div>{this.state.referralCode}</div>
					</div>
					{/* 
					<Facebook />
					<GooglePlus />
					<Instagram />
					<LinkedIn />
					*/}
				</div>
			</div>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	};
};

export default connect(mapStateToProps)(AccountDiscounts);
