import React, { Component } from "react";
import { connect } from "react-redux";
import PersonalInfoIcon from "../../Components/svgLogos/AccountPageFigures/PersonalInfo.svg";
import ProfessionIcon from "../../Components/svgLogos/AccountPageFigures/Profession.svg";
import AvailabilityIcon from "../../Components/svgLogos/AccountPageFigures/Availability.svg";
import BookingMessageIcon from "../../Components/svgLogos/AccountPageFigures/BookingMessage.svg";
import MyReviewsIcon from "../../Components/svgLogos/AccountPageFigures/MyReviews.svg";
import TransactionHistoryIcon from "../../Components/svgLogos/AccountPageFigures/TransactionHistory.svg";
import DiscountIcon from "../../Components/svgLogos/AccountPageFigures/Discount.svg";
import documentsIcon from "../../Assets/documents.svg";
import bankDetails from "../../Assets/bank-details.svg";
//import PaymentTypeMethodIcon from "../../Components/svgLogos/AccountPageFigures/PaymentTypeMethod";
//import LanguageIcon from "../../Components/svgLogos/AccountPageFigures/Language";
//import TimeZoneIcon from "../../Components/svgLogos/AccountPageFigures/TimeZone";
//import PreferencesIcon from "../../Components/svgLogos/AccountPageFigures/Preferences";
// import { Drawer } from "antd";

import "./AccountSideMenu.css";
import Packages from "../../Assets/packages.svg";
class AccountSideMenu extends Component {
  state = {
    activePage: this.props.activePage,
    showMess: this.props.showalert,
    alertProps: this.props.alert,
    target: "",
    save: this.props.save,
    visible: false, // this.props.auth.authData.userType === 1 ? false : true,
  };
  onClose = () => {
    this.setState({ visible: false });
  };

  showDrawer = () => {
    this.setState({ visible: true });
  };

  switchPage = (e) => {
    this.onClose();
    this.setState(
      {
        target: e.target.id,
      },
      () => {
        if (this.props.alert) {
          this.setState(
            {
              showMess: true,
            },
            () => {
              this.props.alertPages(this.state.showMess);
              this.props.targetPage(this.state.target);
            }
          );
          if (this.props.save) {
            this.setState({
              showMess: false,
              alertProps: false,
            });
            this.props.onSwitchPages(this.state.target);
            this.setState({
              // activePage: e.target.id,
            });
          }
        } else {
          this.props.onSwitchPages(this.state.target);
          this.setState({
            showMess: false,
          });
        }
        // this.props.onSwitchPages(e.target.id);
        // this.setState({
        //   activePage: e.target.id,
        // });
      }
    );
  };

  componentDidUpdate(previousProps) {
    if (previousProps.drawerState !== this.props.drawerState) {
      this.showDrawer();
    }
    if (previousProps.activePage !== this.props.activePage) {
      this.setState({ activePage: this.props.activePage });
    }
  }
  render(props) {
    // console.log("showMess: true,",this.state.showMess);
    return (
      // <Drawer
      // 	title=""
      // 	placement="left"
      // 	onClose={this.onClose}
      // 	visible={this.state.visible}
      // 	id="accountDrawer"
      // 	closable={false}
      // >
      <div className="accountSideMenu">
        <label>Account</label>
        <ul className="list-unstyled ">
          <li
            id="personalInfo"
            onClick={this.switchPage}
            className={this.state.activePage === "personalInfo" && "active"}
          >
            <span id="personalInfo">
              <img
                src={PersonalInfoIcon}
                alt="PersonalInfoIcon"
                id="personalInfo"
              />
            </span>
            <span id="personalInfo">Personal info</span>
          </li>
          {this.props.auth.authData.userType === 2 && (
            <li
              id="profession"
              onClick={this.switchPage}
              className={this.state.activePage === "profession" && "active"}
            >
              <span id="profession">
                <img
                  src={ProfessionIcon}
                  alt="ProfessionIcon"
                  id="profession"
                />
              </span>
              <span id="profession">Profession</span>
            </li>
          )}
          {/* 
					<li id="preferences" onClick={this.switchPage}>
						<span id="preferences">
							<PreferencesIcon />
						</span>
						<span id="preferences">Preferences</span>
					</li>
					*/}
          {this.props.auth.authData.userType === 2 && (
            <li
              id="availability"
              onClick={this.switchPage}
              className={this.state.activePage === "availability" && "active"}
            >
              <span id="availability">
                <img
                  src={AvailabilityIcon}
                  alt="availability"
                  id="availability"
                />
              </span>
              <span id="availability">Availability</span>
            </li>
          )}
          {this.props.auth.authData.userType === 2 && (
            <li
              id="bookingMessage"
              onClick={this.switchPage}
              className={this.state.activePage === "bookingMessage" && "active"}
            >
              <span id="bookingMessage">
                <img
                  src={BookingMessageIcon}
                  alt="bookingMessage"
                  id="bookingMessage"
                />
              </span>
              <span id="bookingMessage">Booking message</span>
            </li>
          )}
          {this.props.auth.authData.userType === 2 && (
            <li
              id="myReviews"
              onClick={this.switchPage}
              className={this.state.activePage === "myReviews" && "active"}
            >
              <span id="myReviews">
                <img src={MyReviewsIcon} alt="myReviews" id="myReviews" />
              </span>
              <span id="myReviews">My reviews(Confidential)</span>
            </li>
          )}
          {this.props.auth.authData.userType === 2 && (
            <li
              id="myDocuments"
              onClick={this.switchPage}
              className={this.state.activePage === "myDocuments" && "active"}
            >
              <span id="myDocuments">
                <img
                  src={documentsIcon}
                  className="scaleIcon"
                  alt="myDocuments"
                  id="myDocuments"
                />
              </span>
              <span id="myDocuments">My Documents</span>
            </li>
          )}
          {this.props.auth.authData.userType === 2 && (
            <li
              id="bankDetails"
              onClick={this.switchPage}
              className={this.state.activePage === "bankDetails" && "active"}
            >
              <span id="bankDetails">
                <img
                  src={bankDetails}
                  alt="bankDetails"
                  className="scaleIcon"
                  id="bankDetails"
                />
              </span>
              <span id="bankDetails">Bank Details</span>
            </li>
          )}
          {this.props.auth.authData.userType === 2 && (
            <li
              id="packages"
              onClick={this.switchPage}
              className={this.state.activePage === "packages" && "active"}
            >
              <span id="packages">
                <img
                  src={Packages}
                  alt="packages"
                  className="scaleIcon"
                  id="packages"
                />
              </span>
              <span id="packages">Packages</span>
            </li>
          )}
        </ul>
        {false && this.props.auth.authData.userType === 2 && (
          <div>
            <label>Pricing and Payment</label>
            <ul className="list-unstyled">
              <li
                // className="transactionBox"
                id="transactionHistory"
                onClick={this.switchPage}
                className={
                  this.state.activePage === "transactionHistory" && "active"
                }
              >
                <span id="transactionHistory">
                  <img
                    src={TransactionHistoryIcon}
                    alt="TransactionHistoryIcon"
                    id="transactionHistory"
                  />
                </span>
                <span id="transactionHistory">Transaction history</span>
              </li>
            </ul>
          </div>
        )}
        {false && this.props.auth.authData.userType === 1 && (
          <div>
            <label>Promotions</label>
            <ul className="list-unstyled">
              <li
                id="AccountDiscounts"
                className={
                  this.state.activePage === "AccountDiscounts" && "active"
                }
                onClick={this.switchPage}
              >
                <span id="AccountDiscounts">
                  <img
                    src={DiscountIcon}
                    alt="DiscountIcon"
                    id="AccountDiscounts"
                  />
                </span>
                <span id="AccountDiscounts">20% OFF</span>
              </li>
            </ul>
          </div>
        )}
      </div>
      // </Drawer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(AccountSideMenu);
