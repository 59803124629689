import React from "react";

function ArrowLeft() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="13.164"
			height="19"
			viewBox="0 0 13.164 19"
		>
			<path
				id="Path_5191"
				data-name="Path 5191"
				d="M10,17.17l13.164,9.5v-19Z"
				transform="translate(-10 -7.67)"
				fill="#3c3c3b"
			/>
		</svg>
	);
}

export default ArrowLeft;
