import React from "react";

function HomeIcon(props) {
	return (
		<svg
			id="Icon_Badge"
			data-name="Icon/Badge"
			xmlns="http://www.w3.org/2000/svg"
			width="27.391"
			height="27.391"
			viewBox="0 0 27.391 27.391"
		>
			<path id="Grid" d="M0,0H27.391V27.391H0Z" fill="none" />
			<path
				id="Mask"
				d="M570.24 247.41L512 199.52V104a8 8 0 0 0-8-8h-32a8 8 0 0 0-7.95 7.88v56.22L323.87 45a56.06 56.06 0 0 0-71.74 0L5.76 247.41a16 16 0 0 0-2 22.54L14 282.25a16 16 0 0 0 22.53 2L64 261.69V448a32.09 32.09 0 0 0 32 32h128a32.09 32.09 0 0 0 32-32V344h64v104a32.09 32.09 0 0 0 32 32h128a32.07 32.07 0 0 0 32-31.76V261.67l27.53 22.62a16 16 0 0 0 22.53-2L572.29 270a16 16 0 0 0-2.05-22.59zM463.85 432H368V328a32.09 32.09 0 0 0-32-32h-96a32.09 32.09 0 0 0-32 32v104h-96V222.27L288 77.65l176 144.56z"
				// d="M4.572,20.086a.943.943,0,0,1-.636-.251.9.9,0,0,1-.284-.664V13.695c0-.022,0-.044,0-.066a7.3,7.3,0,1,1,7.3,0c0,.021,0,.043,0,.065v5.477a.908.908,0,0,1-.92.915.886.886,0,0,1-.651-.281L7.3,17.646,5.223,19.805A.9.9,0,0,1,4.572,20.086ZM7.3,15.418a.906.906,0,0,1,.657.279L9.13,16.909V14.378a7.334,7.334,0,0,1-3.652,0v2.532L6.647,15.7A.906.906,0,0,1,7.3,15.418Zm0-13.592A5.478,5.478,0,1,0,12.783,7.3,5.485,5.485,0,0,0,7.3,1.826Zm0,9.13A3.652,3.652,0,1,1,10.956,7.3,3.656,3.656,0,0,1,7.3,10.956Zm0-5.478A1.826,1.826,0,1,0,9.13,7.3,1.828,1.828,0,0,0,7.3,5.478Z"
				transform="translate(6.391 3.652)"
				fill={props.active === "home" ? "#CFA79D" : "#898989"}
			/>
		</svg>
		// <svg
		// 	id="Icon_Badge"
		// 	data-name="Icon/Badge"
		// 	xmlns="http://www.w3.org/2000/svg"
		// 	width="27.391"
		// 	height="27.391"
		// 	viewBox="0 0 27.391 27.391">
		// 	<path id="Grid" d="M0,0H27.391V27.391H0Z" fill="none" />
		// 	<path
		// 		id="Mask"
		// 		transform="translate(6.391 3.652)"
		// 		fill={props.active === "home" ? "#CFA79D" : "#898989"}
		// 		d="M570.24 247.41L512 199.52V104a8 8 0 0 0-8-8h-32a8 8 0 0 0-7.95 7.88v56.22L323.87 45a56.06 56.06 0 0 0-71.74 0L5.76 247.41a16 16 0 0 0-2 22.54L14 282.25a16 16 0 0 0 22.53 2L64 261.69V448a32.09 32.09 0 0 0 32 32h128a32.09 32.09 0 0 0 32-32V344h64v104a32.09 32.09 0 0 0 32 32h128a32.07 32.07 0 0 0 32-31.76V261.67l27.53 22.62a16 16 0 0 0 22.53-2L572.29 270a16 16 0 0 0-2.05-22.59zM463.85 432H368V328a32.09 32.09 0 0 0-32-32h-96a32.09 32.09 0 0 0-32 32v104h-96V222.27L288 77.65l176 144.56z" class="">

		// 	</path>
		// </svg>
	);
}

export default HomeIcon;
