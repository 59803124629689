import React, { Component } from "react";
//import AvatarImageCropper from "react-avatar-image-cropper";
import { /*Link,*/ Redirect } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { baseURL } from "../../../App";
//import DefaultImg from "../../../Assets/DefaultImage.svg";
import { RegActions } from "../../../Store/Actions/RegActions";
// import UploadImg from "../../../Components/UploadImg/UploadImg";
// import GuestNav from "../../../Components/GuestNav/GuestNav";
// import moment from "moment";
// import { Button, Col, Row } from "antd";
import "./AddPhoto.css";
export class Calender extends Component {
	state = {
		crpdimg: null,
		img: null,
		finished: false,
		isModalVisible: false,
		submitState: false,
	};

	componentDidMount() {
		console.log("mounter");
		this.handleSubmit();
	}
	handleSubmit = () => {
		this.setState({ submitState: true });
		this.props.RegActions({ categries: this.state.category });
		let [firstName, lastName] = this.props.Reg.fullName.split(" ");
		axios({
			method: "post",
			url: baseURL + "/user/register",
			data: {
				email: this.props.Reg.email.toLowerCase(),
				password: this.props.Reg.password,
				firstName,
				lastName: lastName ? lastName : " ",
				phone: this.props.Reg.phone,
				userType: this.props.Reg.userType,
				image: this.props.Reg.image,
				birthDate: this.props.Reg.birthDate,
				gender: this.props.Reg.gender,
				specializations: this.state.category,
			},
		})
			.then((res) => {
				this.setState({ finished: true }, () => {
					this.props.RegActions("completed");
				});
			})
			.catch((err) => console.log(err?.response?.data));
	};

	apply = (blob) => {
		var file = new File([blob], blob.name);
		this.setState({ img: file }, () => {
			const data = new FormData();
			data.append(
				"image",
				this.state.img,
				Math.ceil(Math.random() * 1000000000000000) + this.state.img.name
			);
			axios
				.post(baseURL + "/user/imageUpload", data, {
					// receive two parameter endpoint url ,form data
				})
				.then((res) => {
					this.props.RegActions({
						image: res.data.path,
					});
					this.handleSubmit();
				});
		});
	};

	render(props) {
		// const maxsize = 1024 * 1024 * 10;
		// const actions = [
		// 	<Link key={0} to="/personalinfo">
		// 		<button className="back btn">Back</button>
		// 	</Link>,

		// 	<button key={1} className="next btn" onClick={this.handleSubmit}>
		// 		Done{" "}
		// 	</button>,
		// ];
		// const icon = (
		// 	<svg
		// 		id="Group_4180"
		// 		data-name="Group 4180"
		// 		xmlns="http://www.w3.org/2000/svg"
		// 		width="58.165"
		// 		height="45.78"
		// 		viewBox="0 0 58.165 45.78">
		// 		<g id="Group_4179" data-name="Group 4179">
		// 			<path
		// 				id="Path_5094"
		// 				data-name="Path 5094"
		// 				d="M209.03,172.2a6.253,6.253,0,0,0-4.589-1.935h-9.178v-.111a4.966,4.966,0,0,0-1.493-3.594,5.061,5.061,0,0,0-3.594-1.492H173.589a5.1,5.1,0,0,0-5.142,5.086v.111h-9.123a6.253,6.253,0,0,0-4.589,1.935,6.573,6.573,0,0,0-1.935,4.589v27.535a6.255,6.255,0,0,0,1.935,4.589,6.573,6.573,0,0,0,4.589,1.935h45.116a6.253,6.253,0,0,0,4.589-1.935,6.574,6.574,0,0,0,1.935-4.589V176.786A6.254,6.254,0,0,0,209.03,172.2Zm-.94,32.124h-.056a3.587,3.587,0,0,1-3.594,3.594H159.324a3.587,3.587,0,0,1-3.594-3.594V176.786a3.587,3.587,0,0,1,3.594-3.594H170a1.487,1.487,0,0,0,1.493-1.493v-1.6a2.081,2.081,0,0,1,2.156-2.156h16.532a2.082,2.082,0,0,1,2.156,2.156v1.6a1.487,1.487,0,0,0,1.492,1.493H204.5a3.587,3.587,0,0,1,3.594,3.594Z"
		// 				transform="translate(-152.8 -165.065)"
		// 				fill="#CFA79D"
		// 			/>
		// 			<path
		// 				id="Path_5095"
		// 				data-name="Path 5095"
		// 				d="M197.063,188.608a13.592,13.592,0,1,0,9.62,3.981A13.626,13.626,0,0,0,197.063,188.608Zm7.52,21.176a10.679,10.679,0,0,1-15.039,0,10.571,10.571,0,0,1-3.1-7.52,10.8,10.8,0,0,1,3.1-7.519,10.57,10.57,0,0,1,7.52-3.1,10.8,10.8,0,0,1,7.52,3.1,10.569,10.569,0,0,1,3.1,7.519A10.346,10.346,0,0,1,204.583,209.784Z"
		// 				transform="translate(-167.981 -176.721)"
		// 				fill="#CFA79D"
		// 			/>
		// 			<circle
		// 				id="Ellipse_41"
		// 				data-name="Ellipse 41"
		// 				cx="2.709"
		// 				cy="2.709"
		// 				r="2.709"
		// 				transform="translate(46.057 11.832)"
		// 				fill="#CFA79D"
		// 			/>
		// 		</g>
		// 	</svg>
		// );
		// const emptyIcon = <svg></svg>;

		// let rootStyle = {
		// 	position: "static",
		// 	top: "100px",
		// };
		const sessionInfo = this.props.location?.state?.sessionInfo;
		// let errorStyle = { position: "absolute", top: "229px", left: "10px" };
		if (this.state.finished === true) {
			return (
				<Redirect
					to={{
						pathname: "/signin",
						state: { welcome: true, sessionInfo },
					}}
				/>
			);
		}
		if (!this.props.Reg.email)
			return (
				<Redirect
					to={{
						pathname: "/personalInfo",
						state: {
							sessionInfo,
						},
					}}
				/>
			);
		return (
			<div className="authPage addPhoto">
				{/* <GuestNav progress={40} />
				<Row className="flex-1">
					<Col
						xs={24}
						md={sessionInfo ? 12 : 24}
						className="d-flex align-items-center">
						<div
							className={`photoUploaderContainer authForm ${
								!sessionInfo && "fullScreen"
							}`}>
							{this.state.isModalVisible && (
								<UploadImg
									isModalVisible={this.state.isModalVisible}
									onCancel={() => {
										this.setState({
											isModalVisible: !this.state.isModalVisible,
										});
									}}
									parent={"addImg"}
								/>
							)}
							<div
								style={{
									cursor: "pointer",
								}}
								className="photoUploader"
								onClick={() => {
									this.setState({ isModalVisible: true });
								}}>
								{this.props.Reg.image ? (
									<img
										src={baseURL + "/" + this.props.Reg.image}
										alt="defaultImg"
										style={{ width: "100%", height: "100%" }}
									/>
								) : (
									<div
										className="d-flex flex-1 justify-content-center align-items-center"
										style={{ height: "100%" }}>
										<img src={DefaultImg} alt="defaultImg" />
									</div>
								)}
								<span className="d-none">
									<AvatarImageCropper
										actions={actions}
										icon={this.props.Reg.image ? emptyIcon : icon}
										text=" "
										errorStyle={errorStyle}
										rootStyle={rootStyle}
										className="avatarCropper"
										apply={this.apply}
										maxsize={maxsize}
										loadingNode={<div className="loader"></div>}
									/>
								</span>
							</div>

							<div className="KeyPage">
								<Link
									key={0}
									to={{
										pathname: "/personalInfo",
										state: {
											sessionInfo,
										},
									}}>
									<Button
										className="secondary-btn"
										onClick={() => this.setState({ backState: true })}>
										Back
									</Button>
								</Link>
								<Button className="primary-btn" onClick={this.handleSubmit}>
									Done
								</Button>
							</div>
							<div className="progressBar sm">
								<div className="value"></div>
							</div>
						</div>
					</Col>
					<Col
						xs={24}
						md={12}
						className={`authSolidSection ${!sessionInfo && "d-none"}`}>
						{sessionInfo && (
							<div className="guruDetails mx-4">
								<div className="d-flex justify-content-between align-items-center flex-1 guruInfo pb-3">
									<div className="d-flex align-items-center">
										<img
											src={baseURL + "/" + sessionInfo.image}
											alt="guruImage"
											className="guruAvatar"
										/>
										<div className="mx-2">
											<div className="fw-600">{sessionInfo.fullName}</div>
											<div className="fw-300 f-14">
												{sessionInfo.specialization[0].categoryName}
											</div>
										</div>
									</div>
									<div>
										<div className="fw-600">
											{sessionInfo.price} {sessionInfo.unit}
										</div>
										<div className="fw-300 f-14">/hr</div>
									</div>
								</div>
								{sessionInfo.sessionDateTime && (
									<div className="mt-2">
										{moment(
											sessionInfo.sessionDateTime.startDateTime
										).calendar()}
									</div>
								)}
							</div>
						)}
					</Col>
				</Row> */}
			</div>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		Reg: state.reg,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		RegActions: (reg) => dispatch(RegActions(reg)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(Calender);
