import React from "react";

function AccountIcon(props) {
	return (
		<svg
			id="profile"
			xmlns="http://www.w3.org/2000/svg"
			width="22"
			height="22"
			viewBox="0 0 22 22"
		>
			<path
				id="Path_1041"
				data-name="Path 1041"
				d="M16,9a4,4,0,1,1-4-4A4,4,0,0,1,16,9ZM14,9a2,2,0,1,1-2-2A2,2,0,0,1,14,9Z"
				transform="translate(-1 -1)"
				fill={props.active === "account" ? "#CFA79D" : "#898989"}
				fill-rule="evenodd"
			/>
			<path
				id="Path_1042"
				data-name="Path 1042"
				d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1ZM3,12a8.961,8.961,0,0,0,1.908,5.542,9,9,0,0,1,14.249-.083A9,9,0,1,0,3,12Zm9,9a8.963,8.963,0,0,1-5.672-2.012,7,7,0,0,1,11.426-.067A8.964,8.964,0,0,1,12,21Z"
				transform="translate(-1 -1)"
				fill={props.active === "account" ? "#CFA79D" : "#898989"}
				fill-rule="evenodd"
			/>
		</svg>
	);
}

export default AccountIcon;
