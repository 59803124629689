export const BookAction = (book) => {
	if (book === -1) {
		return {
			type: "BOOK_COMPLETED",
			book: {},
		};
	} else {
		return {
			type: "BOOKING",
			book,
		};
	}
};
