const initState = {};

export const AuthReducer = (state = initState, action) => {
	const authData = action.auth;
	switch (action.type) {
		case "USER_LOGIN":
			return {
				...state,
		        authData
			};
		case "LOG_OUT":
			return {};
		default:
			return {
				...state,
			};
	}
};
export default AuthReducer;
