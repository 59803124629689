import React from "react";
function OvalEmptyDot() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="10"
			height="10"
			viewBox="0 0 10 10"
		>
			<g
				id="Oval"
				fill="none"
				stroke="#dac6c1"
				stroke-miterlimit="10"
				stroke-width="1"
			>
				<circle cx="5" cy="5" r="5" stroke="none" />
				<circle cx="5" cy="5" r="4.5" fill="none" />
			</g>
		</svg>
	);
}

export default OvalEmptyDot;
