import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import FilledStar from "../../Components/svgLogos/FilledStar";
import EmptyStar from "../../Components/svgLogos/EmptyStar";
import RedStar from "../../Components/svgLogos/RedStar";
import RemainAnonTickBox from "../../Components/svgLogos/BookingFigures/RemainAnonTickBox";
import RemainAnonEmptyTick from "../../Components/svgLogos/BookingFigures/RemainAnonEmptyTick";
import { baseURL } from "../../App";
import axios from "axios";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import "./RatingModal.css";

function RatingModal({ doctorData, sessionID, auth, doctorSpecialization }) {
	const [pages, switchPages] = useState("reviewContainer");
	const [rate, setRate] = useState(doctorData.rating);
	const [anon, updateAnon] = useState(false);
	const [comment, updateComment] = useState("");
	const [reportComment, updateReportComment] = useState("");
	const [submitStatus, updateSubmitStatus] = useState(null);
	const [title, updateTitle] = useState("");
	const history = useHistory();
	let filledStar = [];
	let emptyStar = [];
	let rateArr = [];
	filledStar.length = rate;
	emptyStar.length = 5 - rate;

	emptyStar.fill("empty");
	if (rate === 1) {
		filledStar.fill("low");
	} else {
		filledStar.fill("filled");
	}
	rateArr = [...filledStar, ...emptyStar];
	const handleSubmit = () => {
		axios({
			method: "post",
			url: baseURL + "/user/AddReview",
			data: {
				coachId: doctorData._id,
				clientId: auth.authData.user._id,
				isAnonymous: anon,
				report: reportComment,
				sessionId: sessionID,
				comment: comment,
				rate: rate,
				title: title,
			},
		}).then((res) => {
			updateSubmitStatus(res.data.status);
		});
	};
	if (submitStatus === 1) {
		history.push("/");
		window.location.reload();
	};
	return (
		<div>
			<Modal
				show={true}
				onHide={() => {
					updateSubmitStatus(1);
				}}
				size="lg"
				id="ratingModal"
				aria-labelledby="contained-modal-title-vcenterv"
				centered
			>
				<Modal.Header closeButton>
					{pages === "reviewContainer" && (
						<div className="reviewContainer">
							<div className="title">
								Leave <span> a Review </span>
							</div>
							<div className="subTitle">
								Let {doctorData.firstName} <br /> Know how they did!
							</div>
						</div>
					)}
					{pages === "reportBox" && (
						<div className="reportBox">
							<div className="title">
								Doctor <span> Report </span>
							</div>
							<div className="subTitle">
								Please tell us in details <br /> what went wrong
							</div>
						</div>
					)}
				</Modal.Header>
				<Modal.Body>
					{pages === "reviewContainer" && (
						<div className="reviewContainer">
							<div className="coachInfo">
								<img
									className="initials"
									src={baseURL + "/" + doctorData.image}
									alt={doctorData.firstName}
								/>
								<div className="info">
									<div className="name">
										{doctorData.firstName} {doctorData.lastName}
									</div>
									<div className="specialization">
										{doctorSpecialization.categoryName}
									</div>
								</div>
								<div className="coachRate">
									{rateArr.map((rate, i) => {
										return (
											<div onClick={() => setRate(i + 1)} key={i}>
												{rate === "filled" ? (
													<FilledStar />
												) : rate === "low" ? (
													<RedStar />
												) : (
													<EmptyStar />
												)}
											</div>
										);
									})}
									{rate === 1 && (
										<button
											className="btn report lg"
											onClick={() => switchPages("reportBox")}
										>
											Report
										</button>
									)}
								</div>

								<div className="commentBox">
									<div>Title:</div>
									<input
										type="text"
										value={title}
										className="form-control"
										onChange={(e) => updateTitle(e.target.value)}
									/>
									<div>Comments:</div>
									<textarea
										value={comment}
										onChange={(e) => updateComment(e.target.value)}
									/>
								</div>
								<div className="anonTitle">
									<span
										onClick={() => {
											updateAnon(!anon);
										}}
									>
										{anon ? <RemainAnonTickBox /> : <RemainAnonEmptyTick />}
									</span>
									I want to remain anonymous
								</div>
							</div>

							<div className="btnContainer">
								<button className="btn">
									<Link to="/mySessions"> Later</Link>
								</button>
								<button className="btn" onClick={handleSubmit}>
									Submit
								</button>
								{rate === 1 && (
									<button
										className="btn report sm"
										onClick={() => switchPages("reportBox")}
									>
										Report
									</button>
								)}
							</div>
						</div>
					)}
					{pages === "reportBox" && (
						<div className="reportBox">
							<div className="coachInfo">
								<img
									className="initials"
									src={baseURL + "/" + doctorData.image}
									alt={doctorData.firstName}
								/>

								<div className="info">
									<div className="name">
										{doctorData.firstName} {doctorData.lastName}
									</div>
									<div className="specialization">
										{doctorSpecialization.categoryName}
									</div>
								</div>
							</div>
							<div className="commentBox">
								<div>Title:</div>
								<input
									type="text"
									value={title}
									className="form-control"
									onChange={(e) => updateTitle(e.target.value)}
								/>
								<textarea
									value={reportComment}
									onChange={(e) => updateReportComment(e.target.value)}
								/>
							</div>
							<div className="btnContainer">
								<button className="btn">
									<Link to="/mySessions">Cancel</Link>
								</button>
								<button className="btn" onClick={handleSubmit}>
									Submit
								</button>
							</div>
						</div>
					)}
				</Modal.Body>
			</Modal>
		</div>
	);
}
const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	};
};
export default connect(mapStateToProps)(RatingModal);
