import React, { useEffect, useState } from 'react'
import DropDownModal from '../DropDownModal/DropDownModal';
import FloatingBar from '../FloatingBar/FloatingBar';
import Footer from '../Footer/Footer';
import NavBarLg from '../NavBarLg/NavBarLg';
import Instagram from "../svgLogos/Instagram";
import weloIcon from "../svgLogos/favicon.png"
import NavBarSm from '../NavBarSm/NavBarSm';
import './Team.css'
function Team() {
    const [dropdownMenu, updateDropdownMenu] = useState(false)
    const toggleDropdownMenu = (val) => { updateDropdownMenu(val) }
    const ourTeamList = [{
        firstName: "Aisha",
        lastName: "Amer",
        imageUrl: "https://weloapi.welo.app/public/images/751753467724943.jpg",
        title: "Founder",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut.",
        weloUrl: "https://welo.app/gurus/62276693103306441856d643",
        instagram: "https://www.instagram.com/aisha_amer/",
    },
    {
        firstName: "Zeina",
        lastName: "El Tohamy",
        imageUrl: "https://weloapi.welo.app/public/images/8082745845484.jpg",
        title: "Body Ambassador",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut.",
        weloUrl: "https://welo.app/gurus/622759ca103306441856d61c",
        instagram: "https://www.instagram.com/ztohamy/",
    }]
    const ourAmbasadorList = [{
        firstName: "Aisha",
        lastName: "Amer",
        imageUrl: "https://weloapi.welo.app/public/images/751753467724943.jpg",
        title: "Founder",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut.",
        weloUrl: "https://welo.app/gurus/62276693103306441856d643",
        instagram: "https://www.instagram.com/aisha_amer/",
    },
    {
        firstName: "Zeina",
        lastName: "El Tohamy",
        imageUrl: "https://weloapi.welo.app/public/images/8082745845484.jpg",
        title: "Body Ambassador",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut.",
        weloUrl: "https://welo.app/gurus/622759ca103306441856d61c",
        instagram: "https://www.instagram.com/ztohamy/",
    }]
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <div className='teamPage'>
            <div className="navbarContainer">
                <NavBarLg
                    toggleDropdownMenuState={dropdownMenu}
                    toggleDropdownMenu={toggleDropdownMenu}
                />
                <DropDownModal
                    toggleDropdownMenu={toggleDropdownMenu}
                    dropDownState={dropdownMenu}
                />

                <NavBarSm parent="contact"
                />
            </div>
            <span className='mainTitle text-center'>
                <span>Our Team </span>
            </span>
            <div className="d-flex content">
                {ourTeamList.map(card => {
                    return (
                        <div className='teamCard text-center p-2 m-4'>
                            <figure className='avatarContainer'>
                                <img src={card.imageUrl} alt='avatar' />
                            </figure>
                            <div className="my-4 name">{card.firstName + " " + card.lastName}</div>
                            <div className="my-2 bio">
                                {card.bio}
                            </div>
                            <div className="my-2 title">
                                {card.title}
                            </div>
                            <div className="d-flex justify-content-center">
                                <a href={card.weloUrl} className='mx-4'>
                                    <figure className='weloIconContainer'>
                                        <img src={weloIcon} alt='weloIcon' />
                                    </figure>
                                </a>
                                <a href={card.instagram} className='mx-4'>
                                    <Instagram />
                                </a>
                            </div>

                        </div>)
                })}
            </div>
            <span className='mainTitle text-center'>
                <span>Ambassadors</span>
            </span>

            <div className="d-flex content">
                {ourAmbasadorList.map(card => {
                    return (
                        <div className='teamCard text-center p-2 m-4'>
                            <figure className='avatarContainer'>
                                <img src={card.imageUrl} alt='avatar' />
                            </figure>
                            <div className="my-4 name">{card.firstName + " " + card.lastName}</div>
                            <div className="my-2 bio">
                                {card.bio}
                            </div>
                            <div className="my-2 title">
                                {card.title}
                            </div>
                            <div className="d-flex justify-content-center">
                                <a href={card.weloUrl} className='mx-4'>
                                    <figure className='weloIconContainer'>
                                        <img src={weloIcon} alt='weloIcon' />
                                    </figure>
                                </a>
                                <a href={card.instagram} className='mx-4'>
                                    <Instagram />
                                </a>
                            </div>

                        </div>)
                })}
            </div>

            <div>
                <Footer />
                <FloatingBar
                    active="home"
                />
            </div>

        </div >
    )
}

export default Team