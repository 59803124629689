import React from "react";

function Lamp() {
	return (
		<div>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="106.004"
				height="127.114"
				viewBox="0 0 106.004 127.114"
			>
				<g
					id="Group_244"
					data-name="Group 244"
					transform="translate(0 -29.484)"
				>
					<g
						id="Group_243"
						data-name="Group 243"
						transform="translate(43.281 30.234)"
					>
						<circle
							id="Ellipse_32"
							data-name="Ellipse 32"
							cx="9.721"
							cy="9.721"
							r="9.721"
							transform="translate(0 106.921)"
							fill="#CFA79D"
						/>
						<line
							id="Line_22"
							data-name="Line 22"
							x1="0.033"
							y2="113.159"
							transform="translate(9.688)"
							fill="none"
							stroke="#000"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="1.5"
						/>
					</g>
					<path
						id="Path_1534"
						data-name="Path 1534"
						d="M53,203.592.75,255.844h104.5Z"
						transform="translate(0 -111.679)"
						fill="#edeeef"
					/>
					<path
						id="Path_1535"
						data-name="Path 1535"
						d="M.75,255.844h104.5L53,203.592"
						transform="translate(0 -111.679)"
						fill="none"
						stroke="#000"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="1.5"
					/>
				</g>
			</svg>
		</div>
	);
}

export default Lamp;
