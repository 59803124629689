import React from "react";

function Clock() {
	return (
		<svg
			id="Group_3329"
			data-name="Group 3329"
			xmlns="http://www.w3.org/2000/svg"
			width="17.515"
			height="17.515"
			viewBox="0 0 17.515 17.515"
		>
			<path
				id="Path_1426"
				data-name="Path 1426"
				d="M8.757,0a8.758,8.758,0,1,0,8.758,8.757A8.758,8.758,0,0,0,8.757,0Zm3.786,10.423H8.831c-.013,0-.024,0-.037,0s-.024,0-.037,0a.606.606,0,0,1-.606-.606V3.634a.606.606,0,0,1,1.211,0V9.212h3.18a.606.606,0,0,1,0,1.211Z"
				fill="#CFA79D"
			/>
		</svg>
	);
}

export default Clock;
