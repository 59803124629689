export const RegActions = (reg) => {
	if (reg === "completed") {
		return {
			type: "REG_COMPLETED",
			reg: {},
		};
	} else {
		return {
			type: "REGESTRATION",
			reg,
		};
	}
};
