import React from "react";
import { Modal } from "antd";
import WelcomeIcon from "../../Assets/WelcomeIcone.svg";
import "./WelcomeModule.css";
function WelcomeModule({ isModalVisible, toggleModal }) {
	return (
		<Modal
			visible={isModalVisible}
			onCancel={toggleModal}
			footer={[]}
			style={{ borderRadius: "150px" }}
			centered
			className="welcomeModal"
		>
			<div className="d-flex justify-content-center mb-4">
				<img src={WelcomeIcon} alt="WelcomeIcon" />
			</div>
			<h5 className="text-center">Please verify your account!</h5>
			<p className="text-center checkEmailText">A verification email was sent to your inbox<br />(Please check your junk folder)</p>

		</Modal>
	);
}

export default WelcomeModule;
