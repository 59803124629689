import React, { useEffect, useState } from "react";
import { Card } from "antd";
import moment from "moment";
import heartFilled from "../../../../Assets/like-heart-filled.svg";
import heartEmpty from "../../../../Assets/like-heart-empty.svg";
import { Link } from "react-router-dom";
import { baseURL } from "../../../../App";
import axios from "axios";
import { connect } from "react-redux";
import "./BlogCard.css";

function BlogCard({ blogDetails, allCategories, auth, recallAllBlogs }) {
	const { image, categoryId, createdAt, title, body, isLiked, _id } =
		blogDetails;
	const [categoryName, updateCategoryName] = useState("");
	const [isLikedState, updateIsLikedState] = useState(null);
	useEffect(() => {
		updateIsLikedState(isLiked);
	}, [isLiked]);
	const handleLikeDislike = () => {
		axios({
			method: "post",
			url: baseURL + "/article/likeArticles",
			data: {
				userId: auth.authData.user._id,
				isLiked: !isLikedState,
				articleId: _id,
			},
		}).then((res) => {
			updateIsLikedState((prevState) => !prevState);
			recallAllBlogs();
		});
	};
	const getCatNameFromCatId = (catId) => {
		const selectedCategoryName = allCategories.filter(
			(category) => category._id === catId
		)[0]?.categoryName;
		updateCategoryName(selectedCategoryName);
	};
	useEffect(() => {
		getCatNameFromCatId(categoryId);
		// eslint-disable-next-line
	}, [allCategories, categoryId]);
	const cover = baseURL + "/" + image;
	return (
		<Card
			className="blogCard m-2 text-center"
			hoverable
			style={{ maxWidth: 440 }}
			cover={
				<div className="cover-container">
					<img alt="cover" src={cover} className="cover" />
					<img
						src={isLikedState ? heartFilled : heartEmpty}
						alt="likeIcon"
						className="likeIcon"
						onClick={handleLikeDislike}
					/>
				</div>
			}>
			<Link
				to={{
					pathname: `/blog/${blogDetails._id}`,
					state: blogDetails,
				}}>
				<div className="category">{categoryName}</div>
				<div className="title my-2">{title}</div>
				<div className="createdAt">
					{moment(createdAt).format("DD-MM-YYYY")}
				</div>

				<div className="description pb-2 my-2">{body}</div>
			</Link>
		</Card>
	);
}
const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	};
};

export default connect(mapStateToProps)(BlogCard);
