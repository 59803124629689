import React, { useState, useEffect, useRef } from 'react'
import ChatSendIcon from "../../Components/svgLogos/ChatSendIcon";
import firebase from "firebase/app";
import { connect } from "react-redux";
import "firebase/messaging";
import "firebase/firestore";
import "firebase/auth";
import "firebase/firebase-database";
function VideoChattingRoom({ toggleChatStateFun, sessionId, auth, chatState, getUnreadMsgState }) {
    const [msgCollection, setMsgCollection] = useState([])
    const [msg, updateMsg] = useState('');
    const [msgKeys, setMsgKeys] = useState([]);
    useEffect(() => {
        dummy.current.scrollIntoView({ behavior: "smooth" });
    }, [chatState])
    useEffect(() => {
        if (sessionId) {
            const db = firebase.database();
            var videoRoomRef = db.ref("videoRooms/" + sessionId);
            videoRoomRef.on("value", (snapshot) => {
                if (snapshot.val()) {
                    const values = Object.values(snapshot.val());
                    const keysArr = Object.keys(snapshot.val());
                    setMsgCollection(values);
                    setMsgKeys(keysArr)
                    values.forEach((msg, msgIndex) => {
                        if (msg.senderId !== auth.authData.user._id) {
                            let unreadMsgCount = 0
                            values.forEach(msg => {
                                if (msg.senderId !== auth.authData.user._id && msg.isRead === false) {
                                    unreadMsgCount++
                                }
                            })
                            getUnreadMsgState({
                                count: unreadMsgCount,
                                senderName: values[values.length - 1].senderName,
                                msg: values[values.length - 1].msg
                            })
                        }
                    })

                }
            });
        }
        // eslint-disable-next-line
    }, [sessionId, chatState]);
    useEffect(() => {
        if (msgCollection.length && msgKeys.length && chatState) {
            const db = firebase.database();
            msgCollection.forEach((msg, msgIndex) => {
                if (msg.senderId !== auth.authData.user._id && msg.isRead === false) {
                    db.ref("videoRooms/" + sessionId + "/" + msgKeys[msgIndex]).update({ isRead: true })
                }
            })
        }
        // eslint-disable-next-line
    }, [msgCollection, msgKeys, chatState])


    const handleSendMsg = (e) => {
        e.preventDefault();
        if (msg.length) {
            firebase
                .database()
                .ref("videoRooms/" + sessionId)
                .push({
                    msg,
                    senderName: auth.authData.user.firstName,
                    senderId: auth.authData.user._id,
                    initials: auth.authData.user.firstName[0] + auth.authData.user.lastName[0],
                    isRead: false
                })
            updateMsg("")
            dummy.current.scrollIntoView({ behavior: "smooth" });

        }
    }
    const dummy = useRef();

    return (
        <div className={chatState ? "videoChattingRoom" : "d-none"}>
            <header className='d-flex'>
                <div className='closeText' onClick={toggleChatStateFun}>Close</div>
                <div className='flex-1 text-center title'>Chat</div>
            </header>
            <div className="chatArea">
                {msgCollection.map((msg, index) => {
                    return <>{
                        msg.initials ? <div key={index} className={`chatBlock d-flex my-2 ${msg.senderId !== auth.authData.user._id ? 'flex-row' : 'flex-row-reverse'}`}>
                            <div className='chatInitials'>{msg.initials}</div>
                            <div className="message">{msg.msg}</div>
                        </div> : <></>
                    }
                    </>
                })}
                <div ref={dummy} className='mt-4 invisible'>test</div>
            </div>
            <form className="inputContainer d-flex align-items-center" onSubmit={handleSendMsg}>
                <input type='text'
                    className='chatInput flex-1'
                    placeholder='Type a here to chat'
                    value={msg}
                    onChange={(e) => updateMsg(e.target.value)}
                />
                <button className='cursorPointer' type='submit' style={{ background: 'transparent', border: 'none' }}>
                    <ChatSendIcon />
                </button>
            </form>

        </ div >
    )
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};
export default connect(mapStateToProps)(VideoChattingRoom)