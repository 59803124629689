import React from "react";

function RemainAnonEmptyTick() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="23.187"
			height="23.187"
			viewBox="0 0 23.187 23.187"
		>
			<circle id="Oval" cx="11.593" cy="11.593" r="11.593" fill="#fff" />
		</svg>
	);
}

export default RemainAnonEmptyTick;
