import React from "react";

function ArrowRightSm() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="8.485"
			height="14.142"
			viewBox="0 0 8.485 14.142"
		>
			<g id="chevron-left" transform="translate(8.485 14.142) rotate(180)">
				<path
					id="chevron-left-2"
					data-name="chevron-left"
					d="M16.243,6.343,14.828,4.929,7.757,12l7.071,7.071,1.414-1.414L10.586,12Z"
					transform="translate(-7.757 -4.929)"
					fill="#3c3c3b"
				/>
			</g>
		</svg>
	);
}

export default ArrowRightSm;
