import firebase from "firebase/app";

// import { SaveFBTokenToRedux } from "./App";
const firebaseConfig = {
	apiKey: "AIzaSyCESDBNFfFZ7poCpd550hjuF2DKhUfBclQ",
	authDomain: "welo-e1758.firebaseapp.com",
	databaseURL: "https://welo-e1758-default-rtdb.firebaseio.com",
	projectId: "welo-e1758",
	storageBucket: "welo-e1758.appspot.com",
	messagingSenderId: "301368606527",
	appId: "1:301368606527:web:ac92d72e8184991b715d8a",
	measurementId: "G-SE0RL59NP0",
};

const app = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore(app);

export { db };


