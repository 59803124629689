import React from "react";

function ArrowRight() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="13.164"
			height="19"
			viewBox="0 0 13.164 19"
		>
			<path
				id="Path_5190"
				data-name="Path 5190"
				d="M23.164,17.17,10,26.67v-19l8.469,6.112Z"
				transform="translate(-10 -7.67)"
				fill="#3c3c3b"
			/>
		</svg>
	);
}

export default ArrowRight;
