export const SHOW_ELEMENT = "SHOW_ELEMENT";
export const HIDE_ELEMENT = "HIDE_ELEMENT";

export const showElement = () => ({
  type: SHOW_ELEMENT,
});

export const hideElement = () => ({
  type: HIDE_ELEMENT,
});
