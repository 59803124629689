import React, { Component } from "react";

class OvalEmptyCircleSm extends Component {
	render() {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="25"
				height="25"
				viewBox="0 0 25 25"
			>
				<g
					id="Atoms_Selectors_Default"
					data-name="Atoms/ Selectors/ Default"
					transform="translate(0 0.054)"
				>
					<g
						id="Default"
						transform="translate(0 -0.054)"
						fill="#fff"
						stroke="#CFA79D"
						stroke-miterlimit="10"
						stroke-width="1"
					>
						<circle cx="12.5" cy="12.5" r="12.5" stroke="none" />
						<circle cx="12.5" cy="12.5" r="12" fill="none" />
					</g>
				</g>
			</svg>
		);
	}
}

export default OvalEmptyCircleSm;
