import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Cross from "../../Assets/PersonalInfoIconCross.png";
import SVGCross from "../../Components/svgLogos/Cross";
import SearchLogo from "../../Components/svgLogos/HomePageFigures/SearchLogo";
import OvalEmptyCircle from "../../Components/svgLogos/OvalEmptyCircle";
import OvalFilledCircle from "../../Components/svgLogos/OvalFilledCircle";
import axios from "axios";
import { baseURL } from "../../App";
import "./FilterModal.css";
export class FilterModal extends Component {
  state = {
    specializationsList: [],
    subSpecializationsList: [],
    currencyList: [],
    gendersList: [],
    languagesList: [],
    priceFilter: "",
    specialization: "",
    specializationName: "",
    subSpecialization: "",
    subSpecializations: [],
    language: "",
    languages: [],
    Currency: "",
    gender: "",
    coaches: [],
  };
  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  handleRemoveSubspecialization = (e) => {
    this.setState({
      subSpecializations: this.state.subSpecializations.filter(
        (word) => word !== e.target.id
      ),
    });
  };
  handleRemoveLanguage = (e) => {
    this.setState({
      languages: this.state.languages.filter((word) => word !== e.target.id),
    });
  };
  handleSubSpecialization = (e) => {
    if (this.state.specialization !== "") {
      axios({
        method: "post",
        url: baseURL + "/account/SubCategoryListByCategoryId",
        data: { categoryId: this.state.specialization },
      }).then((res) => {
        this.setState({ subSpecializationsList: res.data });
      });
      this.setState({ subSpecializations: [], subSpecialization: "" });
    }
  };
  handleAddSubSpecialization = () => {
    if (
      this.state.subSpecialization.length !== 0 &&
      !this.state.subSpecializations.includes(this.state.subSpecialization)
    ) {
      this.setState({
        subSpecializations: [
          ...this.state.subSpecializations,
          this.state.subSpecialization,
        ],
      });
    }
  };
  handleAddLanguage = () => {
    if (
      this.state.language.length !== 0 &&
      !this.state.languages.includes(this.state.language)
    ) {
      this.setState({
        languages: [...this.state.languages, this.state.language],
      });
    }
  };
  handleReset = () => {
    this.setState({
      priceFilter: 0,
      specialization: "",
      specializationName: "",
      subSpecialization: "",
      subSpecializations: [],
      language: "",
      languages: [],
      Currency: "",
      gender: "",
    });
  };
  handleApply = () => {
    axios({
      method: "post",
      url: baseURL + "/user/filterPagesCount",
      data: {
        price: this.state.priceFilter,
        languageName: this.state.languages,
        unitName: this.state.Currency,
        specialization: this.state.specializationName,
        subSpecialization: this.state.subSpecializations,
        genderName: this.state.gender,
      },
    }).then((res) => {
      this.props.filteredPagesCount(res.data.count);
    });
    axios({
      method: "post",
      url: baseURL + "/user/filters?index=0",
      data: {
        price: this.state.priceFilter,
        languageName: this.state.languages,
        unitName: this.state.Currency,
        specialization: this.state.specializationName,
        subSpecialization: this.state.subSpecializations,
        genderName: this.state.gender,
      },
    }).then((res) => {
      this.setState({ coaches: res.data }, () => {
        this.props.toggleFilterPage(false);
        this.props.filteredCoaches(this.state.coaches);
        this.props.filteredprops({
          price: this.state.priceFilter,
          languageName: this.state.languages,
          unitName: this.state.Currency,
          specialization: this.state.specializationName,
          subSpecialization: this.state.subSpecializations,
          genderName: this.state.gender,
        });
      });
    });
  };
  componentDidMount() {
    axios({
      method: "get",
      url: baseURL + "/account/categoryList",
    }).then((res) => {
      this.setState({ specializationsList: res.data.categories });
    });
    axios({
      method: "get",
      url: baseURL + "/account/unitList",
      data: {},
    }).then((res) => {
      this.setState({
        currencyList: res.data.units,
      });
    });
    axios({
      method: "get",
      url: baseURL + "/account/genderList",
      data: {},
    }).then((res) => {
      this.setState({ gendersList: res.data.genders });
    });
    axios({
      method: "get",
      url: baseURL + "/account/languageList",
      data: {},
    }).then((res) => {
      this.setState({
        languagesList: res.data.languages,
      });
    });
  }
  render(props) {
    return (
      <Modal
        id="filterModal"
        show={this.props.filterState}
        onHide={this.props.toggleFilterPage}
      >
        <Modal.Body>
          <div class="filterModal">
            <div className="filterBar">
              <div className="smContainer">
                <span
                  onClick={() => this.props.toggleFilterPage(false)}
                  className="SVGCross"
                >
                  <SVGCross />
                </span>
                <div>Filter</div>
                <div className="searchBox">
                  <input placeholder="Search" type="text" />
                  <span className="searchLogo">
                    <SearchLogo />
                  </span>
                </div>
              </div>
              <label>Specialty</label>
              <select
                onChange={(e) => {
                  this.setState({
                    specialization: e.target.value,
                    specializationName:
                      this.state.specializationsList[e.target.selectedIndex - 1]
                        .categoryName,
                  });
                }}
                id="specialization"
                className="categoryBox form-control"
                value={this.state.specialization}
                onBlur={this.handleSubSpecialization}
              >
                <option selected disabled hidden value=""></option>
                {this.state.specializationsList.map((specialization) => {
                  return (
                    <option key={specialization._id} value={specialization._id}>
                      {specialization.categoryName}
                    </option>
                  );
                })}
              </select>
              <label>Subspecialty</label>
              <input
                list="subSpecialization"
                id="subSpecializationList"
                name="subSpecializationList"
                className="form-control"
                value={this.state.subSpecialization}
                onChange={(e) => {
                  this.setState({ subSpecialization: e.target.value });
                }}
              />
              <datalist id="subSpecialization">
                {this.state.subSpecializationsList.map(
                  (subSpecialization, i) => {
                    return (
                      <option
                        key={i}
                        value={subSpecialization.subCategoryName}
                      />
                    );
                  }
                )}
              </datalist>
              <button
                onClick={this.handleAddSubSpecialization}
                class="btn addSubSpecializationBtn"
              >
                Add
              </button>
              <div className="subSpecializationBox">
                {this.state.subSpecializations.map((subSpecialization, i) => {
                  return (
                    <span key={i} className="subSpecialization">
                      {subSpecialization}
                      <img
                        onClick={this.handleRemoveSubspecialization}
                        id={subSpecialization}
                        className="Cross"
                        name={i}
                        src={Cross}
                        alt="Cross"
                      />
                    </span>
                  );
                })}
              </div>
              <label>Pricing</label>
              <div class="slidecontainer">
                <input
                  type="range"
           
                  min="0"
                  onChange={(e) => {
                    this.setState({ priceFilter: e.target.value });
                  }}
                  value={this.state.priceFilter===""?0:this.state.priceFilter}
                  max="10000"
                  step="5"
                  class="slider"
                  id="myRange"
                />
                <input
                  type="number"
                  value={this.state.priceFilter}
                  min="0"
                  max="10000"
                  step="5"
                  className="PriceInput"
                  onChange={(e) => {
                    this.setState({ priceFilter: e.target.value===""?"":e.target.value });
                  }}
                />
              </div>
              <span className="price" style={{width:"20px", marginRight:"30px"}}>
                {this.state.priceFilter} {this.state.Currency}
              </span>
              <label>Currency</label>
              <select
                onChange={(e) => {
                  this.setState({ Currency: e.target.value });
                }}
                className="form-control currencyBox"
                value={this.state.Currency}
              >
                <option selected disabled hidden value=""></option>
                {this.state.currencyList.map((currency) => {
                  return (
                    <option key={currency._id} value={currency.unitName}>
                      {currency.unitName}
                    </option>
                  );
                })}
              </select>
              <label>Gender</label>
              <div className="genderBox">
                <div className="container-fluid">
                  <div className="row"></div>
                </div>
                {this.state.gendersList.map((gender, i) => {
                  return (
                    <div
                      onClick={(e) => {
                        this.setState({ gender: gender.genderName });
                      }}
                      id={gender.genderName}
                      className="genderSection"
                      key={gender._id}
                    >
                      <div className="icon">
                        {this.state.gender === gender.genderName ? (
                          <OvalFilledCircle />
                        ) : (
                          <OvalEmptyCircle />
                        )}
                      </div>
                      <div>{i === 2 ? "Any" : gender.genderName}</div>
                    </div>
                  );
                })}
              </div>
              <label>Languages</label>
              <input
                list="languages"
                id="LanguagesList"
                name="LanguagesList"
                className="form-control"
                value={this.state.language}
                onChange={(e) => {
                  this.setState({ language: e.target.value });
                }}
              />
              <datalist id="languages">
                {this.state.languagesList.map((language, i) => {
                  return <option key={i} value={language.languageName} />;
                })}
              </datalist>
              <button
                onClick={this.handleAddLanguage}
                class="btn addSubSpecializationBtn"
              >
                Add
              </button>
              <div className="subSpecializationBox">
                {this.state.languages !== null &&
                  this.state.languages.map((language, i) => {
                    return (
                      <span key={i} className="subSpecialization">
                        {language}
                        <img
                          onClick={this.handleRemoveLanguage}
                          id={language}
                          className="Cross"
                          name={i}
                          src={Cross}
                          alt="Cross"
                        />
                      </span>
                    );
                  })}
              </div>
              <div className="btnContainer">
                <button onClick={this.handleReset} class="btn ">
                  Reset
                </button>
                <button onClick={this.handleApply} class="btn">
                  Apply
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default FilterModal;
