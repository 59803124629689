import React from "react";
import { Modal } from "react-bootstrap";

import "./ViewDocumentsModal.css";
function ViewDocumentsModal({ isModalVisible, onHide, documentPath }) {
	return (
		<Modal
			show={isModalVisible}
			onHide={onHide}
			size="lg"
			id="viewDocumentsModal"
			className="viewDocumentsModal"
			aria-labelledby="contained-modal-title-vcenterv"
			centered
		>
			<img src={documentPath} alt="documentPath" />
		</Modal>
	);
}

export default ViewDocumentsModal;
