import React from "react";

function FilledStar() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="10.149"
			height="9.564"
			viewBox="0 0 10.149 9.564"
		>
			<g
				id="Group_4222"
				data-name="Group 4222"
				transform="translate(-141.592 -223.451)"
			>
				<path
					id="Path_1530"
					data-name="Path 1530"
					d="M184.34,223.783l-1.48,3-3.309.481,2.395,2.334-.565,3.3,2.96-1.556,2.96,1.556-.565-3.3,1.2-1.167,1.2-1.167-3.309-.481Z"
					transform="translate(-37.528 -0.243)"
					fill="#FFF06D"
				/>
				<g id="Group_4220" data-name="Group 4220">
					<path
						id="Path_1531"
						data-name="Path 1531"
						d="M179.932,233.016l.616-3.592-2.61-2.544,3.606-.524,1.434-2.9.359.177-1.527,3.093-3.013.438,2.18,2.125-.515,3,2.695-1.417,3.053,1.605-.186.354-2.867-1.507Z"
						transform="translate(-36.346)"
					/>
					<g id="Group_4221" data-name="Group 4221">
						<rect
							id="Rectangle_133"
							data-name="Rectangle 133"
							width="3.344"
							height="0.4"
							transform="matrix(0.716, -0.698, 0.698, 0.716, 149.067, 229.21)"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
}

export default FilledStar;
