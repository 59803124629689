import React, { useState, useCallback } from "react";
import { Modal } from "react-bootstrap";
import Cropper from "react-easy-crop";
import { AuthState } from "../../Store/Actions/AuthActions";
import { RegActions } from "../../Store/Actions/RegActions";
import { connect } from "react-redux";
import axios from "axios";
import { baseURL } from "../../App";
import Rotate from "../../Assets/rotate.svg";
import Upload from "../../Assets/uploadPhoto.svg";
import getCroppedImg from "./cropImage";
import firebase from "firebase/app";
import "./UploadImg.css";
import { Button } from "antd";
function UploadImg({
  isModalVisible,
  onCancel,
  auth,
  parent,
  AuthState,
  RegActions,
  Reg,
  onUploadImg,
}) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [orientation, updateOrientation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [imageFile, updateImageFile] = useState(null);
  const [loading, updateLoading] = useState(false);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
    // eslint-disable-next-line
  }, []);
  const handleChangeImage = (e) => {
    var file = e.target.files[0];
    if (!file.type.includes("image")) {
      alert("Please upload a valid image");
    } else {
      const imageURL = URL.createObjectURL(file);
      updateImageFile(imageURL);
    }
  };
  const handleRotate = () => {
    let newOrientation = orientation + 90;
    if (newOrientation === 360) {
      updateOrientation(0);
    } else {
      updateOrientation(newOrientation);
    }
  };
  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageFile,
        croppedAreaPixels,
        orientation
      );
      uploadProfilePic(croppedImage);
    } catch (e) {
      console.error(e);
    }
    // eslint-disable-next-line
  }, [croppedAreaPixels, orientation, imageFile]);

  const uploadProfilePic = async (imgUrl) => {
    updateLoading(true);

    let blob = await fetch(imgUrl).then((r) => r.blob());

    var file = new File([blob], blob.name);
    const data = new FormData();
    data.append(
      "image",
      file,
      Math.ceil(Math.random() * 1000000000000000) + ".jpg"
    );
    axios
      .post(baseURL + "/user/imageUpload", data, {
        // receive two parameter endpoint url ,form data
      })
      .then((res) => {
        updateLoading(false);
        const path = res.data.path;
        if (parent === "updateImg") {
          updateImg(path);
        } else {
          addImg(path);
        }
        onUploadImg();
      });
  };

  const updateImg = (imagePath) => {
    updateLoading(true);
    axios({
      method: "post",
      url: baseURL + "/user/EditImage",
      data: {
        userType: auth.authData.userType,
        id: auth.authData.user._id,
        imagePath,
      },
    }).then((res) => {
      updateLoading(false);
      let authData = auth.authData;
      authData.user.image = imagePath;
      AuthState(authData);

      firebase
        .database()
        .ref(`messages/${auth.authData.user._id}`)
        .get()
        .then((res) => {
          console.log(res);
        });

      const db = firebase.database();
      var usersRef = db.ref(`users/${auth.authData.user._id}`);

      usersRef.on("value", (snapshot) => {
        if (snapshot.val()) {
          const values = Object.values(snapshot.val());
          if (values.length) {
            firebase
              .database()
              .ref("users/" + auth.authData.user._id + "/image")
              .set(auth.authData.user.image);
          }
        }
      });

      onCancel();
    });
  };
  const addImg = (imagePath) => {
    RegActions({
      image: imagePath,
    });
    onCancel();
  };
  const deleteImg = () => {
    if (Reg.image) {
      RegActions({
        image: "",
      });
      onCancel();
    } else {
      window.location.reload(false);
    }
  };
  return (
    <Modal
      show={isModalVisible}
      onHide={onCancel}
      size="lg"
      id="imgPopup"
      className="imageModal"
      aria-labelledby="contained-modal-title-vcenterv"
      centered
    >
      <Modal.Header closeButton={parent !== "addImg"}>
        <div
          className="d-flex justify-content-between"
          style={{ width: "100%" }}
        >
          <div>Edit Image</div>
          {parent === "addImg" && (
            <div
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={deleteImg}
            >
              Delete
            </div>
          )}
        </div>
      </Modal.Header>
      <Modal.Body>
        {imageFile && (
          <div className="crop-container">
            <Cropper
              image={imageFile}
              crop={crop}
              zoom={zoom}
              rotation={orientation}
              aspect={4 / 4}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
        )}
        <div className="toolBar my-2 d-flex justify-content-center">
          <input
            type="file"
            className="d-none"
            id="imageUploader"
            accept="image/*"
            onChange={handleChangeImage}
          />

          <label
            className="tab d-flex justify-content-center flex-column m-2"
            htmlFor="imageUploader"
          >
            <img
              src={Upload}
              alt="Upload"
              style={{ width: "20px", margin: "auto" }}
            />
            <div className="mx-2 mainColor">Add photo</div>
          </label>
          {imageFile && (
            <label
              className="tab d-flex justify-content-center flex-column m-2"
              onClick={handleRotate}
            >
              <img
                src={Rotate}
                alt="rotate"
                style={{ width: "20px", margin: "auto" }}
              />
              <div className="mx-2">Rotate</div>
            </label>
          )}
        </div>
        {imageFile && (
          <div className="controls">
            <input
              type="range"
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e) => {
                setZoom(e.target.value);
              }}
              className="zoom-range"
              style={{ width: "60%" }}
            />
          </div>
        )}
        <div className="btnContainer text-center mt-2">
          <Button className="btn" onClick={onCancel}>
            Cancel
          </Button>
          <Button loading={loading} className="btn" onClick={showCroppedImage}>
            Confirm
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    Reg: state.reg,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    AuthState: (auth) => dispatch(AuthState(auth)),
    RegActions: (reg) => dispatch(RegActions(reg)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadImg);
