import React, { Component } from "react";
import NavBarLg from "../../Components/NavBarLg/NavBarLg";
import NavBarSm from "../../Components/NavBarSm/NavBarSm";
import FloatingBar from "../../Components/FloatingBar/FloatingBar";
import { connect } from "react-redux";
import moment from "moment";
import { Redirect } from "react-router-dom";
import HamburgerList from "../../Components/HamburgerList/HamburgerList";
import PlayIcon from "../../Components/svgLogos/HomePageFigures/PlayIcon";
import FilledStar from "../../Components/svgLogos/FilledStar";
import FilterModal from "../../Components/FilterModal/FilterModal";
import DropDownModal from "../../Components/DropDownModal/DropDownModal";
import EmptyStar from "../../Components/svgLogos/EmptyStar";
import DoctorDetailCalendar from "../../Components/DoctorDetailCalendar/DoctorDetailCalendar";
import Footer from "../../Components/Footer/Footer";
import axios from "axios";
import VideoModal from "../../Components/VideoModal/VideoModal";
import BookingModal from "../../Components/BookingModal/BookingModal";
import { baseURL, exchangeCurrency, exchangeRate } from "../../App";
import { Modal } from "react-bootstrap";
import Appointments from "../../Components/Appointments/Appointments";
import "./DoctorDetails.css";
class DoctorDetails extends Component {
	state = {
		toggleFilterPage: false,
		toggleDropdownMenu: false,
		toggleHamburgerMenu: false,
		notAuth: false,
		bookingPopup: false,
		videoPopup: false,
		coachDetails: null,
		currentDatesArr: [],
		anonymous: false,
		price: null,
		unit: null,
		exactDate: null,
		exactTime: "",
		exactPeriod: "",
		min: 0,
		sameAccountModal: false,
		timeZone: localStorage.getItem("timeZone"),
		redirectStateSigniN: false,
		timeServer: null,
		schedulesNew: [],
		exact: null,
		nextSessionAv: null,
		message: "",
		quickBook: false,
	};
	toggleFilterPage = (val) => {
		this.setState({ toggleFilterPage: val });
	};
	getNextSession = () => {
		if (this.state.schedulesNew && this.state.schedulesNew.length) {
			const upcomingDates = this.state.schedulesNew.filter(
				(schedule) =>
					schedule.times.length &&
					moment(
						this.state.currentDatesArr[this.state.currentDatesArr.length - 1]
					).valueOf() < moment(schedule.fullDate).valueOf()
			);
			if (upcomingDates.length && upcomingDates[0].times.length) {
				this.setState({ nextSessionAv: upcomingDates[0].times[0] });
			} else {
				this.setState({ nextSessionAv: null });
			}
		}
	};
	componentDidUpdate(prevProps, prevState) {
		if (
			prevState.max !== this.state.max ||
			prevState.currentDatesArr !== this.state.currentDatesArr
		) {
			this.getNextSession();
		}
	}

	toggleDropdownMenu = (val) => {
		this.setState({ toggleDropdownMenu: val });
	};
	toggleHamburgerMenu = (val) => {
		this.setState({ toggleHamburgerMenu: val });
	};
	filteredCoaches = (val) => {
		this.setState({ coaches: val, filteredPagesMode: true, index: 1 });
	};
	filteredPagesCount = (val) => {
		this.setState({ pagesCount: val });
	};
	filteredprops = (val) => {
		this.setState({ filteredprops: val });
	};
	searchedCoachList = (val) => {
		this.setState({ coaches: val });
	};
	handleCalendarPages = (val) => {
		this.setState({ min: val, max: val + 5 });
	};
	handleCurrentDatesArr = (val) => {
		this.setState({ currentDatesArr: val });
	};
	toggleVideoPopup = () => {
		this.setState({ videoPopup: !this.state.videoPopup });
	};
	toggleBookingPages = () => {
		if (this.props.auth.authData === undefined) {
			this.setState({ notAuth: true });
		} else {
			this.setState({
				bookingPopup: !this.state.bookingPopup,
				bookPages: "addSessionDetails",
			});
		}
	};

	componentDidMount() {
		const { id } = this.props.match.params;

		axios({
			method: "get",
			url: `${baseURL}/coache/coachDetailsNew?Id=${id}&timeZone=${this.state.timeZone}`,
		}).then((res) => {
			this.setState(
				{
					coachDetails: res.data[0],
					price: res.data[0].pricePerHour,
					unit: exchangeCurrency,
					schedulesNew: res.data[0].schedulesNew,
				},
				() => {
					if (this.props.location.search.includes("booking=true")) {
						this.setState({ bookingPopup: true, quickBook: true });
					}
				}
			);
		});

		axios({
			method: "get",
			url: baseURL + `/account/GetServerTime?timeZone=${this.state.timeZone}`,
		}).then((res) => {
			this.setState({
				timeServer: res.data,
			});
		});
	}
	render() {
		let selectedDates = [[], [], [], [], []];
		let hasSessions = false;

		const modifiedSchedules = [];
		this.state.schedulesNew.forEach((times) => {
			times.times.forEach((time) => {
				modifiedSchedules.push(time);
			});
		});
		var priceBeforeDiscount = null;
		var priceAfterDiscount = null;

		if (this.state.coachDetails) {
			priceBeforeDiscount =
				this.state.coachDetails.pricePerHour === 0
					? "Free"
					: exchangeCurrency +
					  " " +
					  Math.ceil(this.state.coachDetails?.pricePerHour * exchangeRate) +
					  "/hr";
			if (
				this.props.auth.authData &&
				this.state.coachDetails.perDayDiscount &&
				this.props.auth.authData.user.freeSessionCount === 0
			) {
				priceAfterDiscount =
					this.state.coachDetails.pricePerHour === 0
						? "Free"
						: Math.ceil(
								(this.state.coachDetails.pricePerHour *
									exchangeRate *
									(100 - this.state.coachDetails?.perDayDiscount)) /
									100
						  ) + "/hr";

				if (priceAfterDiscount.startsWith("0")) {
					priceAfterDiscount = "Free";
				}
			}
		}

		// this gives an object with dates as keys
		const groupedSchedules = modifiedSchedules.reduce((groups, game) => {
			const date = game.startDateTime.split(",")[0];

			if (!groups[date]) {
				groups[date] = [];
			}
			groups[date].push(game);
			return groups;
		}, {});

		const finalSchedules = Object.keys(groupedSchedules).map((date) => {
			return {
				fullDate: date,
				times: groupedSchedules[date],
			};
		});

		finalSchedules.forEach((schedule) => {
			for (let j = 0; j < 5; j++) {
				if (
					moment(this.state.currentDatesArr[j]).format("YYYY-MM-DD") ===
					moment(schedule.fullDate).format("YYYY-MM-DD")
				) {
					selectedDates[j] = schedule.times;
					hasSessions = true;
				}
			}
		});

		if (this.state.redirectStateSigniN === true) {
			return <Redirect to="/signin" />;
		}
		let filledStar = [];
		let emptyStar = [];

		if (this.state.coachDetails !== null) {
			filledStar.length = Math.ceil(
				this.state.coachDetails.AverageRate
					? this.state.coachDetails.AverageRate
					: 5
			);
			emptyStar.length =
				5 -
				Math.ceil(
					this.state.coachDetails.AverageRate
						? this.state.coachDetails.AverageRate
						: 5
				);
			filledStar.fill("filled");
			emptyStar.fill("empty");
		}
		const closeAndOpenBooking = () => {
			this.toggleVideoPopup();
			this.toggleBookingPages();
		};
		const sameAccountModal = () => {
			this.setState({ sameAccountModal: true });
		};
		const requestASession = (gurusName) => {
			this.setState({
				requestASession: true,
				message: `I would like to book a session with ${gurusName}, but she is fully booked.Can we talk about availabilities?`,
			});
		};
		if (this.state.notAuth) return <Redirect to="/signin" />;
		return (
			<div className="doctorDetails">
				{this.state.coachDetails && this.state.videoPopup && (
					<VideoModal
						videoPopup={this.state.videoPopup}
						toggleVideoPopup={this.toggleVideoPopup}
						video={this.state.coachDetails.video}
						prefix={this.state.coachDetails.prefix[0].name}
						firstName={this.state.coachDetails.firstName}
						lastName={this.state.coachDetails.lastName}
						image={this.state.coachDetails.image}
						id={this.state.coachDetails._id}
						hideViewProfile={true}
						closeAndOpenBooking={closeAndOpenBooking}
						sameAccountModal={sameAccountModal}
						updateRedirectStateSigniN={() => {
							this.setState({ redirectStateSigniN: true });
						}}
					/>
				)}
				{this.state.coachDetails && this.state.bookingPopup && (
					<BookingModal
						email={this.state.coachDetails.email}
						exact={this.state.exact}
						location={"doctorDetails"}
						bookingPopup={this.state.bookingPopup}
						toggleBookingPages={this.toggleBookingPages}
						firstName={this.state.coachDetails.firstName}
						lastName={this.state.coachDetails.lastName}
						specialization={this.state.coachDetails.category}
						id={this.state.coachDetails._id}
						pricePerHour={this.state.coachDetails.pricePerHour}
						unit={
							this.state.coachDetails.unit.length > 0 &&
							this.state.coachDetails.unit[0].unitName
						}
						rate={this.state.coachDetails.rating}
						image={this.state.coachDetails.image}
						prefix={this.state.coachDetails.prefix[0].name}
						exactDate={this.state.exactDate}
						exactTime={this.state.exactTime}
						exactPeriod={this.state.exactPeriod}
						perDayDiscount={this.state.coachDetails.perDayDiscount}
						quickBook={this.state.quickBook}
					/>
				)}
				{this.state.coachDetails !== null && (
					<div>
						<NavBarLg
							active="Doctors"
							toggleFilterPageState={this.state.toggleFilterPage}
							toggleFilterPage={this.toggleFilterPage}
							toggleDropdownMenuState={this.state.toggleDropdownMenu}
							toggleDropdownMenu={this.toggleDropdownMenu}
							searchedCoachList={this.searchedCoachList}
							searchedPagesCount={this.filteredPagesCount}
							searchBar={false}
							fixedNavbar={true}
						/>
						<NavBarSm
							toggleFilterPage={this.toggleFilterPage}
							toggleHamburgerMenu={this.toggleHamburgerMenu}
							toggleHamburgerMenuState={this.state.toggleHamburgerMenu}
							searchedCoachList={this.searchedCoachList}
							searchedPagesCount={this.filteredPagesCount}
							parent={"doctorDetails"}
						/>

						{this.state.toggleHamburgerMenu && (
							<HamburgerList
								toggleHamburgerMenu={this.toggleHamburgerMenu}
								toggleHamburgerMenuState={this.state.toggleHamburgerMenu}
							/>
						)}

						<FilterModal
							filterState={this.state.toggleFilterPage}
							filteredprops={this.filteredprops}
							filteredPagesCount={this.filteredPagesCount}
							toggleFilterPage={this.toggleFilterPage}
							filteredCoaches={this.filteredCoaches}
						/>
						<DropDownModal
							toggleDropdownMenu={this.toggleDropdownMenu}
							dropDownState={this.state.toggleDropdownMenu}
						/>
						<div className="pageContainer">
							<div className="container-fluid">
								<div className="row">
									<div className="col-lg-4 col-md-6  d-none d-md-block availabilityBox">
										<img
											src={baseURL + "/" + this.state.coachDetails.image}
											alt={this.state.coachDetails.firstName}
										/>
										<div className="watchVideoContainer lg">
											{this.state.coachDetails.video && (
												<button
													onClick={this.toggleVideoPopup}
													className="watchVideoBtn lg btn">
													watch video
													<span>
														<PlayIcon />
													</span>
												</button>
											)}
										</div>
										<div className="d-none d-md-block">
											{this.state.timeServer && (
												<DoctorDetailCalendar
													getCurrentPages={this.handleCurrentDatesArr}
													handleCalendarPages={this.handleCalendarPages}
													timeServer={this.state.timeServer}
												/>
											)}
											<Appointments
												currentDatesArr={this.state.currentDatesArr}
												selectedDates={selectedDates}
												hasSessions={hasSessions}
												nextSessionAv={this.state.nextSessionAv}
												firstName={this.state.coachDetails.firstName}
												lastName={this.state.coachDetails.lastName}
												requestASession={requestASession}
												toggleBookingPages={this.toggleBookingPages}
												updateSameAccountModal={(value) => {
													this.setState({ sameAccountModal: value });
												}}
												updateRedirectStateSigniN={(value) => {
													this.setState({ redirectStateSigniN: value });
												}}
												updateExact={(value) => {
													this.setState({ exact: value }, () => {
														this.toggleBookingPages();
													});
												}}
												parent={"DoctorDetails"}
											/>
										</div>
										<div className="priceBox">
											<span
												className={
													priceAfterDiscount ? "priceBeforeDiscount mr-2" : ""
												}>
												{priceBeforeDiscount}
											</span>
											<span>{priceAfterDiscount}</span>
										</div>

										<div className="bookCoachContainer lg">
											<button
												onClick={() => {
													if (
														this.props.auth.authData &&
														this.props.auth.authData.userType === 1
													) {
														this.setState(
															{
																exact: null,
															},
															() => this.toggleBookingPages()
														);
													} else if (
														this.props.auth.authData &&
														this.props.auth.authData.userType === 2
													) {
														this.setState({
															sameAccountModal: true,
														});
													} else {
														this.setState({ redirectStateSigniN: true });
													}
												}}
												className="bookCoachBtn  btn">
												Book Now
											</button>
										</div>
									</div>
									<div className="col-12 col-lg-8 col-md-6 detailsBox">
										<img
											src={baseURL + "/" + this.state.coachDetails.image}
											alt={this.state.coachDetails.firstName}
											className="initials d-md-none smBox"
										/>
										<div className="smBox details">
											<div className="fullName">
												{this.state.coachDetails.prefix[0].name}{" "}
												{this.state.coachDetails.firstName}{" "}
												{this.state.coachDetails.lastName}
											</div>
											<div className="specialization">
												{this.state.coachDetails.category[0].categoryName}
											</div>
											<div className="coachRate" style={{ display: "none" }}>
												{filledStar.map((rate, i) => {
													return (
														<div key={i} className="filledStar">
															<FilledStar />
														</div>
													);
												})}
												{emptyStar.map((emptyStar) => {
													return (
														<div className="emptyStar">
															<EmptyStar />
														</div>
													);
												})}
											</div>
										</div>
										<div className="subSpecializationBox">
											{this.state.coachDetails.subCategory.map(
												(subCategory) => {
													return <span>{subCategory.subCategoryName}</span>;
												}
											)}
										</div>
										<div className="bioBox">
											<div className="bio">About me</div>
											<div
												dangerouslySetInnerHTML={{
													__html: this.state.coachDetails.coachBio,
												}}
												className="testt"
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="d-md-none d-block">
							{this.state.timeServer && (
								<DoctorDetailCalendar
									getCurrentPages={this.handleCurrentDatesArr}
									handleCalendarPages={this.handleCalendarPages}
									timeServer={this.state.timeServer}
								/>
							)}
							<Appointments
								currentDatesArr={this.props.currentDatesArr}
								selectedDates={selectedDates}
								hasSessions={hasSessions}
								nextSessionAv={this.state.nextSessionAv}
								firstName={this.props.firstName}
								lastName={this.props.lastName}
								requestASession={this.props.requestASession}
								toggleBookingPages={this.toggleBookingPages}
								updateSameAccountModal={(value) => {
									this.setState({ sameAccountModal: value });
								}}
								updateRedirectStateSigniN={(value) => {
									this.setState({ redirectStateSigniN: value });
								}}
								updateExact={(value) => {
									this.setState({ exact: value }, () => {
										this.toggleBookingPages();
									});
								}}
								parent={"DoctorDetails"}
							/>
						</div>
						<div className="bookCoachContainer sm">
							{this.state.coachDetails.video && (
								<button
									onClick={this.toggleVideoPopup}
									className="watchVideoBtn lg btn">
									watch video
									<span>
										<PlayIcon />
									</span>
								</button>
							)}
							<div className="priceBox">
								<span
									className={
										priceAfterDiscount ? "priceBeforeDiscount mr-2" : ""
									}>
									{priceBeforeDiscount}
								</span>
								<span>{priceAfterDiscount}</span>
							</div>
							<button
								onClick={() => {
									if (
										this.props.auth.authData &&
										this.props.auth.authData.userType === 1
									) {
										this.setState({ exact: null }, () =>
											this.toggleBookingPages()
										);
									} else if (
										this.props.auth.authData &&
										this.props.auth.authData.userType === 2
									) {
										this.setState({
											sameAccountModal: true,
										});
									} else {
										this.setState({ redirectStateSigniN: true });
									}
								}}
								className="bookCoachBtn  btn">
								Book Now
							</button>
						</div>
						<FloatingBar
							active="home"
							bookingPopup={this.state.bookingPopup}
							requestASession={this.state.requestASession}
							message={this.state.message}
						/>
						<Footer />
					</div>
				)}
				{this.state.sameAccountModal && (
					<Modal
						show={this.state.sameAccountModal}
						onHide={() => {
							this.setState({
								sameAccountModal: false,
							});
						}}>
						<Modal.Body className="text-center">
							{this.props.auth.authData.user._id === this.state.coachDetails._id
								? "You can't book a session with yourself!"
								: "You must signup as a client with a different Email to book a session"}
						</Modal.Body>
					</Modal>
				)}
			</div>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	};
};

export default connect(mapStateToProps)(DoctorDetails);
