const UpcomingEventIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 36 36"
      style={{ marginRight: "10px" }}
    >
      <path
        id="time-left"
        d="M13.592,43.571H1.439c.3,3.39,1.82,6.305,4.4,7.035-2.579.73-4.1,3.648-4.4,7.038H13.593c-.3-3.39-1.82-6.308-4.4-7.038,2.576-.73,4.1-3.646,4.4-7.035ZM10.1,47.779A7.642,7.642,0,0,1,8.1,49.2v2.824A7.583,7.583,0,0,1,10.1,53.436a6.347,6.347,0,0,1,1.475,2.71.721.721,0,0,1,.042.241.614.614,0,0,1-.58.644H3.992A.5.5,0,0,1,3.813,57a.66.66,0,0,1-.373-.81,6.36,6.36,0,0,1,1.488-2.752,7.57,7.57,0,0,1,2.007-1.417V49.195a7.619,7.619,0,0,1-2.007-1.417,6.325,6.325,0,0,1-1.475-2.71.678.678,0,0,1-.042-.241.614.614,0,0,1,.58-.644h7.049a.511.511,0,0,1,.179.034.658.658,0,0,1,.371.809A6.355,6.355,0,0,1,10.1,47.779ZM4.841,45.472h5.351a4.429,4.429,0,0,1-.877,1.37,6.732,6.732,0,0,1-1.8,1.239,6.776,6.776,0,0,1-1.8-1.239,4.391,4.391,0,0,1-.877-1.37Zm2.675,7.661a6.81,6.81,0,0,1,1.8,1.239,4.454,4.454,0,0,1,.877,1.37H4.841a4.437,4.437,0,0,1,.877-1.37,6.842,6.842,0,0,1,1.8-1.239ZM-.01,41.91V40.217a.357.357,0,0,1,.337-.372h14.38a.355.355,0,0,1,.335.372V41.91a.355.355,0,0,1-.335.372H.327A.357.357,0,0,1-.01,41.91Zm15.051,17.4V61a.356.356,0,0,1-.335.374H.327A.357.357,0,0,1-.01,61V59.306a.357.357,0,0,1,.337-.371h14.38A.355.355,0,0,1,15.041,59.306ZM31.255,30.626a15.164,15.164,0,0,0-22.96,0,18.429,18.429,0,0,0-4.152,7.931H14.707a1.585,1.585,0,0,1,1.5,1.659V41.91a1.593,1.593,0,0,1-1.449,1.659c-.215,2.677-1.126,5.385-3.011,7.038,1.886,1.655,2.8,4.363,3.011,7.04A1.592,1.592,0,0,1,16.2,59.306v1.626a15.207,15.207,0,0,0,15.051-4.819,19.514,19.514,0,0,0,0-25.486ZM29.9,44.014H19.774a.614.614,0,0,1-.58-.644V32.129a.583.583,0,1,1,1.16,0v10.6H29.9a.647.647,0,0,1,0,1.288ZM7.757,51.844c.117.059.23.118.341.176v.408a.584.584,0,1,1-1.162,0V52.02c.111-.059.224-.117.341-.176A.517.517,0,0,1,7.757,51.844Zm.34-3.056V49.2c-.111.059-.224.117-.341.176a.519.519,0,0,1-.48,0c-.117-.059-.23-.118-.341-.176v-.408a.584.584,0,1,1,1.162,0Z"
        transform="translate(0.01 -25.37)"
        fill="#cfa79d"
      />
    </svg>
  );
};

export default UpcomingEventIcon;
