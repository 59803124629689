import React, { useEffect, useReducer, useState } from "react";
import { Modal, Select } from "antd";
import StripeContainer from "../../../StripePayment/StripeContainer";
import RightTransaction from "../../../../Assets/right-event-transaction.svg";
import WrongTransaction from "../../../../Assets/wrong-event-transaction.svg";
import { baseURL, exchangeCurrency, exchangeRate } from "../../../../App";
import RightSign from "../../../svgLogos/BookingFigures/RightSign";
import WrongEmailCheck from "../../../svgLogos/WrongEmailCheck";
import axios from "axios";
import firebase from "firebase/app";
import "./EventBookingModal.css";
import PromocodeIcon from "../../../../Assets/PromocodeIcon";
// import SeatsIcon from "../../../../Assets/SeatsIcon";

const eventReducer = (state, action) => {
  if (action.type === "INIT_COST") {
    return {
      ...state,
      eventCost: action.payload.eventCost,
      totalAmount: action.payload.eventCost,
    };
  }

  if (action.type === "SELECTED") {
    return {
      ...state,
      totalAmount: state.eventCost * action.payload,
      selectedSeats: action.payload,
    };
  }

  return {};
};

function EventBookingModal({
  isModalVisible,
  onCancel,
  paymentData,
  auth,
  updateIsBooked,
}) {
  const [promoCodeState, updatePromoCodeState] = useState(0);
  const [promoLoadingState, updatePromoLoadingState] = useState(false);
  const [promoCode, updatePromoCode] = useState("");
  const [invalidCoupon, setInvalidCoupon] = useState(false);
  const [discount, updateDiscount] = useState(null);
  const [currentTransactionState, updateCurrentTransactionState] =
    useState(null);

  const [costState, dispatch] = useReducer(eventReducer, {
    eventCost: paymentData.eventCost,
    totalAmount: paymentData.eventCost,
    selectedSeats: 1,
  });
  const { eventCost, totalAmount, selectedSeats } = costState;
  const fullDiscount = Math.ceil(eventCost - discount * eventCost) === 0;

  useEffect(() => {
    dispatch({
      type: "INIT_COST",
      payload: {
        eventCost: paymentData.eventCost,
        availabeSeats: paymentData.noOfRemainingSeats,
      },
    });
  }, [paymentData.eventCost, paymentData.noOfRemainingSeats]);

  const seatNumbers = Array.from(
    { length: paymentData.noOfRemainingSeats },
    (_, index) => index + 1
  );

  const options = seatNumbers.map((seatNumber) => {
    return {
      label: seatNumber,
      value: seatNumber,
    };
  });

  const optionsInSelect = [];
  const eventsTransactionStatus = [
    {
      img: RightTransaction,
      msg: "Your event is successfully booked",
      status: true,
    },
    {
      img: WrongTransaction,
      msg: "Unfortunately <br> your event is not reserved",
      status: false,
    },
  ];

  const createChattingRoom = async () => {
    const roomId = paymentData.eventId;
    const userId = auth.authData.user._id;
    const roomsDocRef = firebase
      .firestore()
      .collection("eventsRooms")
      .doc(`${roomId}-${userId}`);

    const messages = [];
    await roomsDocRef.set({
      messages,
    });
  };

  const setRedirectToPay = (isSuccess) => {
    const currentState = eventsTransactionStatus.filter(
      (state) => state.status === isSuccess
    );
    updateCurrentTransactionState(currentState[0]);
    //if (isSuccess) {
    createChattingRoom();
    //}
  };

  const handleTryAgain = () => {
    updateCurrentTransactionState(null);
  };

  const applyPromoCode = () => {
    updatePromoLoadingState(true);
    let data = {
      coupon: promoCode.trim(),
      coachId: paymentData.coachId,
      eventId: paymentData.eventId,
      clientId: auth.authData.user._id,
      clientEmail: auth.authData.user.email,
    };
    axios({
      method: "post",
      url: baseURL + "/account/CheckCoupon",
      data,
    })
      .then((res) => {
        updatePromoLoadingState(false);
        setInvalidCoupon(false);
        updatePromoCodeState(1);
        updateDiscount(res.data.discountPercentage / 100);
      })
      .catch((err) => {
        updatePromoLoadingState(false);
        updatePromoCodeState(-1);
        if (err.response && err.response.data && err.response.data.message) {
          setInvalidCoupon(err.response.data.message);
        }
      });
  };

  const handleSeatChange = (value) => {
    dispatch({ type: "SELECTED", payload: value });
  };

  const handleApply = (event) => {
    event.preventDefault();
    applyPromoCode();
  };

  return (
    <Modal
      visible={isModalVisible}
      onCancel={onCancel}
      footer={[]}
      centered
      title={<div className="f-28">Event Payment</div>}
      className="event-booking-modal"
    >
      {currentTransactionState ? (
        <div className="d-flex align-items-center justify-content-center flex-column my-2">
          <img
            src={currentTransactionState.img}
            alt="currentTransactionState"
          />
          <div
            className="f-32 mainColor fw-600 text-center"
            dangerouslySetInnerHTML={{
              __html: currentTransactionState.msg,
            }}
          ></div>

          {currentTransactionState.status === false && (
            <button className="primary-btn py-2 my-2" onClick={handleTryAgain}>
              Try again
            </button>
          )}
        </div>
      ) : (
        <>
          <div className="d-flex align-items-center">
            <img
              src={baseURL + "/" + paymentData?.guruImage}
              className="initials"
              alt="avatar"
            />
            <span className="info mx-2 text-center">
              <div className="name">{paymentData?.guruName}</div>
              <div className="specialization">
                {paymentData?.guruSpecialization}
              </div>
            </span>
          </div>
          <div className="total-container">
            <div className="d-flex align-items-center my-3">
              <div className="f-28">Total:</div>
              <div className=" mainColor f-28 mx-2">
                <span
                  className={`${
                    discount && !fullDiscount && "priceBeforeDiscount"
                  }`}
                >
                  {fullDiscount ? (
                    "Free"
                  ) : (
                    <>
                      {exchangeCurrency}
                      {Math.ceil(totalAmount * exchangeRate)}
                    </>
                  )}
                </span>
                {discount && !fullDiscount ? (
                  <span className=" mx-2">
                    {exchangeCurrency}
                    {Math.ceil(
                      totalAmount * exchangeRate -
                        totalAmount * exchangeRate * discount
                    )}
                  </span>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>

          <form onSubmit={handleApply}>
            <div className="promoCodeControl">
              <div
                className="promoCodeContainer position-relative"
                style={{ width: "20%" }}
              >
                {/* <select
                  className="promoCodeInput"
                  // size={5}
                  style={{
                    paddingLeft: "40px",
                    width: "100%",
                    textAlign: "center",
                  }}
                  value={selectedSeat}
                  onChange={handleSeatChange}
                >
                  {seatNumbers.map((seatNumber) => (
                    <option key={seatNumber} value={seatNumber}>
                      {seatNumber}
                    </option>
                  ))}
                </select> */}
                <Select
                  defaultValue={1}
                  placeholder="no of seats"
                  className="select-seats"
                  style={{
                    width: "100%",
                    position: "relative",
                  }}
                  onChange={handleSeatChange}
                  options={options}
                />
                {/* <SeatsIcon
                  width={20}
                  height={20}
                  style={{
                    position: "absolute",
                    top: "50%",
                    transform: "translateY(-50%)",
                    // margin: "0 5px",
                  }}
                /> */}
              </div>
              <div className="promoCodeContainer d-flex flex-1 position-relative">
                <PromocodeIcon
                  width={25}
                  height={15}
                  style={{
                    position: "absolute",
                    top: "50%",
                    transform: "translateY(-50%)",
                    margin: "0 5px",
                  }}
                />
                <input
                  type="text"
                  className="promoCodeInput"
                  placeholder="PromoCode"
                  value={promoCode}
                  style={{ paddingLeft: "40px", width: "100%" }}
                  onChange={(e) => {
                    updatePromoCode(e.target.value);
                  }}
                />

                <div className="promoCodeSign">
                  {promoCodeState !== 0 ? (
                    promoCodeState === 1 ? (
                      <RightSign />
                    ) : (
                      <WrongEmailCheck />
                    )
                  ) : (
                    ""
                  )}
                </div>

                <button
                  type="submit"
                  className="apply-code"
                  // onClick={applyPromoCode}
                  disabled={!promoCode}
                  loading={promoLoadingState}
                >
                  <div className="py-2 ">Apply</div>
                </button>
              </div>
            </div>
          </form>

          <p className="invalid-coupon">{invalidCoupon && invalidCoupon}</p>

          <StripeContainer
            onCancel={onCancel}
            selectedSeats={selectedSeats}
            paymentData={{
              ...paymentData,
              coupon: promoCodeState !== 0 ? promoCode : null,
              fullDiscount: fullDiscount,
            }}
            setRedirectToPay={setRedirectToPay}
            coupon={promoCodeState === 1 ? promoCode : null}
            back={null}
            isEvent={true}
          />
        </>
      )}
    </Modal>
  );
}

export default EventBookingModal;
