import React from "react";

function EmptyStar() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="10.009"
			height="9.564"
			viewBox="0 0 10.009 9.564"
		>
			<g
				id="Group_4223"
				data-name="Group 4223"
				transform="translate(-181.24 -223.451)"
			>
				<path
					id="Path_1524"
					data-name="Path 1524"
					d="M333.028,223.783l-1.48,3-3.309.481,2.395,2.334-.565,3.3,2.96-1.556,2.96,1.556-.565-3.3,2.395-2.334-3.309-.481Z"
					transform="translate(-146.568 -0.243)"
					fill="#F5F5F5"
				/>
				<g id="Group_4224" data-name="Group 4224">
					<path
						id="Path_1525"
						data-name="Path 1525"
						d="M328.62,233.016l.616-3.592-2.61-2.544,3.607-.524,1.434-2.9.359.177-1.527,3.093-3.013.438,2.18,2.125-.514,3,2.695-1.417,3.053,1.605-.186.354-2.867-1.507Z"
						transform="translate(-145.385)"
					/>
				</g>
			</g>
		</svg>
	);
}

export default EmptyStar;
