import React from "react";

function WrongEmailCheck() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24.5"
			height="24.5"
			viewBox="0 0 24.5 24.5"
		>
			<g
				id="Atoms_Selectors_Selected"
				data-name="Atoms/ Selectors/ Selected"
				transform="translate(0.25 0.25)"
			>
				<circle
					id="Oval"
					cx="12"
					cy="12"
					r="12"
					fill="red"
					stroke="#eb2f2f"
					stroke-width="0.5"
				/>
				<g
					id="Group_269"
					data-name="Group 269"
					transform="translate(8.566 8.566)"
				>
					<line
						id="Line_25"
						data-name="Line 25"
						x1="6.868"
						y2="6.868"
						fill="none"
						stroke="#fff"
						stroke-linecap="round"
						stroke-width="2"
					/>
					<line
						id="Line_26"
						data-name="Line 26"
						x2="6.868"
						y2="6.868"
						fill="none"
						stroke="#fff"
						stroke-linecap="round"
						stroke-width="2"
					/>
				</g>
			</g>
		</svg>
	);
}

export default WrongEmailCheck;
