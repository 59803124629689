import React from "react";

function OvalFilledCircle() {
	return (
		<svg
			id="Group_4283"
			data-name="Group 4283"
			xmlns="http://www.w3.org/2000/svg"
			width="39.252"
			height="39.252"
			viewBox="0 0 39.252 39.252"
		>
			<path
				id="Oval"
				d="M19.626,0A19.626,19.626,0,1,1,0,19.626,19.626,19.626,0,0,1,19.626,0Z"
				fill="#CFA79D"
			/>
			<path
				id="Tick_Right"
				data-name="Tick Right"
				d="M13.768.419A1.43,1.43,0,0,1,15.79,2.442L7.209,11.023a1.43,1.43,0,0,1-2.023,0L.419,6.256A1.43,1.43,0,0,1,2.442,4.233L6.2,7.989Z"
				transform="translate(11.172 13.953)"
				fill="#fff"
			/>
		</svg>
	);
}

export default OvalFilledCircle;
