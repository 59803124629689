import React, { useEffect, useRef, useState } from "react";
import { Modal } from "antd";
import firebase from "firebase/app";
import "./EventChattingModal.css";

function EventChattingModal({ isModalVisible, onCancel, paymentData, auth }) {
	const [msgsContainer, updateMsgContainer] = useState([]);
	const [msg, updateMsg] = useState("");
	const [loading, updateLoading] = useState(false);
	const lastMsgRef = useRef();
	const handleSendMessage = async () => {
		const roomId = paymentData.eventId + "-" + paymentData.userId;
		const roomsDocRef = firebase
			.firestore()
			.collection("eventsRooms")
			.doc(roomId);
		if (msg.length > 0) {
			const messages = [...msgsContainer];
			messages.push({
				senderId: auth.authData.user._id,
				senderInitials: (
					auth.authData.user.firstName[0] + auth.authData.user.lastName[0]
				).toUpperCase(),
				message: msg,
			});
			await roomsDocRef.set({
				messages,
			});
			updateMsg("");
			lastMsgRef.current.scrollIntoView({
				behavior: "smooth",
				block: "center",
				inline: "start",
			});
			roomsDocRef
				.get()
				.then((querySnapshot) => {
					if (querySnapshot.exists) {
						const data = querySnapshot.data();
						updateMsgContainer(data?.messages);
					} else {
						updateLoading(false);
					}
				})
				.catch((error) => {});
		}
	};

	const getMessages = () => {
		const roomId = paymentData.eventId + "-" + paymentData.userId;
		const roomsDocRef = firebase
			.firestore()
			.collection("eventsRooms")
			.doc(roomId);
		updateLoading(true);
		roomsDocRef.onSnapshot((querySnapshot) => {
			if (querySnapshot.exists) {
				const data = querySnapshot.data();
				updateLoading(false);
				updateMsgContainer(data?.messages);
			} else {
				updateLoading(false);
			}
		});
	};
	useEffect(() => {
		if (paymentData) {
			const roomId = paymentData.eventId + "-" + paymentData.userId;
			const roomsDocRef = firebase
				.firestore()
				.collection("eventsRooms")
				.doc(roomId);

			roomsDocRef.onSnapshot((doc) => {
				if (doc.exists) {
					const data = doc.data();
					updateMsgContainer(data?.messages);
				}
			});
			const unsubscribe = roomsDocRef.onSnapshot((doc) => {
				if (doc.exists) {
					const data = doc.data();
					updateMsgContainer(data?.messages);
				}
			});

			return () => {
				// Clean up the listener when the component unmounts or when paymentData changes
				unsubscribe();
			};
		}
	}, [paymentData]);
	return (
		<Modal
			visible={isModalVisible}
			onCancel={onCancel}
			footer={[]}
			centered
			title={<div className="f-28">Chatting</div>}
			className="event-chatting-modal">
			{loading ? (
				<div className="loader"></div>
			) : (
				<>
					<div className="chatting-box">
						{msgsContainer.map((msgDetails, msgIndex) => {
							const isSender = msgDetails.senderId === auth.authData.user._id;
							return (
								<div
									className={`${
										!isSender && "flex-row-reverse isNotSender"
									} chatting-slot my-2`}>
									<div className="initials text-uppercase">
										{msgDetails.senderInitials}
									</div>
									<div className="msg-container mx-2 p-2">
										{msgDetails.message}
									</div>
								</div>
							);
						})}
						<div ref={lastMsgRef}></div>
					</div>
					<div className="send-box">
						<textarea
							value={msg}
							onChange={(e) => {
								updateMsg(e.target.value);
							}}
							onKeyPress={(e) => {
								if (e.which === 13) {
									e.preventDefault();
									handleSendMessage();
								}
							}}
							placeholder="Type your message here..."
						/>
						<div className="divider"></div>
						<div className="d-flex justify-content-end">
							<button
								className="p-2 mainColor cursorPointer fw-500 send-button"
								onClick={handleSendMessage}>
								Send
							</button>
						</div>
					</div>
				</>
			)}
		</Modal>
	);
}
export default EventChattingModal;
