import React from "react";

function SignOutLogo() {
	return (
		<svg
			id="log-off"
			xmlns="http://www.w3.org/2000/svg"
			width="14.72"
			height="16.544"
			viewBox="0 0 14.72 16.544"
		>
			<path
				id="Path_766"
				data-name="Path 766"
				d="M12.84,3.929a.92.92,0,1,0-1.84,0l0,7.363a.92.92,0,0,0,1.84,0Z"
				transform="translate(-4.56 -3.009)"
				fill="#3c3c3b"
			/>
			<path
				id="Path_767"
				data-name="Path 767"
				d="M4,12.539a7.337,7.337,0,0,1,2.156-5.2l1.3,1.3a5.52,5.52,0,1,0,7.806,0l1.3-1.3A7.36,7.36,0,1,1,4,12.539Z"
				transform="translate(-4 -3.355)"
				fill="#3c3c3b"
			/>
		</svg>
	);
}

export default SignOutLogo;
