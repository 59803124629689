import React from "react";

function ChatIcon({ color }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="19.761"
			height="19.75"
			viewBox="0 0 19.761 19.75"
		>
			<g
				id="Group_179"
				data-name="Group 179"
				transform="translate(-10.614 -244.625)"
			>
				<g id="Group_178" data-name="Group 178" transform="translate(11 245)">
					<path
						id="Path_1386"
						data-name="Path 1386"
						d="M20.5,245a9.511,9.511,0,0,0-9.5,9.5,9.388,9.388,0,0,0,1.482,5.092l-1.44,3.594a.593.593,0,0,0,.772.771l3.594-1.439A9.389,9.389,0,0,0,20.5,264a9.5,9.5,0,0,0,0-19Zm0,17.813a8.193,8.193,0,0,1-4.686-1.45.587.587,0,0,0-.337-.105.6.6,0,0,0-.221.042l-2.6,1.041,1.041-2.6a.6.6,0,0,0-.063-.558,8.193,8.193,0,0,1-1.45-4.686,8.313,8.313,0,1,1,8.313,8.313Z"
						transform="translate(-11 -245)"
						fill={color ? color : "#898989"}
						stroke={color ? color : "#898989"}
						strokeMiterlimit="10"
						strokeWidth="0.75"
					/>
				</g>
				<g
					id="Component_62_1"
					data-name="Component 62 – 1"
					transform="translate(17.5 252.5)"
				>
					<path
						id="Path_5187"
						data-name="Path 5187"
						d="M0,0H6"
						fill="none"
						stroke={color ? color : "#898989"}
						strokeLinecap="round"
						strokeWidth="1.5"
					/>
				</g>
				<g
					id="Component_63_1"
					data-name="Component 63 – 1"
					transform="translate(17.5 256.5)"
				>
					<line
						id="Line_20"
						data-name="Line 20"
						x2="6"
						fill="none"
						stroke={color ? color : "#898989"}
						strokeLinecap="round"
						strokeWidth="1.5"
					/>
				</g>
			</g>
		</svg>
	);
}

export default ChatIcon;
