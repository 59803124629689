import React, { useEffect, useState } from 'react'
import { Button, Form, Input } from 'antd';
import { Alert } from 'react-bootstrap'
import axios from "axios";
import { baseURL } from '../../App';
import { connect } from 'react-redux';
import './AccountBankDetails.css'

function AccountBankDetails({ auth }) {
    const [form] = Form.useForm();
    const [values, updateValues] = useState(null)
    const [loadingState, updateLoadingState] = useState(false);
    const [successState, updateSuccessState] = useState(false)
    useEffect(() => {
        axios({
            method: "get",
            url:
                baseURL +
                `/coache/coachBankDetails?coachId=${auth.authData.user._id}`
        }).then(res => {
            updateValues(res.data.data)
        }).catch(err => {
            updateValues({})
        })
    }, [auth.authData.user._id])
    const onFinish = (valuesData) => {
        valuesData.coachId = auth.authData.user._id
        valuesData.edit = Object.values(values).length > 0 ? true : false
        valuesData.id = values._id
        updateLoadingState(true)
        axios({
            method: "post",
            url:
                baseURL +
                "/coache/coachBankDetails",
            data: valuesData
        }).then((res) => {
            updateLoadingState(false)
            updateSuccessState(true)
        }).catch(err => {
            updateLoadingState(false)
        });
    };
    const validateMessages = {
        // eslint-disable-next-line
        required: "${label} is required!",
        // ...
    };
    return (
        <div className='accountBankDetails' >
            <div className="heading d-flex align-items-center mb-4">
                <div>Bank Details</div>
            </div>
            {values !== null && <Form
                validateMessages={validateMessages}
                form={form}
                name="control-hooks"
                onFinish={onFinish}
                initialValues={values}
                key={values}
            >
                <Form.Item
                    name="beneficiaryName"
                    label="Beneficiary Name"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="accountNumber"
                    label="Account Number"
                    rules={[
                        {
                            required: true,
                        }
                        , () => ({
                            validator(_, value) {
                                if (!value) {
                                    return Promise.reject();
                                }
                                if (isNaN(value)) {
                                    return Promise.reject("Account Number has to be a number.");
                                }
                                return Promise.resolve();
                            }
                        }
                        )]
                    }
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="IBAN"
                    label="IBAN"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="bankName"
                    label="Bank Name"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="branch"
                    label="Branch"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="SWIFTCode"
                    label="SWIFTCode"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" className='btn' htmlType="submit" loading={loadingState}>
                        Submit
                    </Button>
                </Form.Item>
                {successState &&
                    <Alert variant="success" className="mt-3">
                        Added Successfully
                    </Alert>
                }
            </Form>
            }
        </div >
    )
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps)(AccountBankDetails)