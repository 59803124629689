import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import TransactionHistoryIcon from "../../Components/svgLogos/AccountPageFigures/TransactionHistory.svg";
import TransactionCard from "../../Components/TransactionCard/TransactionCard";
import { baseURL } from "../../App";
import moment from "moment";
import { DatePicker, Space } from "antd";
import "./AccountTransactionHistory.css";
class AccountTransactionHistory extends Component {
	state = {
		fromDate: null,
		toDate: null,
		TotalEarning: "",
		transactions: [],
	};

	handleChange = (e) => {
		this.setState({ [e.target.id]: e.target.value });
	};
	clearFilter = () => {
		this.setState({ fromDate: "", toDate: "" });
	};

	componentDidMount() {
		this.requestTransactions();
	}
	requestTransactions = () => {
		let data = {
			coachId: this.props.auth.authData.user._id,
			userType: this.props.auth.authData.userType,
		};
		if (this.state.fromDate) {
			data.from = this.state.fromDate;
		}
		if (this.state.toDate) {
			data.to = this.state.toDate;
		}
		axios({
			method: "post",
			url: baseURL + "/coache/TransactionHistory",
			data: {
				coachId: this.props.auth.authData.user._id,
				userType: this.props.auth.authData.userType,
				from: this.state.fromDate,
				to: this.state.toDate,
			},
		})
			.then((res) => {
				this.setState({
					TotalEarning: res.data.TotalEarning,
					transactions: res.data.data,
				});
			})
			.catch((err) => console.log(err.message));
	};
	onChange = () => {
		this.requestTransactions();
	};

	render() {
		const dateFormat = "DD/MM/YYYY";
		return (
			<div className="accountTransactionHistory">
				<div className="header">
					<div className="title">
						<span>
							<img src={TransactionHistoryIcon} alt="TransactionHistoryIcon" />
						</span>
						Transaction History
					</div>
					<div className="dateFilter">
						<div className="intialTime" style={{ display: "inline-block" }}>
							<span style={{ marginRight: "10px" }}>From:</span>

							<Space direction="vertical" className="Space">
								<DatePicker
									className={
										this.state.errorSign && this.state.dateOfBirth === ""
											? "form-control required"
											: "form-control"
									}
									placeholder="DD/MM/YYYY"
									id={"dateOfBirth"}
									onChange={(date) => {
										this.setState(
											{
												fromDate: moment(date).format("MM/DD/YYYY")
											},
											() => {
												this.onChange();
											}
										);
									}}
									value={this.state.dateOfBirth}
									disabledDate={this.disabledDate}
									format={dateFormat}
									style={{ border: " 1px solid #CFA79D" }}
								/>
							</Space>
						</div>
						<div className="lastTime" style={{ display: "inline-block" }}>
							<span id="lastTime" style={{ marginRight: "10px" }}>
								To:
							</span>

							<Space direction="vertical">
								<DatePicker
									className={
										this.state.errorSign && this.state.dateOfBirth === ""
											? "form-control required"
											: "form-control"
									}
									placeholder="DD/MM/YYYY"
									id={"dateOfBirth"}
									onChange={(date) => {
										this.setState({ toDate: moment(date).format("MM/DD/YYYY") }, () => {
											this.onChange();
										});
									}}
									value={this.state.dateOfBirth}
									disabledDate={this.disabledDate}
									format={dateFormat}
									style={{ border: " 1px solid #CFA79D" }}
								/>
							</Space>
						</div>

						<button className="btn" onClick={this.clearFilter}>
							Clear
						</button>
					</div>
				</div>
				<div className="body">
					<div className="totallEarning">
						Total earnings:{" "}
						<span>
							{this.state.TotalEarning}
							{this.state.unit}
						</span>
					</div>
					{this.state.transactions.map((transaction) => {
						return <TransactionCard transaction={transaction} />;
					})}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	};
};
export default connect(mapStateToProps)(AccountTransactionHistory);
