import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import logger from "redux-logger";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import RootReducer from "./Store/Reducers/RootReducer";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
// import "../node_modules/video-react/dist/video-react.css"; // import css
import "../node_modules/video-react/dist/video-react.css";

// const middlwares = [logger];
// const store = createStore(RootReducer, applyMiddleware(...middlwares));
const store = createStore(RootReducer);
const persistor = persistStore(store);

/*
store.subscribe(() => {
});

if ("serviceWorker" in navigator) {
	navigator.serviceWorker
		.register("../public/firebase-messaging-sw.js")
		.then(function (registration) {
		})
		.catch(function (err) {
		});
}
*/
ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);

serviceWorker.unregister();
