import React from "react";

function RedStar() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="10.009"
			height="9.564"
			viewBox="0 0 45.014 42.423"
		>
			<g
				id="Group_4218"
				data-name="Group 4218"
				transform="translate(-128.376 -223.451)"
			>
				<path
					id="Path_1532"
					data-name="Path 1532"
					d="M151.231,223.783l-6.564,13.3-14.679,2.133,10.621,10.354-2.507,14.62,13.13-6.9,13.13,6.9-2.508-14.62,10.622-10.354L157.8,237.085Z"
					transform="translate(0.295 0.061)"
					fill="red"
				/>
				<g id="Group_4219" data-name="Group 4219">
					<path
						id="Path_1533"
						data-name="Path 1533"
						d="M137.218,265.874l2.732-15.932-11.574-11.283,16-2.324,6.359-12.884,1.59.785-6.771,13.72L132.189,239.9l9.668,9.424-2.282,13.308,11.952-6.283,13.542,7.12-.826,1.571-12.716-6.686Z"
					/>
				</g>
				<g id="Group_4220" data-name="Group 4220">
					<rect
						id="Rectangle_134"
						data-name="Rectangle 134"
						width="14.833"
						height="1.774"
						transform="matrix(0.716, -0.698, 0.698, 0.716, 161.529, 248.996)"
					/>
				</g>
			</g>
		</svg>
	);
}

export default RedStar;
