import { Button, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import DropDownModal from "../../Components/DropDownModal/DropDownModal";
import NavBarLg from "../../Components/NavBarLg/NavBarLg";
import NavBarSm from "../../Components/NavBarSm/NavBarSm";
import BlogCard from "./Components/BlogCard/BlogCard";
import circlePlus from "../../../src/Assets/plus-circle.svg";
import view from "../../../src/Assets/Show.svg";
import edit from "../../../src/Assets/edit.svg";
import deletee from "../../../src/Assets/delete.svg";
import { useHistory } from "react-router";
import HamburgerList from "../../Components/HamburgerList/HamburgerList";
import { connect } from "react-redux";
import axios from "axios";
import { baseURL } from "../../App";
import { Link } from "react-router-dom";
import "./Blog.css";

function Blog({ auth }) {
	const [dropdownMenu, updateDropdownMenu] = useState(false);
	const [hamburgerMenu, toggleHamburgerMenu] = useState(false);
	const [allCategories, updateAllCategories] = useState([]);
	const [pageIndex, updatePageIndex] = useState(0);
	const [blogsList, updateBlogsList] = useState([]);
	const [myArticles, updateMyArticles] = useState([]);
	const [hasNext, updateHasNext] = useState(false);
	const getForAllParameter = async (userType) => {
		var forAll = null;
		await axios({
			method: "get",
			url: baseURL + "/article/getArticleCategories",
		}).then((res) => {
			const allCategories = res.data.data;
			updateAllCategories(allCategories);
			forAll = userType === 2 ? true : false;
		});

		return forAll;
	};
	const getMyArticles = () => {
		axios({
			method: "get",
			url:
				baseURL + `/article/getGuruArticles?guruId=${auth.authData.user._id}`,
		}).then((res) => {
			updateMyArticles(res.data.data);
		});
	};
	const getArticleCategories = () => {
		getForAllParameter(auth.authData.userType).then((forAll) => {
			axios({
				method: "get",
				url:
					baseURL +
					`/article/getArticles?page=${pageIndex}&userId=${auth.authData.user._id}&forAll=${forAll}`,
			}).then((res) => {
				if (pageIndex === 0) {
					updateBlogsList(res.data.data.data);
				} else {
					updateBlogsList([...blogsList, ...res.data.data.data]);
				}
				updateHasNext(res.data.data.hasNext);
			});
		});
	};
	useEffect(() => {
		const isGuru = auth.authData.userType === 2;
		if (isGuru) {
			getMyArticles();
		}
		getArticleCategories();
		// eslint-disable-next-line
	}, [pageIndex]);

	const toggleDropdownMenu = (val) => {
		updateDropdownMenu(val);
	};
	const handleLoadMore = () => {
		updatePageIndex((prevValue) => prevValue + 1);
	};

	const handleDeleteArticle = (articleId) => {
		axios({
			method: "delete",
			url: baseURL + `/article/deleteArticle?articleId=${articleId}`,
		}).then((res) => {
			getArticleCategories();
			getMyArticles();
		});
	};
	const history = useHistory();
	const isGuru = auth.authData.userType === 2;
	return (
		<div className="blog">
			<div className="navbarContainer">
				<NavBarLg
					toggleDropdownMenuState={dropdownMenu}
					toggleDropdownMenu={toggleDropdownMenu}
					active="blog"
				/>
				<DropDownModal
					toggleDropdownMenu={toggleDropdownMenu}
					dropDownState={dropdownMenu}
				/>

				<NavBarSm
					toggleHamburgerMenu={() => {
						toggleHamburgerMenu(true);
					}}
					toggleHamburgerMenuState={hamburgerMenu}
					parent="blog"
				/>
				{hamburgerMenu && (
					<HamburgerList
						toggleHamburgerMenu={() => {
							toggleHamburgerMenu(false);
						}}
						toggleHamburgerMenuState={hamburgerMenu}
					/>
				)}
			</div>
			<div className="my-4 text-center">
				<Row>
					<Col
						className="d-flex align-items-center flex-column blogs-section"
						xs={isGuru ? 16 : 24}
						md={isGuru ? 18 : 24}>
						<div className="d-flex flex-column justify-content-center">
							{blogsList.map((blog, index) => {
								return (
									<BlogCard
										key={index}
										allCategories={allCategories}
										blogDetails={blog}
										recallAllBlogs={getArticleCategories}
									/>
								);
							})}
						</div>
						{hasNext && (
							<Button className="primary-btn" onClick={handleLoadMore}>
								Load More
							</Button>
						)}
					</Col>
					<Col
						xs={isGuru ? 8 : 0}
						md={isGuru ? 6 : 0}
						className="mt-4 text-center action-center">
						<figure className="circlePlus-figure mb-4">
							<img
								src={circlePlus}
								alt="circlePlus"
								onClick={() => {
									history.push("/addBlog");
								}}
							/>
						</figure>
						<div className="mt-4">
							<div className="title mt-4 mb-2">My articles</div>
							{myArticles.map((article) => {
								return (
									<div className="d-flex align-items-center justify-content-between pr-4">
										<div className="article-title">{article.title}</div>
										<div className="d-flex align-items-center">
											<Link
												className="mx-2"
												to={{
													pathname: `/blog/${article._id}`,
													state: article,
												}}>
												<figure className="icon-container">
													<img src={view} alt="view" />
												</figure>
											</Link>
											<Link
												className="mx-2"
												to={{
													pathname: "/addBlog",
													state: article,
												}}>
												<figure className="icon-container">
													<img src={edit} alt="edit" />
												</figure>
											</Link>
											<div
												className="mx-2"
												onClick={() => {
													handleDeleteArticle(article._id);
												}}>
												<figure className="icon-container">
													<img
														src={deletee}
														alt="deletee"
														className="cursorPointer"
													/>
												</figure>
											</div>
										</div>
									</div>
								);
							})}
						</div>
					</Col>
				</Row>
			</div>
		</div>
	);
}
const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	};
};

export default connect(mapStateToProps)(Blog);
