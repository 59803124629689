import { Button, Col, Form, Input, Row, Select, Upload } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";
import DropDownModal from "../../../../Components/DropDownModal/DropDownModal";
import HamburgerList from "../../../../Components/HamburgerList/HamburgerList";
import NavBarLg from "../../../../Components/NavBarLg/NavBarLg";
import NavBarSm from "../../../../Components/NavBarSm/NavBarSm";
import uploadImg from "../../../../Assets/upload_thumbnail.svg";
import { useHistory } from "react-router";
import axios from "axios";
import { connect } from "react-redux";
import { baseURL } from "../../../../App";
import "./AddBlog.css";

function AddBlog({ auth, location }) {
	const [dropdownMenu, updateDropdownMenu] = useState(false);
	const [hamburgerMenu, toggleHamburgerMenu] = useState(false);
	const [isEdit, updateIsEdit] = useState(false);
	const [imageURL, updateImageURL] = useState(null);
	const [loading, updateLoading] = useState(false);
	const [categories, updateCategories] = useState([]);
	const history = useHistory();
	const { Option } = Select;

	const toggleDropdownMenu = (val) => {
		updateDropdownMenu(val);
	};

	const getCategoriesList = () => {
		axios({
			method: "get",
			url: baseURL + "/article/getArticleCategories",
		}).then((res) => {
			updateCategories(res.data.data);
		});
	};
	useEffect(() => {
		if (location?.state) {
			updateIsEdit(true);
			updateImageURL(location.state.image);
		}
	}, [location]);
	useEffect(() => {
		getCategoriesList();
	}, []);
	const onFinish = (values) => {
		updateLoading(true);
		const path = isEdit ? "/article/editArticle" : "/article/addArticle";
		const data = {
			...values,
			guruId: auth.authData.user._id,
			image: imageURL,
			articleId: location?.state?._id,
		};

		axios({
			method: "post",
			url: baseURL + path,
			data,
		}).then((res) => {
			updateLoading(false);
			history.push("/blog");
		});
	};

	const handleDiscard = () => {
		history.push("/blog");
	};
	const handleUploadCover = (e) => {
		const file = e.file.originFileObj;
		updateLoading(true);
		if (file.type.includes("image")) {
			const data = new FormData();
			data.append(
				"image",
				file,
				Math.ceil(Math.random() * 1000000000000000) + ".jpg"
			);
			axios
				.post(baseURL + "/user/imageUpload", data, {
					// receive two parameter endpoint url ,form data
				})
				.then((res) => {
					updateLoading(false);
					updateImageURL(res.data.path);
				});
		} else {
			updateImageURL(null);
		}
	};

	return (
		<div>
			<div className="navbarContainer">
				<NavBarLg
					toggleDropdownMenuState={dropdownMenu}
					toggleDropdownMenu={toggleDropdownMenu}
					active="blog"
				/>
				<DropDownModal
					toggleDropdownMenu={toggleDropdownMenu}
					dropDownState={dropdownMenu}
				/>

				<NavBarSm
					toggleHamburgerMenu={() => {
						toggleHamburgerMenu(true);
					}}
					toggleHamburgerMenuState={hamburgerMenu}
					parent="blog"
				/>
				{hamburgerMenu && (
					<HamburgerList
						toggleHamburgerMenu={() => {
							toggleHamburgerMenu(false);
						}}
						toggleHamburgerMenuState={hamburgerMenu}
					/>
				)}
			</div>

			<Form
				name="basic"
				onFinish={onFinish}
				requiredMark={false}
				initialValues={location.state}
				colon={false}
				className="add-blog mx-2">
				<Row>
					<Col xs={24} md={8} className="px-2">
						<Form.Item
							label="Article Thumbnail"
							name="image"
							rules={[
								{
									required: true,
									message: (
										<div className="text-center">
											Please Upload The Article Thumbnail!
										</div>
									),
								},
								() => ({
									validator(_, value) {
										if (!value) {
											return Promise.reject();
										} else if (!value.file && value?.includes("jpg")) {
											return Promise.resolve();
										} else if (value?.file?.type.includes("image")) {
											return Promise.resolve();
										}
										return Promise.reject(
											<div className="text-center">
												Please Upload Images Only
											</div>
										);
									},
								}),
							]}>
							<Upload
								action="/upload.do"
								accept="image"
								onChange={handleUploadCover}
								className="d-flex justify-content-center">
								{loading ? (
									<div className="loader"></div>
								) : (
									<figure className="thumbnail-figure mt-3">
										{imageURL ? (
											<img src={baseURL + "/" + imageURL} alt="uploadImg" />
										) : (
											<div className="uploadImg-container">
												<img src={uploadImg} alt="uploadImg" />
											</div>
										)}
									</figure>
								)}
							</Upload>
						</Form.Item>

						<Form.Item
							label="Title"
							name="title"
							placeholder="Title"
							rules={[
								{
									required: true,
									message: "Please Enter The Title!",
								},
							]}>
							<Input placeholder="Title" />
						</Form.Item>
						<Form.Item
							label="Category"
							name="categoryId"
							rules={[
								{
									required: true,
									message: "Please Select a Category!",
								},
							]}>
							<Select placeholder="Select Category">
								{categories.map((category) => {
									return (
										<Option value={category._id}>
											{category.categoryName}
										</Option>
									);
								})}
							</Select>
						</Form.Item>
						<div className="text-center d-none d-md-block">
							<div className="my-2">
								<Button
									loading={loading}
									className="primary-btn"
									htmlType="submit"
									type="primary">
									Save
								</Button>
							</div>

							<div className="my-2">
								<Button
									className="secondary-btn  m-auto my-2"
									onClick={handleDiscard}>
									Discard
								</Button>
							</div>
						</div>
					</Col>
					<Col xs={24} md={16}>
						<Form.Item
							label="Body"
							name="body"
							rules={[
								{
									required: true,
									message: "Please Enter The Body!",
								},
							]}>
							<TextArea placeholder="Body..." />
						</Form.Item>
						<div className="text-center d-block d-md-none">
							<div className="my-2">
								<Button
									loading={loading}
									className="primary-btn"
									htmlType="submit"
									type="primary">
									Save
								</Button>
							</div>

							<div className="my-2">
								<Button
									className="secondary-btn  m-auto my-2"
									onClick={handleDiscard}>
									Discard
								</Button>
							</div>
						</div>
					</Col>
				</Row>
			</Form>
		</div>
	);
}
const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	};
};

export default connect(mapStateToProps)(AddBlog);
