import React, { useState, useEffect } from "react";
import OvalFilledDot from "../../svgLogos/OvalFilledDot";
import OvalEmptyDot from "../../svgLogos/OvalEmptyDot";
import { Input, Button } from "antd";
import { baseURL, exchangeCurrency, exchangeRate } from "../../../App";
import { Formik } from "formik";
import moment from "moment";
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-input-2";
import { Link, Redirect } from "react-router-dom";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Alert } from "react-bootstrap";
import axios from "axios";
import { BookAction } from "../../../Store/Actions/BookAction";
import OvalFilledCircle from "../../svgLogos/OvalFilledCircle";
import { AuthState } from "../../../Store/Actions/AuthActions";
import { connect } from "react-redux";
import WrongEmailCheck from "../../svgLogos/WrongEmailCheck";
import RightSign from "../../svgLogos/BookingFigures/RightSign";
import StripeContainer from "../../StripePayment/StripeContainer";
import "./QuickBook.css";

const QuickBook = ({
	gurusDetails,
	SessionDateTime,
	endDateTime,
	AuthState,
	auth,
	timeSlotId,
	sessionPeriod,
	BookAction,
}) => {
	const [pageTitle, updatePageTitle] = React.useState("Contact Information");
	const [page, updatePage] = useState(0);
	const [submitClicked, updateSubmitClicked] = useState(false);
	const [isEmailExist, updateIsEmailExist] = useState(false);
	const [sessionTopic, updateSessionTopic] = useState("");
	const [promoCode, updatePromoCode] = useState("");
	const [promoCodeState, updatePromoCodeState] = useState(0);
	const [promoLoadingState, updatePromoLoadingState] = useState(null);
	const [loadingState, updateLoadingState] = useState(false);
	const [redirectToPay, updateRedirectToPay] = useState(false);
	const [discount, updateDiscount] = useState(null);
	const [isEmailConfirmed, updateIsEmailConfirmed] = useState(null);
	const [isValidPhone, updateIsValidPhone] = useState(false);
	const modalHeaderNode = document.querySelector(".modal-header");
	modalHeaderNode.classList.add("d-none");

	const setRedirectToPay = () => {
		updateRedirectToPay(true);
	};

	useEffect(() => {
		if (page === 2) {
			BookAction({
				startDateTime: SessionDateTime,
				endDateTime: endDateTime,
				coachFirstName: gurusDetails.firstName,
				coachLastName: gurusDetails.lastName,
				coachImage: gurusDetails.image,
				coachPrefix: gurusDetails.coachPrefix,
				coachSpecialization: gurusDetails.coachSpecialization,
				time: SessionDateTime,
				sessionTopic,
				coachId: gurusDetails._id,
				clientId: auth.authData.user._id,
				anonymous: false,
				price: gurusDetails.pricePerHour,
				userType: 1,
				paymentNumber: 0,
				coachTimeId: timeSlotId,
				timeZone: localStorage.getItem("timeZone"),
				applyFreeSession: false,
				//eslint-disable-next-line
				period: sessionPeriod == "1" ? 1 : 0,
				//eslint-disable-next-line
				periodMin: sessionPeriod == "1" ? 0 : 30,
				applyCoupon: promoCodeState === 1 ? true : false,
				currency: exchangeCurrency,
			});
		}
		// eslint-disable-next-line
	}, [page]);
	// useEffect(() => {
	//     return () => {
	//         if (!isEmailConfirmed) {
	//             AuthState(0)
	//         }
	//     }
	//     // eslint-disable-next-line
	// }, [isEmailConfirmed])

	const checkValidEmail = async () => {
		var isEmailConfirmedVar = null;
		await axios({
			method: "post",
			url: baseURL + "/account/CheckEmailConfirmed",
			data: {
				email: auth.authData.user.email.toLowerCase(),
				userType: 1,
			},
		}).then((res) => {
			updateIsEmailConfirmed(res.data.data);
			isEmailConfirmedVar = res.data.data;
		});
		return isEmailConfirmedVar;
	};

	const handleBook = async () => {
		try {
			const isEmailConfirmedVar = await checkValidEmail();
			if (isEmailConfirmedVar) {
				updateIsEmailConfirmed(true);
				updateLoadingState(true);
				let data = {
					sessionTopic,
					coachId: gurusDetails._id,
					clientId: auth.authData.user._id,
					anonymous: false,
					price: gurusDetails.pricePerHour,
					userType: 1,
					paymentNumber: 0,
					coachTimeId: timeSlotId,
					timeZone: localStorage.getItem("timeZone"),
					applyFreeSession: false,
					//eslint-disable-next-line
					period: sessionPeriod == "1" ? 1 : 0,
					//eslint-disable-next-line
					periodMin: sessionPeriod == "1" ? 0 : 30,
					applyCoupon: promoCodeState === 1 ? true : false,
					currency: exchangeCurrency,
				};
				axios({
					method: "post",
					url: baseURL + "/user/bookSessionNew",
					data,
				})
					.then((res) => {
						axios({
							method: "post",
							url: baseURL + "/account/NotifyChangeSchedule",
						}).then((res) => {});
						this.props.BookAction({
							...this.props.book,
							userToken: res.data.data.usertoken,
							sessionId: res.data.data._id,
						});
						localStorage.setItem("transactionState", "Success");
						updateRedirectToPay(true);
					})
					.catch((err) => {
						if (err.response && err.response.data.code === -3) {
							localStorage.setItem("transactionState", "Fail");
							alert(err.response.data.message);
						}
					});
			}
		} catch (e) {
			console.log(e);
		}
	};

	const handleSubmit = (values) => {
		axios({
			method: "post",
			url: baseURL + "/user/register",
			data: {
				email: values.email.toLowerCase(),
				password: values.password,
				firstName: values.fullName.split(" ")[0],
				lastName: values.fullName.split(" ")[1]
					? values.fullName.split(" ")[1]
					: " ",
				phone: values.phone,
				userType: 1,
				timeZone: localStorage.getItem("timeZone"),
			},
		})
			.then((res) => {
				AuthState({
					user: res.data.user,
					userType: 1,
				});
				updatePageTitle("Session Payment");
				updatePage(2);
			})
			.catch((err) => console.log(err.response.data));
	};

	const applyPromoCode = () => {
		updatePromoLoadingState(true);
		let data = {
			coupon: promoCode,
			coachId: gurusDetails._id,
			timeSlotId,
			clientId: auth.authData.user._id,
		};
		axios({
			method: "post",
			url: baseURL + "/account/CheckCoupon",
			data,
		})
			.then((res) => {
				updatePromoLoadingState(false);
				updatePromoCodeState(1);
				updateDiscount(res.data.discountPercentage / 100);
			})
			.catch((error) => {
				updatePromoLoadingState(false);
				if (error.response.data.code === -1) {
					updatePromoCodeState(-1);
				}
			});
	};

	const handleEmailCheck = (email) => {
		if (email) {
			axios({
				method: "post",
				url: baseURL + "/account/checkEmailExist",
				data: {
					email: email.toLowerCase(),
					userType: 1,
				},
			}).then((res) => {
				updateIsEmailExist(res.data.exist);
			});
		}
	};
	if (auth.authData) {
		var paymentData = {
			clientEmail: auth.authData.user.email,
			coacheEmail: gurusDetails.email,
			currency: exchangeCurrency,
			period: sessionPeriod,
			exchangeRate,
			timeSlotId: timeSlotId,
		};
	}

	var priceAfterDiscount = "0";
	var priceBeforeDiscount = "0";

	// eslint-disable-next-line
	if (sessionPeriod == 30) {
		priceAfterDiscount = Math.ceil(
			(gurusDetails.pricePerHour - gurusDetails.pricePerHour * discount) *
				exchangeRate *
				0.6
		);
		priceBeforeDiscount = Math.ceil(
			gurusDetails.pricePerHour * exchangeRate * 0.6
		);
	} else {
		priceAfterDiscount = Math.ceil(
			(gurusDetails.pricePerHour - gurusDetails.pricePerHour * discount) *
				exchangeRate
		);
		priceBeforeDiscount = Math.ceil(gurusDetails.pricePerHour * exchangeRate);
	}
	priceAfterDiscount += exchangeCurrency;
	priceBeforeDiscount += exchangeCurrency;

	if (priceAfterDiscount.startsWith("0")) {
		priceAfterDiscount = "Free";
	}
	const isSessionFree =
		gurusDetails.pricePerHour - gurusDetails.pricePerHour * discount === 0 ||
		gurusDetails.pricePerHour === 0;

	const handleBack = () => {
		updatePage(1);
		updatePageTitle("Contact Information");
	};

	if (redirectToPay) {
		localStorage.setItem("bookingStatus", true);
		return <Redirect to="/" />;
	}
	return (
		<div className="quick-signin">
			{loadingState ? (
				<div className="loader"></div>
			) : (
				<>
					<div className="p-4">
						<div className="title">
							<h4>{pageTitle}</h4>
						</div>
						<div className="Progress">
							<OvalEmptyDot />
							<span className="filledStroke"></span>
							{page === 1 || page === 0 ? <OvalFilledDot /> : <OvalEmptyDot />}
							<span className="dottedStroke"></span>
							{page === 1 || page === 0 ? <OvalEmptyDot /> : <OvalFilledDot />}
						</div>
					</div>
					<div>
						<div className="addSessionDetails">
							{(page === 0 || page === 2) && (
								<div className="PersonInfo">
									<img
										src={baseURL + "/" + gurusDetails.image}
										className="initials"
										alt="avatar"
									/>
									<span className="info">
										<div className="name">
											{gurusDetails.prefix}
											{gurusDetails.firstName} {gurusDetails.lastName}
										</div>
										<div className="specialization">
											{gurusDetails.specialization}
										</div>
									</span>
								</div>
							)}

							{(page === 0 || page === 2) && (
								<div className="sessionDateTime">
									<div className="sessionInfo">
										<div id="addSessionDetails" className="date">
											{moment(SessionDateTime).format("dddd")} <br />
											{moment(SessionDateTime).format("MMMM")}{" "}
											{moment(SessionDateTime).format("DD")} {","}
											{moment(SessionDateTime).format("YYYY")}
										</div>
										<div id="addSessionDetails" className="time">
											{/* {timeSpanToAMPM(this.state)} */}
											{moment(SessionDateTime).format("hh:mm a")}
										</div>
									</div>
								</div>
							)}
							{page === 0 ? (
								<div className="siginOptions">
									<div className="actionsSection">
										<Button className="semi-rounded-btn">
											<Link to="/userType">Create Account</Link>
										</Button>
										<Button className="semi-rounded-btn">
											<Link to="/signin">Sign In </Link>
										</Button>
										<Button
											className="semi-rounded-btn pink"
											onClick={() => {
												if (auth.authData) {
													updatePage(2);
													updatePageTitle("Session Payment");
												} else {
													updatePage(1);
												}
											}}>
											Fast Checkout
										</Button>
									</div>
								</div>
							) : page === 1 ? (
								<Formik
									initialValues={{
										fullName: "",
										email: "",
										password: "",
										phone: "",
									}}
									validate={(values) => {
										const errors = {};
										var passwordReg =
											/^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,}$/;
										var emailReg =
											/^[a-zA-Z0-9.! #$%&'*+/=? ^_`{|}~-]+@[a-zA-Z0-9-]+(?:\. [a-zA-Z0-9-]+)*$/;
										if (!values.email || !emailReg || isEmailExist) {
											errors.email = "Required";
											if (isEmailExist) {
												errors.isExist = "Email already exist";
											} else {
												if (errors.isExist) {
													delete errors.isExist;
												}
											}
										} else {
											if (errors.email) {
												delete errors.email;
											}
										}
										if (!values.fullName) {
											errors.fullName = "Required";
										} else {
											if (errors.fullName) {
												delete errors.fullName;
											}
										}
										if (!values.phone || !isValidPhone) {
											errors.phone = "Required";
										} else {
											if (errors.phone) {
												delete errors.phone;
											}
										}
										if (
											!values.password ||
											!passwordReg.test(values.password)
										) {
											errors.password = [
												"Characteristics of passwords:",
												"-At least 8 characters or more.",
												" -A mixture of both uppercase and lowercase letters.",
												"-At least one of each letters, numbers, and special.",
												"characters (e.g., ! @ # ? ]).",
											];
										} else {
											if (errors.password) {
												delete errors.password;
											}
										}
										return errors;
									}}
									onSubmit={(values) => {
										handleSubmit(values);
									}}>
									{({
										values,
										errors,
										touched,
										handleChange,
										handleBlur,
										handleSubmit,
										isSubmitting,
										/* and other goodies */
									}) => {
										return (
											<form
												onSubmit={(e) => {
													e.preventDefault();
													updateSubmitClicked(true);
													handleSubmit();
												}}>
												<label className="form-label">
													Full Name <span className="required">*</span>{" "}
												</label>
												<Input
													type="text"
													name="fullName"
													value={values.fullName}
													onChange={handleChange}
													onBlur={handleBlur}
													placeholder={"Full Name"}
													className="input"
												/>
												<label className="form-label">
													Phone Number
													<span className="required">*</span>
												</label>
												<PhoneInput
													country={"eg"}
													type="phone"
													name="phone"
													inputStyle={{ width: "100%" }}
													// dropdownStyle={{ textAlign: "left" }}
													value={values.phone}
													onChange={(value) => {
														const isValid = isValidPhoneNumber(`+${value}`);
														updateIsValidPhone(isValid);
														handleChange({ target: { name: "phone", value } });
													}}
													onBlur={handleBlur}
													inputClass={"input-field"}
													placeholder={"Phone number"}
													className="input"
												/>
												{errors.phone && submitClicked && (
													<Alert className="mt-2 text-center" variant="danger">
														Please Enter a valid Phone Number
													</Alert>
												)}
												<label className="form-label">
													Email <span className="required">*</span>
												</label>
												<div className="email-container">
													<Input
														type="email"
														name="email"
														value={values.email}
														onChange={handleChange}
														onBlur={(e) => {
															handleEmailCheck(values.email);
															handleBlur(e);
														}}
														placeholder={"Email"}
														className="input"
													/>
													<span className="emailCheckSign">
														{!isEmailExist ? (
															<span className="right">
																<OvalFilledCircle />
															</span>
														) : (
															<span className="wrong">
																<WrongEmailCheck />
															</span>
														)}
													</span>
													{isEmailExist && (
														<Alert variant={"danger"} className={"text-center"}>
															Email already exists
														</Alert>
													)}
												</div>
												<label className="form-label">
													Password <span className="required">*</span>
												</label>
												<Input.Password
													name="password"
													value={values.password}
													onChange={handleChange}
													onBlur={handleBlur}
													placeholder={"********"}
													className="input"
													iconRender={(visible) =>
														visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
													}
												/>
												{submitClicked && errors.password && (
													<Alert variant={"danger"} className="text-center">
														{errors.password.map((error, index) => (
															<div key={index}>{error}</div>
														))}
													</Alert>
												)}

												<div className="text-center actionCenter">
													<button
														className="btn"
														onClick={() => {
															updatePage(0);
														}}>
														Back
													</button>
													<button
														disabled={isSubmitting}
														className="btn"
														type="submit">
														Next
													</button>
												</div>
											</form>
										);
									}}
								</Formik>
							) : (
								<>
									<details>
										<summary>
											Is there anything you'd like to share with your Guru?
										</summary>
										<div className="textAreaInput">
											<textarea
												onChange={(e) => {
													updateSessionTopic(e.target.value);
												}}
												value={sessionTopic}
												className="input"
											/>
										</div>
									</details>
									{isEmailConfirmed === false && (
										<Alert variant="danger" className={"text-center"}>
											Please Verify Your Account
										</Alert>
									)}
									{!isSessionFree && (
										<div className="d-flex my-2 d-flex align-items-center justify-content-center">
											<div className={"promoCodeContainer"}>
												<input
													type="text"
													className="form-control promoCodeField"
													placeholder="PromoCode"
													value={promoCode}
													onChange={(e) => {
														updatePromoCode(e.target.value);
													}}
												/>
												<div className="promoCodeSign">
													{promoCodeState !== 0 ? (
														promoCodeState === 1 ? (
															<RightSign />
														) : (
															<WrongEmailCheck />
														)
													) : (
														""
													)}
												</div>
											</div>
											<Button
												className="btn mx-2 my-0 promoApplyBtn d-flex justify-content-center align-items-center"
												onClick={applyPromoCode}
												disabled={!promoCode}
												style={{ border: "none" }}>
												{promoLoadingState ? (
													<div className="loader"></div>
												) : (
													<div>Apply</div>
												)}
											</Button>
										</div>
									)}
									{gurusDetails.pricePerHour !== 0 && (
										<div>
											<div className="total">
												Total:{" "}
												<span
													className={
														priceBeforeDiscount !== priceAfterDiscount &&
														"priceBeforeDiscount"
													}>
													{priceBeforeDiscount}
												</span>
												{priceBeforeDiscount !== priceAfterDiscount && (
													<span className="priceAfterDiscount">
														{" "}
														{isSessionFree ? "Free" : priceAfterDiscount}
													</span>
												)}
											</div>
										</div>
									)}
									<div className="btnContainer">
										{isSessionFree ? (
											<span className="actionCenter">
												<button
													id="confirmSessionDetails"
													onClick={() => {
														updatePage(0);
														updatePageTitle("Contact Information");
													}}
													className="btn">
													Back
												</button>
												<button className="btn" onClick={handleBook}>
													<div>Confirm</div>
												</button>
											</span>
										) : (
											<StripeContainer
												// id="confirmSessionDetails"
												mode={"QuickBook"}
												paymentData={paymentData}
												setRedirectToPay={setRedirectToPay}
												onClick={handleBack}
												coupon={promoCodeState === 1 ? promoCode : null}
												checkValidEmail={checkValidEmail}
											/>
										)}
									</div>
								</>
							)}
						</div>
					</div>
				</>
			)}
		</div>
	);
};
const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		AuthState: (auth) => dispatch(AuthState(auth)),
		BookAction: (book) => dispatch(BookAction(book)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(QuickBook);
