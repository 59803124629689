import React from "react";

function Cross({ color }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="14.964"
			height="14.964"
			viewBox="0 0 14.964 14.964"
		>
			<path
				d="M6.225,4.811A1,1,0,0,0,4.811,6.225L10.586,12,4.811,17.775a1,1,0,0,0,1.414,1.414L12,13.414l5.775,5.775a1,1,0,0,0,1.414-1.414L13.414,12l5.775-5.775a1,1,0,0,0-1.414-1.414L12,10.586Z"
				transform="translate(-4.518 -4.518)"
				fill={color ? color : "#3c3c3b"}
			/>
		</svg>
	);
}

export default Cross;
