import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { AuthState } from "../../Store/Actions/AuthActions";
import SignOutLogo from "../svgLogos/HomePageFigures/SignOutLogo";
import "./DropDownModal.css";
class DropDownModal extends Component {
	render() {
		return (
			<Modal
				id="dropdownModal"
				show={this.props.dropDownState}
				onHide={this.props.toggleDropdownMenu}
			>
				<Modal.Body>
					<div className="dropDownMenu">
						<div className="dropDownMenuBar">
							{!this.props.auth.authData ? (
								<div className="guestLinks">
									<Link to="/signin">
										<div className="dropDownItem">Log in</div>
									</Link>
									<Link to="/usertype">
										<div className="dropDownItem">Sign Up</div>
									</Link>
								</div>
							) : (
								<div className="userLinks">
									<Link to="/signin">
										<div
											onClick={() => {
												this.props.AuthState(0);
												this.props.toggleDropdownMenu(false);
											}}
											className="dropDownItem signout"
										>
											<span>
												<SignOutLogo />
											</span>
											LogOut
										</div>
									</Link>
								</div>
							)}
						</div>
					</div>
				</Modal.Body>
			</Modal>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		AuthState: (auth) => dispatch(AuthState(auth)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DropDownModal);
