import React, { Component } from "react";
import AvatarImageCropper from "react-avatar-image-cropper";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { baseURL } from "../../../App";
import DefaultImg from "../../../Assets/DefaultImage.svg";
import UploadImg from "../../../Components/UploadImg/UploadImg";
import { RegActions } from "../../../Store/Actions/RegActions";
import { Alert } from "react-bootstrap";
import GuestNav from "../../../Components/GuestNav/GuestNav";
import { Button, Col, Row } from "antd";
import "./AddPhotoCoach.css";

export class Calender extends Component {
	state = {
		crpdimg: null,
		img: null,
		imgEmptyIndicator: false,
		finished: false,
		uploadingProgress: 0,
		isModalVisible: false,
	};

	apply = (blob) => {
		var file = new File([blob], blob.name);
		this.setState({ img: file }, () => {
			const data = new FormData();
			data.append(
				"image",
				this.state.img,
				Math.floor(Math.random() * 1000000000000000) + this.state.img.name
			);
			axios
				.post(baseURL + "/user/imageUpload", data, {
					onUploadProgress: (data) => {
						//Set the progress value to show the progress bar
						this.setState({
							uploadingProgress: Math.ceil((100 * data.loaded) / data.total),
						});
					},
				})
				.then((res) => {
					this.setState({ finished: true }, () => {
						this.props.RegActions({
							image: res.data.path,
						});
					});
				})
				.catch((err) => console.log(err.data));
		});
	};

	render(props) {
		const maxsize = 1024 * 1024 * 10;
		const actions = [
			<Link key={0} to="/personalinfo">
				<button className="btn back">Back</button>
			</Link>,
			<button onClick={this.apply} key={1} className="btn ">
				Next
			</button>,
		];
		const icon = (
			<svg
				id="Group_4180"
				data-name="Group 4180"
				xmlns="http://www.w3.org/2000/svg"
				width="58.165"
				height="45.78"
				viewBox="0 0 58.165 45.78">
				<g id="Group_4179" data-name="Group 4179">
					<path
						id="Path_5094"
						data-name="Path 5094"
						d="M209.03,172.2a6.253,6.253,0,0,0-4.589-1.935h-9.178v-.111a4.966,4.966,0,0,0-1.493-3.594,5.061,5.061,0,0,0-3.594-1.492H173.589a5.1,5.1,0,0,0-5.142,5.086v.111h-9.123a6.253,6.253,0,0,0-4.589,1.935,6.573,6.573,0,0,0-1.935,4.589v27.535a6.255,6.255,0,0,0,1.935,4.589,6.573,6.573,0,0,0,4.589,1.935h45.116a6.253,6.253,0,0,0,4.589-1.935,6.574,6.574,0,0,0,1.935-4.589V176.786A6.254,6.254,0,0,0,209.03,172.2Zm-.94,32.124h-.056a3.587,3.587,0,0,1-3.594,3.594H159.324a3.587,3.587,0,0,1-3.594-3.594V176.786a3.587,3.587,0,0,1,3.594-3.594H170a1.487,1.487,0,0,0,1.493-1.493v-1.6a2.081,2.081,0,0,1,2.156-2.156h16.532a2.082,2.082,0,0,1,2.156,2.156v1.6a1.487,1.487,0,0,0,1.492,1.493H204.5a3.587,3.587,0,0,1,3.594,3.594Z"
						transform="translate(-152.8 -165.065)"
						fill="#CFA79D"></path>
					<path
						id="Path_5095"
						data-name="Path 5095"
						d="M197.063,188.608a13.592,13.592,0,1,0,9.62,3.981A13.626,13.626,0,0,0,197.063,188.608Zm7.52,21.176a10.679,10.679,0,0,1-15.039,0,10.571,10.571,0,0,1-3.1-7.52,10.8,10.8,0,0,1,3.1-7.519,10.57,10.57,0,0,1,7.52-3.1,10.8,10.8,0,0,1,7.52,3.1,10.569,10.569,0,0,1,3.1,7.519A10.346,10.346,0,0,1,204.583,209.784Z"
						transform="translate(-167.981 -176.721)"
						fill="#CFA79D"></path>
					<circle
						id="Ellipse_41"
						data-name="Ellipse 41"
						cx="2.709"
						cy="2.709"
						r="2.709"
						transform="translate(46.057 11.832)"
						fill="#CFA79D"></circle>
				</g>
			</svg>
		);
		const emptyIcon = <svg></svg>;
		let rootStyle = {
			position: "static",
			top: "100px",
		};
		let errorStyle = { position: "absolute", top: "229px", left: "10px" };
		if (this.state.finished) return <Redirect to="/professionalinfo" />;
		if (!this.props.Reg.email) return <Redirect to="/personalInfo" />;
		return (
			<div className="addPhoto authPage">
				<GuestNav progress={(100 / 6) * 3} total={6} />
				<Row className="flex-1">
					<Col xs={24} md={24} className="d-flex align-items-center">
						<div className="photoUploaderContainer authForm fullScreen">
							{this.state.isModalVisible && (
								<UploadImg
									isModalVisible={this.state.isModalVisible}
									onCancel={() => {
										this.setState({
											isModalVisible: !this.state.isModalVisible,
										});
									}}
									parent={"addImg"}
									onUploadImg={() => {
										this.setState({ imgEmptyIndicator: false });
									}}
								/>
							)}
							<div
								className={
									this.state.imgEmptyIndicator
										? "photoUploader error"
										: "photoUploader"
								}
								style={{
									cursor: "pointer",
								}}
								onClick={() => {
									this.setState({ isModalVisible: true });
								}}>
								{this.props.Reg.image ? (
									<img
										src={baseURL + "/" + this.props.Reg.image}
										alt="defaultImg"
										style={{ width: "100%", height: "100%" }}
									/>
								) : (
									<div
										className="d-flex flex-1 justify-content-center align-items-center"
										style={{ height: "100%" }}>
										<img src={DefaultImg} alt="defaultImg" />
									</div>
								)}
								<span className="d-none">
									<AvatarImageCropper
										actions={actions}
										icon={this.props.Reg.image ? emptyIcon : icon}
										text=" "
										errorStyle={errorStyle}
										rootStyle={rootStyle}
										className="avatarCropper"
										apply={this.apply}
										maxsize={maxsize}
										loadingNode={<div className="loader"></div>}
									/>
								</span>
							</div>
							<div className="coachImgInstrc text-left">
								<div className="fw-700">Photo tips:</div>
								<div>
									<span>1)</span> Head shot.
								</div>
								<div>
									<span>2)</span> High resolution photo taken on a professional
									camera.
								</div>
							</div>
							{this.state.imgEmptyIndicator && (
								<Alert variant={"danger"} className="text-center">
									Please Upload An Image
								</Alert>
							)}
							{/* <Link className="KeyPage" key={0} to="personalinfo">
								<button className="btn back">Back</button>
							</Link>

							<span className="KeyPage" key={1}>
								<button
									onClick={
										this.props.Reg.image
											? () => {
													this.setState({ finished: true });
											  }
											: () => {
													this.setState({ imgEmptyIndicator: true });
											  }
									}
									className="btn next"
								>
									Next
								</button>
							</span> */}

							<div className="KeyPage">
								<Link key={0} to={"/personalInfo"}>
									<Button
										className="secondary-btn"
										onClick={() => this.setState({ backState: true })}>
										Back
									</Button>
								</Link>
								<Button
									className="primary-btn"
									onClick={
										this.props.Reg.image
											? () => {
													this.setState({ finished: true });
											  }
											: () => {
													this.setState({ imgEmptyIndicator: true });
											  }
									}>
									Next
								</Button>
							</div>
						</div>
					</Col>
					<Col xs={24} md={12} className={`authSolidSection d-none`}></Col>
				</Row>
			</div>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		Reg: state.reg,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		RegActions: (reg) => dispatch(RegActions(reg)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(Calender);
