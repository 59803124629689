import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../../Assets/Logo.svg";
import LogoLg from "../../Assets/Logo.svg";
import Close from "../../Components/svgLogos/Cross";
import "./TermsAndConditions.css";
function TermsForGuru() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [])
	return (
		<div className="termsAndCond mb-4">
			<Link to="/">
				<div className="logo sm">
					<img src={Logo} alt="Logo" />
				</div>
				<div className="logo lg">
					<img src={LogoLg} alt="LogoLg" />
				</div>
			</Link>
			<Container className="terms">
				<div className="d-flex justify-content-between">
					<h6 className="main-title my-4">
						<strong>TERMS & CONDITIONS </strong>(between the Professional and the Platform)
					</h6>
					<Link to="/" className="closeBtn">
						<Close />
					</Link>
				</div>
				<h5 className="my-4">INTRODUCTION</h5>
				<div className="my-2">
					These terms and conditions (<strong>“Terms”</strong> and/or <strong>“Agreement”</strong>) shall serve as the full binding agreement concluded between Welo (also referred to as <strong>“Platform”</strong>) and the service provider (which shall be referred to as <strong>“Professional”</strong> and/or <strong>“Guru”</strong>). The Agreement shall come into effect on the date it is signed by the Professional. By signing these terms and conditions, the Professional consents to their content and hereby creates a binding legal document between the Platform and the Professional on the date and time it they are signed.
				</div>
				<div className="my-2 text-center">
					<strong>Whereas</strong>
				</div>
				<div className="my-2">
					1.	Welo is an online platform to facilitate the conduct of online sessions between the clients/end-users of the Platform (“Client”) and the Professional.
				</div>
				<div className="my-2">2.	The Professional, the latter being an independent entity from the Platform, intends to provide online sessions to Client through Welo.
				</div>
				<div className="my-2">	NOW, THEREFORE, FOR AND IN CONSIDERATION of the agreements and undertakings contained herein,  the Professional agrees to provide their services through the Platform, for the benefit of Client, under the terms and conditions hereby agreed upon by the parties:
				</div>

				<h5 className="my-2">
					SECTION  1  -  SCOPE OF WORK
				</h5>
				<div className="my-4">
					<strong>	1.1		Term - </strong>The term of this Agreement shall commence on the date these Terms are signed. The duration of this contract is 1 year, unless terminated earlier in accordance with Section 4.</div>
				<div className="my-4">
					<strong>1.2	Duties -</strong>
					The Professional agrees to provide services to the Client through Welo in accordance with the terms and conditions set forth in this      Agreement and agrees to devote all necessary time and attention to the performance of the duties specified in this Agreement. The Professional’s duties shall be as follows:
				</div>
				<ul className="m-4">
					<li className="my-2">
						Provide sessions/services to the Client and prepare all required material in advance;
					</li>
					<li className="my-2">
						Attend all staff meetings as scheduled by Welo or any competent authority as a requirement;
					</li>
					<li className="my-2">
						Attend all workshop trainings as scheduled by Welo and/or any competent authority as a requirement;
					</li>
					<li className="my-2">
						The Professional further agrees that in all such aspects of their work, the Professional shall comply with the policies, standards, regulations of Welo and shall perform the duties assigned professionally, faithfully, intelligently, to the best of his/her ability, and in the best interest of the Client.
					</li>
				</ul>

				<h5 className="my-4">
					SECTION  2  -  CONFIDENTIALITY
				</h5>
				<div className="my-2">
					<strong>2.1	Confidentiality-</strong>
					The Professional acknowledges and agrees that all records, including but not limited to medical and psychological reports, data and information related to the Client <strong>(“Confidential Information”)</strong> are the exclusive property of Welo. The Professional shall not, during the term of this Agreement or after the termination of this Agreement, disclose any Confidential Information to any person or use any Confidential Information for the benefit of the Professional or any other person, except after                    obtaining the prior written consent of both the Client (concerned by the collected data) and Welo. Notwithstanding this clause, the Professional shall maintain patient confidentiality (if applicable) at all times during the Term of this Agreement.
				</div>

				<div className="my-2">
					<strong>2.2	Return of Documents or Media - 	</strong>The Professional acknowledges and agrees that all originals and copies of records, reports, documents, lists, plans, memoranda, notes and other documentation, voice recordings, photographs other media related to the Client or containing any Confidential Information shall be the sole and exclusive property of the Welo and shall be returned to Welo upon the termination of this Agreement. The Professional is also required to submit the requested reports and data if requested by the Client and only after obtaining approval in writing from Welo.
				</div>
				<div className="my-2">
					<strong>2.3 No Release -</strong> The Professional agrees that the termination of this Agreement shall not release the Professional from any obligations under Section 2.1 or 2.2
				</div>
				<h5 className="my-4">
					SECTION  3  -  COMPENSATION
				</h5>
				<div className="my-2">
					<strong>3.1 Compensation - </strong>In consideration of services to be rendered by the Professional to the Client, the Platform shall collect the fees paid by the Client on the Professional’s behalf. The Platform shall then retain a fee consisting of an amount equivalent to 15% of the amount collected from the Client, the latter being the Platform’s fee in exchange for facilitating the service provision between the Professional and the Client. Welo shall then reimburse the remainder to the Professional, the latter being 85% of the amount collected from the Client.
				</div>
				<div className="my-2">
					<strong>3.2 Cancellation of Sessions by Client-</strong> In the event the sessions have been cancelled by the Client less than 12 hours before the scheduled session, a cancellation shall be applied by Welo (“Cancellation Fee”). Welo shall be entitled to retain 15% of the Cancellation Fee. Welo shall then reimburse the remainder to the Professional, the latter being 85% of the Cancellation Fee. Should any cancellation occur by the Client within a period of more than 12 hours before the scheduled session or should the cancellation occur from the Professional’s end, the Client shall get a full refund and the Professional shall receive no payment whatsoever.
				</div>
				<h5 className="my-4">SECTION  4  -  TERMINATION</h5>
				<div className="my-2">
					<strong>Termination for convenience.</strong> Both Parties may terminate this Agreement for convenience upon giving a thirty-day written notice to the other party, unless both parties agree on a different notice period time.
				</div>
				<h5 className="my-4">SECTION  5 -  REPRESENTATIONS  AND  WARRANTIES  OF  THE PROFESIONNAL</h5>
				<div className="my-2">
					<strong>5.1</strong> The Professional represents and warrants to Welo that there is no employment contract or other contractual obligation to which the Professional is subject to, which prevents the Professional from entering into this Agreement or from performing fully the Professional’s duties under this Agreement.			</div>
				<div className="my-2">
					<strong>5.2</strong>	The Professional represents and warrants to Welo he/she has not been charged or convicted of a misdemeanor or felony.
				</div>
				<div className="my-2">
					<strong>5.3</strong>	The Professional warrants and represents that he/she obtained professional certifications (should their area of expertise require so) to practice the profession, along with training programs in providing professional services and is eligible to work in the region where the service is provided.
				</div>
				<div className="my-2">
					<strong>5.4</strong>	The Professional understands, acknowledges and agrees that that Welo is merely an online platform, acting purely as a medium between the Professional and the Client. As such, Welo assumes no responsibility whatsoever to any acts and/or omissions arising out of or in relation to the sessions between the Professional and the Client. The Professional also undertakes and acknowledges that the relationship between the Professional and the Client is direct, and that Welo assumes no liability whatsoever in this regard.
				</div>
				<div className="my-2">
					<strong>5.5	</strong> The Professional understands, acknowledges and agrees that there is no employment relationship whatsoever between the Professional and Welo and that the Professional is providing their services as an independent entity on a free-lance basis
				</div>


				<h5 className="my-4">SECTION  6 – RELEASE OF  LIABILITY</h5>
				<div className="my-2">
					The Professional releases Welo from all claims for damages, death or injury to persons or property including without   limitation all consequential damages, from any cause whatsoever arising from or connected with the Professional’s service hereunder, whether not resulting from the negligence of the Welo, their agents, contractors or employees.
				</div>
				<h5 className="my-4">SECTION  7 -  MISCELLANEOUS  PROVISIONS</h5>
				<div className="my-2">
					<strong>7.1</strong>No waiver of any provision of this Agreement shall be deemed, or shall constitute, a waiver of any other                              provision, whether or not similar, nor shall any waiver constitute a continuing waiver. No waiver shall be binding unless executed in writing by the party making the waiver.
				</div>
				<div className="my-2">
					<strong>7.2</strong> This Agreement constitutes the entire agreement between the parties pertaining to its subject matter and it supersedes all prior contemporaneous agreements, representations and understandings of the parties. No supplement, modification or amendment of this Agreement shall be binding unless executed in writing by all parties.
				</div>
				<h5 className="my-4">SECTION  8  -  JURISDICTION</h5>
				<div className="my-2">
					This Agreement shall be governed by and shall be construed in accordance with the laws of United Arab Emirates and shall be subject to the exclusive jurisdiction of Dubai Courts (with the exception of DIFC Courts).
				</div>{" "}
				<h5 className="my-4">SECTION 9- ACCEPTANCE OF THESE TERMS</h5>

				<div className="my-2">
					The Professional acknowledges to have read these Terms and that they agree to their content in full. By using Welo as a platform, the Professional agrees to be bound by this Agreement as it constitutes a binding legal document, valid and enforceable on both parties. If the Professional does not agree to abide by the terms of this Agreement, they shall not be authorized to use or access the website and its services.
				</div>{" "}
				<h5 className="my-4">SECTION 10- CONTACT INFORMATION</h5>

				<div className="my-2">
					For any access, correction, registering of a complaint, or for simply more information please contact us:
				</div>{" "}

				<div className="my-4">
					Email: tee@welo.app
				</div>
			</Container>
		</div>
	);
}

export default TermsForGuru;
