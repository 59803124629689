import React from "react";
function RightSign() {
	return (
		<svg
			id="Atoms_Selectors_Selected"
			data-name="Atoms/ Selectors/ Selected"
			xmlns="http://www.w3.org/2000/svg"
			width="18"
			height="18"
			viewBox="0 0 18 18"
		>
			<circle id="Oval" cx="9" cy="9" r="9" fill="#fff" />
			<path
				id="Tick_Right"
				data-name="Tick Right"
				d="M6.37.194a.662.662,0,0,1,.936.936L3.336,5.1a.662.662,0,0,1-.936,0L.194,2.894a.662.662,0,1,1,.936-.936L2.868,3.7Z"
				transform="translate(5.25 6.375)"
				fill="#2dcb8a"
			/>
		</svg>
	);
}

export default RightSign;
