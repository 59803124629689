import React from "react";
import LargeEventCard from "./LargeEventCard/LargeEventCard";
import SmallEventCard from "./SmallEventCard/SmallEventCard";

function EventCard({ isSmallScreen, eventData, extraCardDetails }) {
  return (
    <>
      {isSmallScreen ? (
        <SmallEventCard
          eventData={eventData}
          extraCardDetails={extraCardDetails}
        />
      ) : (
        <LargeEventCard
          eventData={eventData}
          extraCardDetails={extraCardDetails}
        />
      )}
    </>
  );
}

export default EventCard;
