import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../../App";
import Cross from "../../../Assets/PersonalInfoIconCross.png";
import GuestNav from "../../../Components/GuestNav/GuestNav";
import { Button, Col, Row } from "antd";
import { connect } from "react-redux";
import { RegActions } from "../../../Store/Actions/RegActions";
import { Alert } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "./ProfessionalInfo.css";

class ProfessionalInfo extends Component {
	state = {
		prefixList: [],
		specializationsList: [],
		subSpecializationsList: [],
		specialization: "",
		prefix: "60fe8952a2740448c74d03e3",
		subSpecializationId: [],
		subSpecializationName: [],
		covidAssesmentBox: false,
		covidAssesment: false,
		bio:
			this.props.Reg.bio && this.props.Reg.bio.length > 0
				? this.props.Reg.bio
				: "",
		submitKey: 0,
		errMsg: "*",
		subSpecializations: [],
		errorStates: [],
	};
	handleChange = (e) => {
		this.setState({ [e.target.id]: e.target.value });
	};
	handleSubSpecialization = (e) => {
		if (this.state.specialization !== "") {
			axios({
				method: "post",
				url: baseURL + "/account/SubCategoryListByCategoryId",
				data: { categoryId: this.state.specialization },
			}).then((res) => {
				this.setState({ subSpecializationsList: res.data });
			});

			axios({
				method: "post",
				url: baseURL + "/account/CheckCategoryCovid",
				data: { categoryId: this.state.specialization },
			}).then((res) => {
				this.setState({ covidAssesmentBox: res.data });
			});
			// if (e.target.value !== this.state.specialization) {
			this.setState({
				subSpecializationName: [],
				subSpecializationId: [],
				covidAssesment: false,
			});
			//}
		}
	};
	handleRemoveSubspecialization = (e) => {
		this.setState({
			subSpecializations: this.state.subSpecializations.filter(
				(word) => word !== e.target.id
			),
		});
	};

	handleAddSubSpecialization = (e) => {
		//Adding subSpecialization id lists
		if (
			!this.state.subSpecializationId.includes(e.target.value) &&
			this.state.subSpecializationId.length < 5
		) {
			this.setState({
				subSpecializationId: [
					...this.state.subSpecializationId,
					e.target.value,
				],
			});
		}
		//Adding subSpecialization name list
		if (
			!this.state.subSpecializationName.includes(
				this.state.subSpecializationsList[e.target.selectedIndex - 1]
					.subCategoryName
			) &&
			this.state.subSpecializationName.length < 5
		) {
			this.setState({
				subSpecializationName: [
					...this.state.subSpecializationName,
					this.state.subSpecializationsList[e.target.selectedIndex - 1]
						.subCategoryName,
				],
			});
		}
	};
	handleRemove = (e) => {
		this.setState({
			subSpecializationName: this.state.subSpecializationName.filter(
				(word) => word !== e.target.id
			),
			subSpecializationId: this.state.subSpecializationId.filter(
				(word) => word !== this.state.subSpecializationId[e.target.name]
			),
		});
	};

	handleSubmit = () => {
		this.setState({ submitKey: 1 }, () => {
			if (
				this.state.specialization !== "" &&
				this.state.bio !== "" &&
				this.state.subSpecializationId.length >= 3 &&
				this.state.subSpecializationName.length >= 3
			) {
				this.setState({ submitKey: 2 });
				this.props.RegActions({
					specialization: this.state.specialization,
					bio: this.state.bio,
					subSpecializationId: this.state.subSpecializationId,
					subSpecializationName: this.state.subSpecializationName,
					prefix: this.state.prefix,
					covidAssesment: this.state.covidAssesment,
					covidAssesmentBox: this.state.covidAssesmentBox,
				});
			} else {
				let errorsVar = {
					specialization:
						this.state.specialization.length === 0 && "Speciality",
					subSpecializationId:
						(this.state.subSpecializationId.length < 3 ||
							this.state.subSpecializationName.length < 3) &&
						"Subspeciality",
					bio: this.state.bio.length === 0 && "Bio",
				};
				errorsVar = Object.values(errorsVar).filter((error) => error !== false);
				this.setState({
					errorSign: true,
					errorStates: errorsVar,
				});
			}
		});
	};
	componentDidMount() {
		axios({
			method: "get",
			url: baseURL + "/account/categoryList",
		}).then((res) => {
			this.setState({ specializationsList: res.data.categories });
		});
		axios({
			method: "get",
			url: baseURL + "/account/prefixList",
		}).then((res) => {
			this.setState({ prefixList: res.data.prefixes }, () => {
				let prefixList = this.state.prefixList;
				this.state.prefixList.forEach((prefix, i) => {
					if (prefix.name.length === 1) {
						prefixList[i].name = "N/A";
					}
				});
				this.setState({ prefixList });

				if (this.props.Reg.prefix) {
					this.setState({ prefix: this.props.Reg.prefix });
				} else {
					this.state.prefixList.forEach((prefix, i) => {
						if (prefix.name === "") {
							this.setState({ prefix: prefix._id });
						}
					});
				}
			});
		});
		this.props.Reg.specialization &&
			this.setState({
				specialization: this.props.Reg.specialization,
				bio: this.props.Reg.bio,
				subSpecializationId: this.props.Reg.subSpecializationId,
				subSpecializationName: this.props.Reg.subSpecializationName,
				prefix: this.props.Reg.prefix,
				covidAssesment: this.props.Reg.covidAssesment,
				covidAssesmentBox: this.props.Reg.covidAssesmentBox,
			});
	}

	render() {
		if (!this.props.Reg.image || this.props.Reg.image.length === 0)
			return <Redirect to="/addphotocoach" />;
		if (this.state.submitKey === 2) return <Redirect to="/docandvid" />;
		return (
			<div className="profInfo authPage">
				<GuestNav progress={(100 / 6) * 4} total={6} />
				<Row className="flex-1">
					<Col xs={24} md={24} className="d-flex align-items-center">
						<div className="profInfoForm authForm fullScreen">
							<div>
								<label className="my-2">Prefix</label>
							</div>
							<select
								onChange={this.handleChange}
								id="prefix"
								value={this.state.prefix}
								className={
									this.state.prefix === "" && this.state.submitKey === 1
										? "form-control required"
										: "form-control"
								}>
								{this.state.prefixList.map((prefixItem) => {
									return (
										<option
											key={prefixItem._id}
											value={prefixItem._id}
											hidden={prefixItem.name === ""}
											selected={prefixItem.name === ""}>
											{prefixItem.name === "" ? "-" : prefixItem.name}
										</option>
									);
								})}
							</select>

							<div>
								<label className="my-2">
									Specialty
									{this.state.specialization.length < 3 &&
										this.state.submitKey === 1 && (
											<span className="errMsg text-danger">
												{this.state.errMsg}
											</span>
										)}
								</label>
							</div>
							<select
								onChange={(e) => {
									this.setState(
										{
											specialization: e.target.value,
											specializationName:
												this.state.specializationsList[
													e.target.selectedIndex - 2
												].categoryName,
										},
										() => {
											this.handleSubSpecialization(e);
										}
									);
								}}
								id="specialization"
								className="categoryBox form-control"
								value={this.state.specialization}>
								<option value="" disabled selected hidden>
									Please Select
								</option>
								<option selected disabled hidden value=""></option>
								{this.state.specializationsList.map((specialization) => {
									return (
										<option key={specialization._id} value={specialization._id}>
											{specialization.categoryName}
										</option>
									);
								})}
							</select>

							<div>
								<label className="my-2">
									Subspecialty<span>(3-5)</span>
									{this.state.subSpecializationName.length < 3 &&
										this.state.submitKey === 1 && (
											<span className="errMsg text-danger">
												{this.state.errMsg}
											</span>
										)}
								</label>
							</div>
							<select
								onChange={this.handleAddSubSpecialization}
								id="subSpecializationId"
								className={
									this.state.subSpecializationName.length < 3 &&
									this.state.submitKey === 1
										? "form-control required"
										: "form-control"
								}>
								<option
									className="placeholder"
									selected
									disabled
									hidden
									value="">
									Please Select
								</option>
								{this.state.subSpecializationsList.map((subSpecialization) => {
									return (
										<option
											value={subSpecialization._id}
											key={subSpecialization._id}>
											{subSpecialization.subCategoryName}
										</option>
									);
								})}
							</select>
							<div className="subSpecializationContainer">
								{this.state.subSpecializationName.map(
									(subSpecializationName, i) => {
										return (
											<div
												key={subSpecializationName}
												className="subspecialization">
												{subSpecializationName}
												<img
													onClick={this.handleRemove}
													id={subSpecializationName}
													className="Cross"
													name={i}
													src={Cross}
													alt="Cross"
												/>
											</div>
										);
									}
								)}

								{this.state.subSpecializationName.length < 3 &&
									this.state.submitKey === 1 && (
										<div style={{ textAlign: "center", marginTop: "5px" }}>
											<Alert variant="danger">
												<div>Please choose 3-5 subspecialties</div>
											</Alert>
										</div>
									)}
							</div>
							{/* {this.state.covidAssesmentBox && (
						<div className="covidAssesmentBox">
							Covid Assesment
							<span
								onClick={() => {
									this.setState({ covidAssesment: !this.state.covidAssesment });
								}}
							>
								{this.state.covidAssesment ? (
									<OvalFilledCircle />
								) : (
									<OvalEmptyCircle />
								)}
							</span>
						</div>
					)} */}

							<div>
								<label className="my-2">
									Your Bio:{" "}
									{this.state.bio === "" && this.state.submitKey === 1 && (
										<span className="errMsg text-danger">
											{this.state.errMsg}
										</span>
									)}
								</label>

								<div className="f-12 fw-600 text-left mb-2">
									What should your clients know about you?
								</div>
							</div>

							<CKEditor
								editor={ClassicEditor}
								config={{
									toolbar: ["bold", "italic", "numberedList"],
									placeholder: "Keep it friendly ...",
								}}
								//data="<p>Keep it friendly ...</p>"
								data={this.state.bio}
								// className={
								// 	this.state.bio.length === 0 && this.state.submitKey === 1
								// 		? "form-control required textBox"
								// 		: "form-control textBox"
								// }
								className={"required"}
								onReady={(editor) => {
									// You can store the "editor" and use when it is needed.
									console.log("Editor is ready to use!", editor);
								}}
								onChange={(event, editor) => {
									const data = editor.getData();

									this.setState({ bio: data });
								}}
								onBlur={(event, editor) => {
									console.log("Blur.", editor);
								}}
								onFocus={(event, editor) => {
									console.log("Focus.", editor);
								}}
							/>

							{this.state.errorStates.length > 0 && (
								<Alert variant={"danger"} className="text-center my-2">
									Please Fill The
									{this.state.errorStates.map((error, index) => (
										<>
											{" "}
											{error}
											{this.state.errorStates.length - 1 !== index && (
												<span>,</span>
											)}
										</>
									))}{" "}
									Fields
								</Alert>
							)}

							<div className="my-4 btnContainer">
								<div className="text-center">
									<Link to="/addphotocoach">
										<Button className="secondary-btn">Back</Button>
									</Link>
									<Button className="primary-btn" onClick={this.handleSubmit}>
										Next
									</Button>
								</div>
							</div>
						</div>
					</Col>
					<Col xs={24} md={12} className={`authSolidSection d-none`}></Col>
				</Row>
			</div>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		Reg: state.reg,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		RegActions: (reg) => dispatch(RegActions(reg)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfessionalInfo);
