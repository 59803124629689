import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { baseURL } from "../../App";
//import { Player, BigPlayButton } from "video-react";
import "./VideoModal.css";

function VideoModal(props) {
	useEffect(() => {
		const sessionCountdownNode = document.querySelector(".sessionCountdown");
		if (sessionCountdownNode) {
			sessionCountdownNode.classList.add("d-none");
		}
		return () => {
			if (sessionCountdownNode) {
				sessionCountdownNode.classList.remove("d-none");
			}
		};
	}, []);
	return (
		<Modal
			show={props.videoPopup}
			onHide={props.toggleVideoPopup}
			size="lg"
			id="videoPopup"
			aria-labelledby="contained-modal-title-vcenterv"
			centered
		>
			<Modal.Header closeButton>
				<div className="title">
					<div>Guru</div>
					<div>Video</div>
				</div>
			</Modal.Header>
			<Modal.Body>
				<div className="infoContainer">
					<img src={baseURL + "/" + props.image} alt="" className="initials" />

					<div>
						<span className="userInfo">
							{props.prefix + " " + props.firstName + " " + props.lastName}
						</span>
						{props.specialization && (
							<div>{props.specialization[0].categoryName}</div>
						)}
					</div>
				</div>

				<div className="actions">
					{/* <Player
						src={baseURL + "/" + props.video}
						autoPlay={false}
						controls={true}
					>
						<BigPlayButton position="center" />
					</Player> */}
					{/* <Player playsInline src={baseURL + "/" + props.video} /> */}
					<video height="240" controls>
						<source src={baseURL + "/" + props.video} type="video/mp4" />
						<source src={baseURL + "/" + props.video} type="video/ogg" />
						Your browser does not support the video tag.
					</video>
					<div className="text-center">
						{props.hideViewProfile !== true && (
							<span>
								<Link to={"/gurus/" + props.id}>
									<button className="button">View Profile</button>
								</Link>
							</span>
						)}

						<button
							className="bookingBtn"
							onClick={() => {
								if (props.auth.authData?.userType === 1) {
									props.closeAndOpenBooking();
								} else if (props.auth.authData?.userType === 2) {
									props.sameAccountModal();
								} else {
									props.redirectStateSigniN();
								}
							}}
						>
							Book this guru
						</button>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
}
const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	};
};
export default connect(mapStateToProps)(VideoModal);
