import React from "react";
function OvalFilledDot() {
	return (
		<svg
			id="Group_4218"
			data-name="Group 4218"
			xmlns="http://www.w3.org/2000/svg"
			width="22"
			height="22"
			viewBox="0 0 22 22"
		>
			<g id="Oval" fill="none">
				<path d="M11,0A11,11,0,1,1,0,11,11,11,0,0,1,11,0Z" stroke="none" />
				<path
					d="M 11 1 C 5.485979080200195 1 1 5.485979080200195 1 11 C 1 16.51401901245117 5.485979080200195 21 11 21 C 16.51401901245117 21 21 16.51401901245117 21 11 C 21 5.485979080200195 16.51401901245117 1 11 1 M 11 0 C 17.07513046264648 0 22 4.924869537353516 22 11 C 22 17.07513046264648 17.07513046264648 22 11 22 C 4.924869537353516 22 0 17.07513046264648 0 11 C 0 4.924869537353516 4.924869537353516 0 11 0 Z"
					stroke="none"
					fill="#CFA79D"
				/>
			</g>
			<circle
				id="Oval-2"
				data-name="Oval"
				cx="6"
				cy="6"
				r="6"
				transform="translate(5 5)"
				fill="#CFA79D"
			/>
		</svg>
	);
}

export default OvalFilledDot;
