import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseURL } from "../../App";
import { connect } from "react-redux";
import ViewPackages from "./Components/ViewPackages/ViewPackages";
import AddEditPackage from "./Components/AddEditPackage/AddEditPackage";
import "./Packages.css";
import Cross from "../svgLogos/Cross";
function Packages({ auth }) {
	// eslint-disable-next-line
	const [packages, updatePackages] = useState([]);
	const [mode, updateMode] = useState(null);
	const getAllPackages = () => {
		axios({
			method: "get",
			url: baseURL + `/package/coachPackages?coachId=${auth.authData.user._id}`,
		}).then((res) => {
			updatePackages(res.data.data.coachPackages);
		});
	};
	const [selectedPackage, updateSelectedPackage] = useState(null);
	useEffect(() => {
		getAllPackages();
		// eslint-disable-next-line
	}, [auth.authData.user._id]);
	return (
		<div className="packages">
			<div className="d-flex justify-content-between align-items-center">
				<div className="heading">Packages{mode ? " - " + mode : ""}</div>
				{mode ? (
					<div
						className="mx-2 cursorPointer"
						onClick={() => {
							updateMode(null);
						}}>
						<Cross />
					</div>
				) : (
					<div
						style={{ height: "max-content" }}
						className="primary-btn cursorPointer mx-2 py-2"
						onClick={() => {
							updateMode("Add");
						}}>
						Add New Package
					</div>
				)}
			</div>
			{mode ? (
				<AddEditPackage
					getMode={(mode) => {
						updateMode(mode);
						getAllPackages();
					}}
					selectedPackage={selectedPackage}
				/>
			) : (
				<ViewPackages
					getMode={(mode) => {
						updateMode(mode);
					}}
					getAllPackages={getAllPackages}
					getSelectedPackage={(pack) => {
						updateSelectedPackage(pack);
					}}
					packages={packages}
				/>
			)}
		</div>
	);
}
const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	};
};
export default connect(mapStateToProps)(Packages);
