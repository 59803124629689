import React from "react";
import { Form, Input, Button } from "antd";
import axios from "axios";
import { baseURL, exchangeCurrency, exchangeRate } from "../../../../App";
import { connect } from "react-redux";
import "./AddEditPackage.css";
function AddEditPackage({ getMode, selectedPackage, auth }) {
	const { TextArea } = Input;
	const onFinish = (values) => {
		const priceInUSD = values.price / exchangeRate;
		axios({
			method: "post",
			url: baseURL + `/package/coachPackage`,
			data: {
				...values,
				coachId: auth.authData.user._id,
				packageId: selectedPackage?._id,
				price: priceInUSD,
			},
		}).then((res) => {
			getMode(null);
		});
	};

	return (
		<div className="my-2 px-2">
			<Form
				layout={"vertical"}
				name="basic"
				initialValues={{ remember: true, ...selectedPackage }}
				onFinish={onFinish}
				autoComplete="off"
				size="large">
				<Form.Item
					label="Title"
					name="name"
					rules={[
						{ required: true, message: "Please type the package title!" },
					]}>
					<Input />
				</Form.Item>
				<Form.Item
					label="Description"
					name="description"
					rules={[
						{ required: true, message: "Please type the package description!" },
					]}>
					<TextArea style={{ resize: "none", height: "200px" }} />
				</Form.Item>
				<Form.Item
					label="Sessions count"
					name="sessionCount"
					rules={[
						{
							required: true,
							message: "Please type a valid Sessions count!",
							validator: async (rule, value) => {
								const isNumber = /^[1-9]/.test(value);
								if (isNumber) {
									return;
								} else {
									throw new Error();
								}
							},
						},
					]}>
					<Input min="1" type="number" />
				</Form.Item>
				<Form.Item
					label={`Price (${exchangeCurrency})`}
					name="price"
					rules={[
						{
							required: true,
							message: "Please type a valid package price!",
							validator: async (rule, value) => {
								const isNumber = /^[1-9]/.test(value);
								if (isNumber) {
									return;
								} else {
									throw new Error();
								}
							},
						},
					]}>
					<Input min="1" type="number" />
				</Form.Item>
				<Form.Item className="text-center">
					<Button className="primary-btn" type="primary" htmlType="submit">
						Submit
					</Button>
				</Form.Item>
			</Form>
		</div>
	);
}
const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	};
};
export default connect(mapStateToProps)(AddEditPackage);
