import React from "react";

function AccountDiscountsVase() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="173.719"
			height="151.462"
			viewBox="0 0 173.719 151.462"
		>
			<g
				id="Group_4148"
				data-name="Group 4148"
				transform="translate(-545.88 -228.906)"
			>
				<path
					id="Path_5080"
					data-name="Path 5080"
					d="M631.257,304.386l6.017-32.156H598.14l6.018,32.156c-5.781,7.852-9.54,20.595-9.54,34.981a69.978,69.978,0,0,0,4.918,26.658h36.342a69.98,69.98,0,0,0,4.918-26.658C640.8,324.981,637.037,312.238,631.257,304.386Z"
					transform="translate(15.032 13.362)"
				/>
				<g
					id="Group_4141"
					data-name="Group 4141"
					transform="translate(617.644 228.906)"
				>
					<g id="Group_4140" data-name="Group 4140" transform="translate(0)">
						<rect
							id="Rectangle_2160"
							data-name="Rectangle 2160"
							width="10.7"
							height="28.684"
							rx="4.089"
							transform="translate(9.746)"
							fill="#CFA79D"
						/>
						<rect
							id="Rectangle_2161"
							data-name="Rectangle 2161"
							width="10.7"
							height="28.684"
							rx="4.089"
							transform="matrix(0.5, -0.866, 0.866, 0.5, 0, 11.804)"
							fill="#CFA79D"
						/>
						<rect
							id="Rectangle_2162"
							data-name="Rectangle 2162"
							width="10.7"
							height="28.684"
							rx="4.089"
							transform="matrix(-0.5, -0.866, 0.866, -0.5, 5.35, 26.146)"
							fill="#CFA79D"
						/>
					</g>
					<rect
						id="Rectangle_2163"
						data-name="Rectangle 2163"
						width="7.278"
						height="7.278"
						rx="1.254"
						transform="translate(9.949 14.342) rotate(-45)"
						fill="#fff"
					/>
				</g>
				<g
					id="Group_4146"
					data-name="Group 4146"
					transform="translate(597.665 254.766)"
				>
					<g id="Group_4143" data-name="Group 4143" transform="translate(0)">
						<g id="Group_4142" data-name="Group 4142">
							<rect
								id="Rectangle_2164"
								data-name="Rectangle 2164"
								width="9.529"
								height="25.545"
								rx="3.641"
								transform="translate(8.679)"
								fill="#CFA79D"
							/>
							<rect
								id="Rectangle_2165"
								data-name="Rectangle 2165"
								width="9.529"
								height="25.545"
								rx="3.641"
								transform="matrix(0.5, -0.866, 0.866, 0.5, 0, 10.512)"
								fill="#CFA79D"
							/>
							<rect
								id="Rectangle_2166"
								data-name="Rectangle 2166"
								width="9.529"
								height="25.545"
								rx="3.641"
								transform="matrix(-0.5, -0.866, 0.866, -0.5, 4.764, 23.284)"
								fill="#CFA79D"
							/>
						</g>
						<rect
							id="Rectangle_2167"
							data-name="Rectangle 2167"
							width="6.481"
							height="6.481"
							rx="1.116"
							transform="translate(8.86 12.772) rotate(-45)"
							fill="#fff"
						/>
					</g>
					<g
						id="Group_4145"
						data-name="Group 4145"
						transform="translate(43.262)"
					>
						<g id="Group_4144" data-name="Group 4144" transform="translate(0)">
							<rect
								id="Rectangle_2168"
								data-name="Rectangle 2168"
								width="9.529"
								height="25.545"
								rx="3.641"
								transform="translate(8.679)"
								fill="#CFA79D"
							/>
							<rect
								id="Rectangle_2169"
								data-name="Rectangle 2169"
								width="9.529"
								height="25.545"
								rx="3.641"
								transform="translate(0 10.512) rotate(-60)"
								fill="#CFA79D"
							/>
							<rect
								id="Rectangle_2170"
								data-name="Rectangle 2170"
								width="9.529"
								height="25.545"
								rx="3.641"
								transform="translate(4.764 23.284) rotate(-120)"
								fill="#CFA79D"
							/>
						</g>
						<rect
							id="Rectangle_2171"
							data-name="Rectangle 2171"
							width="6.481"
							height="6.481"
							rx="1.116"
							transform="translate(8.86 12.772) rotate(-45)"
							fill="#fff"
						/>
					</g>
				</g>
				<g
					id="Group_4147"
					data-name="Group 4147"
					transform="translate(610.562 266.557)"
				>
					<path
						id="Path_5081"
						data-name="Path 5081"
						d="M609.044,281.567a.982.982,0,0,1-.981-.981,23.579,23.579,0,0,0-12.2-21.041.982.982,0,0,1,.864-1.765,26,26,0,0,1,13.3,22.806A.982.982,0,0,1,609.044,281.567Z"
						transform="translate(-595.315 -257.682)"
					/>
					<path
						id="Path_5082"
						data-name="Path 5082"
						d="M618.952,281.567a.982.982,0,0,1-.981-.981,26,26,0,0,1,13.3-22.806.982.982,0,0,1,.862,1.765,23.579,23.579,0,0,0-12.2,21.041A.982.982,0,0,1,618.952,281.567Z"
						transform="translate(-588.327 -257.682)"
					/>
				</g>
				<path
					id="Path_5083"
					data-name="Path 5083"
					d="M612.5,288.887a.982.982,0,0,1-.981-.981V240.1a.981.981,0,1,1,1.963,0v47.807A.982.982,0,0,1,612.5,288.887Z"
					transform="translate(20.244 3.149)"
				/>
				<path
					id="Path_5084"
					data-name="Path 5084"
					d="M718.617,345.128H546.861a.981.981,0,1,1,0-1.963H718.617a.981.981,0,0,1,0,1.963Z"
					transform="translate(0 35.241)"
				/>
			</g>
		</svg>
	);
}

export default AccountDiscountsVase;
