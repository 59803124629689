import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import { baseURL, exchangeRate } from "../../App";
import { Row, Col, Input } from "antd";
import { Alert } from "react-bootstrap";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { connect } from "react-redux";
import { AuthState } from "../../Store/Actions/AuthActions";
import moment from "moment";
import { RegActions } from "../../Store/Actions/RegActions";
import Lamp from "../../Components/svgLogos/Lamp";
import Logo from "../../Assets/Logo.svg";
import Signin from "../../Components/svgLogos/SignIn";
import WelcomeModule from "../../Components/WelcomeModule/WelcomeModule";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import SigninMessageModal from "./Components/SigninMessageModal/SigninMessageModal";
import GuestNav from "../../Components/GuestNav/GuestNav";
import "./Signin.css";
class SignIn extends Component {
  state = {
    email: "",
    password: "",
    errMsg: null,
    errCode: null,
    togglePassword: false,
    providerReg: false,
    redirectState: null,
    isModalVisible: false,
    counter: {
      state: false,
      count: 59,
    },
    isMessageModalVis: false,
    timeZone: localStorage.getItem("timeZone"),
  };

  changeHandler = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  redirectToHome = () => {
    const { history } = this.props;
    history.push("/");
    window.location.reload();
  };
  submitHandler = (e) => {
    e.preventDefault();
    axios({
      method: "post",
      // url: baseURL + "/user/loginDevTeam",
      url: baseURL + "/user/login",
      data: {
        email: this.state.email.toLowerCase(),
        password: this.state.password,
        timeZone: localStorage.getItem("timeZone"),
      },
    })
      .then((res) => {
        if (typeof res.data === "string") {
          this.setState({
            errMsg: res.data,
          });
        } else {
          this.setState(
            {
              errMsg: null,
            },
            () => {
              this.props.AuthState(
                res.data.userType === 1
                  ? res.data
                  : {
                      user: res.data.user[0],
                      userType: res.data.userType,
                    }
              );
              // this.redirectToHome()
            }
          );
        }
      })
      .catch((err) => {
        if (err.response) {
          this.setState({
            errMsg: err.response.data.message,
            errCode: err.response.data.status,
          });
        }
      });
  };
  componentDidMount() {
    setTimeout(() => {
      if (this.props.location?.state?.welcome) {
        window.history.replaceState({}, document.title);
        this.setState({ isModalVisible: true });
      }
    }, 1000);
    let email = new URLSearchParams(this.props.location.search).get("email");
    let password = new URLSearchParams(this.props.location.search).get(
      "password"
    );
    if (email && password) {
      axios({
        method: "post",
        url: baseURL + "/user/loginOneTime",
        data: {
          email: email.toLowerCase(),
          password: password,
        },
      })
        .then((res) => {
          this.props.AuthState(
            res.data.userType === 1
              ? res.data
              : {
                  user: res.data.user[0],
                  userType: res.data.userType,
                }
          );
        })
        .catch((err) => {
          this.setState({
            errMsg: err.response.data.message,
          });
        });
    }

    if (localStorage.getItem("redirectState")) {
      this.setState(
        {
          redirectState: true,
        },
        () => {
          localStorage.removeItem("redirectState");
        }
      );
    }
    if (this.props.location.state?.message) {
      this.setState({ isMessageModalVis: true });
    }
  }

  render(props) {
    const sessionInfo = this.props.location.state?.sessionInfo;
    const isPackage = this.props.location.state?.isPackage;
    const toggleModal = () => {
      this.setState({ isModalVisible: false });
    };
    const resendVerificationCode = () => {
      this.setState({ counter: { ...this.state.counter, state: true } });
      const timeInterval = setInterval(() => {
        if (this.state.counter.count !== 0) {
          this.setState({
            counter: {
              ...this.state.counter,
              count: this.state.counter.count - 1,
            },
          });
        } else {
          clearInterval(timeInterval);
          this.setState({
            counter: {
              count: 59,
              state: false,
            },
          });
        }
      }, 1000);

      axios({
        method: "post",
        url: baseURL + "/account/ResendVerificationEmail",
        data: {
          email: this.state.email.toLowerCase(),
        },
      }).then((res) => {});
    };
    if (this.props.auth.authData) {
      localStorage.setItem("proceedBook", true);
      return (
        <Redirect
          to={{
            pathname: "/",
            state: {
              sessionInfo,
              isPackage,
              packages: this.props?.location?.state?.packages,
            },
          }}
        />
      );
    }
    return (
      <div className="authPage signPage">
        <GuestNav />
        <Row className="flex-1">
          <Col
            xs={24}
            md={sessionInfo ? 12 : 24}
            className="d-flex align-items-center"
          >
            <form
              onSubmit={this.submitHandler}
              className={`authForm ${!sessionInfo && "fullScreen"} px-2`}
            >
              <Lamp />
              <div className="signInlogo">
                <img src={Logo} alt="Logo" className="mt-3 mb-1" />
              </div>
              <div className="fw-300 f-14"> Sign in to your account</div>
              <Input
                placeholder="Email"
                id="email"
                value={this.state.email}
                onChange={this.changeHandler}
                className="input"
                type="email"
                onKeyDown={(event) => {
                  if (event.keyCode === 13) {
                    const form = event.target.form;
                    const index = Array.prototype.indexOf.call(
                      form,
                      event.target
                    );
                    form.elements[index + 1].focus();
                    event.preventDefault();
                  }
                }}
              />
              <Input.Password
                value={this.state.password}
                id="password"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                placeholder="Password"
                onChange={this.changeHandler}
                className="input"
              />

              <div className="forgetPassword text-right">
                <Link to="/confirmemail" className="mainColor f-14">
                  Forgot your password?
                </Link>
              </div>
              {this.state.errMsg ? (
                <Alert
                  style={{ width: "80%", margin: "auto" }}
                  variant={"danger"}
                >
                  <div>{this.state.errMsg}</div>
                  {this.state.errCode === -4 && (
                    <div>
                      {!this.state.counter.state ? (
                        <div
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={resendVerificationCode}
                        >
                          Resend verification link
                        </div>
                      ) : (
                        <>00:{this.state.counter.count}</>
                      )}
                    </div>
                  )}
                </Alert>
              ) : (
                <></>
              )}
              <button
                className="btn signinButton py-2 my-2 d-flex justify-content-center"
                type="submit"
              >
                <span className="fw-700">Sign in</span>
                <span className="signinIcon mainColor  mx-2">
                  <Signin />
                  <FontAwesomeIcon
                    className="signinIcon"
                    icon={faArrowCircleRight}
                  />
                </span>
              </button>
              {this.state.isMessageModalVis && (
                <SigninMessageModal
                  message={this.props.location.state.message}
                  isModalVisible={this.state.isMessageModalVis}
                  onCancel={() => {
                    this.setState({ isMessageModalVis: false });
                  }}
                />
              )}

              <div className="mt-2">
                Don't have an account?
                <Link
                  to={{
                    pathname: "/personalInfo",
                    state: {
                      sessionInfo,
                    },
                  }}
                  className="fw-700 mainColor create"
                >
                  Create
                </Link>
              </div>
            </form>
          </Col>
          <Col
            xs={24}
            md={12}
            className={`authSolidSection ${!sessionInfo && "d-none"}`}
          >
            {sessionInfo && (
              <div className="guruDetails mx-4">
                <div className="d-flex justify-content-between align-items-center flex-1 guruInfo pb-3">
                  <div className="d-flex align-items-center">
                    <img
                      src={baseURL + "/" + sessionInfo.image}
                      alt="guruImage"
                      className="guruAvatar"
                    />
                    <div className="mx-2">
                      <div className="fw-600">{sessionInfo.fullName}</div>
                      <div className="fw-300 f-14">
                        {sessionInfo.specialization[0].categoryName}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="fw-600">
                      {Math.ceil(exchangeRate * sessionInfo.price)}
                      {sessionInfo.unit}
                    </div>
                    <div className="fw-300 f-14">/hr</div>
                  </div>
                </div>
                {sessionInfo.sessionDateTime && (
                  <div className="mt-2">
                    {moment(sessionInfo.sessionDateTime.startDateTime).format(
                      "DD-MM-YYYY hh:mm a"
                    )}
                  </div>
                )}
              </div>
            )}
          </Col>
        </Row>
        <WelcomeModule
          isModalVisible={this.state.isModalVisible}
          toggleModal={() => toggleModal()}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    AuthState: (auth) => dispatch(AuthState(auth)),
    RegActions: (reg) => dispatch(RegActions(reg)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
