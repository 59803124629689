import React from "react";
// import Logo from "../../Components/svgLogos/Logo";
// import LogoLg from "../../Components/svgLogos/LogoLg";
import TermsForClient from "./TermsForClient";
import TermsForGuru from "./TermsForGuru";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Logo from "../../Assets/Logo.svg";
import LogoLg from "../../Assets/Logo.svg";
import "./TermsAndConditions.css";

function TermsAndConditions(props) {
	// eslint-disable-next-line
	return (
		<div className="termsAndCond">
			<Link to="/">
				<div className="logo sm">
					<img src={Logo} alt="Logo" />
				</div>
				<div className="logo lg">
					<img src={LogoLg} alt="LogoLg" />
				</div>
			</Link>
			<div className="divider"></div>
			{props.Reg.userType === "1" ? <TermsForClient /> : <TermsForGuru />}
			{/* <h3>Terms and Conditions</h3>

			<div style={{ marginLeft: 50, marginRight: 50, marginTop: 25 }}>
				WELCOME TO WELO
				<br />
				PLEASE READ THE FOLLOWING TERMS AND CONDITIONS OF USE CAREFULLY BEFORE
				USING THIS WEBSITE or APP's Welo.
				<br />
				<br />
				All users of this site agree that access to and use of this site are
				subject to the following terms and conditions and other applicable law.
				If you do not agree to these terms and conditions, please do not use
				this site. This agreement was written in English. By using the Welo Site
				You agree to be bound by terms of use set out below. If You do not wish
				to be bound by these terms, You should not use the Welo Site. We may
				change these Terms of use and privacy notice from time to time and so
				you should check these regularly. Your use of the Welo Site will be
				deemed an acceptance of the terms existing at that time.
				<br />
				<br />
				Welo has the right to change the terms and conditions and privacy policy
				terms from time to time. You must periodically review these Terms and
				Conditions and Privacy Policy. We do not need to contact you directly or
				inform you of any changes to our Terms and Conditions and Privacy
				Policy. Terms of Use and Privacy Policy as described above.
				<br />
				<br />
				Also, as part of our commitment, we welcome all your feedback through
				our Contact Us form for any of the policies and conditions listed below.
				<br />
				<br />
				<h2>Privacy and Policy</h2>
				<br />
				<h4>Conditions of Registration</h4>
				<p>
					To access and use Welo site and application you must be at least 18
					years of age (according to the Gregorian calendar) and with a full
					legal capacity. Anyone less than 18 years (according to the Gregorian
					calendar) needs to obtain the consent of his parents or guardians to
					use the Welo site and application, and that by sending the e-mail
					address of the parents or guardian so that we can address them for
					their approval, hence, parents or guardians have to monitor their
					children's activities and be responsible for the actions of their
					children as long as they are under the age of 18 years (according to
					the Gregorian calendar). In case we have been informed or we found
					with our own methods that the user's age is under the legal age to
					create an account on Welo, we will temporarily halt the account till
					we get parental consent, and parents e-mail address will be demanded
					from the user, that according to the requirements of the law
					protecting children's privacy online at any of the sites or
					applications that are directed to children. If you doubt that your
					child takes part in any activities that collect personal information,
					and you did not receive any e-mails to notify you or request for your
					acceptance, please do not hesitate to communicate with us via e-mail.
					<br />
					<br />
					By using Welo, you recognize and warrant that you have the legal right
					and the ability to use the service in accordance with the law of your
					country or the country you exist in. For guided but not limited; the
					minimum legal age to use the service is 18 years for the Arab Republic
					of Egypt as well as Arab countries, and 18 years for China, and 16
					years for The Netherlands, and 14 years for Germany, Australia, South
					Korea, Spain and the United States, and 13 years for the rest of the
					world.
					<br />
					<br />
					Using Welo is at your own risk, and you agree not to post, raise,
					write, distribute, store, find or publish in any way, or cause to be
					published on the site Welo either directly or indirectly through other
					sites any of the followings:
					<br />
					<br />
					1 Doing anything that would impacts on the operation or security of
					the site Welo or causes illogical inconvenience or disables any of the
					other users or our staff.
					<br />
					2 Transfer your account (including page or application you run) to any
					person without obtaining prior written permission from us that allows
					it.
					<br />
					3 Applying any reverse engineering, reverse collect, dismantling, or
					any other activities that would hack the issuers or other formulas or
					computational processors code of the software used in the
					infrastructure and the processes relating to the site or application
					Welo.
					<br />
					4 'Violate other users' privacy, or collecting any data and
					information about other users without their explicit consent, manually
					or by using any software or mechanism (spider) or infiltrating crawler
					or search the site or applying restoration, or uses of any devices or
					other mechanism to penetrate the web site and / or the platform and /
					or restore the index and / or data mining information operations.
					<br />
					5 Provide personal or private information related to a third party,
					for example, but not limited to, family names, address, phone numbers,
					e-mail addresses, and/or credit card numbers.
					<br />
					6 Broadcast viruses, subversive data, or other files that cause
					damage, malfunction or sabotage.
					<br />
					7 Provide any contents or links to contents that exposes us or other
					users to the harm or legal liability at any way. (According to our
					sole judgment).
					<br />
					8 Provide unlawful, libel, slander, or offensive contents to any
					religious or moral group. Contents with hardcore, pornography,
					obscene, indecent, or contains overtones, harassment, threats,
					violation of privacy and publishing rights, violent, sexy,
					sensitivities, fraudulent or unacceptable in any form.
					<br />
					9 Provide content constitutes, or encourages, or provides instructions
					for a foul crime, or breach the rights of any party, or cause in any
					way in legal liability or violate any local laws.
					<br />
					10 Content causes inconvenience or harass to others.
					<br />
					11 Provide contents that are not owned by you personally without a
					legal permission from the legally owner of that contents.
					<br />
					12 Provide a content that impersonates any real or legal person or
					causes in any way in a false claim of dependency to that real or legal
					person, including Welo.
					<br />
					13 Provides undesirable promotional materials, political campaigns,
					ads, contests, raffles, or offers.
					<br />
					<br />
					<br />
					We do not assume any responsibility or acknowledge for any content
					published, uploaded, fostered, distributed, stored, or created by
					users, in any way on the Welo site directly or through a other web
					sites.
					<br />
					<br />
					We do not take any responsibility for any content published, stored or
					uploaded in groups or interactive page (social pages) by you or by any
					third party, or for any loss or damage resulting from it, as we do not
					take responsibility for any errors, vilify, contempt, defamation,
					omissions, lies, pornography, or insults that may come across during
					your use of interactive pages9 social page). Also we do not take any
					responsibility for any statements, representations or content provided
					by users in any public interactive page (social pages). And users must
					inform us immediately to take the necessary procedures according to
					our discretion.
					<br />
					<br />
					If there is an emerging complaints about content that a user has
					published or causes to be publised, submitted, broadcasted,
					distributed, stored, or created, on Welo site directly or through the
					website of third parties, you agree to direct your complaint against
					the user only and not against us.
					<br />
					<br />
					Considering capacity limitations of the servers and devices and the
					multiple use by many people at the same time, you are responsible not
					to use the site Welo in any way that leads to sabotage or exceed the
					capacity of the server hardware of our network or any networks
					connected to any of our servers. As for example to do any act that
					would impose unreasonably disproportionate large load on the available
					infrastructure or bandwidth site or application of Welo.
				</p>
				<h4>Gurus</h4>
				Those who use the platform as specialists in this field as psychiatrists
				or psychologists or mental health professionals, and who have one or all
				of the following; training, qualifications, experience, skills and
				certificates of scientific background and they are sufficient and
				appropriate in their field. When you use any of the specialists'
				services, you have the option to do an assessment graded from 1 to 5,
				and to provide any feedback or notes based on your experience with the
				intended competent.
				<br />
				<br />
				We rely exclusively on promotions that provided to us from psychiatrists
				or psychologists staff specialists on the conditions of their
				employment, as well as on feedback from users that evaluates these
				psychiatrists or psychologists' staff specialists. We do not make any
				test to evaluate these specialists, but we check the backgrounds and
				qualifications information. We urge you to independently verify
				backgrounds and kills or qualifications of any expert before receiving a
				service from him.
				<br />
				<br />
				You have to understand and recognize that none of the specialists is of
				our employee, and / or acts as our agent or our representative, and / or
				follow us in any manner whatsoever. We do not recommend any psychiatrist
				or psychologist; we are only like access connection between you and the
				specialists, and we are not, in any way involved either directly or
				indirectly in any interaction between a psychiatrist and a psychologist
				or any third party and expert. We are not responsible for committing or
				implementation of any applied agreement between you and Psychiatrist or
				Psychologist, including any agreement of remuneration. You alone are
				responsible for any such agreement. If there is any dispute between you
				and any one of the specialists related to transactions made through the
				platform or the website, you are under these conditions exempt us, our
				subsidiaries, our institutions, officials, employees and agents of any
				claims or issues you may have against or related to psychiatrists and
				psychologists staff specialists.
				<br />
				<br />
				<h4>Other Websites</h4>
				We are not be, in any way, liable for any content, advertising, or
				product, located on or available in the other websites, and with the
				observance of laws and which cannot be excluded, we do not offer
				guarantees or commitments or obligations whatever to the following :
				<br />
				<br />
				1 With respect to the quality, accuracy, selling portability and
				suitability of a material, products, and services that available through
				other websites.
				<br />
				2 Materials which are available through websites are not violating the
				intellectual property rights of any person. You agree to indemnify Us,
				and/or any of Our affiliates and Our and their officers, directors and
				employees, immediately on demand, against all claims, liability,
				damages, costs and expenses, including legal fees, arising out of any
				breach of these terms and conditions by You or any other liabilities
				arising out of Your use of WELO.
				<br />
				<br />
				<h4>Intellectual Property</h4>
				Please note that the contents of the site Welo, including but not
				limited to, the source code, software, specifications, pictures, signs,
				and audio files are protected by copyright laws, trademarks and other
				proprietary rights applicable. Based on the foregoing, it is not
				permitted to you to create any derivative work or any other amendments,
				without a prior written consent from Welo. And that you should not make
				any amendment to the copy we are viewing or printed in any way and you
				may not use any of the illustrations, photos, videos or audio sequences
				or any graphics separately from any text. And if you print or download
				any materials from our website (www.Welo.com), that means you breach
				these Terms of Use, and you must in this case, stop immediately and
				destroy any copies of the materials you have.
				<br />
				<br />
				Users of this site are obliged to respect intellectual property rights
				of others, and we may remove materials that appear in our sole
				discretion as infringement of the intellectual property rights of
				others, and if you think that a material you own and is protected by
				copyright is being published or used on the site without permission, in
				this case You have to notify us and provide the following information:
				<br />
				<br />
				1 A detailed description of the material that you claim it is being
				infringed, with sufficient information to enable us to locate it,
				including the URL where the infringed material located.
				<br />
				2 Must provide a statement and documents that prove that this material
				of your own.
				<br />
				3 Your name, mailing address, telephone number and your e-mail.
				<br />
				<br />
				when the user submits any content in the public interactive page owned
				by Welo by e-mail or any other means, Welo will have the right to use
				that content in any way, and the user vows that this article are valid
				for publication and agrees to indemnify Welo on the Internet if any
				third party takes any action against us about the content provided by
				the user.
				<br />
				<br />
				<h4>Responsibility</h4>
				We respect all the rights of the patient where he deserves the best
				possible psychological, according to all internationally accepted
				medical standards and taking into account the provisions of Law No. 71
				for the year 2009 set out in Egypt on psychological patient care.
				<br />
				<br />
				In compliance with the responsibilities prescribed by law and which
				cannot be excluded, we and all of our managers, employees, agents, or
				contractors, do not take any responsibility towards you as a result of
				any losses, damages, responsibilities, claims, and costs (including
				without restriction and without limitation legal costs and fees defense
				or settlement costs) in any form or manner whatsoever either directly,
				or indirectly, or as a result of, arising out of or due to the access to
				the site www.Welo.com by you because of your reliance on the information
				provided through the platform or specialists or content, or other
				websites, or services of others, in any form, whether in contract, or
				damage (including negligence), law, or otherwise. We are not responsible
				for any damage caused by any of the professionals (psychiatrists,
				specialists or psychiatrists) regardless of if we have learned firsthand
				or been briefed our representative commissioner the potential for loss
				or damage.
				<br />
				<br />
				We are committed to actively seeking to provide site Welo with all forms
				of logical care. Except mutatis by law, we assume the responsibility for
				the quality, accuracy and appropriate specific purpose of the service.
				We do not offer any grantees that the material contained in site Welo or
				any of the functions contained in or any of the server hardware will
				work without interruption or delay or will be without errors, free of
				viruses or bugs or work combatable with any other program or other
				material.
				<br />
				<br />
				<h4>Indemnity</h4>
				We reserve the right immediately to terminate Your use of the WELO Site
				if You breach or We have reasonable grounds to believe that You are
				likely to breach these terms and conditions or You otherwise engage in
				conduct which We determine in Our sole discretion to be unacceptable.
				<br />
				<br />
				If any court or regulator decides that any provision of these terms and
				conditions is invalid or otherwise unenforceable, such provisions shall
				be severed and deleted from these terms and conditions and the remainder
				of these terms and conditions shall continue to have full force and
				effect.
				<br />
				<br />
				<h4>General Conditions</h4>
				These terms and conditions and the WELO Site are governed by Arab
				republic of Egypt law. Disputes can be dealt with by the Egyptian
				courts.
				<br />
				<br />
				These terms and conditions apply between us and users. No other person
				is entitled to benefit under these Terms and Conditions.
				<br />
				<br />
				Your Welo Wallet funds have an expiry duration of one year.
				<br />
				<br />
			</div> */}
		</div>
	);
}
const mapStateToProps = (state) => {
	return {
		Reg: state.reg,
	};
};

export default connect(mapStateToProps)(TermsAndConditions);
