import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { baseURL } from "../../App";
import { Alert } from "react-bootstrap";
import "./AccountBookingMessage.css";
import AccountSavingModal from "../AccountSavingModal/AccountSavingModal";
class AccountBookingMessage extends Component {
	state = {
		message: "",
		statusMsg: "",
		editBtn: true,
		loadingState: false,
	};
	onChange = (e) => {
		this.setState({ [e.target.id]: e.target.value });
	};
	handleSubmit = () => {
		this.setState({ loadingState: true });
		axios({
			method: "post",
			url: baseURL + "/coache/AddBookingMessage",
			data: {
				id: this.props.auth.authData.user._id,
				message: this.state.message,
			},
		}).then((res) => {
			this.setState(
				{ statusMsg: res.data.message, editBtn: true, loadingState: false },
				() => {
					// this.props.parentCallback(this.state.editBtn);
					this.props.parentCallback(!this.state.editBtn);
				}
			);
		});
		setTimeout(() => {
			this.setState({ statusMsg: false });
		}, 3000);
	};
	componentDidMount() {
		axios({
			method: "get",
			url:
				baseURL +
				"/coache/GetCoachBookingMessage?id=" +
				this.props.auth.authData.user._id,
		}).then((res) =>
			this.setState({ message: res.data.data[0].bookingMessage })
		);
	}
	render() {
		return (
			<div className="accountBookingMessage">
				<div className="heading">
					Booking Message
					{this.state.editBtn ? (
						<button className="btn" onClick={this.handleSubmit}>
							Save
						</button>
					) : (
						<button
							className="btn"
							onClick={(e) => {
								e.preventDefault();
								this.setState({ editBtn: true }, () => {
									this.props.parentCallback(this.state.editBtn);
								});
							}}
						>
							Edit
						</button>
					)}
				</div>
				<div className="form">
					<div className="label">Make it simple!</div>
					<textarea
						id="message"
						value={this.state.message}
						onChange={this.onChange}
						className="form-control"
						disabled={!this.state.editBtn}
					/>
					<div className="btnContainer">
						{this.state.editBtn ? (
							<button className="btn" onClick={this.handleSubmit}>
								Save
							</button>
						) : (
							<button
								className="btn"
								onClick={(e) => {
									e.preventDefault();
									this.setState({ editBtn: true }, () => {
										this.props.parentCallback(this.state.editBtn);
									});
								}}
							>
								Edit
							</button>
						)}
					</div>
					{this.state.statusMsg && (
						<Alert style={{ textAlign: "center" }} variant="success">
							{this.state.statusMsg}
						</Alert>
					)}
				</div>
				<AccountSavingModal isModalVisible={this.state.loadingState} />
			</div>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	};
};

export default connect(mapStateToProps)(AccountBookingMessage);
