import React from "react";
import VideoChat from "./Pages/Room/VideoChat";
import Signin from "./Pages/Signin/Signin";
import EmailConfirmation from "./Pages/ForgotPassword/EmailConfirmation/EmailConfirmation";
import EnterNewPassword from "./Pages/ForgotPassword/EnterNewPassword/EnterNewPassword";
import UserType from "./Pages/Register/UserType/UserType";
import PersonalInfo from "./Pages/Register/PersonalInfo/PersonalInfo";
import ProfessionalInfo from "./Pages/Register/ProfessionalInfo/ProfessionalInfo";
import DoctorDetails from "./Pages/DoctorDetails/DoctorDetails";
import AddPhoto from "./Pages/Register/AddPhoto/AddPhoto";
import AddPhotoCoach from "./Pages/Register/AddPhotoCoach/AddPhotoCoach";
import DocAndVid from "./Pages/Register/DocAndVid/DocAndVid";
import SessionCalendar from "./Pages/SessionCalendar/SessionCalendar";
// import Purpose from "./Pages/Register/Purpose/Purpose";
import PrcAndAv from "./Pages/Register/PrcAndAv/PrcAndAv";
import HomePage from "./Pages/HomePage/HomePage";
import TermsAndConditions from "./Pages/TermsAndConditions/TermsAndConditions";
import AccountPage from "./Pages/AccountPage/AccountPage";
import Blog from "./Pages/Blog/Blog";
import BlogDetails from "./Pages/Blog/Components/BlogDetails/BlogDetails";
import AddBlog from "./Pages/Blog/Components/AddBlog/AddBlog";
import PostPay from "./Pages/PostPay/PostPay";
import { Redirect } from "react-router-dom";
import Contact from "./Pages/ContactPage/Contact";
import PrivacyPolicy from "./Pages/TermsAndConditions/PrivacyPolicy";
import RefundPolicy from "./Pages/TermsAndConditions/RefundPolicy";
import TermsForGuru from "./Pages/TermsAndConditions/TermsForGuru";
import TermsForClient from "./Pages/TermsAndConditions/TermsForClient";
import Team from "./Components/Team/Team";
import MySessions from "./Pages/MySessions/MySessions";
import { Switch, Route } from "react-router-dom";
import ViewEvents from "./Components/Events/ViewEvents/ViewEvents";
import EventDetails from "./Components/Events/EventDetails/EventDetails";
function Routes({
  auth,
  location,
  getUpcommingSessionHight,
  serverDate,
  updateHomeRerenderState,
}) {
  const isAuth = auth.authData ? true : false;

  return (
    <Switch location={location}>
      <Route
        exact
        path="/"
        render={(props) => (
          <HomePage
            {...props}
            getUpcommingSessionHight={getUpcommingSessionHight}
            timeServer={serverDate}
            rerenderAPIsFromApp={() => {
              updateHomeRerenderState((prevState) => !prevState);
            }}
          />
        )}
      />
      <Route path="/signin" render={(props) => <Signin {...props} />} />
      <Route path="/confirmemail" render={() => <EmailConfirmation />} />
      <Route
        path="/enternewpassword/:id"
        render={(props) => <EnterNewPassword {...props} />}
      />
      <Route path="/usertype" render={() => <UserType />} />
      <Route
        path="/personalinfo"
        render={(props) => <PersonalInfo {...props} />}
      />
      <Route
        path="/room/:token"
        render={(props) => {
          if (isAuth) {
            return <VideoChat {...props} />;
          } else {
            return <Redirect to="/" />;
          }
        }}
      />
      <Route path="/sessioncalendar" render={() => <SessionCalendar />} />
      <Route
        path="/mySessions"
        render={() => {
          if (isAuth) return <MySessions />;
          else {
            return <Redirect to="/" />;
          }
        }}
      />
      <Route path="/addphoto" render={(props) => <AddPhoto {...props} />} />
      {/* <Route path="/purpose" render={() => <Purpose />} /> */}
      <Route path="/professionalinfo" render={() => <ProfessionalInfo />} />
      <Route path="/docandvid" render={() => <DocAndVid />} />
      <Route path="/prcandav" render={() => <PrcAndAv />} />
      <Route path="/addphotocoach" render={() => <AddPhotoCoach />} />
      <Route path="/contact" render={(props) => <Contact {...props} />} />
      <Route
        path="/gurus/:id"
        render={(props) => <DoctorDetails {...props} />}
      />
      <Route path="/account" render={() => <AccountPage />} />
      <Route path="/termsandconditions" render={() => <TermsAndConditions />} />
      <Route
        path="/termsandconditionsforguru"
        render={() => <TermsForGuru />}
      />
      <Route
        path="/termsandconditionsforclient"
        render={() => <TermsForClient />}
      />
      <Route path="/privacyPolicy" render={() => <PrivacyPolicy />} />
      <Route path="/refundPolicy" render={() => <RefundPolicy />} />
      <Route path="/post_pay" render={(props) => <PostPay {...props} />} />
      <Route path="/team" render={(props) => <Team {...props} />} />
      <Route path="/blog/:id" render={(props) => <BlogDetails {...props} />} />
      <Route path="/blog" render={(props) => <Blog {...props} />} />
      <Route path="/addBlog" render={(props) => <AddBlog {...props} />} />
      <Route path="/events" render={(props) => <ViewEvents {...props} />} />
      <Route
        path="/eventdetails"
        render={(props) => <EventDetails {...props} />}
      />
    </Switch>
  );
}

export default Routes;
