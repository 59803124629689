import React from "react";

function ArrowStickRightSm() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="14.416"
			height="9.314"
			viewBox="0 0 14.416 9.314"
		>
			<path
				id="arrow-right"
				d="M13.015,6.343,11.847,7.51l2.707,2.68L3.292,10.2l0,1.646,11.231-.011L11.863,14.5l1.173,1.161,4.671-4.668Z"
				transform="translate(-3.292 -6.343)"
				fill="#d0d0d0"
			/>
		</svg>
	);
}

export default ArrowStickRightSm;
