import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../../Assets/Logo.svg";
import LogoLg from "../../Assets/Logo.svg";
import Close from "../../Components/svgLogos/Cross";

function PrivacyPolicy() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [])
	return (
		<div className="privacypolicy">
			<Link to="/">
				<div className="logo sm">
					<img src={Logo} alt="Logo" />
				</div>
				<div className="logo lg">
					<img src={LogoLg} alt="LogoLg" />
				</div>
			</Link>
			<div className="divider"></div>
			<Container>
				<div className="d-flex justify-content-between">
					<h4 className="main-title my-4">PRIVACY POLICY</h4>
					<Link to="/" className="closeBtn">
						<Close />
					</Link>
				</div>
				<div className="my-2">
					This Privacy Policy describes how your personal information is
					collected, used, and shared when you visit https://welo.app (the
					“Site”).
				</div>
				<div className="my-2">
					Protecting your private information is our priority. This Statement of
					Privacy applies to <a href="https://welo.app">https://welo.app</a> and
					governs data collection and usage. We have developed this policy in
					order for you to understand how we collect, use, communicate and make
					use of personal information. The following outlines our privacy
					policy.
				</div>
				<div className="my-2">
					Similar to other commercial websites, Welo utilizes a standard
					technology called ‘cookies’ (see our cookies policy section for more
					information) and server logs to collect information about how our site
					is used. Information gathered through cookies and server logs may
					include the date and time of visits, the pages viewed, time spent at
					our site, and the websites visited just before and just after our own,
					as well as your IP address.
				</div>
				<h5 className="my-4">INTRODUCTION</h5>
				<div className="my-2">
					This Policy sets out the conditions under which we may process any
					information that we collect from you, or that you provide to us. It
					covers information that could identify you (“personal information”)
					and information that could not. In the context of the law and this
					notice, “process” means collect, store, transfer, use or otherwise act
					on information.
				</div>
				<div className="m-4">
					<div className="my-2">
						1. We take seriously the protection of your privacy and
						confidentiality. We understand that all visitors to Welo are
						entitled to know that their personal data will not be used for any
						purpose unintended by them, and will not accidentally fall into the
						hands of a third party.
					</div>{" "}
					<div className="my-2">
						1. We take seriously the protection of your privacy and
						confidentiality. We understand that all visitors to Welo are
						entitled to know that their personal data will not be used for any
						purpose unintended by them, and will not accidentally fall into the
						hands of a third party.
					</div>{" "}
					<div className="my-2">
						2. Welo undertakes to preserve the confidentiality of all
						information you provide to us, and expect you to reciprocate.
					</div>{" "}
					<div className="my-2">
						3. The law requires us to tell you about your rights and our
						obligations towards you in regards to the processing and control of
						your personal data.
					</div>
				</div>
				<h5 className="my-4">LOG FILES</h5>
				<div className="my-2">
					Welo follows a standard procedure of using log files. These files, log
					visitors when they visit websites. All hosting companies do this and a
					part of hosting services' analytics. The information collected from
					log files includes internet protocol (IP) addresses, browser type,
					Internet Service Provider (ISP), date and time stamp, referring/exit
					pages, and possibly the number of clicks. These are not linked to any
					information that is personally identifiable. The purpose of the
					information is for analyzing trends, administering the site, tracking
					users' movement on the website, and gathering demographic information.
				</div>
				<h5 className="my-4">COLLECTION OF YOUR PERSONAL INFORMATION</h5>
				<div className="my-2">
					Welo may collect personally identifiable information such as your:
					Name, Phone Number and E-mail Address when you quote us for our
					services.
				</div>
				<div className="my-2">
					Welo encourages you to review the privacy statements of websites you
					choose to link to from <a href="https://welo.app">https://welo.app</a>{" "}
					so that you can understand how our website collect, use and share your
					information. We are not responsible for the privacy statements or
					other content on websites outside of the
					<a href="https://welo.app">https://welo.app</a> website.
				</div>
				<h5 className="my-4">WHAT INFORMATION WE COLLECT AND WHY?</h5>
				<div className="my-2">
					We may collect information about you directly from you, as well as
					automatically through your use of our Site or Services.
				</div>
				<div className="m-4">
					<div className="my-2">
						<strong>Information We Collect Directly from You:</strong> Certain
						areas and features of our Site and Services require your Name, email
						address, and IP address.
					</div>
					<div className="my-2">
						<strong>Information We Collect Automatically:</strong> We may
						automatically collect the following information about your use of
						our Site via some software analytics including the length of time
						you visit our Site and your movement through our Site.
					</div>
					<div className="my-2">
						<strong>We may also collect the following information:</strong>{" "}
						device ID; location and language information.
					</div>
				</div>
				<h5 className="my-4">
					WE DO NOT SELL, RENT OR LEASE ITS CUSTOMER LISTS TO THIRD PARTIES.
				</h5>
				<div className="my-2">
					Welo may share data with trusted partners to help perform statistical
					analysis, send you emails or postal mail, provide customer support, or
					arrange for deliveries. All such third parties are prohibited from
					using your personal information except to provide these services to
					Welo, and they are required to maintain the confidentiality of your
					information.
				</div>
				<div className="my-2">
					Welo will disclose your personal information, without notice, only if
					required to do so by law or in the good faith belief that such action
					is necessary to: (a) Conform to the edicts of the law or comply with
					legal process served on Welo or the site; (b) protect and defend the
					rights or property of Welo; And, (c) act under exigent circumstances
					to protect the personal safety of users of our website, or the public.
				</div>
				<h5 className="my-4">OUR ADVERTISING PARTNERS</h5>
				<div className="my-2">
					Some of the advertisers on our site may use cookies and web beacons.
					Our advertising partners are listed below. Each of our advertising
					partners has their own Privacy Policy for their policies on user data.
				</div>
				<h5 className="my-4">
					INFORMATION WE PROCESS BECAUSE WE HAVE A CONTRACTUAL OBLIGATION WITH
					YOU
				</h5>
				<div className="my-2">
					When you use our website, you agree to our terms and conditions, a
					contract is formed between you and us.
				</div>
				<div className="my-2">
					In order to carry out our obligations under that contract we must
					process the information you give us. Some of this information may be
					personal information.
				</div>
				<div className="my-2">We may use it in order to:</div>
				<div className="m-4">
					<div className="my-2">
						1. Verify your identity for security purposes
					</div>
					<div className="my-2">2. Provide you with our services</div>
					<div className="my-2">
						3. Provide you with suggestions and advice on services and how to
						obtain the most from using our website.
					</div>
				</div>
				<div className="my-2">
					Welo may aggregate this information in a general way and use it to
					provide class information, for example to monitor our performance with
					respect to a particular service we provide. If we use it for this
					purpose, you as an individual will not be personally identifiable.
					Welo shall continue to process this information until the contract
					between us ends or is terminated by either party under the terms of
					the contract.
				</div>
				<div className="my-2">
					Except where you have consented to our use of your information for a
					specific purpose, we do not use your information in any way that would
					identify you personally. Welo may aggregate it in a general way and
					use it to provide class information, for example to monitor the
					performance of a particular page on our website.
				</div>
				<div className="my-2">
					You may withdraw your consent at any time by instructing us
					(tee@welo.app) However, if you do so, you may not be able to use our
					website or our services further.
				</div>
				<h4 className="my-2">RETENTION PERIOD FOR PERSONAL DATA</h4>
				<div className="my-2">
					Except as otherwise mentioned in this privacy notice, Welo keeps your
					personal information only for as long as required by us:
				</div>
				<div className="m-4">
					<div className="my-2">
						1. To provide you with the services you have requested;
					</div>
					<div className="my-2">
						2. To comply with other laws, including for the period demanded by
						our tax authorities;
					</div>
					<div className="my-2">3. To support a claim or defense in court.</div>
				</div>
				<h5 className="my-4">COOKIES AND WEB BEACONS</h5>
				<div className="my-2">
					Like any other website, our website - https://welo.app uses 'cookies'.
					These cookies are used to store information including visitors'
					preferences, and the pages on the website that the visitor accessed or
					visited. The information is used to optimize the users' experience by
					customizing our web page content based on visitors' browser type
					and/or other information.
				</div>
				<div className="my-2">
					For more general information on cookies, please read "What Are
					Cookies" from Cookie Consent.
				</div>
				<h5 className="my-4">THIRD PARTY PRIVACY POLICIES</h5>
				<div className="my-2">
					Welo Privacy Policy does not apply to other advertisers or websites.
					Thus, we are advising you to consult the respective Privacy Policies
					of these third-party ad servers for more detailed information. It may
					include their practices and instructions about how to opt-out of
					certain options.
				</div>
				<h5 className="my-4">CHILDREN'S INFORMATION</h5>
				<div className="my-2">
					Another part of our priority is adding protection for children while
					using the internet. We encourage parents and guardians to observe,
					participate in, and/or monitor and guide their online activity.
				</div>
				<div className="my-2">
					Welo does not knowingly collect any Personal Identifiable Information
					from children under the age of 13. If you think that your child
					provided this kind of information on our website, we strongly
					encourage you to contact us immediately and we will do our best
					efforts to promptly remove such information from our records.
				</div>
				<h5 className="my-4">OPT-IN, OPT-OUT & UNSUBSCRIBE</h5>
				<div className="my-2">
					When any user provides their details such as Name, Email, Phone
					number, accept Terms & Conditions, Cookies as well as Privacy Policy,
					they have OPTED-IN.
				</div>
				<div className="my-2">
					We provide users the opportunity to opt-out of receiving
					communications from us by reading the unsubscribe instructions located
					at the bottom of any e-mail they receive from us at any time or email
					us.
				</div>
				<div className="my-2">
					We respect your privacy and give you an opportunity to opt-out of
					receiving announcements in relation to certain information or
					services. Users may opt-out of receiving any or all communications
					from Welo by contacting us here:
				</div>
				<div className="my-2">
					Web page: <a href="https://welo.app">https://welo.app</a>
				</div>
				<div className="my-2">Email: tee@welo.app</div>
				<h5 className="my-4">INTELLECTUAL PROPERTY RIGHTS</h5>
				<div className="my-2">
					All copyrights, trademarks, patents and other intellectual property
					rights in and on our website and all content and software located on
					the site shall remain the sole property of Welo or its licensors. The
					use of our trademarks, content and intellectual property is forbidden
					without the express written consent from us.
				</div>
				<div className="my-2">You must not:</div>
				<div className="my-4">
					<div className="my-2">
						Republish material from our website without prior written consent.
					</div>
					<div className="my-2">Sell or rent material from our website</div>
					<div className="my-2">
						Reproduce, duplicate, create derivatives, copy or otherwise exploit
						material on our website for any purpose.
					</div>
					<div className="my-2">
						Redistribute any content from our website, including onto another
						website.
					</div>
				</div>
				<h5 className="my-4">ACCEPTABLE USE</h5>
				<div className="my-2">
					You agree to use our website only for lawful purposes and in a way
					that does not infringe the rights of, restrict or inhibit anyone
					else’s use and enjoyment of the website. Prohibited behavior includes
					harassing or causing distress or inconvenience to any other user
					within our website. You must not use our website to send unsolicited
					commercial communications. You must not use the content on our website
					for any marketing related purpose without our express written consent.
				</div>
				<h5 className="my-4">THIRD PARTY ANALYTICS</h5>
				<div className="my-2">
					We use automated devices and applications, to evaluate usage of our
					Site and, to the extent permitted, our Services. We also may use other
					analytic means to evaluate our Services. We use these tools to help us
					improve our Services, performance and user experiences. These entities
					may use tracking technologies to perform their services.
				</div>{" "}
				<h5 className="my-4">VERIFICATION OF YOUR INFORMATION</h5>
				<div className="my-2">
					When we receive any request to access, edit or delete personal
					identifiable information, we shall first take reasonable steps to
					verify your identity before granting you access or otherwise taking
					any action. This is important to safeguard your information.
				</div>{" "}
				<h5 className="my-4">ENCRYPTION OF DATA SENT BETWEEN US</h5>
				<div className="my-2">
					Our website uses Secure Sockets Layer (SSL) certificates to verify our
					identity to your browser and to encrypt any data you give us. Whenever
					information is transferred between us, you can check that it is done
					so using SSL by looking for a closed padlock symbol or other trust
					mark in your browser’s URL bar or toolbar.
				</div>{" "}
				<h5 className="my-4">HOW YOU CAN COMPLAIN</h5>
				<div className="my-2">
					If you are not happy with our privacy policy or have any complaints,
					then you should tell us by email. Our e-mail address is tee@welo.app
				</div>{" "}
				<h5 className="my-4">REVIEW/MODIFICATION OF THIS PRIVACY POLICY</h5>
				<div className="my-2">
					Welo may update this privacy notice from time to time as necessary.
					The terms that apply to you are those posted here on our website on
					the day you use our website. We solely advise you to print a copy for
					your records.
				</div>
				<div className="my-2">
					If you have any question regarding our privacy policy, please contact
					us through:
				</div>
				<div className="my-2">E-mail: tee@welo.app</div>
				<h5 className="my-4">COOKIES POLICY</h5>
				<div className="my-2">
					Our website uses cookies. They are placed by the software that
					operates on our servers, and by software operated by third parties
					whose services we use. When you first visit our website, we ask you
					whether you wish for us to use cookies. If you choose not to accept
					them, we shall not use them for your visit except to record that you
					have not consented to their use for any other purpose.
				</div>
				<div className="my-2">
					If you choose not to use cookies or you prevent their use through your
					browser settings, you will not be able to enjoy the use of the full
					functionality of our website.
				</div>
				<h5 className="my-4">We use cookies in the following ways:</h5>
				<div className="my-2">To track how you use our website</div>
				<div className="my-2">
					To record whether you have seen specific messages we display on our
					website
				</div>
				<div className="my-2">To keep you signed in on our site</div>
				<div className="my-2">
					To record your answers to surveys and questionnaires on our site while
					you complete them
				</div>
				<div className="my-2">
					To record the conversation thread during a live chat with our support
					team
				</div>
				<h5 className="my-4">WHY WE USE COOKIES ON OUR WEBSITE</h5>
				<div className="my-2">
					Cookies are pieces of information that a website transfers to an
					individual’s computer hard drive for record keeping purposes. Cookies
					make using our Site easier by, among other things, saving your
					passwords and preferences for you. These cookies are restricted for
					use only on our Site, and do not transfer any personal information to
					any other party.
				</div>
				<div className="my-2">
					Most browsers are initially set up to accept cookies. You can,
					however, reset your browser to refuse all cookies or indicate when a
					cookie is being sent. Please consult the technical information
					relevant to your browser for instructions. If you choose to disable
					your cookies settings or refuse to accept a cookie, some parts of the
					Site may not function properly or may be considerably slower.
				</div>
				<h5 className="my-4">
					PERSONAL IDENTIFIERS FROM YOUR BROWSING ACTIVITY
				</h5>
				<div className="my-2">
					Information about your computer hardware and software may be
					automatically collected by Welo. This information can include: your IP
					address, browser type, domain names, access times and referring
					website addresses. This information is used for the operation of the
					service, to maintain quality of the service, and to provide general
					statistics regarding use of the
					<a href="https://welo.app">https://welo.app</a> website.
				</div>
				<div className="my-2">
					Requests by your web browser to our servers for web pages and other
					content on our website are recorded.
				</div>
				<div className="my-2">
					Our website records information such as your geographical location,
					your Internet service provider and your IP address. We also record
					information about the software you are using to browse our website,
					such as the type of computer or device and the screen resolution.
				</div>
				<div className="my-2">
					Welo uses this information in aggregate to assess the popularity of
					the webpages on our website and how we perform in providing content to
					you.
				</div>
				<h5 className="my-4">USE OF RE-MARKETING</h5>
				<div className="my-2">
					Re-marketing involves placing a cookie on your computer when you
					browse our website in order to be able to serve to you an advert for
					our services when you visit some other website.
				</div>
				<div className="my-2">
					<strong>Welo</strong> may use a third party to provide us with
					re-marketing services from time to time. If so, then if you have
					consented to our use of cookies, you may see advertisements for our
					services on other websites.
				</div>
				<h5 className="my-4">LINK TO OTHER WEBSITES</h5>
				<div className="my-2">
					Our service may contain links to other websites that are not operated
					by us. If you click on a third-party link, you will be directed to
					that third party’s site. We strongly advise you to review the privacy
					policy of every site you visit.
				</div>
				<div className="my-2">
					We have no control over and assume no responsibility for the content,
					privacy policies or practices of any third-party sites or services.
				</div>
				<h5 className="my-4">SESSIONS ARE NOT RECORDED</h5>
				<div className="my-2">
					Any communication with your therapist is not recorded whether video or
					audio, only text messages are saved for you to refer back to at any
					time, however, you have the option to delete specific or all messages
					saved.
				</div>
				<h5 className="my-4">ACCEPTANCE OF THESE TERMS</h5>
				<div className="my-2">
					You acknowledge that you have read this privacy policy and agree to
					all its terms. By using the website or its services you agree to be
					bound by this Agreement. If you do not agree to abide by the terms of
					this agreement, you are not authorized to use or access the website
					and its services.
				</div>
				<h5 className="my-4">CONTACT INFORMATION</h5>
				<div className="my-2">
					If you would like to: access, correct, register a complaint, or simply
					want more information please contact us directly Via:
				</div>
				<div className="my-2">Contact: tee@welo.app</div>
			</Container>
		</div>
	);
}

export default PrivacyPolicy;
