import React, { Component } from "react";
import { Alert } from "react-bootstrap";
import axios from "axios";
import { baseURL } from "../../../App";
import { Link, Redirect } from "react-router-dom";
import SigninEye from "../../../Components/svgLogos/SigninEye";
import GuestNav from "../../../Components/GuestNav/GuestNav";
import "./EnterNewPassword.css";
class EnterNewPassword extends Component {
	state = {
		password: "",
		confirmPassword: "",
		errMsg: "",
		id: this.props.match.params.id,
		successMsg: "",
		redirectState: false,
		loading: false,
		passwordsFieldsStates: { password: false, confirmPassword: false },
	};
	onChange = (e) => {
		this.setState({ [e.target.id]: e.target.value }, () => {
			var reg = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,}$/;
			var test = reg.test(this.state.password);
			if (
				this.state.password.length !== 0 &&
				this.state.confirmPassword.length !== 0
			) {
				if (test) {
					this.setState({ errMsg: "" });
				} else {
					this.setState({ errMsg: "Password is weak" });
				}
			}
		});
	};
	handleSubmit = (e) => {
		e.preventDefault();
		if (
			this.state.password === this.state.confirmPassword &&
			this.state.password &&
			this.state.confirmPassword &&
			!this.state.errMsg
		) {
			this.setState({ loading: true });

			axios({
				method: "POST",
				url: baseURL + "/admin/updateAdminPassword",
				data: {
					id: this.state.id,
					password: this.state.password,
					notAdmin: true,
				},
			})
				.then((res) => {
					this.setState({
						successMsg: res.data.message,
						redirectState: true,
						loading: false,
					});
				})
				.catch((err) => {
					this.setState({ loading: false });
				});
		} else {
			if (this.state.password.length === 0) {
				this.setState({ errMsg: "Please fill the New Password" });
			} else if (this.state.confirmPassword.length === 0) {
				this.setState({ errMsg: "Please fill the confirm new password" });
			} else if (this.state.password !== this.state.confirmPassword)
				this.setState({ errMsg: "Passwords don't match" });
		}
	};

	render(props) {
		if (this.state.redirectState) {
			return <Redirect to="/" />;
		}
		return (
			<div className="enterNewPassword">
				<GuestNav />
				<div className="label">
					Password
					<br />
					Reset
					<div>Please enter a new password</div>
				</div>
				<form onSubmit={(e) => this.handleSubmit(e)}>
					<div className="inputBox">
						<label>New Password</label>
						<div className="inputContainer">
							<input
								type={
									this.state.passwordsFieldsStates.password
										? "text"
										: "password"
								}
								value={this.state.password}
								onChange={this.onChange}
								className="form-control"
								id="password"
							/>
							<span
								onClick={() =>
									this.setState({
										passwordsFieldsStates: {
											...this.state.passwordsFieldsStates,
											password: !this.state.passwordsFieldsStates.password,
										},
									})
								}>
								<SigninEye />
							</span>
						</div>
						<label>Confirm New Password</label>
						<div className="inputContainer">
							<input
								type={
									this.state.passwordsFieldsStates.confirmPassword
										? "text"
										: "password"
								}
								value={this.state.confirmPassword}
								onChange={this.onChange}
								className="form-control"
								id="confirmPassword"
							/>
							<span
								onClick={() =>
									this.setState({
										passwordsFieldsStates: {
											...this.state.passwordsFieldsStates,
											confirmPassword:
												!this.state.passwordsFieldsStates.confirmPassword,
										},
									})
								}>
								<SigninEye />
							</span>
						</div>
						{this.state.errMsg.length > 0 && (
							<Alert className="mt-2 show text-center" variant="danger">
								{this.state.errMsg}
							</Alert>
						)}
						{this.state.successMsg.length > 0 && (
							<Alert className="mt-2 text-center show" variant="success">
								{this.state.successMsg}
							</Alert>
						)}
					</div>
					<div className="btnContainer">
						<button className="btn">
							<Link to="/signin">Close</Link>
						</button>
						<button type="submit" disabled={this.state.loading} className="btn">
							Reset
						</button>
					</div>
				</form>
			</div>
		);
	}
}

export default EnterNewPassword;
