import React from "react";

function PlayIcon({ width, height, color }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width ? width : "8.219"}
			height={height ? height : "11.863"}
			viewBox="0 0 8.219 11.863"
		>
			<path
				id="Path_988"
				data-name="Path 988"
				d="M18.219,13.6,10,19.532V7.67Z"
				transform="translate(-10 -7.67)"
				fill={color ? color : "#CFA79D"}
			/>
		</svg>
	);
}

export default PlayIcon;
